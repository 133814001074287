/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddCalendarioDTOOraInizio } from './addCalendarioDTOOraInizio';

export interface AddCalendarioDTO { 
    ripetiOgni?: number;
    tipoPeriodicita?: AddCalendarioDTO.TipoPeriodicitaEnum;
    giorniSettimana?: Array<AddCalendarioDTO.GiorniSettimanaEnum>;
    giornoMese?: number;
    numeroSettimana?: AddCalendarioDTO.NumeroSettimanaEnum;
    giornoSettimana?: AddCalendarioDTO.GiornoSettimanaEnum;
    dataInizio?: string;
    dataFine?: string;
    oraInizio?: AddCalendarioDTOOraInizio;
    oraFine?: AddCalendarioDTOOraInizio;
}
export namespace AddCalendarioDTO {
    export type TipoPeriodicitaEnum = 'SETTIMANALE' | 'MENSILE';
    export const TipoPeriodicitaEnum = {
        SETTIMANALE: 'SETTIMANALE' as TipoPeriodicitaEnum,
        MENSILE: 'MENSILE' as TipoPeriodicitaEnum
    };
    export type GiorniSettimanaEnum = 'LUN' | 'MAR' | 'MER' | 'GIO' | 'VEN' | 'SAB' | 'DOM';
    export const GiorniSettimanaEnum = {
        LUN: 'LUN' as GiorniSettimanaEnum,
        MAR: 'MAR' as GiorniSettimanaEnum,
        MER: 'MER' as GiorniSettimanaEnum,
        GIO: 'GIO' as GiorniSettimanaEnum,
        VEN: 'VEN' as GiorniSettimanaEnum,
        SAB: 'SAB' as GiorniSettimanaEnum,
        DOM: 'DOM' as GiorniSettimanaEnum
    };
    export type NumeroSettimanaEnum = 'PRIMA' | 'SECONDA' | 'TERZA' | 'QUARTA' | 'ULTIMA';
    export const NumeroSettimanaEnum = {
        PRIMA: 'PRIMA' as NumeroSettimanaEnum,
        SECONDA: 'SECONDA' as NumeroSettimanaEnum,
        TERZA: 'TERZA' as NumeroSettimanaEnum,
        QUARTA: 'QUARTA' as NumeroSettimanaEnum,
        ULTIMA: 'ULTIMA' as NumeroSettimanaEnum
    };
    export type GiornoSettimanaEnum = 'LUN' | 'MAR' | 'MER' | 'GIO' | 'VEN' | 'SAB' | 'DOM';
    export const GiornoSettimanaEnum = {
        LUN: 'LUN' as GiornoSettimanaEnum,
        MAR: 'MAR' as GiornoSettimanaEnum,
        MER: 'MER' as GiornoSettimanaEnum,
        GIO: 'GIO' as GiornoSettimanaEnum,
        VEN: 'VEN' as GiornoSettimanaEnum,
        SAB: 'SAB' as GiornoSettimanaEnum,
        DOM: 'DOM' as GiornoSettimanaEnum
    };
}