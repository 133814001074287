/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddAttrezzaturaDTO } from '../model/addAttrezzaturaDTO';
import { AddClienteDTO } from '../model/addClienteDTO';
import { AddDurataAddebitoDTO } from '../model/addDurataAddebitoDTO';
import { AddOperatoreDTO } from '../model/addOperatoreDTO';
import { AddReferenteDTO } from '../model/addReferenteDTO';
import { AddUnitaLocaleDTO } from '../model/addUnitaLocaleDTO';
import { AnagraficheHomeStats } from '../model/anagraficheHomeStats';
import { AttrezzaturaView } from '../model/attrezzaturaView';
import { AutomezzoView } from '../model/automezzoView';
import { ClienteUnitaLocaleDTO } from '../model/clienteUnitaLocaleDTO';
import { ClienteView } from '../model/clienteView';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { OperatoreView } from '../model/operatoreView';
import { PageAddebitoView } from '../model/pageAddebitoView';
import { PageAttrezzaturaView } from '../model/pageAttrezzaturaView';
import { PageAutomezzoView } from '../model/pageAutomezzoView';
import { PageClienteView } from '../model/pageClienteView';
import { PageNotaInterventoBucketView } from '../model/pageNotaInterventoBucketView';
import { PageOperatoreView } from '../model/pageOperatoreView';
import { PatchAttrezzaturaDTO } from '../model/patchAttrezzaturaDTO';
import { PatchAutomezzoDTO } from '../model/patchAutomezzoDTO';
import { PatchClienteDTO } from '../model/patchClienteDTO';
import { PatchDurataAddebitoDTO } from '../model/patchDurataAddebitoDTO';
import { PatchOperatoreDTO } from '../model/patchOperatoreDTO';
import { PatchReferenteDTO } from '../model/patchReferenteDTO';
import { PatchUnitaLocaleDTO } from '../model/patchUnitaLocaleDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class AnagraficheService {

    protected basePath = 'https://api-dev-gestioneinterventi.southengineering.it/gestione-interventi';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Permette di creare un&#x27;attrezzatura.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addAttrezzatura(body: AddAttrezzaturaDTO, observe?: 'body', reportProgress?: boolean): Observable<AttrezzaturaView>;
    public addAttrezzatura(body: AddAttrezzaturaDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttrezzaturaView>>;
    public addAttrezzatura(body: AddAttrezzaturaDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttrezzaturaView>>;
    public addAttrezzatura(body: AddAttrezzaturaDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addAttrezzatura.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AttrezzaturaView>('put',`${this.basePath}/api/v1/anagrafiche/attrezzature`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di creare un cliente.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addCliente(body: AddClienteDTO, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public addCliente(body: AddClienteDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public addCliente(body: AddClienteDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public addCliente(body: AddClienteDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('put',`${this.basePath}/api/v1/anagrafiche/clienti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiungere una durata per un addebito ad un cliente.
     * 
     * @param body 
     * @param idCliente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addDurataAddebitoCliente(body: AddDurataAddebitoDTO, idCliente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public addDurataAddebitoCliente(body: AddDurataAddebitoDTO, idCliente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public addDurataAddebitoCliente(body: AddDurataAddebitoDTO, idCliente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public addDurataAddebitoCliente(body: AddDurataAddebitoDTO, idCliente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addDurataAddebitoCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling addDurataAddebitoCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('post',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/durata-addebito`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di creare un operatore.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addOperatore(body: AddOperatoreDTO, observe?: 'body', reportProgress?: boolean): Observable<OperatoreView>;
    public addOperatore(body: AddOperatoreDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperatoreView>>;
    public addOperatore(body: AddOperatoreDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperatoreView>>;
    public addOperatore(body: AddOperatoreDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addOperatore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OperatoreView>('put',`${this.basePath}/api/v1/anagrafiche/operatori`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiungere un referente ad un cliente.
     * 
     * @param body 
     * @param idCliente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addReferenteCliente(body: AddReferenteDTO, idCliente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public addReferenteCliente(body: AddReferenteDTO, idCliente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public addReferenteCliente(body: AddReferenteDTO, idCliente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public addReferenteCliente(body: AddReferenteDTO, idCliente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addReferenteCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling addReferenteCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('post',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/referente`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di aggiungere una unità locale ad un cliente.
     * 
     * @param body 
     * @param idCliente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addUnitaLocaleCliente(body: AddUnitaLocaleDTO, idCliente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteUnitaLocaleDTO>;
    public addUnitaLocaleCliente(body: AddUnitaLocaleDTO, idCliente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteUnitaLocaleDTO>>;
    public addUnitaLocaleCliente(body: AddUnitaLocaleDTO, idCliente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteUnitaLocaleDTO>>;
    public addUnitaLocaleCliente(body: AddUnitaLocaleDTO, idCliente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling addUnitaLocaleCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling addUnitaLocaleCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteUnitaLocaleDTO>('post',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/unita_locale`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di rimuovere una durata per un addebito da un cliente.
     * 
     * @param idCliente 
     * @param idDurataAddebito 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteDurataAddebitoCliente(idCliente: string, idDurataAddebito: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public deleteDurataAddebitoCliente(idCliente: string, idDurataAddebito: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public deleteDurataAddebitoCliente(idCliente: string, idDurataAddebito: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public deleteDurataAddebitoCliente(idCliente: string, idDurataAddebito: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling deleteDurataAddebitoCliente.');
        }

        if (idDurataAddebito === null || idDurataAddebito === undefined) {
            throw new Error('Required parameter idDurataAddebito was null or undefined when calling deleteDurataAddebitoCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClienteView>('delete',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/durata-addebito/${encodeURIComponent(String(idDurataAddebito))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di rimuovere un referente di un cliente.
     * 
     * @param idCliente 
     * @param idReferente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteReferenteCliente(idCliente: string, idReferente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public deleteReferenteCliente(idCliente: string, idReferente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public deleteReferenteCliente(idCliente: string, idReferente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public deleteReferenteCliente(idCliente: string, idReferente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling deleteReferenteCliente.');
        }

        if (idReferente === null || idReferente === undefined) {
            throw new Error('Required parameter idReferente was null or undefined when calling deleteReferenteCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClienteView>('delete',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/referente/${encodeURIComponent(String(idReferente))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di rimuovere una unità locale di un cliente.
     * 
     * @param idCliente 
     * @param idUnitaLocale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUnitaLocaleCliente(idCliente: string, idUnitaLocale: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public deleteUnitaLocaleCliente(idCliente: string, idUnitaLocale: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public deleteUnitaLocaleCliente(idCliente: string, idUnitaLocale: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public deleteUnitaLocaleCliente(idCliente: string, idUnitaLocale: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling deleteUnitaLocaleCliente.');
        }

        if (idUnitaLocale === null || idUnitaLocale === undefined) {
            throw new Error('Required parameter idUnitaLocale was null or undefined when calling deleteUnitaLocaleCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClienteView>('delete',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/unita_locale/${encodeURIComponent(String(idUnitaLocale))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista degli addebiti.
     * 
     * @param descrizione 
     * @param isCancellato 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAddebitiForm(descrizione?: string, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageAddebitoView>;
    public getAddebitiForm(descrizione?: string, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAddebitoView>>;
    public getAddebitiForm(descrizione?: string, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAddebitoView>>;
    public getAddebitiForm(descrizione?: string, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (isCancellato !== undefined) {
            formParams = formParams.append('isCancellato', <any>isCancellato) as any || formParams;
        }

        return this.httpClient.request<PageAddebitoView>('post',`${this.basePath}/api/v1/anagrafiche/addebiti`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sulle anagrafiche: operatori, clienti, automezzi
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAnagraficheHomeStats(observe?: 'body', reportProgress?: boolean): Observable<AnagraficheHomeStats>;
    public getAnagraficheHomeStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AnagraficheHomeStats>>;
    public getAnagraficheHomeStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AnagraficheHomeStats>>;
    public getAnagraficheHomeStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<AnagraficheHomeStats>('get',`${this.basePath}/api/v1/anagrafiche/home/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista delle attrezzature.
     * 
     * @param descrizione 
     * @param isCancellata 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAttrezzatureForm(descrizione?: string, isCancellata?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageAttrezzaturaView>;
    public getAttrezzatureForm(descrizione?: string, isCancellata?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAttrezzaturaView>>;
    public getAttrezzatureForm(descrizione?: string, isCancellata?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAttrezzaturaView>>;
    public getAttrezzatureForm(descrizione?: string, isCancellata?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (isCancellata !== undefined) {
            formParams = formParams.append('isCancellata', <any>isCancellata) as any || formParams;
        }

        return this.httpClient.request<PageAttrezzaturaView>('post',`${this.basePath}/api/v1/anagrafiche/attrezzature`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista degli automezzi.
     * 
     * @param testo 
     * @param targa 
     * @param descrizione 
     * @param riferimento 
     * @param stato 
     * @param rimorchio 
     * @param isCancellato 
     * @param isGuidabileDaOperatore 
     * @param nascosto 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAutomezziForm(testo?: string, targa?: string, descrizione?: string, riferimento?: string, stato?: string, rimorchio?: boolean, isCancellato?: boolean, isGuidabileDaOperatore?: boolean, nascosto?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageAutomezzoView>;
    public getAutomezziForm(testo?: string, targa?: string, descrizione?: string, riferimento?: string, stato?: string, rimorchio?: boolean, isCancellato?: boolean, isGuidabileDaOperatore?: boolean, nascosto?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageAutomezzoView>>;
    public getAutomezziForm(testo?: string, targa?: string, descrizione?: string, riferimento?: string, stato?: string, rimorchio?: boolean, isCancellato?: boolean, isGuidabileDaOperatore?: boolean, nascosto?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageAutomezzoView>>;
    public getAutomezziForm(testo?: string, targa?: string, descrizione?: string, riferimento?: string, stato?: string, rimorchio?: boolean, isCancellato?: boolean, isGuidabileDaOperatore?: boolean, nascosto?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {













        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (testo !== undefined) {
            formParams = formParams.append('testo', <any>testo) as any || formParams;
        }
        if (targa !== undefined) {
            formParams = formParams.append('targa', <any>targa) as any || formParams;
        }
        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (riferimento !== undefined) {
            formParams = formParams.append('riferimento', <any>riferimento) as any || formParams;
        }
        if (stato !== undefined) {
            formParams = formParams.append('stato', <any>stato) as any || formParams;
        }
        if (rimorchio !== undefined) {
            formParams = formParams.append('rimorchio', <any>rimorchio) as any || formParams;
        }
        if (isCancellato !== undefined) {
            formParams = formParams.append('isCancellato', <any>isCancellato) as any || formParams;
        }
        if (isGuidabileDaOperatore !== undefined) {
            formParams = formParams.append('isGuidabileDaOperatore', <any>isGuidabileDaOperatore) as any || formParams;
        }
        if (nascosto !== undefined) {
            formParams = formParams.append('nascosto', <any>nascosto) as any || formParams;
        }

        return this.httpClient.request<PageAutomezzoView>('post',`${this.basePath}/api/v1/anagrafiche/automezzi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere i dettagli di un cliente a partire dal suo id
     * 
     * @param idCliente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getCliente(idCliente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public getCliente(idCliente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public getCliente(idCliente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public getCliente(idCliente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling getCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ClienteView>('get',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista dei clienti.
     * 
     * @param descrizione 
     * @param isBloccato 
     * @param isCancellato 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getClientiForm(descrizione?: string, isBloccato?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageClienteView>;
    public getClientiForm(descrizione?: string, isBloccato?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageClienteView>>;
    public getClientiForm(descrizione?: string, isBloccato?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageClienteView>>;
    public getClientiForm(descrizione?: string, isBloccato?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {







        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }
        if (isBloccato !== undefined) {
            formParams = formParams.append('isBloccato', <any>isBloccato) as any || formParams;
        }
        if (isCancellato !== undefined) {
            formParams = formParams.append('isCancellato', <any>isCancellato) as any || formParams;
        }

        return this.httpClient.request<PageClienteView>('post',`${this.basePath}/api/v1/anagrafiche/clienti`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista delle note intervento.
     * 
     * @param descrizione 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getNoteInterventoForm(descrizione?: string, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageNotaInterventoBucketView>;
    public getNoteInterventoForm(descrizione?: string, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageNotaInterventoBucketView>>;
    public getNoteInterventoForm(descrizione?: string, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageNotaInterventoBucketView>>;
    public getNoteInterventoForm(descrizione?: string, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {





        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (descrizione !== undefined) {
            formParams = formParams.append('descrizione', <any>descrizione) as any || formParams;
        }

        return this.httpClient.request<PageNotaInterventoBucketView>('post',`${this.basePath}/api/v1/anagrafiche/note-intervento`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere la lista degli operatori.
     * 
     * @param nominativo 
     * @param tipologia 
     * @param nascosto 
     * @param isCancellato 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getOperatoriForm(nominativo?: string, tipologia?: string, nascosto?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageOperatoreView>;
    public getOperatoriForm(nominativo?: string, tipologia?: string, nascosto?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageOperatoreView>>;
    public getOperatoriForm(nominativo?: string, tipologia?: string, nascosto?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageOperatoreView>>;
    public getOperatoriForm(nominativo?: string, tipologia?: string, nascosto?: boolean, isCancellato?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {








        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nominativo !== undefined) {
            formParams = formParams.append('nominativo', <any>nominativo) as any || formParams;
        }
        if (tipologia !== undefined) {
            formParams = formParams.append('tipologia', <any>tipologia) as any || formParams;
        }
        if (nascosto !== undefined) {
            formParams = formParams.append('nascosto', <any>nascosto) as any || formParams;
        }
        if (isCancellato !== undefined) {
            formParams = formParams.append('isCancellato', <any>isCancellato) as any || formParams;
        }

        return this.httpClient.request<PageOperatoreView>('post',`${this.basePath}/api/v1/anagrafiche/operatori`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un&#x27;attrezzatura.
     * 
     * @param body 
     * @param idAttrezzatura 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAttrezzatura(body: PatchAttrezzaturaDTO, idAttrezzatura: string, observe?: 'body', reportProgress?: boolean): Observable<AttrezzaturaView>;
    public patchAttrezzatura(body: PatchAttrezzaturaDTO, idAttrezzatura: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AttrezzaturaView>>;
    public patchAttrezzatura(body: PatchAttrezzaturaDTO, idAttrezzatura: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AttrezzaturaView>>;
    public patchAttrezzatura(body: PatchAttrezzaturaDTO, idAttrezzatura: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchAttrezzatura.');
        }

        if (idAttrezzatura === null || idAttrezzatura === undefined) {
            throw new Error('Required parameter idAttrezzatura was null or undefined when calling patchAttrezzatura.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AttrezzaturaView>('patch',`${this.basePath}/api/v1/anagrafiche/attrezzature/${encodeURIComponent(String(idAttrezzatura))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un automezzo.
     * 
     * @param body 
     * @param idAutomezzo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAutomezzo(body: PatchAutomezzoDTO, idAutomezzo: string, observe?: 'body', reportProgress?: boolean): Observable<AutomezzoView>;
    public patchAutomezzo(body: PatchAutomezzoDTO, idAutomezzo: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AutomezzoView>>;
    public patchAutomezzo(body: PatchAutomezzoDTO, idAutomezzo: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AutomezzoView>>;
    public patchAutomezzo(body: PatchAutomezzoDTO, idAutomezzo: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchAutomezzo.');
        }

        if (idAutomezzo === null || idAutomezzo === undefined) {
            throw new Error('Required parameter idAutomezzo was null or undefined when calling patchAutomezzo.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<AutomezzoView>('patch',`${this.basePath}/api/v1/anagrafiche/automezzi/${encodeURIComponent(String(idAutomezzo))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un cliente (se da win waste consente la sola modifica del parametro isBloccato).
     * 
     * @param body 
     * @param idCliente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchCliente(body: PatchClienteDTO, idCliente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public patchCliente(body: PatchClienteDTO, idCliente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public patchCliente(body: PatchClienteDTO, idCliente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public patchCliente(body: PatchClienteDTO, idCliente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling patchCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('patch',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/manuale`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un referente.
     * 
     * @param body 
     * @param idCliente 
     * @param idDurataAddebito 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchDurataAddebitoCliente(body: PatchDurataAddebitoDTO, idCliente: string, idDurataAddebito: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public patchDurataAddebitoCliente(body: PatchDurataAddebitoDTO, idCliente: string, idDurataAddebito: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public patchDurataAddebitoCliente(body: PatchDurataAddebitoDTO, idCliente: string, idDurataAddebito: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public patchDurataAddebitoCliente(body: PatchDurataAddebitoDTO, idCliente: string, idDurataAddebito: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchDurataAddebitoCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling patchDurataAddebitoCliente.');
        }

        if (idDurataAddebito === null || idDurataAddebito === undefined) {
            throw new Error('Required parameter idDurataAddebito was null or undefined when calling patchDurataAddebitoCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('patch',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/durata-addebito/${encodeURIComponent(String(idDurataAddebito))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un operatore (solo se inserito manualmente).
     * 
     * @param body 
     * @param idOperatore 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchOperatore(body: PatchOperatoreDTO, idOperatore: string, observe?: 'body', reportProgress?: boolean): Observable<OperatoreView>;
    public patchOperatore(body: PatchOperatoreDTO, idOperatore: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OperatoreView>>;
    public patchOperatore(body: PatchOperatoreDTO, idOperatore: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OperatoreView>>;
    public patchOperatore(body: PatchOperatoreDTO, idOperatore: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchOperatore.');
        }

        if (idOperatore === null || idOperatore === undefined) {
            throw new Error('Required parameter idOperatore was null or undefined when calling patchOperatore.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<OperatoreView>('patch',`${this.basePath}/api/v1/anagrafiche/operatori/${encodeURIComponent(String(idOperatore))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un referente.
     * 
     * @param body 
     * @param idCliente 
     * @param idReferente 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchReferenteCliente(body: PatchReferenteDTO, idCliente: string, idReferente: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public patchReferenteCliente(body: PatchReferenteDTO, idCliente: string, idReferente: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public patchReferenteCliente(body: PatchReferenteDTO, idCliente: string, idReferente: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public patchReferenteCliente(body: PatchReferenteDTO, idCliente: string, idReferente: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchReferenteCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling patchReferenteCliente.');
        }

        if (idReferente === null || idReferente === undefined) {
            throw new Error('Required parameter idReferente was null or undefined when calling patchReferenteCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('patch',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/referente/${encodeURIComponent(String(idReferente))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare una unita locale inserita manualmente.
     * 
     * @param body 
     * @param idCliente 
     * @param idUnitaLocale 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchUnitaLocaleCliente(body: PatchUnitaLocaleDTO, idCliente: string, idUnitaLocale: string, observe?: 'body', reportProgress?: boolean): Observable<ClienteView>;
    public patchUnitaLocaleCliente(body: PatchUnitaLocaleDTO, idCliente: string, idUnitaLocale: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ClienteView>>;
    public patchUnitaLocaleCliente(body: PatchUnitaLocaleDTO, idCliente: string, idUnitaLocale: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ClienteView>>;
    public patchUnitaLocaleCliente(body: PatchUnitaLocaleDTO, idCliente: string, idUnitaLocale: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchUnitaLocaleCliente.');
        }

        if (idCliente === null || idCliente === undefined) {
            throw new Error('Required parameter idCliente was null or undefined when calling patchUnitaLocaleCliente.');
        }

        if (idUnitaLocale === null || idUnitaLocale === undefined) {
            throw new Error('Required parameter idUnitaLocale was null or undefined when calling patchUnitaLocaleCliente.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ClienteView>('patch',`${this.basePath}/api/v1/anagrafiche/clienti/${encodeURIComponent(String(idCliente))}/unita_locale/${encodeURIComponent(String(idUnitaLocale))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
