/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GrantType } from './grantType';

export interface LoginForm { 
    /**
     * Username dell'utente. Non può essere nullo o vuoto.
     */
    username?: string;
    /**
     * Password dell'utente. Non può essere nullo o vuoto.
     */
    password?: string;
    grant_type?: GrantType;
}