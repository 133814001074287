import {Component, Inject} from '@angular/core';
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {GestioneClienteDialogConfig} from "../../interfaces/generic-components.interface";
import {MatDatepickerInputEvent} from "@angular/material/datepicker";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {DialogWrapComponent} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {MatButtonModule} from "@angular/material/button";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NgClass, NgForOf, NgIf, TitleCasePipe} from "@angular/common";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {
    AddClienteDTO,
    AddebitoView,
    AnagraficheService,
    ClienteView,
    DurataAddebitoView,
    PatchClienteDTO,
    ReferenteView,
    UnitaLocaleView
} from "../../../../api-clients/generated/services";
import {getFormArrayFromFormGroupTyped, getFormControlFromFormGroupTyped} from "../../utils/utils";
import {finalize, take, takeUntil, tap} from "rxjs";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {ManageErrorService, RequestTypesEnum} from "../../../services/manage-error.service";
import {getTestoTipoIntervento} from "../../utils/mask-functions";
import { isEqual } from 'lodash';
import {cellularRegex} from "../../constants/regex";

@Component({
    selector: 'app-crea-cliente-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        GenericFormFieldComponent,
        MatButtonModule,
        MatDividerModule,
        MatIconModule,
        MatTooltipModule,
        NgForOf,
        NgIf,
        NgClass,
        MatCheckboxModule,
        TitleCasePipe,
        ReactiveFormsModule,
        MatProgressSpinnerModule
    ],
    templateUrl: './crea-cliente-modal.component.html',
    styleUrl: './crea-cliente-modal.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class CreaClienteModalComponent extends AbstractDefaultComponent {

    clienteFormGroup: FormGroup<ClienteForm>;
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    valoriOriginaliCliente: any = {};

    constructor(public dialogRef: MatDialogRef<CreaClienteModalComponent>,
                private dialog: MatDialog,
                private fuseConfirmationService: FuseConfirmationService,
                private dialogManagerService: DialogManagerService,
                private anagraficheService: AnagraficheService,
                private manageErrorService: ManageErrorService,
                @Inject(MAT_DIALOG_DATA) public data: GestioneClienteDialogConfig,) {
        super();
        this.setFormGroup(data?.clienteData);
        this.checkIsPrincipale();

        this.valoriOriginaliCliente = this.clienteFormGroup.value;
    }

    setFormGroup(cliente?: ClienteView) {
        this.clienteFormGroup = new FormGroup<ClienteForm>({
            id: new FormControl(cliente?.id),
            ragioneSociale: new FormControl(cliente?.ragioneSociale, [Validators.required]),
            codiceFiscale: new FormControl(cliente?.codiceFiscale),
            partitaIva: new FormControl(cliente?.partitaIva, [Validators.required]),
            telefono: new FormControl(cliente?.telefono, [Validators.required, Validators.pattern(cellularRegex)]),
            unitaLocali: new FormArray(!!cliente?.unitaLocali && cliente?.unitaLocali?.length > 0 ?
                cliente?.unitaLocali.map((item) =>
                    this.createUnitaLocaleForm(item))
                : [this.createUnitaLocaleForm()]),
            referenti: new FormArray(!!cliente?.referenti && cliente?.referenti?.length > 0 ?
                cliente?.referenti?.map((item) => this.createReferenteForm(item)) :
                [this.createReferenteForm()]
            ),
            sedeLegaleIndirizzo: new FormControl(cliente?.indirizzo),
            sedeLegaleFrazione: new FormControl(cliente?.frazione),
            sedeLegaleComune: new FormControl(cliente?.comune),
            sedeLegaleCap: new FormControl(cliente?.cap),
            sedeLegaleProvincia: new FormControl(cliente?.provincia),
            sedeLegaleNumero: new FormControl(cliente?.numero),

            durateAddebiti: new FormArray(!!cliente?.durateAddebiti && cliente?.durateAddebiti.length > 0 ?
                cliente?.durateAddebiti.map((addebitoDurata) =>
                this.createAddebitoDurataLocaleForm(addebitoDurata)) : [this.createAddebitoDurataLocaleForm()]
            ),
        });
        this.disableFieldsIsIsNotManuale(cliente);
    }

    disableFieldsIsIsNotManuale(cliente?: ClienteView) {
        if (!!this.data.isModifica && !!cliente && !cliente.intIsManuale) {
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'ragioneSociale')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'codiceFiscale')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'partitaIva')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'telefono')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleIndirizzo')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleFrazione')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleComune')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleProvincia')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleCap')?.disable();
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'sedeLegaleNumero')?.disable();
        }
    }

    markClienteControlsAsTouched() {
        getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'ragioneSociale')?.markAsTouched()
        getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'codiceFiscale')?.markAsTouched()
        getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'partitaIva')?.markAsTouched()
        getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'telefono')?.markAsTouched()
    }

    checkDatiClientiValidityControls(): boolean {
        return getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'ragioneSociale')?.valid &&
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'codiceFiscale')?.valid &&
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'partitaIva')?.valid &&
            getFormControlFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'telefono')?.valid;
    }

    createUnitaLocaleForm(unitaLocale?: UnitaLocaleView) {
        let myFg = new FormGroup<UnitaLocaleForm>({
            id: new FormControl(unitaLocale?.id),
            indirizzo: new FormControl(unitaLocale?.indirizzo, [Validators.required]),
            numero: new FormControl(unitaLocale?.numero),
            frazione: new FormControl(unitaLocale?.frazione),
            comune: new FormControl(unitaLocale?.comune),
            cap: new FormControl(unitaLocale?.cap),
            provincia: new FormControl(unitaLocale?.provincia),
            isPrincipale: new FormControl(unitaLocale?.isPrincipale),
            isWinWaste: new FormControl(unitaLocale?.isWinWaste)
        })
        return myFg;
    }

    createReferenteForm(referente?: ReferenteView) {
        return new FormGroup<ReferenteForm>({
            id: new FormControl(referente?.id),
            nominativo: new FormControl(referente?.nominativo, [Validators.required]),
            email: new FormControl(referente?.email, [Validators.required, Validators.email]),
            telefono: new FormControl(referente?.telefono, [Validators.required,
                Validators.pattern(cellularRegex)]),
            isPrincipale: new FormControl(referente?.isPrincipale),
        })
    }

    createAddebitoDurataLocaleForm(durataAddebito?: DurataAddebitoView) {
        return new FormGroup<DurataAddebitoForm>({
            id: new FormControl(durataAddebito?.id),
            durata: new FormControl(durataAddebito?.durata),
            addebitoMask: new FormControl(getTestoTipoIntervento(durataAddebito?.addebito)),
            addebito: new FormControl(durataAddebito?.addebito),
        });
    }

    ctrlAddebitoDurataFormArray(): FormArray {
        return this.clienteFormGroup?.get('durateAddebiti') as FormArray
    }

    ctrlAddebitoDurataFormGroup(index: number): FormGroup<DurataAddebitoForm> {
        return this.ctrlAddebitoDurataFormArray().at(index) as FormGroup<DurataAddebitoForm>
    }

    getAddebitoDurataFormControlByPathAndIndex(index: number, path: string): FormControl {
        return this.ctrlAddebitoDurataFormGroup(index)?.get(path) as FormControl;
    }

    addAddebitoDurataToFormArray() {
        this.ctrlAddebitoDurataFormArray().push(this.createAddebitoDurataLocaleForm());
    }

    removeAddebitoDurataFromFormArray(index: number) {
        this.ctrlAddebitoDurataFormArray().removeAt(index);
    }

    ctrlIdCliente(): FormControl {
        return this.clienteFormGroup?.get('id') as FormControl;
    }

    ctrlUnitaLocaliFormArray(): FormArray {
        return this.clienteFormGroup?.get('unitaLocali') as FormArray
    }

    ctrlUnitaLocaleFormGroup(index: number): FormGroup<UnitaLocaleForm> {
        return this.ctrlUnitaLocaliFormArray().at(index) as FormGroup<UnitaLocaleForm>
    }

    getUnitaLocaleFormControlByPathAndIndex(index: number, path: string): FormControl {
        return this.ctrlUnitaLocaleFormGroup(index)?.get(path) as FormControl;
    }

    addUnitaLocaleToFormArray() {
        this.ctrlUnitaLocaliFormArray().push(this.createUnitaLocaleForm());
    }

    removeUnitaLocaleFromFormArray(index: number) {
        this.ctrlUnitaLocaliFormArray().removeAt(index);
    }

    ctrlReferentiFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<ClienteForm>(this.clienteFormGroup, 'referenti');
    }

    ctrlReferenteFormGroup(index: number): FormGroup<ReferenteForm> {
        return this.ctrlReferentiFormArray().at(index) as FormGroup<ReferenteForm>
    }

    getReferenteFormControlByPathAndIndex(index: number, path: string): FormControl {
        return this.ctrlReferenteFormGroup(index)?.get(path) as FormControl;
    }

    addReferenteToFormArray() {
        this.ctrlReferentiFormArray().push(this.createReferenteForm());
    }

    removeReferenteFromFormArray(index: number) {
        this.ctrlReferentiFormArray().removeAt(index);
    }

    getClienteFormControlByPath(path: string): FormControl {
        return this.clienteFormGroup?.get(path) as FormControl;
    }

    event(type: string, event: MatDatepickerInputEvent<Date>, formControl: FormControl) {
        formControl.setValue(event.value);
        formControl.setErrors(null);
        formControl.setValidators([]);
    }

    closeModal($event: void) {
        if(!isEqual(this.clienteFormGroup.value, this.valoriOriginaliCliente)) {
            this.dialogRef.close(true);
        } else {
            this.dialogRef.close();
        }

    }

    mapFormToAddClienteDTO(): AddClienteDTO {
        return {
            ...this.clienteFormGroup?.value,
            indirizzo: this.clienteFormGroup?.value?.sedeLegaleIndirizzo,
            frazione: this.clienteFormGroup?.value?.sedeLegaleFrazione,
            comune: this.clienteFormGroup?.value?.sedeLegaleComune,
            cap: this.clienteFormGroup?.value?.sedeLegaleCap,
            provincia: this.clienteFormGroup?.value?.sedeLegaleProvincia,
            numero: this.clienteFormGroup?.value?.sedeLegaleNumero,
        }


    }

    onConfirm($event: string) {
        console.log('$event', $event);
        if(!this.isThereReferentePrincipale() || !this.isThereUnitaPrincipale()) {
            this.fuseConfirmationService.openSnackBar({
                message: 'Per la creazione del cliente occorre selezione un referente e un\'unità locale principali',
                type: SnackbarTypes.Error,
            });
        } else {
            if (this.clienteFormGroup.valid) {
                let request$ = this.anagraficheService.addCliente(this.mapFormToAddClienteDTO());
                this.fuseConfirmationService.showLoader();
                request$.pipe(
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe(
                    {
                        next: (value: ClienteView) => {
                            this.fuseConfirmationService.openSnackBar({
                                message: 'Cliente salvato con successo',
                                type: SnackbarTypes.Success,
                            });
                            this.dialogRef.close(value);
                        },
                        error: (error) => {
                            this.manageErrorService.manageError(error, RequestTypesEnum.CREAZIONE_CLIENTE)
                        }
                    }
                );
            } else {
                this.clienteFormGroup.markAllAsTouched();
                this.fuseConfirmationService.openSnackBar({
                    message: 'Uno o più valori inseriti non sono validi',
                    type: SnackbarTypes.Error,
                });
            }
        }
    }

    saveDatiCliente() {
        if (this.checkDatiClientiValidityControls()) {
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.patchCliente(
                this.mapClienteFormToPatchClienteManuale(this.clienteFormGroup),
                this.data.clienteData?.id).pipe(
                take(1),
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader()
                )
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Dati cliente salvati con successo',
                            type: SnackbarTypes.Success,
                        });
                    }
                }
            );
        } else {
            this.markClienteControlsAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }


    mapClienteFormToPatchClienteManuale(fg: FormGroup<ClienteForm>): PatchClienteDTO {
        return {
            ragioneSociale: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'ragioneSociale')?.value,
            codiceFiscale: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'codiceFiscale')?.value,
            partitaIva: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'partitaIva')?.value,
            telefono: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'telefono')?.value,
            indirizzo: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleIndirizzo')?.value,
            numero: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleNumero')?.value,
            frazione: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleFrazione')?.value,
            comune: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleComune')?.value,
            cap: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleCap')?.value,
            provincia: getFormControlFromFormGroupTyped<ClienteForm>(fg, 'sedeLegaleProvincia')?.value,
        }
    }

    saveReferente(i: number) {
        if (this.ctrlReferenteFormGroup(i).valid) {
            let request$ = this.anagraficheService.addReferenteCliente(
                this.ctrlReferenteFormGroup(i).value,
                this.ctrlIdCliente().value,
            );

            this.fuseConfirmationService.showLoader();
            request$.pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Referente salvato con successo',
                            type: SnackbarTypes.Success,
                        });
                    }
                }
            );
        } else {
            this.ctrlReferenteFormGroup(i).markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    modificaReferente(i: number) {
        this.dialogManagerService.openModalModificaReferente(this.ctrlIdCliente().value, this.ctrlReferenteFormGroup(i).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    aggiungiReferente() {
        this.dialogManagerService.openModalModificaReferente(this.ctrlIdCliente().value,
            this.createReferenteForm().value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    removeReferente(i: number) {
        this.dialogManagerService.openModalEliminaReferente(this.ctrlIdCliente().value, this.ctrlReferenteFormGroup(i).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    modificaUnitaLocale(i: number) {
        this.dialogManagerService.openModalModificaUnitaLocale(this.ctrlIdCliente().value, this.ctrlUnitaLocaleFormGroup(i).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    aggiungiUnitaLocale() {
        this.dialogManagerService.openModalModificaUnitaLocale(this.ctrlIdCliente().value,
            this.createUnitaLocaleForm().value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    modificaAddebitoDurata(k: number ) {
        this.dialogManagerService.openModalModificaAddebitoDurata(this.ctrlIdCliente().value,
            this.ctrlAddebitoDurataFormGroup(k).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    aggiungiAddebito() {
        this.dialogManagerService.openModalModificaAddebitoDurata(this.ctrlIdCliente().value,
            this.createAddebitoDurataLocaleForm().value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    removeUnitaLocale(i: number) {
        this.dialogManagerService.openModalEliminaUnitaLocale(this.ctrlIdCliente().value, this.ctrlUnitaLocaleFormGroup(i).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    removeAddebitoEDurata(i: number) {
        this.dialogManagerService.openModalEliminaAddebitoDurata(this.ctrlIdCliente().value,
            this.ctrlAddebitoDurataFormGroup(i).value)
            .afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({
            next: (value) => {
                if(!!value && value !== 'cancelled') {
                    this.setFormGroup(value);
                }
            }
        });
    }

    checkIsPrincipale() {
        this.ctrlReferentiFormArray().valueChanges.pipe(
            tap(() => {
                let oneChecked = this.ctrlReferentiFormArray().controls.some((item, index) =>
                    this.getReferenteFormControlByPathAndIndex(index, 'isPrincipale').value
                );
                if(oneChecked) {
                    this.ctrlReferentiFormArray().controls.forEach((item, index) => {
                        if(!this.getReferenteFormControlByPathAndIndex(index, 'isPrincipale')?.value) {
                            this.getReferenteFormControlByPathAndIndex(index, 'isPrincipale')?.disable({emitEvent: false})
                        }
                    })
                } else {
                    this.ctrlReferentiFormArray().enable({emitEvent: false});
                }
            }),
        ).subscribe();

        this.ctrlUnitaLocaliFormArray().valueChanges.pipe(
            tap(() => {
                let oneChecked = this.ctrlUnitaLocaliFormArray().controls.some((item, index) =>
                    this.getUnitaLocaleFormControlByPathAndIndex(index, 'isPrincipale').value
                );
                if(oneChecked) {
                    this.ctrlUnitaLocaliFormArray().controls.forEach((item, index) => {
                        if(!this.getUnitaLocaleFormControlByPathAndIndex(index, 'isPrincipale')?.value) {
                            this.getUnitaLocaleFormControlByPathAndIndex(index, 'isPrincipale')?.disable({emitEvent: false})
                        }
                    })
                } else {
                    this.ctrlUnitaLocaliFormArray().enable({emitEvent: false});
                }
            }),
        ).subscribe();
    }

    isThereUnitaPrincipale() {
        return this.ctrlUnitaLocaliFormArray()?.controls?.some((item, index) =>
            this.getUnitaLocaleFormControlByPathAndIndex(index, 'isPrincipale').value
        );
    }

    isThereReferentePrincipale() {
        return this.ctrlReferentiFormArray()?.controls?.some((item, index) =>
            this.getReferenteFormControlByPathAndIndex(index, 'isPrincipale').value
        );
    }

}

interface ClienteForm {
    id?: FormControl<string | null>;
    // INTESTAZIONE
    ragioneSociale?: FormControl<string | null>;
    codiceFiscale?: FormControl<string | null>;
    partitaIva?: FormControl<string | null>;
    telefono?: FormControl<string | null>;

    unitaLocali?: FormArray;
    referenti?: FormArray;

    //SEDE LEGALE
    sedeLegaleIndirizzo?: FormControl<string | null>;
    sedeLegaleFrazione?: FormControl<string | null>;
    sedeLegaleComune?: FormControl<string | null>;
    sedeLegaleCap?: FormControl<string | null>;
    sedeLegaleProvincia?: FormControl<string | null>;
    sedeLegaleNumero: FormControl<string | null>;

    durateAddebiti?: FormArray;
}

interface UnitaLocaleForm {
    id?: FormControl<string | null>;
    // INDIRIZZO
    indirizzo?: FormControl<string | null>;
    numero?: FormControl<string | null>;
    frazione?: FormControl<string | null>;
    comune?: FormControl<string | null>;
    cap?: FormControl<string | null>;
    provincia?: FormControl<string | null>;
    isPrincipale?: FormControl<boolean | null>;
    isWinWaste?: FormControl<boolean | null>;
}

interface ReferenteForm {
    id?: FormControl<string | null>;
    nominativo?: FormControl<string | null>;
    email?: FormControl<string | null>;
    telefono?: FormControl<string | null>;
    isPrincipale?: FormControl<boolean | null>;
}

export interface DurataAddebitoForm {
    id?: FormControl<string | null>;
    durata?: FormControl<number | null>;
    addebitoMask?: FormControl<string | null>;
    addebito?: FormControl<AddebitoView | null>;
}
