import {Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2} from '@angular/core';
import {CommonModule} from '@angular/common';
import {formGroupConfigInterface} from "../../../shared/interfaces/generic-components.interface";
import {FormControl, FormGroup, ReactiveFormsModule, ValidatorFn, Validators} from "@angular/forms";
import {TypeDialogFormEnum} from "../../../shared/enum/generic-components-enum";
import {FormModuleService} from "../../../services/form-module.service";
import {get, isEmpty} from 'lodash';
import {distinctUntilChanged} from "rxjs";
import {keyPressNumbers} from '../../../shared/utils/utils';
import {CustomValidators} from "../../../shared/validators/custom-validators";
import {MatDatepickerInputEvent, MatDatepickerModule} from "@angular/material/datepicker";
import moment from "moment";
import {InputFileComponent} from "../../../shared/components/input-file/input-file.component";
import {TextFieldModule} from "@angular/cdk/text-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {PipeModule} from "../../../shared/pipe/pipe.module";
import {OnlyDecimalNumbersDirective} from "../../../shared/directives/only-decimal-numbers.directive";
import {TranslocoDirective} from "@ngneat/transloco";
import {RemoveUnderscoresPipe} from "../../../shared/pipe/remove-underscores.pipe";
import {DateAdapter} from "angular-calendar";
import {MatNativeDateModule, NativeDateAdapter} from "@angular/material/core";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {CustomDateAdapter} from "../../../services/custom-date-adapter.service";
import {NgxCurrencyDirective} from "ngx-currency";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {AbstractDefaultComponent} from "../../../shared/abstracts/abstract-default-component";
import {AbstractFocusManagerComponent} from "../../../shared/abstracts/abstract-focus-manager.component";

@Component({
    selector: 'app-form',
    standalone: true,
    imports: [
        MatMenuModule,
        MatButtonModule,
        MatIconModule,
        MatDividerModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        CommonModule,
        InputFileComponent,
        TextFieldModule,
        MatInputModule,
        PipeModule,
        OnlyDecimalNumbersDirective,
        TranslocoDirective,
        NgxCurrencyDirective,
        MatCheckboxModule
    ],
    templateUrl: './form.component.html',
    styleUrl: './form.component.scss',
    providers: [
        FormModuleService,
        RemoveUnderscoresPipe,
        {provide: DateAdapter, useClass: CustomDateAdapter},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class FormComponent extends AbstractFocusManagerComponent {
    @Input() formGroupConfig: formGroupConfigInterface[];
    @Input() validatorFns: ValidatorFn[];
    @Output() formValue = new EventEmitter<FormGroup>();
    @Input() touched = false;
    @Input() valueForm: any;
    @Input() readOnly: boolean;
    @Input() isOneColumn: boolean;
    @Input() isUnitaLocaleForm: boolean;

    type = TypeDialogFormEnum;
    formGroup: FormGroup = new FormGroup({});
    outputFormatData = 'DD/MM/YYYY';
    private status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';

    defaultOptions = {
        allowNegative: false,
        nullable: false,
        min: 0,
        allowZero: true,
        suffix: '',

        precision: 0,
        prefix: '',
        thousands: ',', decimal: '.', align: 'left'
    }


    constructor(private formService: FormModuleService,
                protected el: ElementRef,
                protected renderer: Renderer2,) {
        super(el, renderer);
    }

    ngOnDestroy(): void {
        this.formGroup.reset();
    }


    ngOnInit() {
        if (!isEmpty(this.formGroupConfig)) {
            this.formService.createFormGroup(this.formGroupConfig, this.formGroup, this.validatorFns);
        }

        if (this.valueForm) {
            this.formGroup.patchValue(this.valueForm);
        }

        if (this.readOnly) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
            this.disableFormField();
            this.formGroup.statusChanges.pipe(distinctUntilChanged()).subscribe((value) => {
                if (this.status === 'DISABLED') {
                    this.disableFormField();
                }
                this.status = value;
            });
        }

        this.valueChanges();
    }

    private disableFormField(): void {
        this.formGroupConfig.forEach((value) => {
            if (value.disable) {
                this.getFormControl(value.name).disable();
            }
        });
    }

    getFormControl(name: string): FormControl {
        return this.formGroup.get(name) as FormControl;
    }

    keyPressNumbers(event) {
        return keyPressNumbers(event);
    }


    private valueChanges() {
        this.formGroupConfig.forEach((value) => {
            if (value.showIf) {
                this._valueChanges(value.showIf.referenceForm, value.name, value.showIf.referenceFormValue);
            }
            if (value.changeValue) {
                this._changesOption(value.name, value.changeValue.keyOptionChange, value.changeValue.values);
            }
            if (value.onSelectValueChange) {
                this._subscribeToOnSelectValueChange(value.name, value.onSelectValueChange);
            }
        });
    }

    _subscribeToOnSelectValueChange(control: string, callback: (string, FormGroup) => void) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((key) => {
                callback(key, this.formGroup);
            });
    }

    _valueChanges(control: string, mainControl: string, valueArray: string[]) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                if (valueArray.includes(value)) {
                    this.formGroupConfig.find(form => form.name === mainControl).show = true;
                    if (this.formGroupConfig.find(form => form.name === mainControl).required) {
                        this.formGroup.get(mainControl).setValidators(Validators.required);
                    }
                    if (this.formGroupConfig.find(form => form.name === mainControl).isSpace) {
                        this.formGroup.get(mainControl).setValidators(CustomValidators.noSpace);
                    }
                    if (this.formGroupConfig.find(form => form.name === mainControl).regex?.pattern) {
                        this.formGroup.get(mainControl).setValidators(Validators.pattern(this.formGroupConfig.find(form => form.name === mainControl).regex?.pattern));
                    }
                } else {
                    this.formGroup.get(mainControl).setValue(null);
                    this.formGroupConfig.find(form => form.name === mainControl).show = false;
                    this.formGroup.get(mainControl).setErrors(null);
                    this.formGroup.get(mainControl).setValidators([]);
                }
            });
    }


    dateValue(nameControl: string) {
        return this.getFormControl(nameControl).value ? moment(this.getFormControl(nameControl).value, this.outputFormatData).toDate() : null;
    }

    event(type: string, event: MatDatepickerInputEvent<Date>, nameControl: string) {
        this.getFormControl(nameControl).setValue(moment(event.value).format(this.outputFormatData));
        this.getFormControl(nameControl).setErrors(null);
        this.getFormControl(nameControl).setValidators([]);
        if (this.formGroupConfig?.find(conf => conf?.name === nameControl)?.required) {
            this.getFormControl(nameControl).addValidators(Validators.required);
        }

    }

    ngAfterViewInit(): void {
        setTimeout(() => super.ngAfterViewInit(), 100);
        if (this.valueForm) {
            this.formGroupConfig.forEach((v) => {
                if (v.type === TypeDialogFormEnum.DATA) {
                    this.getFormControl(v.name).setErrors(null);
                    this.getFormControl(v.name).setValidators([]);
                    if (v.required) {
                        this.getFormControl(v.name).addValidators(Validators.required);
                    }
                }

            });
        }

    }

    private _changesOption(control: string, keyOptionChange: string, values: {
        key: string;
        value: string[];
    }[]) {
        this.formGroup.get(control).valueChanges
            .pipe(distinctUntilChanged())
            .subscribe((value) => {
                const index = values.findIndex(v => v.key === value);
                if (index > -1) {
                    const indexConf = this.formGroupConfig.findIndex(conf => conf.name === keyOptionChange);
                    this.formGroupConfig[indexConf].options = values[index].value;
                    if (!this.formGroupConfig[indexConf]?.options?.includes(this.formGroup.get(keyOptionChange).value)) {
                        this.formGroup.get(keyOptionChange).setValue(null);
                    }
                } else {
                    const indexConf = this.formGroupConfig.findIndex(conf => conf.name === keyOptionChange);
                    this.formGroupConfig[indexConf].options = [];
                    this.formGroup.get(keyOptionChange).setValue(null);
                }
            });
    }

    getUploadedFile(file: File, nameControl: string) {
        //const blob = new Blob([event], {type: event.type});
        this.getFormControl(nameControl).setValue(file);
    }

    eventForDate(type: string, event: MatDatepickerInputEvent<Date>, formControl: FormControl) {
        formControl.setValue(event.value);
        formControl.setErrors(null);
        formControl.setValidators([]);
    }

    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.el.nativeElement.contains(document.activeElement)) {
            const inputElements = this.el.nativeElement.querySelectorAll('.input-field-modal');
            const activeElement = document.activeElement as HTMLInputElement;
            let activeElementIndex = Array.from(inputElements).findIndex(input => input === activeElement);

            // Navigazione destra
            if (event.key === "ArrowDown" || event.key === "Enter") {
                do {
                    activeElementIndex = (activeElementIndex + 1) % inputElements.length;
                    const nextElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!nextElement.disabled) {
                        nextElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }

            // Navigazione sinistra
            if (event.key === "ArrowUp") {
                do {
                    activeElementIndex = (activeElementIndex - 1 + inputElements.length) % inputElements.length;
                    const previousElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!previousElement.disabled) {
                        previousElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }
        }
    }
}
