import {Navigation} from "../../core/navigation/navigation.types";
import {PathEnum} from "../enum/path-enum";
import {MenuItemTitleEnum} from "../enum/menu-item-title-enum";

export const menuCompactNavigationFoAllUsers: Navigation = {
    compact: [
        {
            title: MenuItemTitleEnum.STATISTICHE,
            type: 'basic',
            link: '/' + PathEnum.HOME,
            icon: 'mat_outline:home',
        },
        {
            title: MenuItemTitleEnum.GESTIONE_UTENTI,
            type: 'basic',
            link: '/' + PathEnum.GESTIONE_UTENTI,
            icon: 'mat_outline:group',
        },
        {
            title: MenuItemTitleEnum.GESTIONE_ANAGRAFICHE,
            type: 'basic',
            link: '/' + PathEnum.GESTIONE_ANAGRAFICHE,
            icon: 'mat_outline:receipt_long',
        },
        {
            title: MenuItemTitleEnum.PROGRAMMAZIONE_INTERVENTI,
            type: 'basic',
            link: '/' + PathEnum.PROGRAMMAZIONE_INTERVENTI,
            icon: 'heroicons_outline:calendar-days',
        },
        {
            title: MenuItemTitleEnum.REGISTRI_OPERATORI,
            type: 'basic',
            link: '/' + PathEnum.REGISTRI_OPERATORI,
            icon: 'mat_outline:settings',
        },
    ]
};

export const menuCompactNavigation: Navigation = {
    compact: [
        {
            title: MenuItemTitleEnum.STATISTICHE,
            type: 'basic',
            link: '/' + PathEnum.HOME,
            icon: 'mat_outline:home',
        },
        {
            title: MenuItemTitleEnum.GESTIONE_UTENTI,
            type: 'basic',
            link: '/' + PathEnum.GESTIONE_UTENTI,
            icon: 'mat_outline:group',
        },
        {
            title: MenuItemTitleEnum.GESTIONE_ANAGRAFICHE,
            type: 'basic',
            link: '/' + PathEnum.GESTIONE_ANAGRAFICHE,
            icon: 'mat_outline:receipt_long',
        },
        {
            title: MenuItemTitleEnum.PROGRAMMAZIONE_INTERVENTI,
            type: 'basic',
            link: '/' + PathEnum.PROGRAMMAZIONE_INTERVENTI,
            icon: 'heroicons_outline:calendar-days',
        },
        {
            title: MenuItemTitleEnum.REGISTRI_OPERATORI,
            type: 'basic',
            link: '/' + PathEnum.REGISTRI_OPERATORI,
            icon: 'mat_outline:settings',
        },
        {
            title: MenuItemTitleEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
            type: 'basic',
            link: '/' + PathEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
            icon: 'mat_outline:domain_verification',
        },
        {
            title: MenuItemTitleEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
            type: 'basic',
            link: '/' + PathEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
            icon: 'mat_outline:fact_check',
        },
    ]
};
