import {
    AddAttrezzaturaInterventoDTO,
    AddAutomezzoInterventoDTO,
    AddClienteInterventoDTO,
    AddebitoView,
    AddNotaInterventoDTO,
    AddOperatoreInterventoDTO, AddOperatoreInterventoFormularioDTO,
    AddReferenteInterventoDTO,
    AddTipoInterventoDTO,
    AddUnitaLocaleInterventoDTO,
    AttrezzaturaInterventoView,
    AttrezzaturaView,
    AutomezzoView,
    ClienteView,
    NotaInterventoBucketView,
    NotaInterventoView, OperatoreInterventoFormularioView,
    OperatoreInterventoView,
    OperatoreView,
    ReferenteIntervento,
    ReferenteView,
    UnitaLocaleIntervento,
    UnitaLocaleView
} from "../../../api-clients/generated/services";

export function mapOperatoreInterventoViewToAddOperatoreInterventoDTO(operatoreView: OperatoreInterventoView, index: number): AddOperatoreInterventoDTO {
    return {
        idOperatore: operatoreView?.operatore?.id,
        ordine: index + 1,
    }
}

export function mapOperatoreInterventoFormularioViewToAddOperatoreInterventoFormularioDTO(operatore: OperatoreInterventoFormularioView,
                                                                                          index: number): AddOperatoreInterventoFormularioDTO {
    return {
        idOperatore: operatore?.operatore?.id,
        ordine: index + 1,
    }
}

export function mapUnitaLocaleInterventoToAddUnitaLocaleInterventoDTO(unita: UnitaLocaleIntervento): AddUnitaLocaleInterventoDTO {
    return {
        idUnitaLocale: unita?.idUnitaLocale
    }
}
export function mapReferenteInterventoToAddReferenteInterventoDTO(referente: ReferenteIntervento): AddReferenteInterventoDTO {
    return {
        idReferente: referente?.idReferente
    }
}

export function mapUnitaLocaleInterventoToUnitaLocaleView(unita: UnitaLocaleIntervento): UnitaLocaleView {
    return {
        ...unita,
        id: unita.idUnitaLocale,
    }
}
export function mapReferenteInterventoToReferenteView(referente: ReferenteIntervento): ReferenteView {
    return {
        ...referente,
        id: referente?.idReferente
    }
}
export function mapClienteViewToAddClienteInterventoDTO(cliente: ClienteView,
                                                        unita: UnitaLocaleIntervento,
                                                        referente: ReferenteIntervento):AddClienteInterventoDTO {
    return {
        id: cliente?.id,
        unitaLocale: mapUnitaLocaleInterventoToAddUnitaLocaleInterventoDTO(unita),
        referente: !!referente ? mapReferenteInterventoToAddReferenteInterventoDTO(referente) : undefined,
    }
}

export function mapAttrezzaturaInterventoToAddAttrezzaturaIntervento (attrezzatura: AttrezzaturaInterventoView, index: number): AddAttrezzaturaInterventoDTO {
    return {
        ordine: index + 1,
        idAttrezzatura: attrezzatura?.attrezzatura?.id,
    }
}

export function mapNotaInterventoBucketViewToNotaInterventoView(notaInterventoBucketView: NotaInterventoBucketView, index: number):
    Partial<NotaInterventoView> {
    return {
        notaInterventoBucket: notaInterventoBucketView,
        ordine: index + 1,
    }
}

export function mapOperatoreViewToOperatoreInterventoView(operatoreView: OperatoreView, index: number): Partial<OperatoreInterventoView> {
    return {
        isAutista: (index === 0),
        operatore: operatoreView,
        ordine: index+1,
        user: operatoreView?.utente,
    }
}

export function mapOperatoreViewToOperatoreInterventoFormularioView(operatoreView: OperatoreView, index: number): Partial<OperatoreInterventoView> {
    return {
        operatore: operatoreView,
        ordine: index+1,
        user: operatoreView?.utente,
    }
}

export function mapAttrezzaturaViewToAttrezzaturaInterventoView(attrezzatura: AttrezzaturaView, index: number): Partial<AttrezzaturaInterventoView> {
    return {
        attrezzatura: attrezzatura,
        ordine: (index + 1),

    }
}

export function mapReferenteViewToReferenteIntervento(referente: ReferenteView): Partial<ReferenteIntervento> {
    return {
        idReferente: referente?.id,
        nominativo: referente?.nominativo,
        email: referente?.email,
        telefono: referente?.telefono,
        isPrincipale: referente?.isPrincipale,
    }
}

export function mapUnitaViewToUnitaLocaleIntervento(unita: UnitaLocaleView): Partial<UnitaLocaleIntervento> {
    return {
        idUnitaLocale: unita?.id,
        indirizzo: unita?.indirizzo,
        numero: unita?.numero,
        frazione: unita?.frazione,
        comune: unita?.comune,
        cap: unita?.cap,
        provincia: unita?.provincia,
        isPrincipale: unita?.isPrincipale,
        isWinWaste: unita?.isWinWaste,
    }
}

export function mapAutomezzoViewToAddAutomezzoInterventoDTO(automezzo: AutomezzoView): AddAutomezzoInterventoDTO {
    if (!!automezzo) {
        return {
            id: automezzo?.id
        }
    } else {
        return undefined
    }
}

export function mapAddebitoViewToAddTipoInterventoDTO(addebito: AddebitoView): AddTipoInterventoDTO {
    return {
        id: addebito?.id
    }
}

export function mapOperatoreViewToAddOperatoreInterventoDTO(operatoreView: OperatoreView, index: number): AddOperatoreInterventoDTO {
    return {
        idOperatore: operatoreView?.id,
        ordine: index + 1,
    }
}

export function mapAttrezzaturaViewToAddAttrezzaturaIntervento (attrezzatura: AttrezzaturaView, index: number): AddAttrezzaturaInterventoDTO {
    return {
        ordine: index + 1,
        idAttrezzatura: attrezzatura?.id,
    }
}
