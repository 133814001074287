import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {compact, head, join} from "lodash-es";
import {AttrezzaturaView, ClienteView, OperatoreView} from "../../../../api-clients/generated/services";

export function createTableConfigurationForClienti(inputList?: Array<ClienteView>,
                                                   isSelection?: boolean,
                                                   listLength?: number,
                                                   pageSize?: number, pageIndex?: number,
                                                   isCancellato?: boolean,
                                                   isBloccato?: boolean): GenericTableConfigurationModel {
    let list = inputList.map((cliente) => {
        let unitaPrinc = (cliente.unitaLocali.find((unita) => unita.isPrincipale) || head(cliente.unitaLocali));
        return {
            ...cliente,
            descrizione: join(compact([cliente.ragioneSociale, cliente.estensioneRagioneSociale, cliente.alias]), ' - '),
            indirizzoCompleto: join(compact([
                unitaPrinc?.indirizzo,
                unitaPrinc?.frazione,
                unitaPrinc?.comune,
                unitaPrinc?.provincia]), ' - ')
        }
    });
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            pageSize: pageSize,
            pageIndex: pageIndex,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Descrizione',
                    colonnaKey: 'descrizione',
                    flex: 90,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Indirizzo',
                    colonnaKey: 'indirizzoCompleto',
                    flex: 45,
                },
                {
                    nomeColonna: 'Dettagli',
                    colonnaKey: 'dettagli',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'description',
                        click: TipoClickEnum.INFO,
                        tooltip: 'Descrizione',
                        color: 'accent'
                    }],
                    hideColonna: isSelection
                },
                {
                    hideColonna: isSelection,
                    nomeColonna: 'Modifica',
                    colonnaKey: 'modifica',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'edit',
                        click: TipoClickEnum.EDIT,
                        tooltip: 'Modifica il cliente',
                        color: 'accent'
                    }],
                },
                {
                    hideColonna: isSelection || isCancellato,
                    nomeColonna: 'Cancella',
                    colonnaKey: 'removecliente',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'delete',
                        click: TipoClickEnum.DELETE,
                        tooltip: 'Cancella il cliente',
                        color: 'accent'
                    }],
                    show: (value, element) => showModificaIfIsManule(value, element)
                },
                {
                    hideColonna: isSelection || !isCancellato,
                    nomeColonna: 'Ripristina',
                    colonnaKey: 'addcliente',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'add',
                        click: TipoClickEnum.RIPRISTINA,
                        tooltip: 'Ripristina il cliente',
                        color: 'accent'
                    }],
                    show: (value, element) => showModificaIfIsManule(value, element)
                },
                /// marK: block
                {
                    hideColonna: isSelection || isBloccato,
                    nomeColonna: 'Blocca',
                    colonnaKey: 'bloccaCliente',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'lock',
                        click: TipoClickEnum.LOCK,
                        tooltip: 'Blocca il cliente',
                        color: 'accent'
                    }],
                    //show: (value, element) => showModificaIfIsManule(value, element)
                },
                {
                    hideColonna: isSelection || !isBloccato,
                    nomeColonna: 'Sblocca',
                    colonnaKey: 'sbloccaCliente',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'lock_open',
                        click: TipoClickEnum.UNLOCK,
                        tooltip: 'Sblocca il cliente',
                        color: 'accent'
                    }],
                    //show: (value, element) => showModificaIfIsManule(value, element)
                },
                /// endmark: block
                {
                    hideColonna: !isSelection,
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}

export function showModificaIfIsManule(value?: any, element?: ClienteView | OperatoreView) {
    return element.intIsManuale;
}
