import {Route} from '@angular/router';
import {initialDataResolver} from 'app/app.resolvers';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {PathEnum} from "./shared/enum/path-enum";
import {RuoloUtenteGuard} from "./core/auth/guards/ruolo-utente.guard";
import {RuoloUtenteEnum} from "./shared/enum/ruolo-utente-enum";
import {NoLoggedGuard} from "./core/auth/guards/noLogged.guard";

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch : 'full', redirectTo: PathEnum.HOME},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch : 'full', redirectTo: PathEnum.HOME},

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'forgot-password', loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')},
            {path: 'sign-in', loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes')},
        ]
    },

    {
        path: '',
        canActivate: [NoLoggedGuard],
        canActivateChild: [NoLoggedGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'verify-account', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
            {path: 'recover-password', loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')},
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {path: 'sign-out', loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes')},
        ]
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            //{path: 'home', loadChildren: () => import('app/modules/landing/home/home.routes')},
        ]
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver
        },
        children: [
           //{path: 'example', loadChildren: () => import('app/modules/admin/example/example.routes')},
            {path: PathEnum.HOME, loadChildren: () => import('app/modules/landing/home/home.routes')},
            {path: PathEnum.GESTIONE_UTENTI, loadChildren: () => import('app/modules/landing/gestione-utenti/gestione-utenti.routes')},
            {path: PathEnum.GESTIONE_ANAGRAFICHE, loadChildren: () => import('app/modules/landing/gestione-anagrafiche/gestione-anagrafiche.routes')},
            {
                path: PathEnum.PROGRAMMAZIONE_INTERVENTI,
                loadChildren: () => import('app/modules/landing/programmazione-interventi/programmazione-interventi.routes'),

            },
            {path: PathEnum.IMPOSTAZIONI, loadChildren: () => import('app/modules/landing/impostazioni/impostazioni.routes')},
            {path: PathEnum.DISPONIBILITA_MEZZI, loadChildren: () => import('app/modules/landing/disponibilita-mezzi/disponibilita-mezzi.routes')},
            {
                path: PathEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
                loadChildren: () => import('app/modules/landing/validazione-interventi-eseguiti/validazione-interventi-eseguiti.routes'),
                data: {roles: [RuoloUtenteEnum.ADMIN, RuoloUtenteEnum.OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO]},
                canActivate: [RuoloUtenteGuard]
            },
            {
                path: PathEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
                loadChildren: () => import('app/modules/landing/rivalidazione-su-base-contrattuale/rivalidazione-su-base-contrattuale.routes'),
                data: {roles: [RuoloUtenteEnum.ADMIN, RuoloUtenteEnum.OPERATORE_CED]},
                canActivate: [RuoloUtenteGuard]
            },
            {path: PathEnum.PROFILO, loadChildren: () => import('app/modules/landing/profilo/profilo.routes')},
            {
                path: PathEnum.REGISTRI_OPERATORI,
                data: {roles: [RuoloUtenteEnum.ADMIN, RuoloUtenteEnum.OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO]},
                canActivate: [RuoloUtenteGuard],
                loadChildren: () => import('app/modules/landing/registro-operatori/registro-operatori.routes')
            },
        ]
    },
    {path: '**', pathMatch : 'full', redirectTo: PathEnum.HOME},
];
