<app-dialog-wrap [data]="wrapData"
                 (close$)="closeModal($event)"
                 (confirm$)="onConfirm($event)"
                 class="h-full">
    <div class="w-full flex flex-col gap-2">
        <ng-container *ngIf="!!data.formValue.calendario;">
            <mat-tab-group #tabGroupModificaPrincipale (selectedTabChange)="onTabChangedTipoModifica($event);">
                <mat-tab #tabModificaInterventoPuntuale aria-label="modifica_puntuale">
                    <ng-template mat-tab-label>
                        Modifica puntuale
                        <mat-icon *ngIf="tabModificaInterventoPuntuale.isActive" class="ml-2" color="primary">check
                        </mat-icon>
                    </ng-template>
                    <div class="w-full flex flex-row justify-center py-2">
                        <ng-container *ngTemplateOutlet="gestioneOrarioNonPeriodica"></ng-container>
                    </div>

                </mat-tab>
                <mat-tab #tabModificaInterventoESuccessivi aria-label="modifica_successivi">
                    <ng-template mat-tab-label>
                        Modifica presente e successivi
                        <mat-icon *ngIf="tabModificaInterventoESuccessivi.isActive" class="ml-2" color="primary">check
                        </mat-icon>
                    </ng-template>
                    <div class="w-full flex flex-row justify-center py-2">
                        <ng-container *ngTemplateOutlet="gestionePeriodicita"></ng-container>
                    </div>
                </mat-tab>
                <mat-tab #tabModificaTutti aria-label="modifica_tutti">
                    <ng-template mat-tab-label>
                        Modifica tutti gi interventi
                        <mat-icon *ngIf="tabModificaTutti.isActive" class="ml-2" color="primary">check
                        </mat-icon>
                    </ng-template>
                    <div class="w-full flex flex-row justify-center py-2">
                        <ng-container *ngTemplateOutlet="gestionePeriodicita"></ng-container>
                    </div>
                </mat-tab>
            </mat-tab-group>
        </ng-container>

        <ng-container *ngTemplateOutlet="datiGenericiRichiestaDaEvadere"></ng-container>
    </div>

</app-dialog-wrap>


<!-- MARK DATI GENERICI -->
<ng-template #datiGenericiRichiestaDaEvadere>
    <div class="w-full flex flex-col gap-4 pt-2">
        <div class="p-2 flex-row flex justify-center">
            <span class="font-size-x-large"><b>Dati intervento generali</b></span>
        </div>
        <div class="w-full flex flex-col gap-4" *ngIf="!!attivitaFormGroup">
            <div class="flex flex-col md:flex-row justify-between w-full">

                <div class="flex flex-col gap-2 w-full md:w-45/100">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Intervento</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <!-- MARK: TIPOLOGIA INTERVENTO -->

                    <ng-container *ngIf="ctrlAddebitiFormArray()?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let addebitoFormControl of ctrlAddebitiFormArray()?.controls;  let j=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Tipo Intervento ' +(j+1)" [style.width.%]="100"
                                                            [control]="ctrlAddebitoMask(j)"
                                                            [cantWriteText]="true"
                                                            [autofocus]="true"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.TIPOLOGIA_INTERVENTO"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.TIPOLOGIA_INTERVENTO,
                                                            ctrlAddebito(j), ctrlAddebitoMask(j))"
                                                            (setNullEmitter)="ctrlAddebitoMask(j).setValue(null);
                                                                ctrlAddebito(j).setValue(null);"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between"
                                         *ngIf="!readonly">
                                        <button mat-icon-button color="primary"
                                                (click)="addAddebito()"
                                                matTooltip="Aggiungi tipologia intervento"
                                                *ngIf="j === 0 && ctrlAddebitoMask(j)?.enabled">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="ctrlAddebitiFormArray()?.controls?.length > 1 &&
                                                        ctrlAddebitoMask(j)?.enabled"
                                                matTooltip="Rimuovi tipologia intervento"
                                                (click)="removeAddebito(j)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARK: TIPOLOGIA INTERVENTO --------------->

                    <!-- COSTO -->
                    <app-generic-form-field [label]="'Costo'"
                                            [control]="getAttivitaFormControlByPath('costo')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [pipe]="CurrencyEuroPipe"
                    ></app-generic-form-field>

                    <!-- numeroPreventivo -->
                    <app-generic-form-field [label]="'N° Preventivo'"
                                            [control]="getAttivitaFormControlByPath('numeroPreventivo')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [type]="'text'"
                    ></app-generic-form-field>

                    <!-- Referente Unico -->
                    <app-generic-form-field [label]="'Referente Unico'"
                                            [control]="getAttivitaFormControlByPath('referenteUnico')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [type]="'text'"
                    ></app-generic-form-field>

                    <!-- Referente Unico Tel.-->
                    <app-generic-form-field [label]="'Referente Unico Tel.'"
                                            [control]="getAttivitaFormControlByPath('referenteUnicoTel')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [type]="'text'"
                    ></app-generic-form-field>

                    <div *ngIf="!readonly; else readOnlyCheckBox" tabindex="0" class="input-field-modal mt-1"
                         (keydown.F1)="$event.preventDefault(); cambiaValoreCheckbox()">
                        <mat-checkbox [disabled]="false"
                                      [checked]="!!ctrlIsInterventoFormulario()?.value"
                                      [disableRipple]="false"
                                      [formControl]="ctrlIsInterventoFormulario()">
                            {{IsInterventoFormularioLabel}}
                        </mat-checkbox>
                    </div>

                    <ng-template #readOnlyCheckBox>
                        <div>
                            <mat-checkbox [disabled]="true"
                                          [checked]="!!ctrlIsInterventoFormulario()?.value"
                                          [disableRipple]="true"
                                          (click)="$event.preventDefault()">
                                <span class="font-size-small">{{IsInterventoFormularioLabel}}</span>
                            </mat-checkbox>
                        </div>
                    </ng-template>

                    <!-- CLIENTE -->
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Cliente</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <app-generic-form-field [label]="'Cliente'"
                                            [cantWriteText]="true"
                                            [control]="getAttivitaFormControlByPath('clienteMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.CLIENTE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.CLIENTE,
                                            getAttivitaFormControlByPath('cliente'),
                                            getAttivitaFormControlByPath('clienteMask')
                                            )"
                                            (setNullEmitter)="setNullCliente()"
                                            [readonly]="readonly"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Unità Locale'"
                                            [cantWriteText]="true"
                                            [control]="getAttivitaFormControlByPath('unitaLocaleMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.UNITA_LOCALE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.UNITA_LOCALE,
                                            getAttivitaFormControlByPath('unitaLocale'),
                                             getAttivitaFormControlByPath('unitaLocaleMask'))"
                                            (setNullEmitter)="getAttivitaFormControlByPath('unitaLocale').setValue(null);
                                             getAttivitaFormControlByPath('unitaLocaleMask').setValue(null)"
                                            [readonly]="readonly"
                                            [disabled]="!ctrlCliente().value || ctrlCliente().disabled"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Referente Cliente'"
                                            [cantWriteText]="true"
                                            [control]="getAttivitaFormControlByPath('referenteClienteMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.REFERENTE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.REFERENTE,
                                            getAttivitaFormControlByPath('referenteCliente'),
                                             getAttivitaFormControlByPath('referenteClienteMask'))"
                                            (setNullEmitter)=" getAttivitaFormControlByPath('referenteCliente').setValue(null);
                                             getAttivitaFormControlByPath('referenteClienteMask').setValue(null)"
                                            [readonly]="readonly"
                                            [disabled]="!ctrlCliente().value || ctrlCliente().disabled"
                    ></app-generic-form-field>

                    <!----------------------MARK: DATA E ORA-------------------------- -->
                    <ng-container *ngIf="!data.formValue.calendario;">
                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Data e ora</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <div class="flex flex-row justify-between">
                            <mat-checkbox *ngIf="!ctrlAttivitaPeriodica.value && !readonly"
                                          [formControl]="ctrlDataNonConosciuta">Non conosco la data
                            </mat-checkbox>
                        </div>
                        <app-generic-form-field [label]="'Data'"
                                                [type]="'date'"
                                                [control]="getAttivitaFormControlByPath('data')"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [readonly]="readonly"
                        ></app-generic-form-field>
                        <div class="flex flex-row w-full justify-between">
                            <div [style.width.%]="45">
                                <app-generic-form-field [label]="'Inizio Previsto'"
                                                        [type]="'time'"
                                                        [control]="getAttivitaFormControlByPath('oraInizioPrevista')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="readonly"
                                ></app-generic-form-field>
                            </div>
                            <div [style.width.%]="45">
                                <app-generic-form-field [label]="'Fine Prevista'"
                                                        [type]="'time'"
                                                        [control]="getAttivitaFormControlByPath('oraFinePrevista')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="readonly"
                                ></app-generic-form-field>
                            </div>
                        </div>
                    </ng-container>
                    <!---------------------- ENDMARK: DATA E ORA-------------------------- -->


                </div>

                <div class="flex flex-col gap-2 w-full md:w-45/100">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Automezzo e attrezzature</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <!-- ----------- MARK: AUTOMEZZO --------------->
                    <app-generic-form-field [label]="'Automezzo'"
                                            [cantWriteText]="true"
                                            [control]="getAttivitaFormControlByPath('automezzoMask')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [campoDaSelezionare]="CampoDaSelezionare.AUTOMEZZO"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.AUTOMEZZO,
                                             getAttivitaFormControlByPath('automezzo'),
                                             getAttivitaFormControlByPath('automezzoMask')
                                             )"
                                            (setNullEmitter)="getAttivitaFormControlByPath('automezzo').setValue(null);
                                             getAttivitaFormControlByPath('automezzoMask').setValue(null)"
                    ></app-generic-form-field>

                    <!-- ----------- MARK: RIMORCHIO --------------->
                    <app-generic-form-field [label]="'Rimorchio'"
                                            [cantWriteText]="true"
                                            [control]="getAttivitaFormControlByPath('rimorchioMask')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="readonly"
                                            [campoDaSelezionare]="CampoDaSelezionare.AUTOMEZZO"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.RIMORCHIO,
                                            getAttivitaFormControlByPath('rimorchio'),
                                            getAttivitaFormControlByPath('rimorchioMask')
                                            )"
                                            (setNullEmitter)=" getAttivitaFormControlByPath('rimorchio').setValue(null);
                                            getAttivitaFormControlByPath('rimorchioMask').setValue(null)"
                    ></app-generic-form-field>
                    <!-- ----------- MARK: ATTREZZATURE --------------->
                    <ng-container *ngIf="getFormArrayByPath('attrezzature')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let attrezzaturaFormControl of getFormArrayByPath('attrezzature')?.controls;  let j=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [cantWriteText]="true"
                                                            [label]="'Attrezzatura ' +(j+1)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('attrezzatureMask', j)"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.ATTREZZATURA"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.ATTREZZATURA,
                                                            getFormControlByIndexInFormArrayByPath('attrezzature', j),
                                                            getFormControlByIndexInFormArrayByPath('attrezzatureMask', j)
                                                            )"
                                                            (setNullEmitter)=" getFormControlByIndexInFormArrayByPath('attrezzature', j).setValue(null);
                                                            getFormControlByIndexInFormArrayByPath('attrezzatureMask', j).setValue(null)"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between" *ngIf="!readonly">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArray('attrezzature');
                                                addFormControlToFormArray('attrezzatureMask');"
                                                matTooltip="Aggiungi attrezzatura"
                                                *ngIf="j === 0">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('attrezzature')?.controls?.length > 1"
                                                matTooltip="Rimuovi attrezzatura"
                                                (click)="removeFormControlToFormArray('attrezzature', j);
                                                         removeFormControlToFormArray('attrezzatureMask', j)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARK: ATTREZZATURE --------------->

                    <!-- ----------- MARK: OPERATORI --------------->
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Operatori</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <ng-container *ngIf="getFormArrayByPath('operatori')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let operatoreFormControl of getFormArrayByPath('operatori')?.controls; let k=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Operatore ' +(k+1)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('operatoriMask', k)"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [cantWriteText]="true"
                                                            [readonly]="readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.OPERATORE"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.OPERATORE,
                                                            getFormControlByIndexInFormArrayByPath('operatori', k),
                                                            getFormControlByIndexInFormArrayByPath('operatoriMask', k))"
                                                            (setNullEmitter)="getFormControlByIndexInFormArrayByPath('operatori', k).setValue(null);
                                                            getFormControlByIndexInFormArrayByPath('operatoriMask', k).setValue(null)"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between" *ngIf="!readonly">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArray('operatori');
                                                         addFormControlToFormArray('operatoriMask')"
                                                matTooltip="Aggiungi operatore"
                                                *ngIf="k === 0">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('operatori')?.controls?.length > 1"
                                                matTooltip="Rimuovi operatore"
                                                (click)="removeFormControlToFormArray('operatori', k);
                                                         removeFormControlToFormArray('operatoriMask', k)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARK: OPERATORI --------------->
                    <!-- ----------- MARK: NOTE --------------->
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Note</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <ng-container *ngIf="getFormArrayByPath('note')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let notaFormControl of getFormArrayByPath('note')?.controls;  let i=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Nota ' +(i+1)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('noteMask', i)"
                                                            [cantWriteText]="false"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.NOTA,
                                                            getFormControlByIndexInFormArrayByPath('note', i),
                                                            getFormControlByIndexInFormArrayByPath('noteMask', i))"
                                                            [campoDaSelezionare]="CampoDaSelezionare.NOTA"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="readonly"
                                                            (setNullEmitter)="getFormControlByIndexInFormArrayByPath('note', i).setValue(null);
                                                            getFormControlByIndexInFormArrayByPath('noteMask', i).setValue(null)"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between" *ngIf="!readonly">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArray('note'); addFormControlToFormArray('noteMask')"
                                                matTooltip="Aggiungi nota"
                                                *ngIf="i === 0">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('note')?.controls?.length > 1"
                                                matTooltip="Rimuovi nota"
                                                (click)="removeFormControlToFormArray('note', i);
                                                removeFormControlToFormArray('noteMask', i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>

                            </ng-container>

                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARK: NOTE --------------->
                </div>

            </div>

        </div>

    </div>
</ng-template>
<!-- MARK DATI GENERICI -->

<!--MARK GESTIONE PERIODICITA -->
<ng-template #gestionePeriodicita>
    <div class="w-full flex flex-col gap-4">
        <div class="container">
            <div class="line">
                <mat-divider></mat-divider>
            </div>
            <div class="text mat-typography">Dati periodicità</div>
            <div class="line">
                <mat-divider></mat-divider>
            </div>
        </div>
        <div class="flex flex-col md:flex-row justify-between w-full">
            <div class="flex flex-col gap-2 w-full md:w-45/100">
                <!-- MARK: DATI PERIODICI -->
                <div class="flex flex-col w-full gap-2">
                    <div class="flex flex-row w-full justify-between items-center">
                        <div class="flex flex-col justify-center h-full">
                            <span>Ripeti ogni</span>
                        </div>

                        <mat-form-field appearance="outline" [style.width.%]="20">
                            <input matInput class="input-field-modal"
                                   [formControl]="getCalendarioFormControlByPath('numeroPeriodicita')">
                        </mat-form-field>

                        <mat-form-field class="w-1/2" appearance="outline">
                            <mat-select [formControl]="getCalendarioFormControlByPath('tipoPeriodicita')"
                                        [multiple]="false" class="input-field-modal">
                                <ng-container *ngFor="let tipo of arrayTipoPeriodicita">
                                    <mat-option [value]="tipo">{{tipo}}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>

                    </div>
                    <div class="flex w-full flex-row justify-between">
                        <mat-form-field class="w-5/12 mat-button-datapicker" appearance="outline">
                            <mat-label>Dal</mat-label>
                            <input matInput class="input-field-modal"
                                   [placeholder]="'Dal'"
                                   type="date"
                                   [formControl]="getCalendarioFormControlByPath('dataInizio')"
                            >
                            <mat-error *ngIf="getCalendarioFormControlByPath('dataInizio').hasError('required')">
                                {{errorRequiredMessage}}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field class="w-5/12 mat-button-datapicker" appearance="outline">
                            <mat-label>Al</mat-label>
                            <input matInput class="input-field-modal"
                                   [placeholder]="'Al'"
                                   type="date"
                                   [formControl]="getCalendarioFormControlByPath('dataFine')">
                            <mat-error class="whitespace-nowrap"
                                       *ngIf="getCalendarioFormControlByPath('dataFine').hasError('required')">
                                {{errorRequiredMessage}}
                            </mat-error>
                            <mat-error class="whitespace-nowrap"
                                       *ngIf="getCalendarioFormControlByPath('dataFine').hasError('dateCheck')">
                                {{errorDateMessage}}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="flex flex-row w-full justify-between">
                        <app-generic-form-field [label]="'Inizio Previsto'" class="w-5/12"
                                                [type]="'time'"
                                                [control]="getCalendarioFormControlByPath('oraInizioPrevista')"
                                                [errorRequiredMessage]="errorRequiredMessage"
                        ></app-generic-form-field>

                        <app-generic-form-field [label]="'Fine Prevista'" class="w-5/12"
                                                [type]="'time'"
                                                [control]="getCalendarioFormControlByPath('oraFinePrevista')"
                                                [errorRequiredMessage]="errorRequiredMessage"
                        ></app-generic-form-field>
                    </div>
                </div>
                <!-- ENDMARK: DATI PERIODICI -->
            </div>
            <div class="flex flex-col gap-2 w-full md:w-45/100">
                <!-- MARK: GESTIONE GIORNI -->

                <ng-container *ngIf="isSettimanale()">
                    <div class="flex flex-wrap justify-center gap-2 p-3 pb-5 ">
                        <ng-container *ngFor="let giorno of giorniDellaSettimana">
                            <div
                                    class="py-0.5 px-3 cursor-pointer rounded-2xl text-2xl font-semibold text-primary-800 bg-gray-200"
                                    [class.bg-accent-400]="giorniSelezionati.includes(giorno)"
                                    [class.mat-elevation-z8]="giorniSelezionati.includes(giorno)"
                                    (click)="setGiorno(giorno)"
                            > {{giorno}} </div>
                        </ng-container>
                    </div>
                </ng-container>

                <ng-container *ngIf="isMensile()">
                    <mat-tab-group #tabGroupMensile (selectedTabChange)="onTabChanged($event);">
                        <mat-tab #tabMensile1>
                            <ng-template mat-tab-label>
                                Numero giorno
                                <mat-icon *ngIf="tabMensile1.isActive" class="ml-2" color="primary">check
                                </mat-icon>
                            </ng-template>
                            <div class="flex flex-row justify-center py-1">
                                <mat-label>Seleziona il giorno</mat-label>
                            </div>

                            <div class="grid grid-cols-7 gap-2 p-2">
                                <ng-container *ngFor="let monthDay of arrayMonthsDays">
                                    <div
                                            class="py-0.5 px-3 cursor-pointer rounded-2xl text-2xl font-semibold
                                            text-primary-800 bg-gray-200 text-center"
                                            [class.bg-accent-400]="selectedMonthsDays.includes(monthDay)"
                                            [class.mat-elevation-z8]="selectedMonthsDays.includes(monthDay)"
                                            (click)="setMonthDay(monthDay)"
                                    > {{monthDay}} </div>
                                </ng-container>
                            </div>
                        </mat-tab>

                        <mat-tab #tabMensile2>
                            <ng-template mat-tab-label>
                                Ordine ricorrenza e giorno
                                <mat-icon *ngIf="tabMensile2.isActive" class="ml-2" color="primary">check
                                </mat-icon>
                            </ng-template>
                            <div class="p-2">

                                <div class="flex flex-row justify-center py-1">
                                    <mat-form-field class="w-1/2" appearance="outline">
                                        <mat-label>Seleziona l'ordinale</mat-label>
                                        <mat-select
                                                [formControl]="getCalendarioFormControlByPath('numeroSettimana')"
                                                [multiple]="false">
                                            <ng-container *ngFor="let settimana of arraySettimaneDelMese">
                                                <mat-option [value]="settimana">
                                                    {{replaceLastLetter(settimana)}}</mat-option>
                                            </ng-container>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <div class="flex flex-row justify-center py-1">
                                    <mat-label>Seleziona il giorno</mat-label>
                                </div>
                                <div class="flex flex-wrap justify-center gap-2 p-3 pb-5 ">
                                    <ng-container *ngFor="let giorno of giorniDellaSettimana">
                                        <div
                                                class="py-0.5 px-3 cursor-pointer rounded-2xl text-2xl font-semibold text-primary-800 bg-gray-200 text-center"
                                                [class.bg-accent-400]="giorniSelezionatiCasoMensile.includes(giorno)"
                                                [class.mat-elevation-z8]="giorniSelezionatiCasoMensile.includes(giorno)"
                                                (click)="setGiornoCasoPeriodicitaMensile(giorno)"> {{giorno}} </div>
                                    </ng-container>
                                </div>

                                <div *ngIf="!!getCalendarioFormControlByPath('numeroSettimana')?.value
                                                    && this.ctrlGiornoSettimanaCasoMensile()?.value"
                                     class="flex flex-row justify-center py-1">
                                    <mat-label>{{getDescrizioneRicorrenza()}}</mat-label>
                                </div>

                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </ng-container>
                <!-- ENDMARK: GESTIONE GIORNI -->
            </div>
        </div>
    </div>
</ng-template>
<!-- ENDMARK GESTIONE PERIODICITA -->

<!-- MARK: DATA E ORA NON-PERIODICA -->
<ng-template #gestioneOrarioNonPeriodica>
    <div class="flex flex-col gap-2 w-full md:w-45/100">
        <div class="container">
            <div class="line">
                <mat-divider></mat-divider>
            </div>
            <div class="text mat-typography">Data e ora</div>
            <div class="line">
                <mat-divider></mat-divider>
            </div>
        </div>
        <app-generic-form-field [label]="'Data'"
                                [type]="'date'"
                                [control]="getAttivitaFormControlByPath('data')"
                                [errorRequiredMessage]="errorRequiredMessage"
                                [readonly]="readonly"
        ></app-generic-form-field>
        <div class="flex flex-row w-full justify-between">
            <div [style.width.%]="45">
                <app-generic-form-field [label]="'Inizio Previsto'"
                                        [type]="'time'"
                                        [control]="getAttivitaFormControlByPath('oraInizioPrevista')"
                                        [errorRequiredMessage]="errorRequiredMessage"
                                        [readonly]="readonly"
                ></app-generic-form-field>
            </div>
            <div [style.width.%]="45">
                <app-generic-form-field [label]="'Fine Prevista'"
                                        [type]="'time'"
                                        [control]="getAttivitaFormControlByPath('oraFinePrevista')"
                                        [errorRequiredMessage]="errorRequiredMessage"
                                        [readonly]="readonly"
                ></app-generic-form-field>
            </div>
        </div>
    </div>


</ng-template>
<!-- ENDMARK: DATA E ORA NON-PERIODICA -->
