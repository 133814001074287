/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { LocalTime } from './localTime';

export interface AddCalendarioDTOOraInizioOffsetRulesTransitionRules { 
    month?: AddCalendarioDTOOraInizioOffsetRulesTransitionRules.MonthEnum;
    timeDefinition?: AddCalendarioDTOOraInizioOffsetRulesTransitionRules.TimeDefinitionEnum;
    dayOfWeek?: AddCalendarioDTOOraInizioOffsetRulesTransitionRules.DayOfWeekEnum;
    dayOfMonthIndicator?: number;
    localTime?: LocalTime;
    midnightEndOfDay?: boolean;
}
export namespace AddCalendarioDTOOraInizioOffsetRulesTransitionRules {
    export type MonthEnum = 'JANUARY' | 'FEBRUARY' | 'MARCH' | 'APRIL' | 'MAY' | 'JUNE' | 'JULY' | 'AUGUST' | 'SEPTEMBER' | 'OCTOBER' | 'NOVEMBER' | 'DECEMBER';
    export const MonthEnum = {
        JANUARY: 'JANUARY' as MonthEnum,
        FEBRUARY: 'FEBRUARY' as MonthEnum,
        MARCH: 'MARCH' as MonthEnum,
        APRIL: 'APRIL' as MonthEnum,
        MAY: 'MAY' as MonthEnum,
        JUNE: 'JUNE' as MonthEnum,
        JULY: 'JULY' as MonthEnum,
        AUGUST: 'AUGUST' as MonthEnum,
        SEPTEMBER: 'SEPTEMBER' as MonthEnum,
        OCTOBER: 'OCTOBER' as MonthEnum,
        NOVEMBER: 'NOVEMBER' as MonthEnum,
        DECEMBER: 'DECEMBER' as MonthEnum
    };
    export type TimeDefinitionEnum = 'UTC' | 'WALL' | 'STANDARD';
    export const TimeDefinitionEnum = {
        UTC: 'UTC' as TimeDefinitionEnum,
        WALL: 'WALL' as TimeDefinitionEnum,
        STANDARD: 'STANDARD' as TimeDefinitionEnum
    };
    export type DayOfWeekEnum = 'MONDAY' | 'TUESDAY' | 'WEDNESDAY' | 'THURSDAY' | 'FRIDAY' | 'SATURDAY' | 'SUNDAY';
    export const DayOfWeekEnum = {
        MONDAY: 'MONDAY' as DayOfWeekEnum,
        TUESDAY: 'TUESDAY' as DayOfWeekEnum,
        WEDNESDAY: 'WEDNESDAY' as DayOfWeekEnum,
        THURSDAY: 'THURSDAY' as DayOfWeekEnum,
        FRIDAY: 'FRIDAY' as DayOfWeekEnum,
        SATURDAY: 'SATURDAY' as DayOfWeekEnum,
        SUNDAY: 'SUNDAY' as DayOfWeekEnum
    };
}