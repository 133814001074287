/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipologie di Job.
 */
export type JobType = 'AUTOMEZZI' | 'ADDEBITI' | 'CLIENTI' | 'AUTISTI';

export const JobType = {
    AUTOMEZZI: 'AUTOMEZZI' as JobType,
    ADDEBITI: 'ADDEBITI' as JobType,
    CLIENTI: 'CLIENTI' as JobType,
    AUTISTI: 'AUTISTI' as JobType
};