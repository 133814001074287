/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddAttrezzaturaInterventoDTO } from './addAttrezzaturaInterventoDTO';
import { AddNotaInterventoDTO } from './addNotaInterventoDTO';
import { AddOperatoreInterventoDTO } from './addOperatoreInterventoDTO';

export interface PatchInterventoDTO { 
    dataOraInizioPrevista?: Date;
    dataOraFinePrevista?: Date;
    idTipiIntervento?: Array<string>;
    costoIntervento?: number;
    numeroPreventivo?: string;
    referenteUnico?: string;
    referenteUnicoTel?: string;
    isInterventoFormulario?: boolean;
    attrezzature?: Array<AddAttrezzaturaInterventoDTO>;
    note?: Array<AddNotaInterventoDTO>;
    operatori?: Array<AddOperatoreInterventoDTO>;
}