<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<!-- Navigation -->
<fuse-vertical-navigation
    class="dark bg-primary-900 print:hidden"
    [appearance]="'compact'"
    [mode]="isScreenSmall ? 'over' : 'side'"
    [name]="'mainNavigation'"
    [navigation]="navigation.compact"
    [opened]="!isScreenSmall">
    <!-- Navigation header hook -->
    <ng-container fuseVerticalNavigationContentHeader>
        <!-- Logo -->
        <div class="flex items-center justify-center h-20 mt-3 mb-4">
            <img src="assets/images/logo/logo-maya.png"
                alt="Logo image">
        </div>
    </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex flex-col flex-auto w-full min-w-0">

    <!-- Header -->
    <div class="bg-accent-500
    relative flex flex-0 items-center w-full h-16 px-4 md:px-6 z-49
    shadow dark:shadow-none dark:border-b bg-card dark:bg-transparent print:hidden gap-1.5 bar_navigation">
        <!-- Navigation toggle button -->

        <button
            mat-icon-button
            (click)="toggleNavigation('mainNavigation')">
            <mat-icon [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
        </button>

     @for(item of navbarCompactNavigation; track item.title) {
        <div class="hidden lg:flex">
       <button (click)="openNavbarItemModal(item)" mat-button class="nav-bar-button hover:bg-info-800">
        {{item.title}}
        <mat-icon class="pl_5_px"
        iconPositionEnd [svgIcon]="item.icon"></mat-icon>
    </button>
    </div>
        }



        <!-- Components -->
        <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <fuse-fullscreen class="hidden md:block"></fuse-fullscreen>
            <user></user>
            <app-mobile-header-action-menu class="block lg:hidden">

            </app-mobile-header-action-menu>
        </div>
    </div>

    <!-- Content -->
    <div class="flex flex-col flex-auto m-1.5 lg:m-5">
        <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
             Otherwise, layout changes won't be registered and the view won't be updated! -->
        <router-outlet *ngIf="true"></router-outlet>
    </div>

    <!-- Footer -->
    <div class="relative flex flex-0 items-center justify-start w-full h-14 px-4 md:px-6 z-49 border-t bg-card dark:bg-transparent print:hidden">
        <span class="font-medium text-secondary">South Engineering</span>
    </div>

</div>

