/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { EvadiInterventoDTO } from './evadiInterventoDTO';

export interface Body { 
    evadiInterventoDTO: EvadiInterventoDTO;
    /**
     * File firma cliente
     */
    firmaCliente?: Blob;
    /**
     * File allegato 1
     */
    multipartFile1?: Blob;
    /**
     * File allegato 2
     */
    multipartFile2?: Blob;
    /**
     * File allegato 3
     */
    multipartFile3?: Blob;
    /**
     * File allegato 4
     */
    multipartFile4?: Blob;
    /**
     * File allegato 5
     */
    multipartFile5?: Blob;
}