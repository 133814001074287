<app-dialog-wrap-intervento [data]="data" (close$)="closeModal($event)"
                            (confirm$)="onConfirm($event)"
                            [showOdl]="false"
                            [canShowOdl]="false"
                            [showChip]="showChip"
                            [statusChip]="statusChip"
                            [dialogForInterventoType]="data.dialogType"
                            [tipologiaIntervento]="tipologiaIntervento"
                            [interventoFormGroup]="interventoFormGroup"
                            [isInterventoNelPassato]="!!data?.formValue?.isInterventoPassato">

    <!--- MARK: CHIP TIPOLOGIA INTERVENTO -->
    <div class="w-full flex flex-row py-1 justify-center" *ngIf="!!tipologiaIntervento">
        <mat-chip [class]="tipologiaIntervento | statusEnumToColorTheme: InterventoProgrammaDTO.TipologiaEnum "
                  class="justify-between"><span class="line-clamp-1">
            {{checkChipLabel(tipologiaIntervento) | removeUnderscores | uppercase}}</span>
        </mat-chip>
    </div>
    <!--- ENDMARK: CHIP TIPOLOGIA INTERVENTO -->

    <!--- MARK: DATI INTERVENTO PRINCIPALI -->
    <div class="px-2 w-full flex flex-col gap-4" *ngIf="!!interventoFormGroup"
         [ngClass]="data.dataWithGrayBackground ? 'bg-gray-100' : null">
        <div class="flex flex-row justify-between w-full">

            <div class="flex flex-col gap-5" [style.width.%]="31">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Ordine di lavoro</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <app-generic-form-field [label]="'Codice ODL'"
                                        [control]="ctrlCodiceOrdineDiLavoro()"
                                        [errorRequiredMessage]="errorRequiredMessage"
                                        [readonly]="data?.readonly"
                                        [autofocus]="true"
                                        [onlyPositiveIntegers]="true"
                ></app-generic-form-field>

                <!-- ----------- MARK: AUTOMEZZI --------------->
                <div #sezioneAutomezzi class="flex flex-col gap-2">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Automezzo</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <app-generic-form-field [label]="'Automezzo'"
                                            [control]="getInterventoFormControlByPath('automezzoMask')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [cantWriteText]="true"
                                            [readonly]="data?.readonly"
                                            [campoDaSelezionare]="CampoDaSelezionare.AUTOMEZZO"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.AUTOMEZZO)"
                                            (setNullEmitter)="getInterventoFormControlByPath('automezzoMask').setValue(null);
                                                                  getInterventoFormControlByPath('automezzo').setValue(null);"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Rimorchio'"
                                            [control]="getInterventoFormControlByPath('rimorchioMask')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [cantWriteText]="true"
                                            [readonly]="data?.readonly"
                                            [campoDaSelezionare]="CampoDaSelezionare.RIMORCHIO"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.RIMORCHIO)"
                                            (setNullEmitter)="getInterventoFormControlByPath('rimorchioMask').setValue(null);
                                                                  getInterventoFormControlByPath('rimorchio').setValue(null);"
                    ></app-generic-form-field>
                </div>

                <!-- ----------- MARK: OPERATORI --------------->
                <div #sezioneOperatori class="flex flex-col gap-2">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Operatori</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <ng-container *ngIf="getFormArrayByPath('operatoriMask')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let operatoreFormControl of getFormArrayByPath('operatoriMask')?.controls; let k=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="getLabelOperatore(k)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('operatoriMask', k)"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [cantWriteText]="true"
                                                            [readonly]="data?.readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.OPERATORE"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.OPERATORE, k)"
                                                            (setNullEmitter)="getFormControlByIndexInFormArrayByPath('operatoriMask', k).setValue(null);
                                                                                   getFormControlByIndexInFormArrayByPath('operatore', k).setValue(null)"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between"
                                         *ngIf="!data?.readonly && !!isAttivita && getFormControlByIndexInFormArrayByPath('operatoriMask', k)?.enabled">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArray('operatore');
                                                addFormControlToFormArray('operatoriMask')"
                                                matTooltip="Aggiungi operatore"
                                                *ngIf="k === 0">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('operatore')?.controls?.length > 1 && !!isAttivita
                                                            && getFormControlByIndexInFormArrayByPath('operatoriMask', k)?.enabled"
                                                matTooltip="Rimuovi operatore"
                                                (click)="removeFormControlToFormArray('operatore', k);
                                                           removeFormControlToFormArray('operatoriMask', k)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <!-- ----------- ENDMARK: OPERATORI --------------->
            </div>

            <div class="flex flex-col gap-5" [style.width.%]="31">
                <div #sezioneClienti class="flex flex-col gap-2" *ngIf="!!isAttivita">
                    <!-- CLIENTE -->
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Cliente</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <app-generic-form-field [label]="'Cliente'"
                                            [control]="getInterventoFormControlByPath('clienteMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.CLIENTE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [cantWriteText]="true"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.CLIENTE)"
                                            [readonly]="data?.readonly"
                                            (setNullEmitter)="setNullEmitterOnCliente()"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Referente'"
                                            [control]="getInterventoFormControlByPath('referenteClienteMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.REFERENTE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [cantWriteText]="true"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.REFERENTE)"
                                            [readonly]="data?.readonly"
                                            (setNullEmitter)="getInterventoFormControlByPath('referenteCliente').setValue(null);
                                                                    getInterventoFormControlByPath('referenteClienteMask').setValue(null)"
                                            [disabled]="!ctrlCliente().value || ctrlCliente().disabled"
                                            [disabledTooltip]="requiredClienteForReferente"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Unità locale'"
                                            [control]="getInterventoFormControlByPath('unitaLocaleMask')"
                                            [campoDaSelezionare]="CampoDaSelezionare.UNITA_LOCALE"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [cantWriteText]="true"
                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.UNITA_LOCALE)"
                                            [readonly]="data?.readonly"
                                            (setNullEmitter)="getInterventoFormControlByPath('unitaLocale').setValue(null);
                                                                    getInterventoFormControlByPath('unitaLocaleMask').setValue(null)"
                                            [disabled]="!ctrlCliente().value || ctrlCliente().disabled"
                                            [disabledTooltip]="requiredClienteForUnitaLocale"
                    ></app-generic-form-field>
                </div>
                <div #sezioneIntervento class="flex flex-col gap-2" *ngIf="!!isAttivita">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Intervento</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <!-- MARk: TIPOLOGIA INTERVENTO array -->
                    <ng-container *ngIf="ctrlAddebitiFormArray()?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let addebitoFormControl of ctrlAddebitiFormArray()?.controls;  let j=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Tipo Intervento ' +(j+1)" [style.width.%]="100"
                                                            [control]="ctrlAddebitoMask(j)"
                                                            [cantWriteText]="true"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.TIPOLOGIA_INTERVENTO"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.TIPOLOGIA_INTERVENTO, j)"
                                                            (setNullEmitter)="ctrlAddebitoMask(j).setValue(null);
                                                                ctrlAddebito(j).setValue(null);"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between"
                                         *ngIf="!data?.readonly && !!isAttivita">
                                        <button mat-icon-button color="primary"
                                                (click)="addAddebito()"
                                                matTooltip="Aggiungi tipologia intervento"
                                                *ngIf="j === 0 && ctrlAddebitoMask(j)?.enabled">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="ctrlAddebitiFormArray()?.controls?.length > 1 && !!isAttivita &&
                                                        ctrlAddebitoMask(j)?.enabled"
                                                matTooltip="Rimuovi tipologia intervento"
                                                (click)="removeAddebito(j)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARk: TIPOLOGIA INTERVENTO array --------------->

                    <!-- COSTO -->
                    <app-generic-form-field [label]="'Costo'"
                                            [control]="getInterventoFormControlByPath('costo')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                    ></app-generic-form-field>

                    <!-- numeroPreventivo -->
                    <app-generic-form-field [label]="'N° Preventivo'"
                                            [control]="getInterventoFormControlByPath('numeroPreventivo')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Referente Unico'"
                                            [control]="getInterventoFormControlByPath('referenteUnico')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Referente Unico Tel'"
                                            [control]="getInterventoFormControlByPath('referenteUnicoTel')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                    ></app-generic-form-field>

                    <div class="flex flex-row justify-start">
                        <div *ngIf="!data?.readonly; else readOnlyCheckBox" tabindex="0" class="input-field-modal mt-1"
                             (keydown.F1)="$event.preventDefault(); cambiaValoreCheckbox()">
                            <mat-checkbox [disabled]="false"
                                          [checked]="!!ctrlIsInterventoFormulario()?.value"
                                          [disableRipple]="false"
                                          [formControl]="ctrlIsInterventoFormulario()">
                                {{IsInterventoFormularioLabel}}
                            </mat-checkbox>
                        </div>

                        <ng-template #readOnlyCheckBox>
                            <div>
                                <mat-checkbox [disabled]="true"
                                              [checked]="!!ctrlIsInterventoFormulario()?.value"
                                              [disableRipple]="true"
                                              (click)="$event.preventDefault()">
                                    {{IsInterventoFormularioLabel}}
                                </mat-checkbox>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-5" [style.width.%]="31">
                <div #sezioneOrario class="flex flex-col gap-2">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Data e ora</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <app-generic-form-field [label]="'Data'"
                                            [type]="'date'"
                                            [control]="getInterventoFormControlByPath('data')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                    ></app-generic-form-field>
                    <div class="flex flex-row w-full justify-between">
                        <div [style.width.%]="45">
                            <app-generic-form-field [label]="'Inizio'"
                                                    [type]="'time'"
                                                    [control]="getInterventoFormControlByPath('oraInizioPrevista')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                        <div [style.width.%]="45">
                            <app-generic-form-field [label]="'Fine'"
                                                    [type]="'time'"
                                                    [control]="getInterventoFormControlByPath('oraFinePrevista')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                    </div>
                </div>
                <div #sezioneAttrezzature *ngIf="!!isAttivita"
                     class="flex flex-col gap-2">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Attrezzature</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <!-- ----------- MARK: ATTREZZATURE --------------->
                    <ng-container *ngIf="getFormArrayByPath('attrezzatura')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let attrezzaturaFormControl of getFormArrayByPath('attrezzatura')?.controls;  let j=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Attrezzatura ' +(j+1)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('attrezzatureMask', j)"
                                                            [cantWriteText]="true"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [campoDaSelezionare]="CampoDaSelezionare.ATTREZZATURA"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.ATTREZZATURA, j)"
                                                            (setNullEmitter)="getFormControlByIndexInFormArrayByPath('attrezzatureMask', j).setValue(null);
                                                                getFormControlByIndexInFormArrayByPath('attrezzatura', j).setValue(null);"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between"
                                         *ngIf="!data?.readonly && !!isAttivita">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArrayWithoutValidator('attrezzatura');
                                                    addFormControlToFormArrayWithoutValidator('attrezzatureMask')"
                                                matTooltip="Aggiungi attrezzatura"
                                                *ngIf="j === 0 && getFormArrayByPath('attrezzatureMask')?.enabled">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('attrezzatura')?.controls?.length > 1 && !!isAttivita &&
                                                        getFormControlByIndexInFormArrayByPath('attrezzatureMask', j)?.enabled"
                                                matTooltip="Rimuovi attrezzatura"
                                                (click)="removeFormControlToFormArray('attrezzatura', j);
                                                    removeFormControlToFormArray('attrezzatureMask', j);">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                    <!-- ----------- ENDMARK: ATTREZZATURE --------------->

                </div>
                <!-- ----------- MARK: NOTE --------------->
                <div #sezioneNote class="flex flex-col gap-2" *ngIf="!!isAttivita">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Note</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <ng-container *ngIf="getFormArrayByPath('nota')?.controls?.length > 0">
                        <div [style.width.%]="100">
                            <ng-container
                                    *ngFor="let notaFormControl of getFormArrayByPath('nota')?.controls;  let i=index">
                                <div class="flex flex-row justify-between p-1">
                                    <app-generic-form-field [label]="'Nota ' +(i+1)" [style.width.%]="100"
                                                            [control]="getFormControlByIndexInFormArrayByPath('notaMask', i)"
                                                            [cantWriteText]="false"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            (openSelect)="openModaleDiSelezione(CampoDaSelezionare.NOTA, i)"
                                                            [campoDaSelezionare]="CampoDaSelezionare.NOTA"
                                                            (setNullEmitter)="getFormControlByIndexInFormArrayByPath('notaMask', i).setValue(null);
                                                                getFormControlByIndexInFormArrayByPath('nota', i).setValue(null)"
                                    ></app-generic-form-field>
                                    <div class="flex flex-row justify-between"
                                         *ngIf="!data?.readonly && !!isAttivita">
                                        <button mat-icon-button color="primary"
                                                (click)="addFormControlToFormArrayWithoutValidator('nota');
                                                    addFormControlToFormArrayWithoutValidator('notaMask')"
                                                matTooltip="Aggiungi nota"
                                                *ngIf="i === 0 && getFormArrayByPath('notaMask')?.enabled">
                                            <mat-icon>add</mat-icon>
                                        </button>

                                        <button mat-icon-button color="warn"
                                                *ngIf="getFormArrayByPath('nota')?.controls?.length > 1 && !!isAttivita &&
                                                            getFormControlByIndexInFormArrayByPath('notaMask', i)?.enabled"
                                                matTooltip="Rimuovi nota"
                                                (click)="removeFormControlToFormArray('nota', i); removeFormControlToFormArray('notaMask', i)">
                                            <mat-icon>delete</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <!------------- ENDMARK: NOTE --------------->
            </div>
        </div>
    </div>
    <!--- ENDMARK: DATI INTERVENTO PRINCIPALI -->

    <!----------------- MARK: DATI EVASIONE ATTIVITA' ---------------->
    <ng-container *ngIf="!!isAttivita && false">
        <!-- MARK: EVASA PART -->
        <ng-container *ngIf="!data.editDialog && isEvasoVerificatoOrCosolidato()">

            <div class="px-2 flex flex-row justify-between w-full">
                <div class="flex flex-col gap-5" [style.width.%]="45">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Orario effettivo</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>
                    <div class="flex flex-row w-full justify-between">
                        <div [style.width.%]="45">
                            <app-generic-form-field [label]="'Inizio Effettivo'"
                                                    [type]="'time'"
                                                    [control]="getInterventExtendedFormControlByPath('oraInizioEffettiva')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                        <div [style.width.%]="45">
                            <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato()"
                                                    [label]="'Fine Effettiva'"
                                                    [type]="'time'"
                                                    [control]="getInterventExtendedFormControlByPath('oraFineEffettiva')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                    </div>

                    <div class="flex flex-row w-full justify-between" *ngIf="isEvasoVerificatoOrCosolidato()">
                        <div [style.width.%]="45">
                            <app-generic-form-field [label]="'Inizio ODL'"
                                                    [type]="'time'"
                                                    [control]="getInterventExtendedFormControlByPath('dataOraInizioODL')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                        <div [style.width.%]="45">
                            <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato()"
                                                    [label]="'Fine ODL'"
                                                    [type]="'time'"
                                                    [control]="getInterventExtendedFormControlByPath('dataOraFineODL')"
                                                    [errorRequiredMessage]="errorRequiredMessage"
                                                    [readonly]="data?.readonly"
                            ></app-generic-form-field>
                        </div>
                    </div>
                    <div #sezioneDurate class="flex flex-col gap-2">
                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Durata attività</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>

                        <div class="w-full flex-col flex gap-2">
                            <mat-checkbox [formControl]="getInterventExtendedFormControlByPath('isPausaPranzo')">
                                L'orario di intervento comprende una pausa pranzo? Se sì, indicarne la durata in
                                minuti
                            </mat-checkbox>
                            <div class="flex flex-row justify-between">
                                <app-generic-form-field [style.width.%]="45"
                                                        *ngIf="!!getInterventExtendedFormControlByPath('isPausaPranzo')?.value"
                                                        [label]="'Inizio pausa pranzo'"
                                                        [type]="'time'"
                                                        [control]="getInterventExtendedFormControlByPath('dataOraInizioPausaPranzo')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="false"
                                ></app-generic-form-field>
                                <app-generic-form-field [style.width.%]="45"
                                                        *ngIf="!!getInterventExtendedFormControlByPath('isPausaPranzo')?.value"
                                                        [label]="'Fine pausa pranzo'"
                                                        [type]="'time'"
                                                        [control]="getInterventExtendedFormControlByPath('dataOraFinePausaPranzo')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="false"
                                ></app-generic-form-field>
                            </div>
                        </div>

                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Reperibilità</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>

                        <div class="w-full flex-col flex gap-2">
                            <mat-checkbox
                                    [formControl]="getInterventExtendedFormControlByPath('isInterventoInReperibilita')">
                                L'intervento è stato effettuato durante la reperibilità? Se sì, indicare mittente e
                                ora della richiesta
                            </mat-checkbox>
                            <div class="flex flex-row justify-between">
                                <app-generic-form-field [style.width.%]="45"
                                                        *ngIf="!!getInterventExtendedFormControlByPath('isInterventoInReperibilita')?.value"
                                                        [label]="'Ora richiesta reperibilità'"
                                                        [type]="'time'"
                                                        [control]="getInterventExtendedFormControlByPath('dataOraRichiestaReperibilita')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="false"
                                ></app-generic-form-field>
                                <app-generic-form-field [style.width.%]="45"
                                                        *ngIf="!!getInterventExtendedFormControlByPath('isInterventoInReperibilita')?.value"
                                                        [label]="'Mittente Reperibilita'"
                                                        [type]="'text'"
                                                        [control]="getInterventExtendedFormControlByPath('mittenteReperibilita')"
                                                        [errorRequiredMessage]="errorRequiredMessage"
                                                        [readonly]="false"
                                ></app-generic-form-field>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="flex flex-col gap-5" [style.width.%]="45">
                    <div #sezioneFirmatario class="flex flex-col gap-2">
                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Nominativo firmatario</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <app-generic-form-field [label]="'Nominativo Firmatario'"
                                                [control]="getInterventExtendedFormControlByPath('nominativoFirmatario')"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [readonly]="data?.readonly"
                        ></app-generic-form-field>
                    </div>
                    <div #sezioneODL class="flex flex-col gap-2">
                        <div class="container">
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                            <div class="text mat-typography">Ordine di lavoro</div>
                            <div class="line">
                                <mat-divider></mat-divider>
                            </div>
                        </div>
                        <!-- odl -->
                        <app-generic-form-field [label]="'Nota Ordine Di Lavoro'"
                                                [type]="'text'"
                                                [control]="getInterventExtendedFormControlByPath('notaOrdineDiLavoro')"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [readonly]="data?.readonly"
                        ></app-generic-form-field>

                        <app-generic-form-field *ngIf="!!isInCorso() || !!isEvasoVerificatoOrCosolidato()"
                                                [label]="'Nota Operatore'"
                                                [control]="ctrlNotaOperatore()"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [readonly]="data?.readonly"
                        ></app-generic-form-field>

                        <app-generic-form-field [label]="'Nota Percorrenza'"
                                                *ngIf="!!isInCorso() || !!isProgrammato()"
                                                [control]="ctrlnotaPercorrenza()"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [readonly]="data?.readonly"
                        ></app-generic-form-field>
                    </div>
                </div>
            </div>

            <!-- MARK: Sezione foto -->
            <div #foto *ngIf="!!fotoArray && fotoArray.length > 0" class="flex flex-col gap-2 p-1 w-full">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Foto</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="flex flex-row gap-2 justify-start">
                    <ng-container *ngFor="let fotoUri of fotoArray; let i = index">
                        <div [style.width.%]="17"
                             class="flex items-center flex-col justify-between py-1">
                            <img class="custom-image"
                                 [src]="fotoUri">

                            <button
                                    color="accent" mat-flat-button (click)="scaricaFoto(fotoUri, i)">
                                {{ 'Scarica foto'}}
                                <mat-icon iconPositionEnd class="pl-1">download</mat-icon>
                            </button>
                        </div>

                    </ng-container>
                </div>
            </div>
            <!-- ENDMARK: Sezione foto -->
        </ng-container>
        <!-- ENDMARK: EVASA PART -->
    </ng-container>
    <!----------------- ENDMARK: DATI EVASIONE ATTIVITA' ---------------->

</app-dialog-wrap-intervento>
