import {
    AutomezzoView,
    InterventoFormularioView,
    InterventoProgrammaDTO,
    JobStatus,
    ProgrammaDTO
} from "../../../api-clients/generated/services";
import {statusUser} from "./table-configurations/configurazione-tabella-utenti";
import {InterventoFormularioServizioEnum} from "./table-configurations/configurazione-interventi-validazione";

export function getColorByInterventoProgrammaDTOStatoEnum(value: InterventoProgrammaDTO.StatoEnum) {
    switch (value) {
        case InterventoProgrammaDTO.StatoEnum.EVASO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        case InterventoProgrammaDTO.StatoEnum.DAEVADERE:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
        case InterventoProgrammaDTO.StatoEnum.PROGRAMMATO:
            return 'text-blue-800 bg-blue-300 dark:text-blue-50 dark:bg-blue-600';
        case InterventoProgrammaDTO.StatoEnum.ANNULLATO:
            return 'text-gray-900 bg-gray-300 dark:text-blue-50 dark:bg-blue-600';
        case InterventoProgrammaDTO.StatoEnum.SPOSTATO:
            return 'text-lightgray-600 bg-lightgray-100 dark:text-blue-50 dark:bg-blue-600';
        case InterventoProgrammaDTO.StatoEnum.INCORSO:
            return 'text-orange-600 bg-orange-100 dark:text-blue-50 dark:bg-blue-600';
        case InterventoProgrammaDTO.StatoEnum.VERIFICATO:
            return 'text-green-700 bg-green-200 dark:text-blue-50 dark:bg-blue-600';
        case InterventoProgrammaDTO.StatoEnum.CONSOLIDATO:
            return 'text-green-800 bg-green-300 dark:text-blue-50 dark:bg-blue-600';
        default:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByAutomezzoViewIntStatoEnum(value: AutomezzoView.IntStatoEnum) {
    switch (value) {
        case AutomezzoView.IntStatoEnum.ATTIVO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        case AutomezzoView.IntStatoEnum.NONDISPONIBILE:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
        case AutomezzoView.IntStatoEnum.INMANUTENZIONE:
            return 'text-blue-800 bg-blue-300 dark:text-blue-50 dark:bg-blue-600';
        case AutomezzoView.IntStatoEnum.FERMO:
            return 'text-gray-900 bg-gray-300 dark:text-blue-50 dark:bg-blue-600';
        default:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByJobStatus(value: JobStatus) {
    switch (value) {
        case JobStatus.TERMINATOCONSUCCESSO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        case JobStatus.DAAVVIARE:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
        case JobStatus.INCORSO:
            return 'text-orange-600 bg-orange-100 dark:text-blue-50 dark:bg-blue-600';
        case JobStatus.FALLITO:
            return 'text-red-500 bg-red-200 dark:text-red-50 dark:bg-red-600';
        default:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
    }
}

export function getColorByStatusUser(value: statusUser) {
    switch (value) {
        case statusUser.ABILITATO:
        case statusUser.NON_BLOCCATO:
        case statusUser.VERIFICATO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        case statusUser.BLOCCATO:
        case statusUser.NON_ABILITATO:
        case statusUser.NON_VERIFICATO:
            return 'text-red-500 bg-red-200 dark:text-red-50 dark:bg-red-600';
    }
}

export function getColorByTipologiaIntervento(value: InterventoProgrammaDTO.TipologiaEnum) {
    switch (value) {
        case InterventoProgrammaDTO.TipologiaEnum.ATTIVITA:
        case   InterventoProgrammaDTO.TipologiaEnum.PARTENZA:
        case   InterventoProgrammaDTO.TipologiaEnum.RIENTRO:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
        default:
    }
}

export function getColorByStatoProgramma(value: ProgrammaDTO.StatoEnum) {
    switch (value) {
        case ProgrammaDTO.StatoEnum.COMUNICATO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        case ProgrammaDTO.StatoEnum.BOZZA:
            return 'text-blue-600 bg-blue-100 dark:text-lightblue-50 dark:bg-lightblue-600';
        case ProgrammaDTO.StatoEnum.MODIFICATO:
            return 'text-blue-800 bg-blue-300 dark:text-blue-50 dark:bg-blue-600';
        case ProgrammaDTO.StatoEnum.INCORSO:
            return 'text-orange-600 bg-orange-100 dark:text-blue-50 dark:bg-blue-600';
        case ProgrammaDTO.StatoEnum.VERIFICATO:
            return 'text-green-700 bg-green-200 dark:text-blue-50 dark:bg-blue-600';
        case ProgrammaDTO.StatoEnum.CONSOLIDATO:
            return 'text-green-800 bg-green-300 dark:text-blue-50 dark:bg-blue-600';
        case ProgrammaDTO.StatoEnum.EVASO:
            return 'text-green-600 bg-green-100 dark:text-green-50 dark:bg-green-600';
        default:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByInterventoFormularioStatoEnum(value: InterventoFormularioView.StatoEnum) {
    switch (value) {
        case InterventoFormularioView.StatoEnum.DAVERIFICARE:
            return 'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600';
        case InterventoFormularioView.StatoEnum.VERIFICATO:
            return 'text-green-700 bg-green-200 dark:text-blue-50 dark:bg-blue-600';
        case InterventoFormularioView.StatoEnum.CONSOLIDATO:
            return 'text-green-800 bg-green-300 dark:text-blue-50 dark:bg-blue-600';
    }
}

export function getColorByInterventoFormularioServizioEnum(value: InterventoFormularioServizioEnum) {
    switch (value) {
        case InterventoFormularioServizioEnum.INTERVENTO_SERVIZIO:
            return;
        case InterventoFormularioServizioEnum.INTERVENTO_FORMULARIO:
            return 'text-yellow-500 bg-yellow-200 dark:text-yellow-50 dark:bg-yellow-600';
    }
}
