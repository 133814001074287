/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface InterventiProgrammaFilterForm { 
    tipologieIntervento?: Array<InterventiProgrammaFilterForm.TipologieInterventoEnum>;
    statiIntervento?: Array<InterventiProgrammaFilterForm.StatiInterventoEnum>;
    data?: string;
    idOperatore?: string;
    targaAutomezzo?: string;
}
export namespace InterventiProgrammaFilterForm {
    export type TipologieInterventoEnum = 'ATTIVITA' | 'PARTENZA' | 'PERCORRENZA' | 'RIENTRO' | 'NOTA';
    export const TipologieInterventoEnum = {
        ATTIVITA: 'ATTIVITA' as TipologieInterventoEnum,
        PARTENZA: 'PARTENZA' as TipologieInterventoEnum,
        PERCORRENZA: 'PERCORRENZA' as TipologieInterventoEnum,
        RIENTRO: 'RIENTRO' as TipologieInterventoEnum,
        NOTA: 'NOTA' as TipologieInterventoEnum
    };
    export type StatiInterventoEnum = 'DA_EVADERE' | 'PROGRAMMATO' | 'ANNULLATO' | 'CANCELLATO' | 'SPOSTATO' | 'IN_CORSO' | 'EVASO' | 'VERIFICATO' | 'CONSOLIDATO' | 'ND';
    export const StatiInterventoEnum = {
        DAEVADERE: 'DA_EVADERE' as StatiInterventoEnum,
        PROGRAMMATO: 'PROGRAMMATO' as StatiInterventoEnum,
        ANNULLATO: 'ANNULLATO' as StatiInterventoEnum,
        CANCELLATO: 'CANCELLATO' as StatiInterventoEnum,
        SPOSTATO: 'SPOSTATO' as StatiInterventoEnum,
        INCORSO: 'IN_CORSO' as StatiInterventoEnum,
        EVASO: 'EVASO' as StatiInterventoEnum,
        VERIFICATO: 'VERIFICATO' as StatiInterventoEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatiInterventoEnum,
        ND: 'ND' as StatiInterventoEnum
    };
}