import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {DialogWrapComponent, DialogWrapI} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgForOf, NgIf} from "@angular/common";
import {MatListModule, MatListOption} from "@angular/material/list";
import {CalendarEvent} from "angular-calendar";
import {MatExpansionModule} from "@angular/material/expansion";
import {FormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {NgxCurrencyDirective} from "ngx-currency";
import {FuseAlertComponent} from "../../../../@fuse/components/alert";
import moment from "moment";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {
    InterventoProgrammaDTO,
    InterventoView,
    ProgrammaDTO,
    ProgrammazioneService
} from "../../../../api-clients/generated/services";
import {finalize, takeUntil} from "rxjs";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";
import {ManageErrorService} from "../../../services/manage-error.service";
import {
    getTestoAutomezzo,
    getTestoCliente,
    getTestoOperatore,
    getTestoTipoIntervento
} from "../../utils/mask-functions";
import {groupBy, orderBy} from "lodash";
import {join} from "lodash-es";

export interface dataCommunicationConfig {
    events: Array<InterventoView>
    date: string;
    programma: ProgrammaDTO;
}

@Component({
    selector: 'app-comunicazione-programmazione-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgIf,
        MatDialogClose,
        MatListModule,
        NgForOf,
        MatExpansionModule,
        FormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgxCurrencyDirective,
        FuseAlertComponent
    ],
    templateUrl: './comunicazione-programmazione-modal.component.html',
    styleUrl: './comunicazione-programmazione-modal.component.scss'
})
export class ComunicazioneProgrammazioneModalComponent extends AbstractDefaultComponent {
    wrapData: DialogWrapI = {
        title: 'Comunicazione programmazione',
        icon: {
            name: 'send',
            color: 'primary'
        },
        close: true,
        hasSubHeader: true,
        hideConfirmSubHeader: false,
    };
    allSelected: boolean = false;

    @ViewChild('matListOption') child: MatListOption
    isAttividaDaEvadere: boolean = false;
    alertMessage: string;
    attivitaProgrammate: InterventoProgrammaDTO[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: dataCommunicationConfig,
                public dialogRef: MatDialogRef<ComunicazioneProgrammazioneModalComponent>,
                private fuseConfirmationService: FuseConfirmationService,
                private programmazioneService: ProgrammazioneService,
                private manageErrorService: ManageErrorService) {
        super();
        this.wrapData.title = 'Comunicazione programmazione - ' + this.data.date
        console.log('DATA', data)
        this.isAttividaDaEvadere = !!this.data.events.find((event) =>
            event.stato === InterventoProgrammaDTO.StatoEnum.DAEVADERE)
        this.attivitaProgrammate = orderBy(this.data.events.filter((event) =>
            event.stato === InterventoProgrammaDTO.StatoEnum.PROGRAMMATO).sort((a,b) =>
            new Date(a.dataOraInizioPrevista).getTime() - new Date(b.dataOraInizioPrevista).getTime()
        ), 'automezzo.targa')?.filter((item) => item.tipologia !== InterventoView.TipologiaEnum.NOTA)
        this.alertMessage = 'Attenzione, ci sono delle richieste da evadere in giorno ' + data.date;
    }

    onConfirmClick() {
        this.dialogRef.close()
    }

    mapDataEvent(event: InterventoView) {
        // TODO ADDEBIT
        return (event.tipologia === InterventoView.TipologiaEnum.ATTIVITA) ?
            join(event.tipiIntervento.map((inter) =>
                getTestoTipoIntervento(inter)), '\n') : event.tipologia
    }

    getClienteEvent(event: InterventoProgrammaDTO) {
        return getTestoCliente(event?.cliente)
    }

    getOraInizioEvent(event: InterventoProgrammaDTO) {
        return !!event?.dataOraInizioPrevista ?
        moment(event?.dataOraInizioPrevista).format('HH:mm') : ' - '
    }

    getOraFineEvent(event: InterventoProgrammaDTO) {
        return !!event?.dataOraFinePrevista ?
        moment(event.dataOraFinePrevista).format('HH:mm') : ' - '
    }

    closeModal($event: void) {
        this.dialogRef.close();
    }

    onConfirm($event: string) {
        this.fuseConfirmationService.showLoader();
        this.programmazioneService.comunicaProgramma(this.data.date).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: () => {
                this.fuseConfirmationService.openSnackBar({
                    message: 'Programmazione comunicata con successo',
                    type: SnackbarTypes.Success,
                });
                this.dialogRef.close(true);
            },
            error: (error) => {
                this.manageErrorService.showBackendErrorMessage(error);
            }
        })
    }

    getAutomezzo(event: InterventoProgrammaDTO) {
        let automezzo = this.data.programma.righe.find((riga) =>
            riga.interventi.find((intervento) => intervento.id === event.id)).automezzo;
        return getTestoAutomezzo(automezzo);
    }

    getOperatori(event: InterventoProgrammaDTO): string[] {
        return this.data?.programma?.righe?.find((riga) =>
            riga?.interventi?.find((intervento) => intervento?.id === event?.id))
            ?.operatori?.map((ope) => getTestoOperatore(ope));
    }
}
