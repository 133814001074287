import {Component, HostListener, Inject, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormModuleService} from "../../../../services/form-module.service";
import {FormComponent} from "../../form/form.component";
import {FormGroup} from "@angular/forms";
import {BehaviorSubject} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {GenericComponentDialogConfig} from "../../../../shared/interfaces/generic-components.interface";
import {FuseConfirmationService} from "../../../../../@fuse/services/confirmation";
import {TranslocoService} from "@ngneat/transloco";
import {get, isEmpty} from 'lodash';
import {SnackbarTypes} from "../../../../shared/enum/snackbar-types";
import {DialogWrapComponent} from "../dialog-wrap/dialog-wrap.component";
import {FuseAlertComponent} from "../../../../../@fuse/components/alert";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
    selector: 'app-generic-dialog',
    standalone: true,
    imports: [CommonModule, DialogWrapComponent, FormComponent, FuseAlertComponent, MatInputModule, MatButtonModule, MatProgressSpinnerModule, MatDialogClose],
    templateUrl: './generic-dialog.component.html',
    styleUrl: './generic-dialog.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class GenericDialogComponent {

    dialogForm = new FormGroup({});
    formExist = false;
    @ViewChild(FormComponent) formComponent: FormComponent;
    alert$: BehaviorSubject<string> = new BehaviorSubject('');
    touched: boolean;

    constructor(public formService: FormModuleService,
                @Inject(MAT_DIALOG_DATA) public data: GenericComponentDialogConfig,
                private fuseConfirmationService: FuseConfirmationService,
                public dialogRef: MatDialogRef<GenericDialogComponent>) {
    }

    ngOnInit(): void {
        this.formExist = !!this.data && !isEmpty(this.data.formConfig);

    }

    @HostListener('keydown.F10', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        if(!this.formComponent?.formGroup?.disabled) {
            this.saveForm();
        }
    }


    saveForm() {
        const formIsValid = this.formComponent.formGroup.valid;
        if (formIsValid) {
            this.alert$.next('')
            this.data.actions.confirm.function(this.formComponent.formGroup, this.dialogRef, this.alert$)
        } else {
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
            this.touched = true
            this.formComponent.formGroup.markAllAsTouched();
        }
    }

    closeModal($event: void) {
        this.dialogRef.close();
    }
}
