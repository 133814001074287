import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {AttrezzaturaView} from "../../../../api-clients/generated/services";
import {showModificaIfIsManule} from "./configurazione-tabella-clienti";

export function createTableConfigurationForAttrezzature(inputList?: Array<AttrezzaturaView>,
                                                        isSelection?: boolean, listLength?: number, pageSize?: number,
                                                        pageIndex?: number, isCancellato?: boolean): GenericTableConfigurationModel {
    let list = inputList;
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            pageIndex: pageIndex,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Descrizione',
                    colonnaKey: 'descrizione',
                    flex: 45,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'Quantità',
                    colonnaKey: 'quantita',
                    flex: 45,
                },
                {
                    hideColonna: isSelection,
                    nomeColonna: 'Modifica',
                    colonnaKey: 'modifica',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'edit',
                        click: TipoClickEnum.EDIT,
                        tooltip: 'Modifica l\'attrezzatura',
                        color: 'accent'
                    }],
                },
                {
                    hideColonna: isSelection || isCancellato,
                    nomeColonna: 'Cancella',
                    colonnaKey: 'removeAttrezzatura',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'delete',
                        click: TipoClickEnum.DELETE,
                        tooltip: 'Cancella l\'attrezzatura',
                        color: 'accent'
                    }],
                },
                {
                    hideColonna: isSelection || !isCancellato,
                    nomeColonna: 'Ripristina',
                    colonnaKey: 'addAttrezzatura',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'add',
                        click: TipoClickEnum.RIPRISTINA,
                        tooltip: 'Ripristina l\'attrezzatura',
                        color: 'accent'
                    }],
                },
                {
                    hideColonna: !isSelection,
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}
