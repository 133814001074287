/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddAttrezzaturaInterventoDTO } from './addAttrezzaturaInterventoDTO';
import { AddAutomezzoInterventoDTO } from './addAutomezzoInterventoDTO';
import { AddClienteInterventoDTO } from './addClienteInterventoDTO';
import { AddNotaInterventoDTO } from './addNotaInterventoDTO';
import { AddOperatoreInterventoDTO } from './addOperatoreInterventoDTO';

export interface PatchInterventoVerificaDTO { 
    dataOraInizioODL?: Date;
    dataOraFineODL?: Date;
    dataOraInizioReale?: Date;
    dataOraFineReale?: Date;
    isPresentePausaPranzo?: boolean;
    isInterventoFormulario?: boolean;
    dataOraInizioPausaPranzo?: Date;
    dataOraFinePausaPranzo?: Date;
    isInterventoInReperibilita?: boolean;
    dataOraRichiestaReperibilita?: Date;
    mittenteReperibilita?: string;
    notaOrdineDiLavoro?: string;
    nominativoFirmaCliente?: string;
    idTipiIntervento?: Array<string>;
    costoIntervento?: number;
    numeroPreventivo?: string;
    referenteUnico?: string;
    referenteUnicoTel?: string;
    attrezzature?: Array<AddAttrezzaturaInterventoDTO>;
    note?: Array<AddNotaInterventoDTO>;
    operatori?: Array<AddOperatoreInterventoDTO>;
    automezzo?: AddAutomezzoInterventoDTO;
    rimorchio?: AddAutomezzoInterventoDTO;
    cliente?: AddClienteInterventoDTO;
}