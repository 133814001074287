import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, ApplicationConfig, importProvidersFrom, inject} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {provideAnimations} from '@angular/platform-browser/animations';
import {PreloadAllModules, provideRouter, withInMemoryScrolling, withPreloading} from '@angular/router';
import {provideFuse} from '@fuse';
import {provideTransloco, TranslocoService} from '@ngneat/transloco';
import {appRoutes} from 'app/app.routes';
import {provideIcons} from 'app/core/icons/icons.provider';
import {mockApiServices} from 'app/mock-api';
import {TranslocoHttpLoader} from './core/transloco/transloco.http-loader';
import {AppInitService} from "./services/app-init.service";
import {CUSTOM_DATE_FORMAT} from "./shared/constants/app-constants";
import {CustomDateAdapter} from "./services/custom-date-adapter.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {ApiModule, Configuration} from "../api-clients/generated/services";
import {environment} from "../environments/environment";
import {LocalStorageService} from "./services/local-storage.service";
import {RefreshTokenInterceptor} from "./interceptor/refresh-token.interceptor";
import {MatPaginatorIntl} from "@angular/material/paginator";
import {MatPaginatorIntlItalian} from "./shared/mat-paginator-intl-italian/mat-paginator-intl-italian";

/*importProvidersFrom(AppRoutingModule),
    provideHttpClient()*/
export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        importProvidersFrom(ApiModule),
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {provide: MatPaginatorIntl, useClass: MatPaginatorIntlItalian},
        provideRouter(appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({scrollPositionRestoration: 'enabled'}),
        ),
        {
            provide : DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide : MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMAT
        },
        {provide: MAT_DATE_LOCALE, useValue: 'it'},
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs      : [
                    {
                        id   : 'en',
                        label: 'English',
                    },
                    {
                        id   : 'tr',
                        label: 'Turkish',
                    },
                ],
                defaultLang         : 'en',
                fallbackLang        : 'en',
                reRenderOnLangChange: true,
                prodMode            : true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide   : APP_INITIALIZER,
            useFactory: (appInitService: AppInitService) =>
            {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                //return () => firstValueFrom(translocoService.load(defaultLang));
                return () => appInitService.init();
            },
            deps: [AppInitService],
            multi     : true,
        },

        // Fuse
        provideIcons(),
        provideFuse({
            mockApi: {
                delay   : 0,
                services: mockApiServices,
            },
            fuse   : {
                layout : 'compact',
                scheme : 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme  : 'theme-brand',
                themes : [
                    {
                        id  : 'theme-default',
                        name: 'Default',
                    },
                    {
                        id  : 'theme-brand',
                        name: 'Brand',
                    },
                    {
                        id  : 'theme-teal',
                        name: 'Teal',
                    },
                    {
                        id  : 'theme-rose',
                        name: 'Rose',
                    },
                    {
                        id  : 'theme-purple',
                        name: 'Purple',
                    },
                    {
                        id  : 'theme-amber',
                        name: 'Amber',
                    },
                ],
            },
        }),

        {
            provide: Configuration,
            useFactory: (localStorageService: LocalStorageService) => {
                return new Configuration(
                    {
                        basePath: environment.servicesUrl,
                        accessToken: (): string => localStorageService.getAccessToken(),
                    });
            },
            deps: [LocalStorageService],
            multi: false,
        },
    ],
};
