/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddAttrezzaturaInterventoDTO } from './addAttrezzaturaInterventoDTO';
import { AddAutomezzoInterventoDTO } from './addAutomezzoInterventoDTO';
import { AddClienteInterventoDTO } from './addClienteInterventoDTO';
import { AddNotaInterventoDTO } from './addNotaInterventoDTO';
import { AddOperatoreInterventoDTO } from './addOperatoreInterventoDTO';
import { AddTipoInterventoDTO } from './addTipoInterventoDTO';

export interface AddInterventoPassatoDTO { 
    dataOraInizio?: Date;
    dataOraFine?: Date;
    costo?: number;
    numeroPreventivo?: string;
    referenteUnico?: string;
    referenteUnicoTel?: string;
    isInterventoFormulario?: boolean;
    codiceODL?: number;
    automezzo?: AddAutomezzoInterventoDTO;
    rimorchio?: AddAutomezzoInterventoDTO;
    operatori?: Array<AddOperatoreInterventoDTO>;
    tipiIntervento?: Array<AddTipoInterventoDTO>;
    cliente?: AddClienteInterventoDTO;
    note?: Array<AddNotaInterventoDTO>;
    attrezzature?: Array<AddAttrezzaturaInterventoDTO>;
}