/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserView } from './userView';

export interface OperatoreView { 
    id?: string;
    intIsCancellato?: boolean;
    intNascostoInProgramma?: boolean;
    nomeCognome?: string;
    intIsManuale?: boolean;
    tipologia?: OperatoreView.TipologiaEnum;
    utente?: UserView;
}
export namespace OperatoreView {
    export type TipologiaEnum = 'AUTISTA' | 'OPERATORE';
    export const TipologiaEnum = {
        AUTISTA: 'AUTISTA' as TipologiaEnum,
        OPERATORE: 'OPERATORE' as TipologiaEnum
    };
}