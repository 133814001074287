import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostListener,
    Inject,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import {DialogWrapComponent, DialogWrapI} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {debounceTime, finalize, of, switchMap, takeUntil, tap} from "rxjs";
import {GenericTableComponent} from "../../components/generic-table/generic-table.component";
import {
    ClickEvent,
    GenericTableConfigurationModel,
    TipoClickEnum
} from "../../components/generic-table/generic-table-model";
import {CampoDaSelezionare} from "../creazione-intervento-modal/creazione-intervento-modal.component";
import {createTableConfigurationForClienti} from "../../utils/table-configurations/configurazione-tabella-clienti";
import {createTableConfigurationForAttrezzature} from "../../utils/table-configurations/configurazione-attrezzature";
import {
    createTableConfigurationForAutisti,
    createTableConfigurationForNote
} from "../../utils/table-configurations/configurazione-autisti";
import {createTableConfigurationForAutomezzi} from "../../utils/table-configurations/configurazione-automezzi";
import {
    createTableConfigurationForTipiIntervento
} from "../../utils/table-configurations/configurazione-tipi-intervento";
import {compact, join} from "lodash-es";
import {
    createTableConfigurationForRichiesteDaEvadere
} from "../../utils/table-configurations/configurazione-richieste-da-evadere";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {createTableConfigurationForReferenti} from "../../utils/table-configurations/configurazione-referenti-cliente";
import {
    createTableConfigurationForUnitaLocali
} from "../../utils/table-configurations/configurazione-tabella-unita-locali";
import {
    AnagraficheService,
    ClienteView,
    InterventoView,
    OperatoreView,
    ReferenteView,
    UnitaLocaleView
} from "../../../../api-clients/generated/services";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {paginate} from "../../utils/utils";
import {PageEvent} from "@angular/material/paginator";
import {ManageErrorService} from "../../../services/manage-error.service";
import {TableSelectionManagerService} from "../../../services/table-selection-manager.service";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";

@Component({
    selector: 'app-generic-form-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        GenericTableComponent,
        MatButtonModule,
        MatIconModule,
        MatTooltipModule,
        NgSwitch,
        NgSwitchCase
    ],
    templateUrl: './generic-select-form-modal.component.html',
    styleUrl: './generic-select-form-modal.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class GenericSelectFormModalComponent extends AbstractDefaultComponent implements OnInit, AfterViewInit, OnDestroy {
    wrapData: DialogWrapI = {
        title: '',
        icon: {
            name: '',
            color: 'primary'
        },
        close: true,
        hasSubHeader: true,
    };
    formGroup: FormGroup = new FormGroup({});
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    filteredData: CurrentData[];
    tableConfiguration: GenericTableConfigurationModel;
    isCliente: boolean;
    isUnitaLocale: boolean;
    isReferente: boolean;
    protected readonly CampoDaSelezionare = CampoDaSelezionare;
    currentPageSize: number = 10;
    updatedCliente: ClienteView | undefined;
    private _filterInputElementRef: ElementRef;

    @ViewChild('filterInput') set filterInput(filterInput: ElementRef) {
        if (filterInput) {
            this._filterInputElementRef = filterInput;
            this.renderer.selectRootElement(filterInput.nativeElement).focus();
        }
    };

    constructor(@Inject(MAT_DIALOG_DATA) public data: SelectModalForm,
                public dialogRef: MatDialogRef<GenericSelectFormModalComponent>,
                private _dialogManagerService: DialogManagerService,
                private dialog: MatDialog,
                protected fuseConfirmationService: FuseConfirmationService,
                private anagraficheService: AnagraficheService,
                private renderer: Renderer2,
                private manageErrorService: ManageErrorService,
                private tableSelectionManagerService: TableSelectionManagerService,
                private _changeDetectorRef: ChangeDetectorRef,
    ) {
        super();
        this.wrapData = {
            title: this.data?.title || 'Seleziona ' + this.data?.formConfig?.placeholder,
            icon: {
                name: 'playlist_add_check',
                color: 'primary'
            },
            close: true,
            hasSubHeader: true,
            hideConfirmSubHeader: true,
        };

    }

    ngOnInit(): void {
        console.log('idOperatoriDaEscludere', this.data.idOperatoriDaEscludere);
        this.isCliente = this.data.tableConfigurationType === CampoDaSelezionare.CLIENTE;
        this.isReferente = this.data.tableConfigurationType === CampoDaSelezionare.REFERENTE;
        this.isUnitaLocale = this.data.tableConfigurationType === CampoDaSelezionare.UNITA_LOCALE;
        if (!!this.data.formConfig) {
            this.formGroup.addControl(this.data.formConfig.name, new FormControl(this.data.formConfig.value));
            this.valueChange();
        }
        this.getData(this.data.datiCorrenti, 0, 10,
            this.getFormControl(this.data?.formConfig?.name)?.value)

        this.selectionFocusChanges();
    }

    selectionFocusChanges() {
        this.selectionRowModel();
        this.selectionFocus();
    }

    selectionRowModel() {
        this.tableSelectionManagerService.checkSelectionRowModel$.asObservable().pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    if (!!value) {
                        this._callTableEvent(value)
                    }
                }
            }
        )
    }

    selectionFocus() {
        this.tableSelectionManagerService.checkFocusOnSelectionFilterForm$.asObservable().pipe(
            takeUntil(this.destroy$)
        ).subscribe(
            {
                next: (value) => {
                    if (!!value) {
                        console.log('filter forcus called');
                        this.renderer.selectRootElement(this._filterInputElementRef.nativeElement).focus();
                    }
                }
            }
        )
    }

    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {

        const activeElement = document.activeElement as HTMLInputElement;

        // Navigazione destra
        if (event.key === "ArrowDown") {
            if (activeElement.id === 'filterInput') {
                event.preventDefault();
                this.tableSelectionManagerService.checkFocusOnTable$.next(true);
            }
        }
    }


    ngAfterViewInit() {

    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.tableSelectionManagerService.checkSelectionRowModel$.next(null);
        this.tableSelectionManagerService.checkFocusOnSelectionFilterForm$.next(null);
        this.tableSelectionManagerService.checkFocusOnTable$.next(null);
    }

    getFormControl(name: string): FormControl {
        return this.formGroup.get(name) as FormControl;
    }

    closeModal($event: void) {
        switch (this.data.tableConfigurationType) {
            case CampoDaSelezionare.REFERENTE:
                let result1: ClienteUnitaReferente = {
                    cliente: this.updatedCliente,
                    referente: null
                }
                this.dialogRef.close(result1);
                break;
            case CampoDaSelezionare.UNITA_LOCALE:
                let result2: ClienteUnitaReferente = {
                    cliente: this.updatedCliente,
                    unita: null
                }
                this.dialogRef.close(result2);
                break;
            default:
                this.dialogRef.close();
                break;
        }

    }

    valueChange() {
        this.getFormControl(this.data.formConfig.name)?.valueChanges?.pipe(
            debounceTime(1000),
        ).subscribe({
            next: (value) => {
                this.searchByRequest();
            }
        })
    }

    searchElement(inputElement: any, filterValue: string) {
        switch (this.data.tableConfigurationType) {
            case CampoDaSelezionare.REFERENTE:
                const referente = inputElement as ReferenteView;
                return join(compact([referente?.nominativo, referente?.telefono]))?.trim()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase() || '');
            case CampoDaSelezionare.UNITA_LOCALE:
                const unitalocale = inputElement as UnitaLocaleView;
                return join(compact([unitalocale?.indirizzo, unitalocale?.numero,
                    unitalocale?.frazione, unitalocale?.comune, unitalocale?.provincia]))?.trim()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase() || '');
            case CampoDaSelezionare.ATTIVITA_DA_EVADERE:
            case CampoDaSelezionare.ATTIVITA_DA_EVADERE_READONLY:
                const intervento = inputElement as InterventoView;
                return join(compact([intervento?.cliente?.ragioneSociale,
                    intervento?.cliente?.estensioneRagioneSociale, intervento?.cliente?.alias]))?.trim()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase() || '');
            case CampoDaSelezionare.OPERATORE_REGISTRO:
                const operatore = inputElement as OperatoreView;
                return operatore.nomeCognome?.trim()?.toLowerCase()?.includes(filterValue?.trim()?.toLowerCase() || '');

        }
    }

    _callTableEvent(value: any) {
        this.handleTableEvent({
            tipoClick: TipoClickEnum.CONFERMA,
            value: value,
        });
    }

    handleTableEvent($event: ClickEvent) {
        console.log('AAA $event', $event);
        if ($event.tipoClick === TipoClickEnum.SHOW) {
            if (this.data.tableConfigurationType === CampoDaSelezionare.ATTIVITA_DA_EVADERE
                || this.data.tableConfigurationType === CampoDaSelezionare.ATTIVITA_DA_EVADERE_READONLY) {
                this._dialogManagerService.openModalInfoInterventoNotCalendar($event.value);
            }
        } else {
            switch (this.data.tableConfigurationType) {
                case CampoDaSelezionare.REFERENTE:
                    let result1: ClienteUnitaReferente = {
                        cliente: this.updatedCliente,
                        referente: $event.value
                    }
                    this.dialogRef.close(result1);
                    break;
                case CampoDaSelezionare.UNITA_LOCALE:
                    let result2: ClienteUnitaReferente = {
                        cliente: this.updatedCliente,
                        unita: $event.value
                    }
                    this.dialogRef.close(result2);
                    break;
                default:
                    this.dialogRef.close($event);
                    break;
            }
        }
    }

    openModalCreazioneCliente() {
        this._dialogManagerService.openModalCreazioneCliente(undefined, false,
            'add', 'Crea Cliente', true, undefined, true).afterClosed().pipe(
            switchMap((value) => {
                if (!!value && value !== 'cancelled') {
                    this.getFormControl(this.data.formConfig.name).setValue(null, {emitEvent: false})
                    return this.getDatiCliente$();
                } else {
                    return of(value)
                }
            })
        ).subscribe();
    }

    getDatiCliente$() {
        this.fuseConfirmationService.showLoader();
        return this.anagraficheService.getClientiForm(undefined, undefined,
            false, 0, this.currentPageSize || 10).pipe(
            tap((clienti) => this.tableConfiguration =
                createTableConfigurationForClienti(clienti.content, true, clienti.totalElements, clienti.size, 0)),
            finalize(() => this.fuseConfirmationService.hideLoader())
        );
    }

    openModaleCreazioneUnitaLocale() {
        this._dialogManagerService.openModalCreazioneUnitaLocale(this.data?.idCliente, false).afterClosed().pipe(
            tap((value) => {
                if (!!value && value !== 'cancelled') {
                    this.getFormControl(this.data.formConfig.name).setValue(null, {emitEvent: false});
                    this.data.datiCorrenti = (value as ClienteView)?.unitaLocali;
                    this.updatedCliente = value;
                    this.searchByRequest();
                }
            })
        ).subscribe();
    }

    openModaleCreazioneReferente() {
        this._dialogManagerService.openModalCreazioneReferenteCliente(this.data?.idCliente).afterClosed().pipe(
            tap((value) => {
                if (!!value && value !== 'cancelled') {
                    this.getFormControl(this.data.formConfig.name).setValue(null, {emitEvent: false});
                    this.data.datiCorrenti = (value as ClienteView)?.referenti;
                    this.updatedCliente = value;
                    this.searchByRequest();
                }
            })
        ).subscribe();
    }

    openModaleCreazioneAttrezzatura() {
        this._dialogManagerService.openModaleCreazioneAttrezzatura().afterClosed().pipe(
            switchMap((value) => {
                if (!!value && value !== 'cancelled') {
                    this.getFormControl(this.data.formConfig.name).setValue(null, {emitEvent: false})
                    return this.getDatiAttrezzatura$();
                } else {
                    return of(value)
                }
            })
        ).subscribe();
    }

    getDatiAttrezzatura$() {
        this.fuseConfirmationService.showLoader();
        return this.anagraficheService.getAttrezzatureForm(undefined, false, 0, this.currentPageSize || 10).pipe(
            tap((attrezzature) => this.tableConfiguration =
                createTableConfigurationForAttrezzature(attrezzature.content, true, attrezzature.totalElements, attrezzature.size, 0)),
            finalize(() => this.fuseConfirmationService.hideLoader())
        );
    }

    getData(data: Array<CurrentData> = [], pageIndex: number = 0, pageSize = 10, filterValue: string) {
        filterValue = !!filterValue ? filterValue : undefined;
        switch (this.data.tableConfigurationType) {
            case CampoDaSelezionare.CLIENTE :
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getClientiForm(filterValue, undefined,
                    false, pageIndex, pageSize).pipe(
                    tap((clienti) => this.tableConfiguration =
                        createTableConfigurationForClienti(clienti.content, true, clienti.totalElements, clienti.size, pageIndex)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.TIPOLOGIA_INTERVENTO:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getAddebitiForm(filterValue, false, pageIndex, pageSize).pipe(
                    tap((addebiti) => this.tableConfiguration =
                        createTableConfigurationForTipiIntervento(addebiti.content, true, addebiti.totalElements, addebiti.size, pageIndex)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.ATTREZZATURA:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getAttrezzatureForm(filterValue, false, pageIndex, pageSize).pipe(
                    tap((attrezzature) => this.tableConfiguration =
                        createTableConfigurationForAttrezzature(attrezzature.content, true, attrezzature.totalElements, attrezzature.size, pageIndex)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.AUTOMEZZO:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getAutomezziForm(filterValue, undefined, undefined, undefined,
                    undefined, false, false, undefined, undefined, pageIndex, pageSize).pipe(
                    tap((automezzi) => this.tableConfiguration =
                        createTableConfigurationForAutomezzi(
                            automezzi.content,
                            true, automezzi.totalElements, automezzi.size, pageIndex, false)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.RIMORCHIO:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getAutomezziForm(filterValue, undefined, undefined, undefined,
                    undefined, true, false, undefined, false, pageIndex, pageSize).pipe(
                    tap((automezzi) => this.tableConfiguration =
                        createTableConfigurationForAutomezzi(
                            automezzi.content, true, automezzi.totalElements, automezzi.size, pageIndex, true)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.OPERATORE:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getOperatoriForm(filterValue, undefined, false, false, pageIndex, pageSize).pipe(
                    tap((operatori) => this.tableConfiguration =
                        createTableConfigurationForAutisti(this.filterOperatoriEsclusi(operatori.content),
                            true, operatori.totalElements, operatori.size, pageIndex)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.NOTA:
                this.fuseConfirmationService.showLoader();
                this.anagraficheService.getNoteInterventoForm(filterValue, pageIndex, pageSize).pipe(
                    tap((note) => this.tableConfiguration =
                        createTableConfigurationForNote(note.content, true, note.totalElements, note.size, pageIndex)),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {

                    },
                    error: (error) => {
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
                break;
            case CampoDaSelezionare.ATTIVITA_DA_EVADERE:
                const attivitaDaEvadereFilteredData: InterventoView[] = data?.filter((element: InterventoView) => {
                    return this.searchElement(element, filterValue)
                })
                this.tableConfiguration = undefined;
                setTimeout(() => this.tableConfiguration = createTableConfigurationForRichiesteDaEvadere(
                    paginate(attivitaDaEvadereFilteredData, pageSize, pageIndex), attivitaDaEvadereFilteredData.length,
                    pageIndex,
                    pageSize,
                    true), 100);
                break;
            case CampoDaSelezionare.ATTIVITA_DA_EVADERE_READONLY:
                const attivitaDaEvadereFilteredDataRO: InterventoView[] = data?.filter((element: InterventoView) => {
                    return this.searchElement(element, filterValue)
                })
                this.tableConfiguration = undefined;
                setTimeout(() => this.tableConfiguration = createTableConfigurationForRichiesteDaEvadere(
                    paginate(attivitaDaEvadereFilteredDataRO, pageSize, pageIndex),
                    attivitaDaEvadereFilteredDataRO.length,
                    pageIndex,
                    pageSize,
                    false), 100);
                break;
            case CampoDaSelezionare.REFERENTE:
                const referenteFilteredData: ReferenteView[] = data?.filter((element) => {
                    return this.searchElement(element, filterValue)
                })
                this.tableConfiguration = undefined;
                setTimeout(() => this.tableConfiguration = createTableConfigurationForReferenti(paginate(referenteFilteredData, pageSize, pageIndex),
                    true, data.length, pageSize, pageIndex),
                    100)
                this._changeDetectorRef.detectChanges();
                break;
            case CampoDaSelezionare.UNITA_LOCALE:
                const unitaLocaleFilteredData: UnitaLocaleView[] = data?.filter((element) => {
                    return this.searchElement(element, filterValue)
                });
                this.tableConfiguration = undefined;
                setTimeout(() =>this.tableConfiguration = createTableConfigurationForUnitaLocali(paginate(unitaLocaleFilteredData, pageSize, pageIndex),
                    true, data.length, pageSize, pageIndex), 100);
                break;
            case CampoDaSelezionare.OPERATORE_REGISTRO:
                const operatoreRegistroFilteredData: OperatoreView[] = data?.filter((element) => {
                    return this.searchElement(element, filterValue)
                });
                this.tableConfiguration = undefined;
                setTimeout(() =>this.tableConfiguration = createTableConfigurationForAutisti(paginate(operatoreRegistroFilteredData, pageSize, pageIndex),
                    true, data.length, pageSize, pageIndex), 100);
                break;
            default:
                break;
        }
    }

    filterOperatoriEsclusi(operatori: OperatoreView[]): OperatoreView[] {
        return operatori.filter((operatore) => !this.data?.idOperatoriDaEscludere?.includes(operatore.id))
    }

    onPageAction($event: PageEvent) {
        console.log('AAA $event', $event);
        this.currentPageSize = $event.pageSize;
        this.getData(this.data.datiCorrenti, $event.pageIndex, $event.pageSize, this.getFormControl(this.data?.formConfig?.name)?.value)
    }

    searchByRequest() {
        this.getData(this.data.datiCorrenti, 0, this.currentPageSize, this.getFormControl(this.data?.formConfig?.name)?.value)
    }
}

export interface SelectModalForm {
    formConfig?: {
        placeholder?: string;
        name: string;
        value?: any;
        errorRequiredMessage?: string;
        isRequired?: boolean;
        fieldsToSearch?: Array<string>;
    },
    datiCorrenti?: Array<CurrentData>;
    tableConfigurationType?: CampoDaSelezionare;
    title?: string;
    idCliente?: string;
    idOperatoriDaEscludere?: Array<string>;
    onlyEnabledValues?: boolean;
}

export interface CurrentData {
    [key: string]: any;
}

export interface ClienteUnitaReferente {
    cliente: ClienteView;
    unita?: UnitaLocaleView;
    referente?: ReferenteView;
}

export interface TableItem {
    name: string;
}
