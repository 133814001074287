import { Injectable } from '@angular/core';
import {EMPTY, forkJoin, map, Observable, of, tap, timer, zip} from "rxjs";
import {LocalStorageService} from "./local-storage.service";
import {UserControllerService, UserView} from "../../api-clients/generated/services";

@Injectable({
  providedIn: 'root'
})
export class AppInitService {

    constructor(
        private localStorageService: LocalStorageService,
        private _userControllerService: UserControllerService,
    ) {
    }

    init() {
        return new Observable((subscriber) => {
            zip([
                this.init$(),
                timer(3000)
            ]).subscribe({
                next: (value) => {
                    subscriber.complete();
                },
                error: (err) => {
                    subscriber.complete();
                },
                complete: () => {
                    subscriber.complete();
                }
            });
        })
    }

    init$(): Observable<any> {
        const accessTokenFromStorage = this.localStorageService.getAccessToken(true);
        if (accessTokenFromStorage) {
            this.localStorageService.accessToken = accessTokenFromStorage;
            this.localStorageService.refreshToken = this.localStorageService.getRefreshToken(true);
            return this.getProfilo$();
        } else {
            return EMPTY;
        }
    }

    getProfilo$(): Observable<UserView> {
        return this._userControllerService.getUser().pipe(
            tap((userView: UserView) => this.localStorageService.setProfileResponse(userView)),
        );
    }
}
