import {Injectable} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormControl,
    FormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";
import {RigaInterventoForm, RigaProgrammazioneForm} from "../shared/interfaces/forms-interface";
import moment from "moment/moment";
import {
    getNotaGenericaFromInterventoDiTipoNota,
    getTestoAttrezzatura,
    getTestoAutomezzo,
    getTestoCliente,
    getTestoNotaFromNotaProgrammaDTO,
    getTestoOperatore,
    getTestoReferente,
    getTestoTipoIntervento,
    getTestoUnitaLocale
} from "../shared/utils/mask-functions";
import {
    ClienteProgrammaDTO,
    InterventoProgrammaDTO,
    NotaProgrammaDTO,
    ProgrammaDTO,
    RigaProgrammaDTO
} from "../../api-clients/generated/services";
import {
    getFormArrayFromFormGroupTyped,
    getFormControlFromFormGroupTyped,
    getFormGroupFromFormGroupTyped, orderBydataOraInizioPrevista, orderByDescrizione,
    orderByOrdine
} from "../shared/utils/utils";
import {compact} from "lodash-es";
import {BehaviorSubject} from "rxjs";
import {CustomValidators} from "../shared/validators/custom-validators";

@Injectable({
    providedIn: 'root'
})
export class ProgrammazioneFormsManagerService {

    checkRefreshProgramma$ = new BehaviorSubject(null);

    constructor() {
    }

    createRigaProgrammazioneFormGroup(rigaProgramma?: RigaProgrammaDTO, dataDelProgramma?: string): FormGroup<RigaProgrammazioneForm> {
        const myForm = new FormGroup<RigaProgrammazioneForm>({
            //id: new FormControl(rigaProgramma?.id),
            ordine: new FormControl(rigaProgramma?.ordine),
            //idInterventoInizio: new FormControl(rigaProgramma?.idInterventoInizio),
            //idInterventoRientro: new FormControl(rigaProgramma?.idInterventoRientro),
            automezzo: new FormControl(rigaProgramma?.automezzo),
            rimorchio: new FormControl(rigaProgramma?.rimorchio),
            dataInizioAutomezzo: new FormControl(
                !!rigaProgramma?.inizio ?
                    moment(rigaProgramma?.inizio)?.format('YYYY-MM-DD')
                    : moment(dataDelProgramma, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
            ),
            oraInizioAutomezzo: new FormControl(rigaProgramma?.inizio),
            oraInizioAutomezzoMask: new FormControl(
                moment(rigaProgramma?.inizio)?.format('HH:mm')),
            operatori: new FormArray(!!rigaProgramma?.operatori && rigaProgramma?.operatori?.length > 0 ?
                orderByOrdine(rigaProgramma?.operatori)?.map((item) => createFormControlWithValidator(item)) : [createFormControlWithValidator()]),
            interventi: new FormArray(!!this.filterInterventiDiTipoAttivita(rigaProgramma?.interventi)
            && this.filterInterventiDiTipoAttivita(rigaProgramma?.interventi)?.length > 0 ?
                orderBydataOraInizioPrevista(this.filterInterventiDiTipoAttivita(rigaProgramma?.interventi))?.map((item) =>
                    this.createInterventoFormGroup(item, InterventoProgrammaDTO.TipologiaEnum.ATTIVITA, dataDelProgramma))
                : []),
            notaGenerica: new FormControl(
                !!this.findInterventoDiTipoNota(rigaProgramma?.interventi) ?
                    getNotaGenericaFromInterventoDiTipoNota(this.findInterventoDiTipoNota(rigaProgramma?.interventi)) : null),
            interventoDiTipoNota: this.createInterventoFormGroup(this.findInterventoDiTipoNota(rigaProgramma?.interventi),
                InterventoProgrammaDTO.TipologiaEnum.NOTA, dataDelProgramma),

            automezzoMask: new FormControl(getTestoAutomezzo(rigaProgramma?.automezzo)),
            rimorchioMask: new FormControl(getTestoAutomezzo(rigaProgramma?.rimorchio)),
            operatoriMask: new FormArray(!!rigaProgramma?.operatori && rigaProgramma?.operatori?.length > 0 ?
                orderByOrdine(rigaProgramma?.operatori)?.map((item) =>
                    createFormControlWithValidator(getTestoOperatore(item))) : [createFormControlWithValidator()]),
        }, {validators: this.checkValidator()});

        return myForm;
    }

    filterInterventiDiTipoAttivita(interventi?: InterventoProgrammaDTO[]) {
        return interventi?.filter((intervento) => intervento?.tipologia === InterventoProgrammaDTO.TipologiaEnum.ATTIVITA);
    }

    findInterventoDiTipoNota(interventi?: InterventoProgrammaDTO[]) {
        return interventi?.find((intervento) => intervento?.tipologia === InterventoProgrammaDTO.TipologiaEnum.NOTA);
    }

    createInterventoFormGroup(intervento?: InterventoProgrammaDTO,
                              tipologia?: InterventoProgrammaDTO.TipologiaEnum,
                              dataDelProgramma?: string) {
        const myFormIntervento = new FormGroup<RigaInterventoForm>({
                ordine: new FormControl(intervento?.ordine),
                idProgrammaDTO: new FormControl(intervento?.id),
                dataOraInizioPrevista: new FormControl(intervento?.dataOraInizioPrevista),
                dataOraFinePrevista: new FormControl(intervento?.dataOraFinePrevista),
                stato: new FormControl(intervento?.stato),
                tipologia: new FormControl(intervento?.tipologia || tipologia),

                oraInizio: new FormControl(!!intervento?.dataOraInizioPrevista ?
                    moment(intervento?.dataOraInizioPrevista).format('HH:mm') : null,
                ),
                oraFine: new FormControl(!!intervento?.dataOraFinePrevista ?
                    moment(intervento?.dataOraFinePrevista).format('HH:mm') : null,
                ),
                dataInizio: new FormControl(!!intervento?.dataOraInizioPrevista ?
                    moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD')
                    : moment(dataDelProgramma, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
                ),
                dataFine: new FormControl(!!intervento?.dataOraFinePrevista ?
                    moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') :
                    moment(dataDelProgramma, 'DD/MM/YYYY')?.format('YYYY-MM-DD')
                ),
                tipoInterventoArray: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                    orderByDescrizione(intervento?.tipiIntervento)?.map((item) => this.createFormControlByTipologia(tipologia, item))
                    : [this.createFormControlByTipologia(tipologia)]),
                tipoInterventoArrayMask: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                    orderByDescrizione(intervento?.tipiIntervento)?.map((item) =>
                        this.createFormControlByTipologia(tipologia, getTestoTipoIntervento(item)))
                    : [this.createFormControlByTipologia(tipologia)]),
                costo: new FormControl(intervento?.costo),
                numeroPreventivo: new FormControl(intervento?.numeroPreventivo),
                referenteUnico: new FormControl(intervento?.referenteUnico),
                referenteUnicoTel: new FormControl(intervento?.referenteUnicoTel),
                cliente: new FormControl(intervento?.cliente),
                attrezzature: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                    orderByOrdine(intervento?.attrezzature)?.map((item) => createFormControlWithoutValidator(item))
                    : [createFormControlWithoutValidator()]),
                noteRigaIntervento: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                    orderByOrdine(intervento?.note)?.map((item) => createFormControlWithoutValidator(item))
                    : [createFormControlWithoutValidator()]),
                idRichiestaDaEvadere: new FormControl(intervento?.idRichiestaDaEvadere),
                clienteMask: new FormControl(getTestoCliente(intervento?.cliente)),
                attrezzatureMask: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                    orderByOrdine(intervento?.attrezzature)?.map((item) =>
                        createFormControlWithoutValidator(getTestoAttrezzatura(item)))
                    : [createFormControlWithoutValidator()]),
                noteRigaInterventoMask: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                    orderByOrdine(intervento?.note)?.map((item: NotaProgrammaDTO) =>
                        createFormControlWithoutValidator(getTestoNotaFromNotaProgrammaDTO(item)))
                    : [createFormControlWithoutValidator()]),

                referenteCliente: new FormControl(intervento?.cliente?.referente),
                referenteClienteMask: new FormControl(getTestoReferente(intervento?.cliente?.referente)),
                unitaLocale: new FormControl(intervento?.cliente?.unitaLocale),
                unitaLocaleMask: new FormControl(getTestoUnitaLocale(intervento?.cliente?.unitaLocale)),
                isInterventoFormulario: new FormControl(intervento?.isInterventoFormulario)
            }, CustomValidators.comparisonOrarioEGiornoValidator(
                'oraInizio', 'oraFine', 'dataInizio', 'dataFine'
            )
        );
        return myFormIntervento;
    }

    createFormControlByTipologia(tipologia?: InterventoProgrammaDTO.TipologiaEnum, item?: any) {
        return (tipologia === InterventoProgrammaDTO.TipologiaEnum.NOTA) ? createFormControlWithoutValidator(item)
            : createFormControlWithValidator(item);
    }

    createInterventoIDFormGroup(intervento?: InterventoProgrammaDTO) {
        return new FormGroup({
            id: new FormControl(intervento?.id),
        });
    }


    checkValidator(): ValidatorFn {
        return (group: FormGroup<RigaProgrammazioneForm>): ValidationErrors => {
            const arrayOperatori = getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'operatori');
            const controlNotaGenerica = getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'notaGenerica');
            const arrayInterventi = getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'interventi');

            const condition1 = arrayOperatori.controls.some((control) => !!control.value);
            const condition2 = arrayInterventi.controls.length === 0;
            const condition3 = !!controlNotaGenerica.value
            return (condition2 && !condition3) ? {rigaVuota: true} :
                (condition1 && condition2 && condition3) ? {invalid: true} : undefined;
        };
    }

    mapRigheProgrammazioneFormArrayToProgrammaDTO(id: string, data: string, promemoria: string,
                                                  stato: ProgrammaDTO.StatoEnum,
                                                  righeFormArray: FormArray<FormGroup<RigaProgrammazioneForm>>): ProgrammaDTO {
        return {
            id: id,
            data: data,
            promemoria: promemoria,
            stato: stato,
            righe: righeFormArray.controls.map((group: FormGroup<RigaProgrammazioneForm>, index) =>
                this.mapGroupToRigaProgramma(group, data, index))
        }
    }

    mapGroupToRigaProgramma(group: FormGroup<RigaProgrammazioneForm>, data: string, index: number): RigaProgrammaDTO {
        return {
            //id: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'id')?.value,
            //ordine: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'ordine')?.value,
            ordine: (index + 1),
            // idInterventoInizio: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'idInterventoInizio')?.value,
            // idInterventoRientro: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'idInterventoRientro')?.value,
            inizio: this.calcolaInizioRiga(
                getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'dataInizioAutomezzo').value,
                getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'oraInizioAutomezzoMask')
            ),
            automezzo: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'automezzo')?.value,
            rimorchio: getFormControlFromFormGroupTyped<RigaProgrammazioneForm>(group, 'rimorchio')?.value,
            operatori: getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'operatori')?.value?.map((object) => {
                return {
                    ...object,
                    idOperatoreIntervento: undefined
                }
            }),
            interventi: orderBydataOraInizioPrevista(this.getInterventiForProgrammaDTO(group, data)).map((intervento, index) =>
                ({
                    ...intervento,
                    ordine: index + 1,
                })
            )
        }
    }

    getInterventiForProgrammaDTO(group: FormGroup<RigaProgrammazioneForm>, data: string): InterventoProgrammaDTO[] {
        if (!!getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'notaGenerica')?.value) {
            return [this.mapInterventoNotaToInterventoProgrammaDTO(getFormGroupFromFormGroupTyped<RigaProgrammazioneForm>(group, 'interventoDiTipoNota'),
                getFormGroupFromFormGroupTyped<RigaProgrammazioneForm>(group, 'notaGenerica').value)]
        } else {
            const sortedArray = getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'interventi').controls.sort(
                (a, b) =>
                    new Date(a.value?.dataOraInizioPrevista).getTime() - new Date(b?.value?.dataOraInizioPrevista).getTime()
            )
            group.setControl('interventi', new FormArray(sortedArray))

            return getFormArrayFromFormGroupTyped<RigaProgrammazioneForm>(group, 'interventi').controls.map(
                (_group: FormGroup<RigaInterventoForm>, index) =>
                    this.mapFormInterventoToInterventoProgrammaDTO(_group, data, index));
        }
    }

    mapFormInterventoToInterventoProgrammaDTO(_group: FormGroup<RigaInterventoForm>, data: string, index: number): InterventoProgrammaDTO {
        return {
            //ordine: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'ordine')?.value,
            ordine: (index + 1),
            // id: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'id')?.value,
            idRichiestaDaEvadere: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'idRichiestaDaEvadere')?.value,
            dataOraInizioPrevista: this.calcolaInizioRiga(
                getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'dataInizio')?.value,
                getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'oraInizio')),
            dataOraFinePrevista: this.calcolaInizioRiga(
                getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'dataFine')?.value,
                getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'oraFine')),
            stato: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'stato')?.value,
            tipologia: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'tipologia')?.value,
            costo: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'costo')?.value,
            numeroPreventivo: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'numeroPreventivo')?.value,
            referenteUnico: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'referenteUnico')?.value,
            referenteUnicoTel: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'referenteUnicoTel')?.value,

            tipiIntervento: !!getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'tipoInterventoArray').value
            && getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'tipoInterventoArray').value.length > 0 ?
                getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'tipoInterventoArray')?.value?.map((object) => {
                    return {
                        ...object,
                        id: object.id,
                    }
                }) : undefined,
            cliente: this.mapFormGroupInterventoToClienteProgrammaDTO(_group),
            //note: getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaIntervento')?.value,
            note: this.mapNote(_group),
            attrezzature: !!getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'attrezzatureMask').value &&
            compact(getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'attrezzatureMask').value).length > 0 ?
                getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'attrezzature')?.value?.map((object) => {
                    return {
                        ...object,
                        idAttrezzaturaIntervento: undefined,
                    }
                }) : undefined,
            isInterventoFormulario: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'isInterventoFormulario')?.value
        }
    }

    mapInterventoNotaToInterventoProgrammaDTO(_group: FormGroup<RigaInterventoForm>, notaGenerica: string): InterventoProgrammaDTO {
        const ctrlNota: FormControl<NotaProgrammaDTO> =
            getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaIntervento')?.at(0) as FormControl<NotaProgrammaDTO>
        return {
            //ordine: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'ordine')?.value,
            ordine: 1,
            // id: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'id')?.value,
            idRichiestaDaEvadere: undefined,
            dataOraInizioPrevista: undefined,
            dataOraFinePrevista: undefined,
            stato: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'stato')?.value,
            tipologia: InterventoProgrammaDTO.TipologiaEnum.NOTA,
            costo: undefined,
            tipiIntervento: undefined,
            cliente: undefined,
            note: [{
                ...getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaIntervento')?.at(0)?.value,
                nota: notaGenerica,
                ordine: 1,
                idNotaIntervento: undefined,
                idNotaInterventoBucket: checkUpdateNota(
                    ctrlNota,
                    notaGenerica) ? ctrlNota.value?.idNotaInterventoBucket : undefined,
            }],
            attrezzature: undefined,
        }
    }

    mapNote(_group: FormGroup<RigaInterventoForm>): Array<NotaProgrammaDTO> {
        return !!getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaInterventoMask').value &&
        compact(getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaInterventoMask').value).length > 0 ?
            getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaInterventoMask')?.controls
                .map((notaTestuale, index) => {
                    let ctrlNotaByIndex: FormControl<NotaProgrammaDTO> =
                        getFormArrayFromFormGroupTyped<RigaInterventoForm>(_group, 'noteRigaIntervento')?.at(index) as FormControl<NotaProgrammaDTO>
                        return {
                            ...ctrlNotaByIndex.value,
                            idNotaIntervento: undefined,
                            nota: notaTestuale?.value,
                            idNotaInterventoBucket: checkUpdateNota(
                                ctrlNotaByIndex,
                                notaTestuale?.value) ? ctrlNotaByIndex?.value?.idNotaInterventoBucket : undefined,
                        }
                    }
                ).filter((item) => !!item.nota).map((nota, index) => {
                return {
                    ...nota,
                    ordine: index + 1
                }
            }) : undefined
    }

    getCliente(_group: FormGroup<RigaInterventoForm>): ClienteProgrammaDTO {
        return getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'cliente')?.value
    }

    mapFormGroupInterventoToClienteProgrammaDTO(_group: FormGroup<RigaInterventoForm>): ClienteProgrammaDTO {
        return {
            id: this.getCliente(_group)?.id,
            ragioneSociale: this.getCliente(_group)?.ragioneSociale,
            estensioneRagioneSociale: this.getCliente(_group)?.estensioneRagioneSociale,
            alias: this.getCliente(_group)?.alias,
            codiceFiscale: this.getCliente(_group)?.codiceFiscale,
            partitaIva: this.getCliente(_group)?.partitaIva,
            unitaLocale: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'unitaLocale')?.value,
            referente: getFormControlFromFormGroupTyped<RigaInterventoForm>(_group, 'referenteCliente')?.value,
        }
    }


    calcolaInizioRiga(data: string, controlOrario: FormControl) {
        //moment('04/04/2024', 'DD/MM/YYYY').hours(11).minutes(0).toDate(),
        let arr = controlOrario?.value?.split(':')
        let hour = +arr[0]
        let minute = +arr[1]
        console.log('DATA E ORA CALCOLATE', moment(data, 'YYYY-MM-DD').hours(hour).minutes(minute).toDate());
        return moment(data, 'YYYY-MM-DD').hours(hour).minutes(minute).toDate();
    }
}


export function createFormControlWithValidator(value?: any) {
    return new FormControl<any | null>(value, {
        validators: [Validators.required]
    });
}

export function createFormControlWithoutValidator(value?: any) {
    return new FormControl<any | null>(value);
}


export function checkUpdateNota(notaProgrammaCtrl: FormControl<NotaProgrammaDTO>, testoNota: string) {
    return notaProgrammaCtrl?.value?.nota === testoNota;
}
