/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddInterventoDTO } from '../model/addInterventoDTO';
import { AddInterventoDaEvadereDTO } from '../model/addInterventoDaEvadereDTO';
import { AddInterventoPassatoDTO } from '../model/addInterventoPassatoDTO';
import { AvviaInterventoDTO } from '../model/avviaInterventoDTO';
import { EvadiInterventoDTO } from '../model/evadiInterventoDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { InterventiHomeStats } from '../model/interventiHomeStats';
import { InterventoView } from '../model/interventoView';
import { PatchInterventoConsolidamentoDTO } from '../model/patchInterventoConsolidamentoDTO';
import { PatchInterventoDTO } from '../model/patchInterventoDTO';
import { PatchInterventoMovimentoDTO } from '../model/patchInterventoMovimentoDTO';
import { PatchInterventoVerificaDTO } from '../model/patchInterventoVerificaDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InterventiService {

    protected basePath = 'https://api-dev-gestioneinterventi.southengineering.it/gestione-interventi';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consente di aggiungere un intervento
     * 
     * @param body 
     * @param forzaAggiunta 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiungiIntervento(body: AddInterventoDTO, forzaAggiunta?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public aggiungiIntervento(body: AddInterventoDTO, forzaAggiunta?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public aggiungiIntervento(body: AddInterventoDTO, forzaAggiunta?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public aggiungiIntervento(body: AddInterventoDTO, forzaAggiunta?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiungiIntervento.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forzaAggiunta !== undefined && forzaAggiunta !== null) {
            queryParameters = queryParameters.set('forzaAggiunta', <any>forzaAggiunta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/interventi/aggiungi`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere un intervento da evadere
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiungiInterventoDaEvadere(body: AddInterventoDaEvadereDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public aggiungiInterventoDaEvadere(body: AddInterventoDaEvadereDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public aggiungiInterventoDaEvadere(body: AddInterventoDaEvadereDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public aggiungiInterventoDaEvadere(body: AddInterventoDaEvadereDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiungiInterventoDaEvadere.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/interventi/aggiungi-da-evadere`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiungere un intervento nel passato
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiungiInterventoNelPassato(body: AddInterventoPassatoDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public aggiungiInterventoNelPassato(body: AddInterventoPassatoDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public aggiungiInterventoNelPassato(body: AddInterventoPassatoDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public aggiungiInterventoNelPassato(body: AddInterventoPassatoDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiungiInterventoNelPassato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/interventi/aggiungi-nel-passato`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di annullare l&#x27;inizio di un intervento
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullaAvvioIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public annullaAvvioIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public annullaAvvioIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public annullaAvvioIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling annullaAvvioIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/annulla-avvio`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di annullare il consolidamento di un intervento.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullaConsolidamentoIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public annullaConsolidamentoIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public annullaConsolidamentoIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public annullaConsolidamentoIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling annullaConsolidamentoIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/annulla-consolidamento`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di annullare la verifica di un intervento.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullaVerificaIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public annullaVerificaIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public annullaVerificaIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public annullaVerificaIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling annullaVerificaIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/annulla-verifica`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di annullare un intervento
     * 
     * @param idIntervento 
     * @param forzaRimozioneExtraSosta 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public anullaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public anullaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public anullaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public anullaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling anullaIntervento.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forzaRimozioneExtraSosta !== undefined && forzaRimozioneExtraSosta !== null) {
            queryParameters = queryParameters.set('forzaRimozioneExtraSosta', <any>forzaRimozioneExtraSosta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/annulla`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di indicare l&#x27;inizio di un intervento
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public avviaIntervento(body: AvviaInterventoDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public avviaIntervento(body: AvviaInterventoDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public avviaIntervento(body: AvviaInterventoDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public avviaIntervento(body: AvviaInterventoDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling avviaIntervento.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling avviaIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/avvia`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di verificare se un intervento è avviabile
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkInterventoAvviabile(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public checkInterventoAvviabile(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public checkInterventoAvviabile(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public checkInterventoAvviabile(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling checkInterventoAvviabile.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('get',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/check-avviabile`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di verificare se un intervento da evadere è modificabile
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public checkModificaInterventoDaEvadere(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public checkModificaInterventoDaEvadere(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public checkModificaInterventoDaEvadere(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public checkModificaInterventoDaEvadere(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling checkModificaInterventoDaEvadere.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('get',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/check-modifica-da-evadere`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di settare un intervento verificato come consolidato.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public consolidaIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public consolidaIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public consolidaIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public consolidaIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling consolidaIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/consolida`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di rimuovere un intervento da evadere.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInterventoDaEvadere(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteInterventoDaEvadere(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteInterventoDaEvadere(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteInterventoDaEvadere(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling deleteInterventoDaEvadere.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/rimuovi-da-evadere`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di rimuovere un intervento nel passato.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInterventoNelPassato(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteInterventoNelPassato(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteInterventoNelPassato(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteInterventoNelPassato(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling deleteInterventoNelPassato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/rimuovi-nel-passato`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di scaricare l&#x27;allegato di un intervento.
     * 
     * @param idIntervento 
     * @param fileUri 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadAllegato(idIntervento: string, fileUri: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadAllegato(idIntervento: string, fileUri: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadAllegato(idIntervento: string, fileUri: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadAllegato(idIntervento: string, fileUri: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling downloadAllegato.');
        }

        if (fileUri === null || fileUri === undefined) {
            throw new Error('Required parameter fileUri was null or undefined when calling downloadAllegato.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (fileUri !== undefined && fileUri !== null) {
            queryParameters = queryParameters.set('fileUri', <any>fileUri);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/allegato`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di scaricare il pdf contenente la lista degli ODL del giorno indicato.
     * 
     * @param data Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadListaODL(data: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadListaODL(data: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadListaODL(data: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadListaODL(data: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling downloadListaODL.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/interventi/lista-odl`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di indicare l&#x27;evasione di un intervento
     * 
     * @param idIntervento 
     * @param evadiInterventoDTO 
     * @param firmaCliente 
     * @param multipartFile1 
     * @param multipartFile2 
     * @param multipartFile3 
     * @param multipartFile4 
     * @param multipartFile5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public evadiInterventoForm(idIntervento: string, evadiInterventoDTO?: EvadiInterventoDTO, firmaCliente?: Blob, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public evadiInterventoForm(idIntervento: string, evadiInterventoDTO?: EvadiInterventoDTO, firmaCliente?: Blob, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public evadiInterventoForm(idIntervento: string, evadiInterventoDTO?: EvadiInterventoDTO, firmaCliente?: Blob, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public evadiInterventoForm(idIntervento: string, evadiInterventoDTO?: EvadiInterventoDTO, firmaCliente?: Blob, multipartFile1?: Blob, multipartFile2?: Blob, multipartFile3?: Blob, multipartFile4?: Blob, multipartFile5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling evadiIntervento.');
        }








        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (evadiInterventoDTO !== undefined) {
            formParams = formParams.append('evadiInterventoDTO', new Blob([JSON.stringify(evadiInterventoDTO)], { type: 'application/json'})) as any || formParams;
        }
        if (firmaCliente !== undefined) {
            formParams = formParams.append('firmaCliente', <any>firmaCliente) as any || formParams;
        }
        if (multipartFile1 !== undefined) {
            formParams = formParams.append('multipartFile1', <any>multipartFile1) as any || formParams;
        }
        if (multipartFile2 !== undefined) {
            formParams = formParams.append('multipartFile2', <any>multipartFile2) as any || formParams;
        }
        if (multipartFile3 !== undefined) {
            formParams = formParams.append('multipartFile3', <any>multipartFile3) as any || formParams;
        }
        if (multipartFile4 !== undefined) {
            formParams = formParams.append('multipartFile4', <any>multipartFile4) as any || formParams;
        }
        if (multipartFile5 !== undefined) {
            formParams = formParams.append('multipartFile5', <any>multipartFile5) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/evadi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di generare una extra sosta per un intervento
     * 
     * @param idIntervento 
     * @param durata 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generaExtraSostaIntervento(idIntervento: string, durata: number, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public generaExtraSostaIntervento(idIntervento: string, durata: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public generaExtraSostaIntervento(idIntervento: string, durata: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public generaExtraSostaIntervento(idIntervento: string, durata: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling generaExtraSostaIntervento.');
        }

        if (durata === null || durata === undefined) {
            throw new Error('Required parameter durata was null or undefined when calling generaExtraSostaIntervento.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (durata !== undefined && durata !== null) {
            queryParameters = queryParameters.set('durata', <any>durata);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/genera-extra-sosta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce le statistiche sugli Interventi settimanali e della settimana precedente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAccessiStatsHome(observe?: 'body', reportProgress?: boolean): Observable<InterventiHomeStats>;
    public getAccessiStatsHome(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InterventiHomeStats>>;
    public getAccessiStatsHome(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InterventiHomeStats>>;
    public getAccessiStatsHome(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InterventiHomeStats>('get',`${this.basePath}/api/v1/interventi/home/stats`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli Interventi con eventuali filtri.
     * Il formato dei filtri di tipo LocalDate è &#x27;dd/MM/yyyy&#x27;. Il filtraggio per data è valido solo se il valore del filtro è in &#x27;dataInizioPrevistaDa&#x27;.
     * @param idOperatore 
     * @param targaAutomezzo 
     * @param tipologieIntervento 
     * @param statiIntervento 
     * @param dataInizioPrevistaDa 
     * @param dataInizioPrevistaA 
     * @param dataFineOdlDa 
     * @param dataFineOdlA 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInterventiForm(idOperatore?: string, targaAutomezzo?: string, tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, dataInizioPrevistaDa?: string, dataInizioPrevistaA?: string, dataFineOdlDa?: string, dataFineOdlA?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InterventoView>>;
    public getInterventiForm(idOperatore?: string, targaAutomezzo?: string, tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, dataInizioPrevistaDa?: string, dataInizioPrevistaA?: string, dataFineOdlDa?: string, dataFineOdlA?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InterventoView>>>;
    public getInterventiForm(idOperatore?: string, targaAutomezzo?: string, tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, dataInizioPrevistaDa?: string, dataInizioPrevistaA?: string, dataFineOdlDa?: string, dataFineOdlA?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InterventoView>>>;
    public getInterventiForm(idOperatore?: string, targaAutomezzo?: string, tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, dataInizioPrevistaDa?: string, dataInizioPrevistaA?: string, dataFineOdlDa?: string, dataFineOdlA?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {









        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idOperatore !== undefined) {
            formParams = formParams.append('idOperatore', <any>idOperatore) as any || formParams;
        }
        if (targaAutomezzo !== undefined) {
            formParams = formParams.append('targaAutomezzo', <any>targaAutomezzo) as any || formParams;
        }
        if (tipologieIntervento) {
            tipologieIntervento.forEach((element) => {
                formParams = formParams.append('tipologieIntervento', <any>element) as any || formParams;
            })
        }
        if (statiIntervento) {
            statiIntervento.forEach((element) => {
                formParams = formParams.append('statiIntervento', <any>element) as any || formParams;
            })
        }
        if (dataInizioPrevistaDa !== undefined) {
            formParams = formParams.append('dataInizioPrevistaDa', <any>dataInizioPrevistaDa) as any || formParams;
        }
        if (dataInizioPrevistaA !== undefined) {
            formParams = formParams.append('dataInizioPrevistaA', <any>dataInizioPrevistaA) as any || formParams;
        }
        if (dataFineOdlDa !== undefined) {
            formParams = formParams.append('dataFineOdlDa', <any>dataFineOdlDa) as any || formParams;
        }
        if (dataFineOdlA !== undefined) {
            formParams = formParams.append('dataFineOdlA', <any>dataFineOdlA) as any || formParams;
        }

        return this.httpClient.request<Array<InterventoView>>('post',`${this.basePath}/api/v1/interventi`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli Interventi da evadere con eventuali filtri.
     * Il formato dei filtri di tipo LocalDate è &#x27;dd/MM/yyyy&#x27;. 
     * @param idAutomezzo 
     * @param idRimorchio 
     * @param idAutista 
     * @param isInseritoInProgramma 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInterventiDaEvadereForm(idAutomezzo?: string, idRimorchio?: string, idAutista?: string, isInseritoInProgramma?: boolean, data?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InterventoView>>;
    public getInterventiDaEvadereForm(idAutomezzo?: string, idRimorchio?: string, idAutista?: string, isInseritoInProgramma?: boolean, data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InterventoView>>>;
    public getInterventiDaEvadereForm(idAutomezzo?: string, idRimorchio?: string, idAutista?: string, isInseritoInProgramma?: boolean, data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InterventoView>>>;
    public getInterventiDaEvadereForm(idAutomezzo?: string, idRimorchio?: string, idAutista?: string, isInseritoInProgramma?: boolean, data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idAutomezzo !== undefined) {
            formParams = formParams.append('idAutomezzo', <any>idAutomezzo) as any || formParams;
        }
        if (idRimorchio !== undefined) {
            formParams = formParams.append('idRimorchio', <any>idRimorchio) as any || formParams;
        }
        if (idAutista !== undefined) {
            formParams = formParams.append('idAutista', <any>idAutista) as any || formParams;
        }
        if (isInseritoInProgramma !== undefined) {
            formParams = formParams.append('isInseritoInProgramma', <any>isInseritoInProgramma) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<Array<InterventoView>>('post',`${this.basePath}/api/v1/interventi/da-evadere`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli Interventi appartenenti al programma di una certa data (più quelli inseriti nel passato) con eventuali filtri.
     * Il formato dei filtri di tipo LocalDate è &#x27;dd/MM/yyyy&#x27;.
     * @param tipologieIntervento 
     * @param statiIntervento 
     * @param data 
     * @param idOperatore 
     * @param targaAutomezzo 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInterventiDaProgrammaForm(tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, data?: string, idOperatore?: string, targaAutomezzo?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InterventoView>>;
    public getInterventiDaProgrammaForm(tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, data?: string, idOperatore?: string, targaAutomezzo?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InterventoView>>>;
    public getInterventiDaProgrammaForm(tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, data?: string, idOperatore?: string, targaAutomezzo?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InterventoView>>>;
    public getInterventiDaProgrammaForm(tipologieIntervento?: Array<string>, statiIntervento?: Array<string>, data?: string, idOperatore?: string, targaAutomezzo?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {






        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (tipologieIntervento) {
            tipologieIntervento.forEach((element) => {
                formParams = formParams.append('tipologieIntervento', <any>element) as any || formParams;
            })
        }
        if (statiIntervento) {
            statiIntervento.forEach((element) => {
                formParams = formParams.append('statiIntervento', <any>element) as any || formParams;
            })
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }
        if (idOperatore !== undefined) {
            formParams = formParams.append('idOperatore', <any>idOperatore) as any || formParams;
        }
        if (targaAutomezzo !== undefined) {
            formParams = formParams.append('targaAutomezzo', <any>targaAutomezzo) as any || formParams;
        }

        return this.httpClient.request<Array<InterventoView>>('post',`${this.basePath}/api/v1/interventi/da-programma`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce l&#x27;intervento con id in input.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<InterventoView>;
    public getIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<InterventoView>>;
    public getIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<InterventoView>>;
    public getIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling getIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<InterventoView>('get',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di inviare la mail di fine intervento per l&#x27;intervento con id in input
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviaMailBozzaIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public inviaMailBozzaIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public inviaMailBozzaIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public inviaMailBozzaIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling inviaMailBozzaIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/invia-mail-intervento`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di inviare la mail con allegato odl agli interventi consolidati inviati in input.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public inviaMailOdl(body: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public inviaMailOdl(body: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public inviaMailOdl(body: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public inviaMailOdl(body: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling inviaMailOdl.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/invia-mail-odl`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di modificare un intervento da evadere
     * 
     * @param body 
     * @param tipoModificaInterventoDaEvadere 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaInterventoDaEvadere(body: AddInterventoDaEvadereDTO, tipoModificaInterventoDaEvadere: string, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public modificaInterventoDaEvadere(body: AddInterventoDaEvadereDTO, tipoModificaInterventoDaEvadere: string, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public modificaInterventoDaEvadere(body: AddInterventoDaEvadereDTO, tipoModificaInterventoDaEvadere: string, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public modificaInterventoDaEvadere(body: AddInterventoDaEvadereDTO, tipoModificaInterventoDaEvadere: string, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modificaInterventoDaEvadere.');
        }

        if (tipoModificaInterventoDaEvadere === null || tipoModificaInterventoDaEvadere === undefined) {
            throw new Error('Required parameter tipoModificaInterventoDaEvadere was null or undefined when calling modificaInterventoDaEvadere.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling modificaInterventoDaEvadere.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (tipoModificaInterventoDaEvadere !== undefined && tipoModificaInterventoDaEvadere !== null) {
            queryParameters = queryParameters.set('tipoModificaInterventoDaEvadere', <any>tipoModificaInterventoDaEvadere);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/modifica-da-evadere`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di modificare un intervento nel passato
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public modificaInterventoNelPassato(body: AddInterventoPassatoDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public modificaInterventoNelPassato(body: AddInterventoPassatoDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public modificaInterventoNelPassato(body: AddInterventoPassatoDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public modificaInterventoNelPassato(body: AddInterventoPassatoDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling modificaInterventoNelPassato.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling modificaInterventoNelPassato.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/modifica-nel-passato`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un intervento.
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchIntervento(body: PatchInterventoDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public patchIntervento(body: PatchInterventoDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public patchIntervento(body: PatchInterventoDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public patchIntervento(body: PatchInterventoDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchIntervento.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling patchIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un intervento verificato in fase di consolidamento.
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchInterventoConsolidamento(body: PatchInterventoConsolidamentoDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public patchInterventoConsolidamento(body: PatchInterventoConsolidamentoDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public patchInterventoConsolidamento(body: PatchInterventoConsolidamentoDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public patchInterventoConsolidamento(body: PatchInterventoConsolidamentoDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchInterventoConsolidamento.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling patchInterventoConsolidamento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/consolidamento`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un intervento di partenza o rientro già evaso.
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchInterventoMovimento(body: PatchInterventoMovimentoDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public patchInterventoMovimento(body: PatchInterventoMovimentoDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public patchInterventoMovimento(body: PatchInterventoMovimentoDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public patchInterventoMovimento(body: PatchInterventoMovimentoDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchInterventoMovimento.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling patchInterventoMovimento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/movimenti`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un intervento evaso in fase di verifica.
     * 
     * @param body 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchInterventoVerifica(body: PatchInterventoVerificaDTO, idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public patchInterventoVerifica(body: PatchInterventoVerificaDTO, idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public patchInterventoVerifica(body: PatchInterventoVerificaDTO, idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public patchInterventoVerifica(body: PatchInterventoVerificaDTO, idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchInterventoVerifica.');
        }

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling patchInterventoVerifica.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/verifica`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di spostare un intervento
     * 
     * @param idIntervento 
     * @param forzaRimozioneExtraSosta 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public spostaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public spostaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public spostaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public spostaIntervento(idIntervento: string, forzaRimozioneExtraSosta?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling spostaIntervento.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (forzaRimozioneExtraSosta !== undefined && forzaRimozioneExtraSosta !== null) {
            queryParameters = queryParameters.set('forzaRimozioneExtraSosta', <any>forzaRimozioneExtraSosta);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/sposta`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di settare un intervento evaso come verificato.
     * 
     * @param idIntervento 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaIntervento(idIntervento: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public verificaIntervento(idIntervento: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public verificaIntervento(idIntervento: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public verificaIntervento(idIntervento: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idIntervento === null || idIntervento === undefined) {
            throw new Error('Required parameter idIntervento was null or undefined when calling verificaIntervento.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi/${encodeURIComponent(String(idIntervento))}/verifica`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
