import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {MatDialog} from "@angular/material/dialog";
import {PathEnum} from "../shared/enum/path-enum";
import {Router} from "@angular/router";
import {UserView} from "../../api-clients/generated/services";
import {RuoloUtenteEnum} from "../shared/enum/ruolo-utente-enum";


@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    accessToken: string;
    refreshToken: string;
    profile: UserView;
    checkCurrentProfile$ = new BehaviorSubject(this.getProfileResponse());
    constructor(private matDialog: MatDialog,
                private _router: Router,) {

    }

    setTokenResponse(tokenResponse: any): void {
        localStorage.setItem(StorageProperties.ACCESS_TOKEN, tokenResponse.access_token);
        this.accessToken = tokenResponse.access_token;
        localStorage.setItem(StorageProperties.REFRESH_TOKEN, tokenResponse.refresh_token);
        this.refreshToken = tokenResponse.refresh_token;
    }

    removeTokenResponse(): void {
        localStorage.removeItem(StorageProperties.ACCESS_TOKEN);
        localStorage.removeItem(StorageProperties.REFRESH_TOKEN);
        this.accessToken = undefined;
        this.refreshToken = undefined;
    }

    getAccessToken(fromStorage?: boolean): string {
        if(fromStorage) {
        return localStorage.getItem(StorageProperties.ACCESS_TOKEN);
        }
        return this.accessToken;
    }

    getRefreshToken(fromStorage?: boolean): string {
        if(fromStorage) {
            return localStorage.getItem(StorageProperties.REFRESH_TOKEN);
        }
        return this.refreshToken;
    }

    cleanAllClassVariablesAndStorage(): void {
        this.removeTokenResponse();
        this.removeProfileResponse();
        this.matDialog?.closeAll();
    }

    check() {
        if (!this.getAccessToken() && !this.getAccessToken(true)) {
            // Redirect to the root
            //this._router.navigate(['/' + PathEnum.SIGN_IN]);

            // Prevent the access
            return of(false);
        }

        // Allow the access
        return of(true);
    }

    checkRuolo(roles: Array<string>) {
        if (!this.profile?.authorities?.find((authority) =>
            roles.includes(authority?.authority))) {
            return of(false);
        }

        return of(true);
    }

    isAdminOrCed() {
        const roles = [
            RuoloUtenteEnum.ADMIN, RuoloUtenteEnum.OPERATORE_CED
        ] as string[];
        return !!this.profile?.authorities?.find((authority) =>
            roles.includes(authority?.authority));
    }

    checkTipoRuolo(roles: Array<string>) {
        return !!this.profile?.authorities?.find((authority) =>
            roles.includes(authority?.authority));
    }

    setProfileResponse(profile: UserView) {
        localStorage.setItem(StorageProperties.PROFILE, JSON.stringify(profile));
        this.profile = profile;
        this.checkCurrentProfile$.next(profile);
    }

    getProfileResponse(fromStorage?: boolean): UserView {
        if(fromStorage) {
            return JSON.parse(localStorage.getItem(StorageProperties.PROFILE));
        } else {
            return this.profile;
        }
    }

    removeProfileResponse(): void {
        localStorage.removeItem(StorageProperties.PROFILE);
        this.profile = undefined;
    }
}

export enum StorageProperties {
    ACCESS_TOKEN = 'ACCESS_TOKEN',
    REFRESH_TOKEN = 'REFRESH_TOKEN',
    PROFILE = 'PROFILE',
}
