import {Component, Input, OnInit} from '@angular/core';
import {AggiornamentoInterventoView, InterventoView} from "../../../../api-clients/generated/services";
import {InputValueKeyComponent} from "../input-value-key/input-value-key.component";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {FuseAlertComponent} from "../../../../@fuse/components/alert";
import moment from "moment";
import {MatDividerModule} from "@angular/material/divider";

@Component({
  selector: 'app-annotazioni-intervento',
  standalone: true,
  imports: [
    InputValueKeyComponent,
    NgIf,
    FuseAlertComponent,
    NgForOf,
    MatDividerModule,
    NgClass
  ],
  templateUrl: './annotazioni-intervento.component.html',
  styleUrl: './annotazioni-intervento.component.scss'
})
export class AnnotazioniInterventoComponent implements OnInit{
  @Input({required: true}) intervento: InterventoView;
  aggiornamenti: Array<AggiornamentoInterventoView>;
  noAggiornamentiMessage = 'L\'intervento non presenta aggiornamenti';

  constructor() {
  }

  ngOnInit() {
    this.aggiornamenti = this.intervento?.aggiornamenti;
  }

  getOra(date: Date) {
    return moment(date).format('HH:mm');
  }

  getData(date: Date) {
    return moment(date).format('DD/MM/YYYY');
  }

}
