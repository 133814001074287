<app-dialog-wrap [data]="data" (close$)="closeModal($event)"
                 (confirm$)="onConfirm($event)">
    <div class="w-full flex flex-col gap-1" *ngIf="!!addebitoDurataFormGroup">

        <app-generic-form-field [label]="'Tipologia Intervento'" [style.width.%]="100"
                                [control]="ctrlAddebitoMask()"
                                [errorRequiredMessage]="errorRequiredMessage"
                                [readonly]="data?.readonly"
                                [readonlyform]="!!ctrlID()?.value"
                                [campoDaSelezionare]="CampoDaSelezionare.TIPOLOGIA_INTERVENTO"
                                (openSelect)="openModaleDiSelezione(CampoDaSelezionare.TIPOLOGIA_INTERVENTO)"
                                (setNullEmitter)="ctrlAddebitoMask().setValue(null); ctrlAddebito().setValue(null)"
        ></app-generic-form-field>

        <app-generic-form-field [label]="'Durata'" [style.width.%]="100"
                                [control]="ctrlDurata()"
                                [errorRequiredMessage]="errorRequiredMessage"
                                [readonly]="data?.readonly"
        ></app-generic-form-field>

    </div>
</app-dialog-wrap>
