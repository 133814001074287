<div class="relative flex flex-col w-full h-full px-3">
    <!-- Dismiss button -->
    <ng-container *ngIf="true">
        <div class="absolute top-0 right-0 pt-0.5 pr-3">
            <button class="border-radius"
                    mat-icon-button color="warn"
                    (click)="close$.emit()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <!-- lg:pb-6 lg:pt-6 -->
    <div class="flex flex-col flex-auto sm:items-start ">


        <div class="flex flex-col items-center mt-4 sm:mt-0 space-y-1 text-center sm:text-left w-full"
             style="align-self: center">

            <div class="flex flex-col w-full">
                <!--                    <div class=" flex flex-row items-start pl-6 pr-6">-->
                <div class=" flex flex-row items-center">
                    <!-- Icon -->
                    <ng-container *ngIf="data?.icon?.name">
                        <div class="flex-0 items-center justify-center lg:flex w-10 h-10 sm:mr-4 rounded-full hidden sm:flex"
                             [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                            }">
                            <mat-icon
                                class="text-current"
                                [svgIcon]="data.icon.name"></mat-icon>
                        </div>
                    </ng-container>

                    <!-- Title -->
                    <ng-container>
                        <div class="text-xl leading-6 font-medium lg:mt-2 lg:mb-2 lg:mr-4 align-self-center"
                             style="padding-left: 5px; padding-right: 35px;"
                             [innerHTML]="data.title| uppercase"></div>
                    </ng-container>

                </div>
            </div>

            <ng-container *ngIf="data.message">
                <div class="text-secondary font-semibold px-6 pt-3 text-left w-full"
                     [innerHTML]="data.message">
                </div>
            </ng-container>
            <div matDialogContent class="h-full w-full py-0.5 py-0_5-important flex flex-col">
                <ng-content></ng-content>
            </div>

        </div>

    </div>
</div>

<!-- ACTIONS -->
<div class="w-full" *ngIf="!!data?.hasSubHeader; else whiteFooter">
    <div
        class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div class="flex flex-col md:flex-row w-full justify-center sm:justify-end px-3 py-0.5 gap-3">
            <ng-container *ngIf="showOdl && canShowOdl">
                <button *ngIf="!!data?.formValue?.ordineDiLavoro?.uriOdl"
                    color="accent" mat-flat-button (click)="openOdl()" class="height-30-px">
                    {{ 'Visualizza ODL'}}
                    <mat-icon iconPositionEnd class="pl-1">visibility</mat-icon>
                </button>

                <button *ngIf="!!data?.formValue?.ordineDiLavoro?.uriImmagineFirmaCliente"
                    color="accent" mat-flat-button (click)="openFirma()" class="height-30-px">
                    {{ 'Visualizza Firma'}}
                    <mat-icon iconPositionEnd class="pl-1">visibility</mat-icon>
                </button>

                <button *ngIf="!!data?.formValue?.ordineDiLavoro?.uriOdl && !isInterventoFormulario"
                        color="accent" mat-flat-button (click)="inviaMailBozza()" class="height-30-px">
                    {{ 'Invia mail bozza'}}
                    <mat-icon iconPositionEnd class="pl-1">mail</mat-icon>
                </button>
            </ng-container>

            <ng-container *ngIf="!showOdl">
                <!-- Confirm -->
                <ng-container>
                    <button *ngIf="!data.hideConfirmSubHeader &&
                    ((statusChip === statusIncorso) &&
                    (dialogForInterventoType !== dialogTypeModifica))"
                            class="height-30-px"
                            mat-flat-button
                            (click)="confirm$.emit(DialogInterventoConfirmEnum.EVADI)"
                            [color]="data?.actions?.confirm?.color || 'primary'"
                            [disabled]="formComponent?.formGroup?.disabled">
                        <span>{{'Concludi'}}</span>
                        <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                                  iconPositionEnd>send
                        </mat-icon>
                    </button>

                    <button *ngIf="!data.hideConfirmSubHeader &&
                    statusChip === statusProgrammato && (dialogForInterventoType !== dialogTypeModifica)"
                            class="height-30-px"
                            mat-flat-button
                            (click)="confirm$.emit(DialogInterventoConfirmEnum.AVVIA)"
                            [color]="data?.actions?.confirm?.color || 'primary'"
                            [disabled]="formComponent?.formGroup?.disabled">
                        <span>{{'Avvia'}}</span>
                        <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                                  iconPositionEnd>check
                        </mat-icon>
                    </button>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="(dialogForInterventoType === dialogTypeModifica)">
                <button *ngIf="(tipologiaIntervento === InterventoTipologiaEnum.ATTIVITA) &&
                (statusChip === statusIncorso) && !isOrHasExtraSosta"
                        class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.CREA_EXTRA_SOSTA)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled">
                    <div class="flex flex-row items-center">
                        <span>{{'Crea Extra Sosta'}}</span>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>schedule
                    </mat-icon>
                </button>

                <button *ngIf="(tipologiaIntervento === InterventoTipologiaEnum.ATTIVITA) &&
                (statusChip === statusProgrammato) || isInterventoNelPassato"
                        class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.ELIMINA)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled">
                    <div class="flex flex-row items-center">
                        <span>{{'Elimina'}}</span>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>delete
                    </mat-icon>
                </button>

                <button *ngIf="(tipologiaIntervento === InterventoTipologiaEnum.ATTIVITA) &&
                (statusChip === statusProgrammato)"
                        class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.SPOSTA)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled">
                    <div class="flex flex-row items-center">
                        <span>{{'Sposta'}}</span>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>free_cancellation
                    </mat-icon>
                </button>

                <div [matTooltip]="!enableModifiche ? 'Nessuna modifica effettuata' : 'Conferma modifiche'">
                <button *ngIf="showConfermaModifiche()"
                        class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.PATCH_MODIFICA)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled || !enableModifiche">
                    <div class="flex flex-row items-center">
                        <span>{{'Conferma'}}</span>
                        <mat-progress-spinner
                                style="margin-left: 5px"
                                *ngIf="formComponent?.formGroup?.disabled"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>check
                    </mat-icon>
                </button>
                </div>
            </ng-container>

            <ng-container *ngIf="dialogForInterventoType === DialogForInterventoType.CREAZIONE">
                <button class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.CREAZIONE)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled">
                    <div class="flex flex-row items-center">
                        <span>{{'Crea intervento'}}</span>
                        <mat-progress-spinner
                                style="margin-left: 5px"
                                *ngIf="formComponent?.formGroup?.disabled"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>check
                    </mat-icon>
                </button>
            </ng-container>

            <ng-container *ngIf="!!isEvasoModificabile">
                <button class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.PATCH_MODIFICA_EVASO)"
                        [color]="data?.actions?.confirm?.color || 'primary'">
                    <div class="flex flex-row items-center">
                        <span>{{'Modifica'}}</span>
                        <mat-progress-spinner
                                style="margin-left: 5px"
                                *ngIf="formComponent?.formGroup?.disabled"
                                [diameter]="24"
                                [mode]="'indeterminate'"></mat-progress-spinner>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>check
                    </mat-icon>
                </button>
            </ng-container>

            <ng-container *ngIf="dialogForInterventoType === DialogForInterventoType.MODIFICA_MOVIMENTO">
                <button class="height-30-px"
                        mat-flat-button
                        (click)="confirm$.emit(DialogInterventoConfirmEnum.PATCH_MODIFICA_MOVIMENTO)"
                        [color]="data?.actions?.confirm?.color || 'primary'"
                        [disabled]="formComponent?.formGroup?.disabled">
                    <div class="flex flex-row items-center">
                        <span>{{'Modifica movimento'}}</span>
                        <mat-progress-spinner
                            style="margin-left: 5px"
                            *ngIf="formComponent?.formGroup?.disabled"
                            [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                    </div>
                    <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                              iconPositionEnd>check
                    </mat-icon>
                </button>
            </ng-container>


        </div>
    </div>
</div>

<ng-template #whiteFooter>
    <div class="flex w-full px-6 pb-2 space-x-3">

    </div>
</ng-template>
