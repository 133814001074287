export enum MenuItemTitleEnum {
    //MENU LATERALE

    STATISTICHE = 'STATISTICHE',
    GESTIONE_UTENTI = 'GESTIONE UTENTI',
    GESTIONE_ANAGRAFICHE = 'GESTIONE ANAGRAFICHE',
    PROGRAMMAZIONE_INTERVENTI = 'PROGRAMMAZIONE INTERVENTI',
    VALIDAZIONE_INTERVENTI_ESEGUITI = 'VALIDAZIONE INTERVENTI ESEGUITI',
    RIVALIDAZIONE_SU_BASE_CONTRATTUALE = 'RIVALIDAZIONE SU BASE CONTRATTUALE',
    REGISTRI_OPERATORI = 'REGISTRI OPERATORI',

    //NAVBAR
    RICHIESTE_DA_EVADERE = 'Richiesta Da Evadere',
    INTERVENTI = 'Intervento Imprevisto',
    ATTREZZATURE = 'Attrezzatura',
    CLIENTE = 'Cliente',
    PROGRAMMAZIONE = 'Programmazione',
    INTERVENTO_PASSATO = 'Intervento passato'
}
