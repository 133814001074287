/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Ruoli degli utenti. Ruoli previsti:   -*ADMIN*  -*OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO*  -*OPERATORE_CED*  -*OPERATORE_TRASPORTO*
 */
export type AuthorityType = 'ADMIN' | 'OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO' | 'OPERATORE_CED' | 'OPERATORE_TRASPORTO';

export const AuthorityType = {
    ADMIN: 'ADMIN' as AuthorityType,
    OPERATORELOGISTICAPROGRAMMAZIONEECONTROLLO: 'OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO' as AuthorityType,
    OPERATORECED: 'OPERATORE_CED' as AuthorityType,
    OPERATORETRASPORTO: 'OPERATORE_TRASPORTO' as AuthorityType
};