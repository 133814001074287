import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {UnitaLocaleView} from "../../../../api-clients/generated/services";
import {compact, join} from "lodash-es";


export function createTableConfigurationForUnitaLocali(inputList?: Array<UnitaLocaleView>, isSelection?: boolean,
                                                       listLength?: number,
                                                       pageSize?: number, pageIndex?: number): GenericTableConfigurationModel {
    let list = inputList.map((element) => {
        return {
            ...element,
            indirizzoCompleto: join(compact([element.indirizzo, element.numero]), ' '),
        }
    });
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Indirizzo',
                    colonnaKey: 'indirizzoCompleto',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Frazione',
                    colonnaKey: 'frazione',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Comune',
                    colonnaKey: 'comune',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'CAP',
                    colonnaKey: 'cap',
                    flex: 18,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Provincia',
                    colonnaKey: 'provincia',
                    flex: 18,
                },
                {
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            pageIndex: pageIndex,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}
