/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DurataAddebitoView } from './durataAddebitoView';
import { ReferenteView } from './referenteView';
import { UnitaLocaleView } from './unitaLocaleView';

export interface ClienteView { 
    id?: string;
    intIsBloccato?: boolean;
    referenti?: Array<ReferenteView>;
    durateAddebiti?: Array<DurataAddebitoView>;
    intIsCancellato?: boolean;
    codice?: string;
    estensioneRagioneSociale?: string;
    indirizzo?: string;
    numero?: string;
    comune?: string;
    provincia?: string;
    ragioneSociale?: string;
    cap?: string;
    frazione?: string;
    partitaIva?: string;
    codiceFiscale?: string;
    alias?: string;
    intIsManuale?: boolean;
    unitaLocali?: Array<UnitaLocaleView>;
    telefono?: string;
}