
export function endsWithPathUserPath(url: string): boolean {
    const targetPath = "/services-api/api/v1/user";
    return endsWithPath(url, targetPath)
}


export function endsWithPath(url: string, targetPath: string): boolean {
    try {
        const parsedUrl = new URL(url);
        const urlPath = parsedUrl.pathname;
        return urlPath.endsWith(targetPath);
    } catch (e) {
        console.error('Invalid URL:', e);
        return false;
    }
}
