import {AfterContentInit, Component, ElementRef, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {DialogWrapComponent} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet, UpperCasePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {
    FormArray,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    ɵFormGroupValue,
    ɵTypedOrUntyped
} from "@angular/forms";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import moment from "moment/moment";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {TipoClickEnum} from "../../components/generic-table/generic-table-model";
import {get, isEqual} from "lodash";
import {
    GenericComponentDialogConfig,
    InterventoComponentDialogConfig
} from "../../interfaces/generic-components.interface";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {compact, head, join} from "lodash-es";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {
    getTestoAutomezzo,
    getTestoCliente,
    getTestoNota,
    getTestoOperatore,
    getTestoReferente,
    getTestoUnitaLocale
} from "../../utils/mask-functions";
import {DialogForInterventoType, DialogInterventoConfirmEnum} from "../../enum/dialog-intervento-confirm-enum";
import {
    AddebitoView,
    AddInterventoPassatoDTO,
    AttrezzaturaInterventoView,
    ClienteView,
    InterventiService,
    InterventoProgrammaDTO,
    InterventoView,
    NotaInterventoBucketView,
    NotaInterventoView,
    OperatoreInterventoView,
    ReferenteIntervento,
    ReferenteView,
    UnitaLocaleIntervento,
    UnitaLocaleView
} from "../../../../api-clients/generated/services";
import {ClienteUnitaReferente} from "../generic-form-modal/generic-select-form-modal.component";
import {MatChipsModule} from "@angular/material/chips";
import {PipeModule} from "../../pipe/pipe.module";
import {
    getFormArrayFromFormGroupTyped,
    getFormControlFromFormGroupTyped,
    orderByDescrizione,
    orderByOrdine
} from "../../utils/utils";
import {catchError, finalize, forkJoin, Observable, of, takeUntil, tap} from "rxjs";
import {ManageErrorService} from "../../../services/manage-error.service";
import {MatTabsModule} from "@angular/material/tabs";
import {AnnotazioniInterventoComponent} from "../../components/annotazioni-intervento/annotazioni-intervento.component";
import {
    FormAvvioInterventiNonAttivitaComponent
} from "../../components/form-avvio-interventi-non-attivita/form-avvio-interventi-non-attivita.component";
import {InputFileComponent} from "../../components/input-file/input-file.component";
import {BlobManagerService} from "../../../services/blob-manager.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {
    DialogWrapInterventoComponent
} from "../../../layout/common/dialogs/dialog-wrap-intervento/dialog-wrap-intervento.component";
import {
    mapAddebitoViewToAddTipoInterventoDTO,
    mapAttrezzaturaInterventoToAddAttrezzaturaIntervento,
    mapAttrezzaturaViewToAttrezzaturaInterventoView,
    mapAutomezzoViewToAddAutomezzoInterventoDTO,
    mapClienteViewToAddClienteInterventoDTO,
    mapNotaInterventoBucketViewToNotaInterventoView,
    mapOperatoreInterventoViewToAddOperatoreInterventoDTO,
    mapOperatoreViewToOperatoreInterventoView,
    mapReferenteViewToReferenteIntervento,
    mapUnitaViewToUnitaLocaleIntervento
} from "../../utils/intervento-map-functions";
import {
    GenericFieldRigaProgrammazioneComponent
} from "../../components/generic-field-riga-programmazione/generic-field-riga-programmazione.component";
import {
    CampoDaSelezionare,
    InterventoForm,
    InterventoToSubmit
} from '../creazione-intervento-modal/creazione-intervento-modal.component';
import {AbstractFocusManagerComponent} from "../../abstracts/abstract-focus-manager.component";
import {TypeDialogFormEnum} from "../../enum/generic-components-enum";
import {GenericDialogComponent} from "../../../layout/common/dialogs/generic-dialog/generic-dialog.component";
import {CustomValidators} from "../../validators/custom-validators";
import {checkUpdateNota} from "../../../services/programmazione-forms-manager.service";


@Component({
    selector: 'app-modifica-intervento-evaso-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgIf,
        MatDialogClose,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        MatIconModule,
        MatDividerModule,
        MatTooltipModule,
        GenericFormFieldComponent,
        NgClass,
        MatCheckboxModule,
        MatChipsModule,
        PipeModule,
        UpperCasePipe,
        MatTabsModule,
        NgTemplateOutlet,
        AnnotazioniInterventoComponent,
        FormAvvioInterventiNonAttivitaComponent,
        InputFileComponent,
        NgOptimizedImage,
        DialogWrapInterventoComponent,
        GenericFieldRigaProgrammazioneComponent
    ],
    templateUrl: './aggiungi-intervento-passato-modal.component.html',
    styleUrl: './aggiungi-intervento-passato-modal.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class AggiungiInterventoPassatoModalComponent extends AbstractFocusManagerComponent implements OnInit {
    interventoFormGroup: FormGroup<InterventoForm>;
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    canShowOdl: boolean;
    showChip: boolean;
    statusChip: InterventoProgrammaDTO.StatoEnum | undefined;
    showEditPart: boolean;
    CampoDaSelezionare = CampoDaSelezionare;
    tipologiaIntervento: InterventoProgrammaDTO.TipologiaEnum;

    interventoExtendedFormGroup: FormGroup<InterventoToSubmit> = new FormGroup<InterventoToSubmit>({});
    dialogTypeModifica = DialogForInterventoType.MODIFICA
    isDialogTypeModifica: boolean;
    isAttivita: boolean;
    idIntervento: string;
    fotoArray: SafeUrl[] = [];
    initialValueIntervento: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any>;
    initialValueInterventoExtended: ɵTypedOrUntyped<InterventoToSubmit, ɵFormGroupValue<InterventoToSubmit>, any>;
    InterventoProgrammaDTO = InterventoProgrammaDTO;
    requiredClienteForReferente = 'Per selezionare un referente occorre selezionare prima un cliente';
    requiredClienteForUnitaLocale = 'Per selezionare un\'unità locale occorre selezionare prima un cliente';
    IsInterventoFormularioLabel = 'È un intervento formulario?';

    constructor(public dialogRef: MatDialogRef<AggiungiInterventoPassatoModalComponent>,
                private dialog: MatDialog,
                private fuseConfirmationService: FuseConfirmationService,
                private dialogManagerService: DialogManagerService,
                @Inject(MAT_DIALOG_DATA) public data: InterventoComponentDialogConfig,
                private interventiService: InterventiService,
                private manageErrorService: ManageErrorService,
                private blobManagerService: BlobManagerService,
                private sanitizer: DomSanitizer,
                protected el: ElementRef,
                protected renderer: Renderer2) {
        super(el, renderer);
        this.setAttributes();
        this.idIntervento = this.data?.formValue?.id
        this.setFormGroup(this.data.formValue);
    }

    ngOnInit(): void {
        this.initialValueIntervento = this.interventoFormGroup.getRawValue()
        this.initialValueInterventoExtended = this.interventoExtendedFormGroup.getRawValue()
    }

    setAttributes() {
        this.showChip = !!this.data?.formValue?.stato;
        this.statusChip = this.data?.formValue?.stato
        this.isDialogTypeModifica = this.data.dialogType === this.dialogTypeModifica;
        this.tipologiaIntervento = this.data?.formValue?.tipologia;
        this.isAttivita = (this.tipologiaIntervento === InterventoProgrammaDTO.TipologiaEnum.ATTIVITA);
        this.canShowOdl = (this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.EVASO ||
            this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.VERIFICATO ||
            this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.CONSOLIDATO) && this.isAttivita;
    }

    setFormGroup(intervento?: InterventoView) {
        this.interventoFormGroup = new FormGroup<InterventoForm>({
            id: new FormControl(intervento?.id),
            isInterventoFormulario: new FormControl(intervento?.isInterventoFormulario),
            idOrdineLavoro: new FormControl(intervento?.ordineDiLavoro?.codice),
            data: new FormControl(!!intervento?.dataOraInizioPrevista ?
                    moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD') : null,
                {
                    validators: [Validators.required]
                }),
            oraInizioPrevista: new FormControl(
                !!intervento?.dataOraInizioPrevista ?
                    moment(intervento?.dataOraInizioPrevista).format('HH:mm') : null, {
                    validators: [Validators.required]
                }),
            oraFinePrevista: new FormControl(
                !!intervento?.dataOraFinePrevista ?
                    moment(intervento?.dataOraFinePrevista).format('HH:mm') : null, {
                    validators: [Validators.required]
                }),
            stato: new FormControl(intervento?.stato),
            costo: new FormControl(intervento?.costo || 0),
            numeroPreventivo: new FormControl(intervento?.numeroPreventivo),
            referenteUnico: new FormControl(intervento?.referenteUnico),
            referenteUnicoTel: new FormControl(intervento?.referenteUnicoTel),
            tipologiaInterventoArray: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                orderByDescrizione(intervento?.tipiIntervento).map((item: AddebitoView) => this.createFormControl(item)) : [this.createFormControl()]),
            cliente: new FormControl(intervento?.cliente, {
                validators: [Validators.required]
            }),
            automezzo: new FormControl(intervento?.automezzo, {
                validators: [Validators.required]
            }),
            rimorchio: new FormControl(intervento?.rimorchio),
            attrezzatura: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                orderByOrdine(intervento?.attrezzature).map((item) => this.createFormControlWithoutValidator(item)) : [this.createFormControlWithoutValidator()]),
            operatore: new FormArray(!!intervento?.operatori && intervento?.operatori?.length > 0 ?
                orderByOrdine(intervento?.operatori).map((item) => this.createFormControl(item)) : [this.createFormControl()]),
            nota: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                orderByOrdine(intervento?.note).map((item) => this.createFormControlWithoutValidator(item)) : [this.createFormControlWithoutValidator()]),
            durata: new FormControl(intervento?.durataStimata),

            //---- MASK
            automezzoMask: new FormControl(getTestoAutomezzo(intervento?.automezzo), {validators: Validators.required}),
            rimorchioMask: new FormControl(getTestoAutomezzo(intervento?.rimorchio),),
            operatoriMask: new FormArray(!!intervento?.operatori && intervento?.operatori?.length > 0 ?
                orderByOrdine(intervento?.operatori).map((item: OperatoreInterventoView) =>
                    this.createFormControl(get(item.operatore, 'nomeCognome', null)))
                : [this.createFormControl()]),
            tipologiaInterventoArrayMask: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                orderByDescrizione(intervento?.tipiIntervento).map((item: AddebitoView) =>
                    this.createFormControl(get(item, 'descrizione', null)))
                : [this.createFormControl()]),
            attrezzatureMask: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                orderByOrdine(intervento?.attrezzature).map((item: AttrezzaturaInterventoView) =>
                    this.createFormControlWithoutValidator(get(item.attrezzatura, 'descrizione', null)))
                : [this.createFormControlWithoutValidator()]),
            clienteMask: new FormControl(getTestoCliente(intervento?.cliente), {validators: Validators.required}),
            notaMask: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                orderByOrdine(intervento?.note).map((item: NotaInterventoView) =>
                    this.createFormControlWithoutValidator(get(item.notaInterventoBucket, 'descrizione', null)))
                : [this.createFormControlWithoutValidator()]),
            referenteCliente: new FormControl(intervento?.referenteIntervento),
            referenteClienteMask: new FormControl(getTestoReferente(intervento?.referenteIntervento)),
            unitaLocale: new FormControl(intervento?.unitaLocaleIntervento, {validators: Validators.required}),
            unitaLocaleMask: new FormControl(getTestoUnitaLocale(intervento?.unitaLocaleIntervento), {validators: Validators.required}),
        }, CustomValidators.comparisonOrarioValidator(
            'oraInizioPrevista', 'oraFinePrevista'
        ));
        this.setEditForms();
        this.switchOnActivityStatusToAddControls(intervento);
        if (this.data.readonly) {
            this.interventoFormGroup.disable();
            this.interventoFormGroup.updateValueAndValidity();
        }
    }

    ctrlCodiceOrdineDiLavoro(): FormControl<any | null> {
        return getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'idOrdineLavoro');
    }

    setEditForms(intervento?: InterventoView) {
        if (this.data.editDialog) {
            this.interventoExtendedFormGroup.addControl(
                'notaPercorrenza', new FormControl(null)
            );
            this.onValueChange();
        }
        this.showEditPart = true;
    }

    switchOnActivityStatusToAddControls(intervento?: InterventoView) {
        if (!this.data.editDialog) {
            this.interventoExtendedFormGroup.addControl(
                'oraInizioEffettiva', new FormControl(
                    !!intervento?.dataOraInizioReale ?
                        moment(intervento?.dataOraInizioReale).format('HH:mm') : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'oraFineEffettiva', new FormControl(
                    !!intervento?.dataOraFineReale ?
                        moment(intervento?.dataOraFineReale).format('HH:mm') : undefined
                )
            );
            this.interventoExtendedFormGroup.addControl(
                'nominativoFirmatario',
                new FormControl(intervento?.ordineDiLavoro?.nominativoFirmaCliente)
            );
            this.interventoExtendedFormGroup.addControl(
                'isPausaPranzo', new FormControl(intervento?.ordineDiLavoro?.isPresentePausaPranzo)
            );
            this.interventoExtendedFormGroup.addControl(
                'dataOraInizioPausaPranzo', new FormControl(
                    !!intervento?.ordineDiLavoro?.dataOraInizioPausaPranzo ?
                        moment(intervento?.ordineDiLavoro?.dataOraInizioPausaPranzo).format('HH:mm') : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'dataOraFinePausaPranzo', new FormControl(
                    !!intervento?.ordineDiLavoro?.dataOraFinePausaPranzo ?
                        moment(intervento?.ordineDiLavoro?.dataOraFinePausaPranzo).format('HH:mm') : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'isInterventoInReperibilita',
                new FormControl(intervento?.ordineDiLavoro?.isInterventoInReperibilita)
            );
            this.interventoExtendedFormGroup.addControl(
                'dataOraRichiestaReperibilita', new FormControl(
                    !!intervento?.ordineDiLavoro?.dataOraRichiestaReperibilita ?
                        moment(intervento?.ordineDiLavoro?.dataOraRichiestaReperibilita).format('HH:mm')
                        : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'mittenteReperibilita', new FormControl(
                    intervento?.ordineDiLavoro?.mittenteReperibilita)
            );
            this.interventoExtendedFormGroup.addControl(
                'dataOraInizioODL', new FormControl(
                    !!intervento?.ordineDiLavoro?.dataOraInizioODL ?
                        moment(intervento?.ordineDiLavoro?.dataOraInizioODL).format('HH:mm') : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'dataOraFineODL', new FormControl(
                    !!intervento?.ordineDiLavoro?.dataOraFineODL ?
                        moment(intervento?.ordineDiLavoro?.dataOraFineODL).format('HH:mm') : undefined
                )
            );
            this.interventoExtendedFormGroup.addControl(
                'durata', new FormControl(intervento?.ordineDiLavoro?.durataEffettiva)
            );
            this.interventoExtendedFormGroup.addControl(
                'firma', new FormControl(intervento?.ordineDiLavoro?.uriImmagineFirmaCliente)
            );
            this.interventoExtendedFormGroup.addControl(
                'notaOrdineDiLavoro', new FormControl(intervento?.ordineDiLavoro?.notaOrdineDiLavoro)
            );
            if (this.data?.readonly) {
                this.getInterventExtendedFormControlByPath('isEvasoByDashBoard').disable();
            }
            this.interventoExtendedFormGroup.addControl(
                'ODL_PDF', new FormControl(intervento?.ordineDiLavoro?.uriOdl)
            );
            this.interventoExtendedFormGroup.addControl(
                'notaOperatore', new FormControl(intervento?.notaOperatore)
            );

            this.interventoExtendedFormGroup.setValidators([
                CustomValidators.comparisonOrarioEGiornoValidator(
                    'dataOraInizioODL', 'dataOraFineODL', 'giornoInizioODL', 'giornoFineODL'
                ),
                CustomValidators.comparisonOrarioEGiornoValidatorCanBeSame(
                    'oraInizioEffettiva', 'oraFineEffettiva', 'giornoInizioEffettivo', 'giornoFineEffettivo'
                ),
                CustomValidators.comparisonOrarioValidator(
                    'dataOraInizioPausaPranzo', 'dataOraFinePausaPranzo'
                ),
                CustomValidators.comparisonOraPausaInterna(
                    'dataOraInizioPausaPranzo', 'dataOraFinePausaPranzo', 'dataOraInizioODL', 'dataOraFineODL'
                )
            ])
            this.requestFoto()
        }
    }

    onValueChange() {
        getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'isPausaPranzo')?.valueChanges.pipe(
            tap((value) => {
                const dataOraInizioControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'dataOraInizioPausaPranzo');
                const dataOraFineControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'dataOraFinePausaPranzo');
                if (!!value) {
                    dataOraInizioControl.setValidators([Validators.required]);
                    dataOraFineControl.setValidators([Validators.required]);
                    dataOraInizioControl.enable()
                    dataOraFineControl.enable()
                } else {
                    dataOraInizioControl.clearValidators();
                    dataOraFineControl.clearValidators();
                    dataOraInizioControl.setErrors(null);
                    dataOraFineControl.setErrors(null);
                    dataOraInizioControl.setValue(null)
                    dataOraFineControl.setValue(null)
                    dataOraInizioControl.disable()
                    dataOraFineControl.disable()
                }

                dataOraInizioControl.updateValueAndValidity();
                dataOraFineControl.updateValueAndValidity();
            })
        ).subscribe()

        const isInterventoInReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'isInterventoInReperibilita'
        );

        const dataOraRichiestaReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'dataOraRichiestaReperibilita'
        );

        const mittenteReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'mittenteReperibilita'
        );


        isInterventoInReperibilitaControl?.valueChanges.pipe(
            tap((value) => {
                if (value) {
                    dataOraRichiestaReperibilitaControl.setValidators(Validators.required);
                    mittenteReperibilitaControl.setValidators(Validators.required);
                    dataOraRichiestaReperibilitaControl.enable();
                    mittenteReperibilitaControl.enable();
                } else {
                    dataOraRichiestaReperibilitaControl.clearValidators();
                    mittenteReperibilitaControl.clearValidators();
                    dataOraRichiestaReperibilitaControl.setErrors(null);
                    mittenteReperibilitaControl.setErrors(null);
                    dataOraRichiestaReperibilitaControl.setValue(null);
                    mittenteReperibilitaControl.setValue(null);
                    dataOraRichiestaReperibilitaControl.disable();
                    mittenteReperibilitaControl.disable();
                }

                dataOraRichiestaReperibilitaControl.updateValueAndValidity();
                mittenteReperibilitaControl.updateValueAndValidity();
            })
        ).subscribe();
    }

    getInterventoFormControlByPath(path: string): FormControl {
        return this.interventoFormGroup?.get(path) as FormControl;
    }

    getInterventExtendedFormControlByPath(path: string): FormControl {
        return this.interventoExtendedFormGroup?.get(path) as FormControl;
    }

    ctrlCliente(): FormControl<ClienteView> {
        return this.getInterventoFormControlByPath('cliente')
    }

    ctrlReferente(): FormControl<ReferenteIntervento> {
        return this.getInterventoFormControlByPath('referenteCliente')
    }

    ctrlUnitaLocale(): FormControl<UnitaLocaleIntervento> {
        return this.getInterventoFormControlByPath('unitaLocale')
    }

    ctrlClienteMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('clienteMask')
    }

    ctrlReferenteMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('referenteClienteMask')
    }

    ctrlUnitaLocaleMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('unitaLocaleMask')
    }

    openModaleDiSelezione(field: CampoDaSelezionare, index?: number) {
        switch (field) {
            case CampoDaSelezionare.NOTA :
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('nota', index).value
                ).subscribe({
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const notaInterventoBucketView: NotaInterventoBucketView = value.value as NotaInterventoBucketView;
                                this.getFormControlByIndexInFormArrayByPath('notaMask', index).setValue(getTestoNota(notaInterventoBucketView));
                                this.getFormControlByIndexInFormArrayByPath('nota', index).setValue(
                                    mapNotaInterventoBucketViewToNotaInterventoView(notaInterventoBucketView, index)
                                )
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.CLIENTE :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('cliente').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const descrizioneCliente = get(value.value, 'descrizione', null);
                                this.getInterventoFormControlByPath('clienteMask').setValue(descrizioneCliente);
                                this.getInterventoFormControlByPath('cliente').setValue(value.value)
                                const cliente = value.value as ClienteView;
                                const unitaLocale = cliente?.unitaLocali?.find((unita) => !!unita.isPrincipale) ||
                                    head(cliente?.unitaLocali);
                                this.ctrlUnitaLocale().setValue(mapUnitaViewToUnitaLocaleIntervento(unitaLocale));
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(unitaLocale));
                                const referente = cliente?.referenti?.find((ref) => !!ref.isPrincipale) ||
                                    head(cliente?.referenti);
                                this.ctrlReferente().setValue(mapReferenteViewToReferenteIntervento(referente));
                                this.ctrlReferenteMask().setValue(getTestoReferente(referente));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.TIPOLOGIA_INTERVENTO:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlAddebito(index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.ctrlAddebitoMask(index).setValue(result);
                                this.ctrlAddebito(index).setValue(value.value,);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.ATTREZZATURA:
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('attrezzatura', index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.getFormControlByIndexInFormArrayByPath('attrezzatureMask', index).setValue(result);
                                this.getFormControlByIndexInFormArrayByPath('attrezzatura', index).setValue(
                                    mapAttrezzaturaViewToAttrezzaturaInterventoView(value.value, index));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.AUTOMEZZO :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('automezzo').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                this.getInterventoFormControlByPath('automezzoMask').setValue(getTestoAutomezzo(value.value));
                                this.getInterventoFormControlByPath('automezzo').setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.RIMORCHIO :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('rimorchio').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                this.getInterventoFormControlByPath('rimorchioMask').setValue(getTestoAutomezzo(value.value));
                                this.getInterventoFormControlByPath('rimorchio').setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.OPERATORE:
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('operatore', index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.getFormControlByIndexInFormArrayByPath('operatoriMask', index)
                                    .setValue(getTestoOperatore(value.value));
                                this.getFormControlByIndexInFormArrayByPath('operatore', index).setValue(
                                    mapOperatoreViewToOperatoreInterventoView(value.value, index))
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.REFERENTE:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlReferente().value,
                    this.ctrlCliente()?.value?.referenti, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value: ClienteUnitaReferente) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.referente) {
                                let referente: ReferenteView = value.referente;
                                this.ctrlReferenteMask().setValue(getTestoReferente(value.referente));
                                this.ctrlReferente().setValue(mapReferenteViewToReferenteIntervento(referente));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.UNITA_LOCALE:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlUnitaLocale().value,
                    this.ctrlCliente()?.value?.unitaLocali, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value: ClienteUnitaReferente) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.unita) {
                                let unita: UnitaLocaleView = value.unita
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(value.unita));
                                this.ctrlUnitaLocale().setValue(mapUnitaViewToUnitaLocaleIntervento(unita));
                            }
                        }
                    })
                break;
        }
    }

    closeModal($event: any) {
        this.dialogRef.close($event);
    }

    createFormControl(value?: any) {
        return new FormControl<any | null>(value, {
            validators: [Validators.required]
        });
    }

    createFormControlWithoutValidator(value?: any) {
        return new FormControl<any | null>(value);
    }

    getFormArrayByPath(path: string): FormArray {
        return this.interventoFormGroup.controls[path] as FormArray;
    }

    addFormControlToFormArray(path: string): void {
        this.getFormArrayByPath(path).push(this.createFormControl());
    }

    addFormControlToFormArrayWithoutValidator(path: string): void {
        this.getFormArrayByPath(path).push(this.createFormControlWithoutValidator());
    }

    getFormControlByIndexInFormArrayByPath(path: string, index: number) {
        return this.getFormArrayByPath(path).at(index) as FormControl
    }

    removeFormControlToFormArray(path: string, index: number) {
        this.getFormArrayByPath(path).removeAt(index);
    }

    onConfirm($event: string) {
        switch ($event) {
            case DialogInterventoConfirmEnum.ELIMINA:
                this.eliminaInterventoNelPassato();
                break;
            case DialogInterventoConfirmEnum.PATCH_MODIFICA:
                this.modificaInterventoNelPassato();
                break;
            case DialogInterventoConfirmEnum.CREAZIONE:
                this.addInterventoNelPassato();
                break;
            default:
                break;
        }
    }

    eliminaInterventoNelPassato() {
        let messageVerifica = 'Eliminare quest\'intervento aggiunto nel passato?';
        this.openModalEliminaIntervento(messageVerifica).afterClosed().pipe(
            takeUntil(this.destroy$),
        ).subscribe({
            next: (value) => {
                if (!!value) {
                    this.onSuccessResult('Intervento annullato con successo')
                }
            }
        });
    }

    openModalEliminaIntervento(dialogMessage: string) {
        const data: GenericComponentDialogConfig = {
            title: 'ATTENZIONE',
            message: dialogMessage,
            icon: {
                show: true,
                name: 'delete',
                color: 'primary'
            },
            formConfig: [{
                show: false,
                name: 'confirm',
                transloco: '',
                type: TypeDialogFormEnum.BOOLEAN,
            }],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.eliminaInterventoNelPassatoRequest$(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    eliminaInterventoNelPassatoRequest$(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,) {
        this.fuseConfirmationService.showLoader();
        this.interventiService.deleteInterventoNelPassato(this.idIntervento).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
                next: (value) => {
                    dialogRef.close(true);
                },
                error: (error) => {
                    this.manageErrorService.showBackendErrorMessage(error);
                }
            }
        )
    }

    createBodyPatchAddInterventoPassatoDTO(): AddInterventoPassatoDTO {
        const currentValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any> = this.interventoFormGroup.getRawValue();
        return {
            dataOraInizio: this.initialValueIntervento.oraInizioPrevista === currentValue?.oraInizioPrevista ?
                undefined : this.calcolaDataOra(
                    moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista')
                ),
            dataOraFine: this.initialValueIntervento.oraFinePrevista === currentValue?.oraFinePrevista ?
                undefined : this.calcolaDataOra(
                    moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista')
                ),
            tipiIntervento: isEqual(this.initialValueIntervento.tipologiaInterventoArray, compact(currentValue.tipologiaInterventoArray)) ? undefined :
                currentValue.tipologiaInterventoArray
                    .filter((tipoIntervento: AddebitoView) => !!tipoIntervento?.id)
                    .map((tipoIntervento, index) => {
                        return {
                            id: tipoIntervento?.id
                        }
                    }),
            costo: (this.initialValueIntervento.costo === currentValue.costo ? undefined : currentValue.costo),
            numeroPreventivo: (this.initialValueIntervento.numeroPreventivo === currentValue.numeroPreventivo
                ? undefined : currentValue.numeroPreventivo),
            referenteUnico: (this.initialValueIntervento.referenteUnico === currentValue.referenteUnico
                ? undefined : currentValue.referenteUnico),
            referenteUnicoTel: (this.initialValueIntervento.referenteUnicoTel === currentValue.referenteUnicoTel
                ? undefined : currentValue.referenteUnicoTel),
            attrezzature: isEqual(this.initialValueIntervento.attrezzatura, compact(currentValue.attrezzatura)) ? undefined :
                orderByOrdine(currentValue.attrezzatura)
                    .filter((attrezzatura: AttrezzaturaInterventoView) => !!attrezzatura?.attrezzatura)
                    .map((attrezzatura, index) =>
                        mapAttrezzaturaInterventoToAddAttrezzaturaIntervento(attrezzatura, index)),
            note: isEqual(this.initialValueIntervento.notaMask, compact(currentValue.notaMask)) ? undefined :
                orderByOrdine(currentValue.notaMask).map((nota, index) => {
                    let ctrlNotaInterventoView = getFormArrayFromFormGroupTyped<InterventoForm>
                    (this.interventoFormGroup, 'nota').at(index) as FormControl<NotaInterventoView>;
                    return {
                        nota: nota,
                        idNotaInterventoBucket: this.checkUpdateNota(ctrlNotaInterventoView, nota) ?
                        (ctrlNotaInterventoView?.value as NotaInterventoView)?.notaInterventoBucket?.id : undefined,
                    }
                }).filter((item) => !!item.nota).map((_nota, index) => {
                    return {
                        ..._nota,
                        ordine: index + 1
                    } as NotaInterventoView
                }),
            operatori: isEqual(this.initialValueIntervento.operatore, currentValue.operatore) ? undefined :
                orderByOrdine(currentValue.operatore)
                    .filter((operatore: OperatoreInterventoView) => !!operatore.operatore)
                    .map((operatore, index) =>
                        mapOperatoreInterventoViewToAddOperatoreInterventoDTO(operatore, index)),
        }
    }

    checkUpdateNota(notaCtrl: FormControl<NotaInterventoView>, testoNota: string) {
        return notaCtrl?.value?.notaInterventoBucket?.descrizione === testoNota;
    }

    modificaInterventoNelPassato() {
        if (this.interventoFormGroup.valid) {
            let bodyAddInterventoPassatoDTO = this.createBodyAddInterventoPassatoDTO();
            this.fuseConfirmationService.showLoader();
            this.interventiService.modificaInterventoNelPassato(bodyAddInterventoPassatoDTO, this.idIntervento).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    this.onSuccessResult('Intervento nel passato modificato con successo')
                },
                error: (err) => {
                    this.manageErrorService.showBackendErrorMessage(err);

                }
            })
        } else {
            this.interventoFormGroup.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }

    }

    //-----  MARK: CREAZIONE BODY PER LE RICHIESTE -------//
    addInterventoNelPassato() {
        if (this.interventoFormGroup.valid) {
            let bodyAddInterventoPassatoDTO = this.createBodyAddInterventoPassatoDTO();
            this.fuseConfirmationService.showLoader();
            this.interventiService.aggiungiInterventoNelPassato(bodyAddInterventoPassatoDTO).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    this.onSuccessResult('Intervento nel passato creato con successo')
                },
                error: (err) => {
                    this.manageErrorService.showBackendErrorMessage(err);

                }
            })
        } else {
            this.interventoFormGroup.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }

    }

    createBodyAddInterventoPassatoDTO(): AddInterventoPassatoDTO {
        const currentValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any> = this.interventoFormGroup.value;
        console.log(this.interventoFormGroup.value, 'this.interventoFormGroup.value');
        return {
            codiceODL: this.ctrlCodiceOrdineDiLavoro()?.value,
            isInterventoFormulario: this.ctrlIsInterventoFormulario()?.value,
            dataOraInizio: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'data')?.value
                ).format('DD/MM/YYYY'),
                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista')
            ),
            dataOraFine: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'data')?.value
                ).format('DD/MM/YYYY'),
                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista')
            ),
            costo: currentValue?.costo,
            numeroPreventivo: currentValue?.numeroPreventivo,
            referenteUnico: currentValue?.referenteUnico,
            referenteUnicoTel: currentValue?.referenteUnicoTel,
            automezzo: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.automezzo),
            rimorchio: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.rimorchio),
            operatori: orderByOrdine(currentValue.operatore)
                .filter((operatore: OperatoreInterventoView) => !!operatore.operatore)
                .map((operatore, index) =>
                    mapOperatoreInterventoViewToAddOperatoreInterventoDTO(operatore, index)),
            tipiIntervento: compact(currentValue.tipologiaInterventoArray)
                .map((tipoIntervento) => mapAddebitoViewToAddTipoInterventoDTO(tipoIntervento)),
            cliente: mapClienteViewToAddClienteInterventoDTO(
                currentValue.cliente,
                currentValue.unitaLocale,
                currentValue.referenteCliente
            ),
            note: orderByOrdine(currentValue.notaMask).map((nota, index) => {
                let ctrlNotaInterventoView = getFormArrayFromFormGroupTyped<InterventoForm>
                (this.interventoFormGroup, 'nota').at(index) as FormControl<NotaInterventoView>;
                return {
                    nota: nota,
                    idNotaInterventoBucket: this.checkUpdateNota(ctrlNotaInterventoView, nota) ?
                    (getFormArrayFromFormGroupTyped<InterventoForm>
                    (this.interventoFormGroup, 'nota').at(index)?.value as NotaInterventoView)?.notaInterventoBucket?.id :
                    undefined,
                }
            }).filter((item) => !!item.nota).map((_nota, index) => {
                return {
                    ..._nota,
                    ordine: index + 1
                } as NotaInterventoView
            }),
            attrezzature: orderByOrdine(currentValue.attrezzatura)
                .filter((attrezzatura: AttrezzaturaInterventoView) => !!attrezzatura?.attrezzatura)
                .map((attrezzatura, index) =>
                    mapAttrezzaturaInterventoToAddAttrezzaturaIntervento(attrezzatura, index)),
        }
    }

    //-----  ENDMARK: CREAZIONE BODY PER LE RICHIESTE -------//
    onSuccessResult(message: string) {
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Success,
        });
        this.closeModal(true);
    }

    isEvasoVerificatoOrCosolidato(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.EVASO || this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.VERIFICATO
            || this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.CONSOLIDATO;
    }

    isInCorso(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.INCORSO;
    }

    isProgrammato(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.PROGRAMMATO;
    }

    clienteViewToClienteWithDescrizione(cliente: ClienteView) {
        return {
            ...cliente,
            descrizione: join(compact([cliente.ragioneSociale, cliente.estensioneRagioneSociale, cliente.alias]), ' - '),
            indirizzoCompleto: join(compact([
                head(cliente.unitaLocali)?.indirizzo,
                head(cliente.unitaLocali)?.frazione,
                head(cliente.unitaLocali)?.comune,
                head(cliente.unitaLocali)?.provincia]), ' - ')
        }
    }

    checkChipLabel(tipologiaAttivita: "ATTIVITA" | "PARTENZA" | "RIENTRO" | "NOTA") {
        if (tipologiaAttivita === "ATTIVITA") {
            return 'ATTIVITÀ'
        } else {
            return tipologiaAttivita;
        }
    }

    getLabelOperatore(index: number): string {
        if (index === 0) {
            return 'Autista '
        } else {
            return 'Operatore ' + index;
        }
    }

    ctrlnotaPercorrenza(): FormControl {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'notaPercorrenza')
    }

    calcolaDataOra(data: string, controlOrario: FormControl) {
        if (!!data && !!controlOrario?.value) {
            let arr = controlOrario?.value?.split(':')
            let hour = +arr[0]
            let minute = +arr[1]
            return moment(data, 'DD/MM/YYYY').hours(hour).minutes(minute).toDate();
        } else {
            return undefined
        }

    }

    requestFoto() {
        if (!!this.getFoto() && this.getFoto().length > 0) {
            this.fuseConfirmationService.showLoader();
            forkJoin(
                this.getFoto().map((fotoUri) => {
                    return this.interventiService.downloadAllegato(this.idIntervento, fotoUri).pipe(
                        catchError((error) => {
                                console.error(error);
                                return of(error)
                            }
                        )
                    ) as Observable<Blob>
                })
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    value.forEach((blob: Blob) => {
                        if (!!blob) {
                            let objectURL = URL.createObjectURL(blob);
                            let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                            this.fotoArray.push(image);
                        }
                    })
                },
                error: async (error) => {
                    await this.manageErrorService.showBackendErrorMessageBLOB(error)
                }
            })
        }
    }

    getFoto(): Array<string> {
        return this.data?.formValue?.ordineDiLavoro?.foto?.map(
            (foto) => foto?.fotoUri);
    }

    ctrlNotaOperatore(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'notaOperatore') as FormControl;
    }

    scaricaFoto(fotouri: SafeUrl, index: number) {
        this.fuseConfirmationService.showLoader()
        this.interventiService.downloadAllegato(this.idIntervento, this.getFoto().at(index)).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                this.blobManagerService.download(value, 'Foto_ordine_di_lavoro_' + (index + 1) + '.png')
            },
            error: async (error) => {
                console.error(error);
                await this.manageErrorService.showBackendErrorMessageBLOB(error)
            }
        })
    }

    setNullEmitterOnCliente() {
        this.getInterventoFormControlByPath('clienteMask').setValue(null);
        this.getInterventoFormControlByPath('cliente').setValue(null);
        this.getInterventoFormControlByPath('referenteCliente').setValue(null);
        this.getInterventoFormControlByPath('referenteClienteMask').setValue(null);
        this.getInterventoFormControlByPath('unitaLocale').setValue(null);
        this.getInterventoFormControlByPath('unitaLocaleMask').setValue(null);
    }

    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.el.nativeElement.contains(document.activeElement)) {
            const inputElements = this.el.nativeElement.querySelectorAll('.input-field-modal');
            const activeElement = document.activeElement as HTMLInputElement;
            let activeElementIndex = Array.from(inputElements).findIndex(input => input === activeElement);

            // Navigazione destra
            if (event.key === "ArrowDown" || event.key === "Enter") {
                do {
                    activeElementIndex = (activeElementIndex + 1) % inputElements.length;
                    const nextElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!nextElement.disabled) {
                        nextElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }

            // Navigazione sinistra
            if (event.key === "ArrowUp") {
                do {
                    activeElementIndex = (activeElementIndex - 1 + inputElements.length) % inputElements.length;
                    const previousElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!previousElement.disabled) {
                        previousElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }
        }
    }

    @HostListener('keydown.F10', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        switch (this.data?.dialogType) {
            case DialogForInterventoType.CREAZIONE:
                this.onConfirm(DialogInterventoConfirmEnum.CREAZIONE);
                break;
            case DialogForInterventoType.MODIFICA:
                if(isEqual(this.initialValueIntervento, this.interventoFormGroup.getRawValue())) {
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Nessuna modifica effettuata',
                        type: SnackbarTypes.Warning,
                    });
                } else {
                    this.onConfirm(DialogInterventoConfirmEnum.PATCH_MODIFICA);
                }
                break;
        }


    }

    // MARK ADDEBITO //
    ctrlAddebitiFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray') as FormArray;
    }

    ctrlAddebitiMaskFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask') as FormArray;

    }

    ctrlAddebito(index: number): FormControl {
        return this.ctrlAddebitiFormArray().at(index) as FormControl;
    }

    ctrlAddebitoMask(index: number): FormControl {
        return this.ctrlAddebitiMaskFormArray().at(index) as FormControl;
    }

    addAddebito() {
        this.addAddebitoFormControlToFormArray();
        this.addAddebitoMaskFormControlToFormArray();
    }

    addAddebitoFormControlToFormArray(): void {
        this.ctrlAddebitiFormArray().push(this.createFormControl(null));
    }

    addAddebitoMaskFormControlToFormArray(): void {
        this.ctrlAddebitiMaskFormArray().push(this.createFormControl(null));
    }

    removeAddebito(index: number) {
        this.removeAddebitoMaskFormControlFromFormArray(index);
        this.removeAddebitoFormControlFromFormArray(index);
    }

    removeAddebitoFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiFormArray().removeAt(index);
    }

    removeAddebitoMaskFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiMaskFormArray().removeAt(index);
    }

    // ENDMARK ADDEBITO //

    ctrlIsInterventoFormulario(): FormControl<boolean | null> {
        return getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'isInterventoFormulario')
    }

    cambiaValoreCheckbox() {
        const currentValue = this.ctrlIsInterventoFormulario().value;
        this.ctrlIsInterventoFormulario().setValue(!currentValue);
    }
}
