<ng-container *ngIf="!readonly; else readOnlyTemplate">
    <mat-form-field [style.width.%]="100" appearance="outline"
                    [matTooltip]="control?.disabled ? disabledTooltip : null">
        <mat-label>{{label}}</mat-label>
        <div class="flex flex-row gap-1">
        <ng-container *ngIf="label !== 'Costo'; else costoInput">
            <input *ngIf="!onlyPositiveIntegers" (keydown)="preventKeyDown()" class="input-field-modal"
                   id="remindDate" matInput [formControl]="control" type="{{type}}" [readonly]="readonlyform"
                   autocomplete="off" #myInput
                   (keydown.F1)="$event.preventDefault(); !control?.disabled ? openSelect.emit(campoDaSelezionare) : null"
                   (keydown.control.backspace)="!control?.disabled ? setNullEmitter.emit(campoDaSelezionare) : null;">

            <input *ngIf="!!onlyPositiveIntegers" (keydown)="preventKeyDown()" class="input-field-modal"
                   matInput [formControl]="control" [readonly]="readonlyform" autocomplete="off"
                   [type]="'number'" [min]="0"
                   appOnlyDecimalNumbers>

        </ng-container>
        <ng-template #costoInput>
            <input matInput currencyMask
                   [disabled]="control?.disabled"
                   [readonly]="readonly"
                   class="flex-grow-1 w-full input-field-modal"
                   [options]="options"
                   [formControl]="control">
        </ng-template>

            <mat-icon [matTooltip]="errorHourPranzoMessage"
                      matSuffix class="color-warn-500 px-0.5" *ngIf="!!control?.errors && control?.touched
                  && control?.hasError('hourPranzoCheck') && !control?.hasError('hourCheck')
                  && !control?.hasError('required')">
                error
            </mat-icon>

            <mat-icon [matTooltip]="errorHourMessage"
                      matSuffix class="color-warn-500 px-0.5" *ngIf="!!control?.errors &&
                      control?.hasError('hourCheck')">
                error
            </mat-icon>

            <mat-icon [matTooltip]="errorDateMessage"
                      matSuffix class="color-warn-500 px-0.5" *ngIf="!!control?.errors &&
                      control?.hasError('dateCheck')">
                error
            </mat-icon>

            <mat-icon [matTooltip]="errorRequiredMessage"
                      matSuffix class="color-warn-500 px-0.5" *ngIf="!!control?.errors && control?.touched
                  && (control?.hasError('required') || control?.hasError('nonEmptyString'))">
                error
            </mat-icon>

            <mat-icon *ngIf="!!campoDaSelezionare && !!control?.value && !control?.disabled"
                      [ngClass]="control?.disabled ? 'disabled_icon' : 'cursor-pointer'"
                      matSuffix class="px-0.5 color-warn-500"
                      (click)="!control?.disabled ? setNullEmitter.emit(campoDaSelezionare) : null;
                  $event.stopPropagation()">
                close
            </mat-icon>


            <mat-icon [ngClass]="control?.disabled ? 'disabled_icon' : 'cursor-pointer'"
                      matSuffix class="px-1 mr-1" *ngIf="!!campoDaSelezionare"
                      (click)="!control?.disabled ? openSelect.emit(campoDaSelezionare) : null;
                  $event.stopPropagation()">
                visibility
            </mat-icon>
        </div>

        <mat-error *ngIf="control?.hasError('required')">
            {{errorRequiredMessage}}
        </mat-error>
        <mat-error *ngIf="control?.hasError('email')">
            {{errorPatternMessage}}
        </mat-error>
        <mat-error *ngIf="control?.hasError('pattern')">
            {{errorGenericPatternMessage}}
        </mat-error>
    </mat-form-field>
</ng-container>

<ng-template #readOnlyTemplate>
    <app-input-value-key [label]="label"
                         [value]="getReadOnlyValue(control?.value)"
                         [pipe]="pipe"
                         [isInputFormat]="true">
    </app-input-value-key>
</ng-template>
