/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClienteView } from './clienteView';
import { OperatoreInterventoFormularioView } from './operatoreInterventoFormularioView';
import { ProgrammaIdStatoDataView } from './programmaIdStatoDataView';

export interface InterventoFormularioView { 
    id?: string;
    programma?: ProgrammaIdStatoDataView;
    codiceOrdineDiLavoro?: string;
    stato?: InterventoFormularioView.StatoEnum;
    costo?: number;
    operatori?: Array<OperatoreInterventoFormularioView>;
    cliente?: ClienteView;
    nota?: string;
    data?: string;
}
export namespace InterventoFormularioView {
    export type StatoEnum = 'DA_VERIFICARE' | 'VERIFICATO' | 'CONSOLIDATO';
    export const StatoEnum = {
        DAVERIFICARE: 'DA_VERIFICARE' as StatoEnum,
        VERIFICATO: 'VERIFICATO' as StatoEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatoEnum
    };
}