import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {ReferenteView} from "../../../../api-clients/generated/services";

export function createTableConfigurationForReferenti(inputList?: Array<ReferenteView>, isSelection?: boolean, listLength?: number,
                                                     pageSize?: number, pageIndex?: number): GenericTableConfigurationModel {
    let list = inputList;
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Nome e Cognome',
                    colonnaKey: 'nominativo',
                    flex: 30,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'E-mail',
                    colonnaKey: 'email',
                    flex: 30,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Telefono',
                    colonnaKey: 'telefono',
                    flex: 30,
                },
                {
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }],
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            pageIndex: pageIndex,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}
