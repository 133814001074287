/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { Dispositivo } from './dispositivo';

export interface AggiornamentoInterventoView { 
    id?: string;
    dataOra?: Date;
    operazione?: AggiornamentoInterventoView.OperazioneEnum;
    dispositivo?: Dispositivo;
    operatore?: string;
    nota?: string;
}
export namespace AggiornamentoInterventoView {
    export type OperazioneEnum = 'AVVIO' | 'EVASIONE' | 'MODIFICA' | 'ANNULLAMENTO' | 'SPOSTAMENTO';
    export const OperazioneEnum = {
        AVVIO: 'AVVIO' as OperazioneEnum,
        EVASIONE: 'EVASIONE' as OperazioneEnum,
        MODIFICA: 'MODIFICA' as OperazioneEnum,
        ANNULLAMENTO: 'ANNULLAMENTO' as OperazioneEnum,
        SPOSTAMENTO: 'SPOSTAMENTO' as OperazioneEnum
    };
}