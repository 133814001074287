import {AfterContentInit, Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {
    GenericComponentDialogConfig,
    InterventoComponentDialogConfig
} from "../../../../shared/interfaces/generic-components.interface";
import {FormComponent} from "../../form/form.component";
import {MatChipsModule} from "@angular/material/chips";
import {PipeModule} from "../../../../shared/pipe/pipe.module";
import {
    DialogForInterventoType,
    DialogInterventoConfirmEnum,
} from "../../../../shared/enum/dialog-intervento-confirm-enum";
import {InterventoProgrammaDTO, InterventoView} from "../../../../../api-clients/generated/services";
import {FormGroup, ɵFormGroupValue, ɵTypedOrUntyped} from "@angular/forms";
import {
    InterventoForm
} from "../../../../shared/modals/creazione-intervento-modal/creazione-intervento-modal.component";
import {tap} from "rxjs";
import {isEqual} from "lodash";
import {MatTooltipModule} from "@angular/material/tooltip";

@Component({
  selector: 'app-dialog-wrap-intervento',
  standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogContent, MatProgressSpinnerModule, MatDialogClose, MatChipsModule, PipeModule, MatTooltipModule],
  templateUrl: './dialog-wrap-intervento.component.html',
  styleUrl: './dialog-wrap-intervento.component.scss'
})
export class DialogWrapInterventoComponent implements AfterContentInit {

    @Input() data: InterventoComponentDialogConfig;
    @Output() close$: EventEmitter<void> = new EventEmitter<void>();
    @Output() confirm$: EventEmitter<string> = new EventEmitter<string>();
    @Input() formComponent: FormComponent;
    @Input() showOdl: boolean;
    @Input() showChip: boolean | undefined;
    @Input() statusChip: InterventoProgrammaDTO.StatoEnum | undefined
    @Input() canShowOdl: boolean;
    @Input() dialogForInterventoType: DialogForInterventoType;
    @Input() isInterventoNelPassato: boolean;
    @Input() isInterventoFormulario: boolean;
    @Input() isOrHasExtraSosta: boolean;
    DialogForInterventoTypeEnum = DialogForInterventoType

    DialogForInterventoType = DialogForInterventoType

    dialogTypeModifica = DialogForInterventoType.MODIFICA
    statusProgrammato = InterventoProgrammaDTO.StatoEnum.PROGRAMMATO;
    statusIncorso = InterventoProgrammaDTO.StatoEnum.INCORSO;

    @Input() tipologiaIntervento: InterventoProgrammaDTO.TipologiaEnum;
    InterventoTipologiaEnum = InterventoProgrammaDTO.TipologiaEnum;
    @Input({required: true}) interventoFormGroup: FormGroup<InterventoForm>
    initialValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any>;
    enableModifiche: boolean;
    @Input() isEvasoModificabile: boolean;
    protected readonly InterventoProgrammaDTO = InterventoProgrammaDTO;
    protected readonly DialogInterventoConfirmEnum = DialogInterventoConfirmEnum;

    @HostListener('keydown.Escape', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        this.close$.emit();
    }

    openOdl() {
        this.confirm$.emit(DialogInterventoConfirmEnum.ODL);
    }

    openFirma() {
        this.confirm$.emit(DialogInterventoConfirmEnum.FIRMA);
    }

    showConfermaModifiche() {
        const condition1 = (this.tipologiaIntervento === InterventoView.TipologiaEnum.ATTIVITA
        || this.tipologiaIntervento === InterventoView.TipologiaEnum.PARTENZA);
        const condition2 = (this.statusChip === this.statusProgrammato ||
        this.statusChip === this.statusIncorso)
        const condition3 = (this.isInterventoNelPassato && this.dialogTypeModifica === this.dialogForInterventoType);
        return condition1 && (condition2 || condition3);
    }

    ngAfterContentInit() {
        this.initialValue = this.interventoFormGroup.value;
        this.onGroupValueChange()
    }

    onGroupValueChange() {
        this.interventoFormGroup.valueChanges.pipe(
            tap((value) => {
                this.enableModifiche = !isEqual(this.initialValue, value)
            })
        ).subscribe()
    }

    inviaMailBozza() {
        this.confirm$.emit(DialogInterventoConfirmEnum.INVIA_MAIL_BOZZA);
    }
}

export interface DialogWrapI {
    close?: boolean;
    title?: string;
    message?: string;
    icon?: {
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
    hasSubHeader?: boolean;
    hideConfirmSubHeader?: boolean;
}

