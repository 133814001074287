import {Component, Input, OnInit} from '@angular/core';
import {GenericFormFieldComponent} from "../generic-form-field/generic-form-field.component";
import {MatDividerModule} from "@angular/material/divider";
import {NgIf} from "@angular/common";
import {FormControl} from "@angular/forms";
import {DialogForInterventoType} from "../../enum/dialog-intervento-confirm-enum";

@Component({
    selector: 'app-form-avvio-interventi-non-attivita',
    standalone: true,
    imports: [
        GenericFormFieldComponent,
        MatDividerModule,
        NgIf
    ],
    templateUrl: './form-avvio-interventi-non-attivita.component.html',
    styleUrl: './form-avvio-interventi-non-attivita.component.scss'
})
export class FormAvvioInterventiNonAttivitaComponent implements OnInit {
    @Input({required: true}) isPercorrenza: boolean;
    @Input({required: true}) isPartenza: boolean;
    @Input({required: true}) ctrlKilometri: any;
    @Input({required: true}) isProgrammato: boolean;
    errorRequiredMessage = 'Il campo è obbligatorio';

    @Input({required: true}) ctrlLuogoPartenza: FormControl<string | null>;
    @Input({required: true}) isRientro: boolean;
    @Input({required: true}) ctrlLuogoDestinazione: FormControl<string | null>;
    @Input({required: true}) isInCorso: boolean;
    @Input({required: true}) isEvasoVerificatoOrCosolidato: boolean;
    @Input({required: true}) ctrlnotaPercorrenza: FormControl<string | null>;
    @Input({required: true}) apertoPerEvadereAvviare: boolean;
    @Input({required: true}) ctrlNotaOperatore: FormControl<string | null>;
    @Input({required: true}) ctrlOraInizioEffettiva: FormControl<string | null>;
    @Input({required: true}) ctrlOraFineEffettiva: FormControl<string | null>;
    @Input() dialogForInterventoType: DialogForInterventoType
    isModificabile: boolean;
    @Input({required: true}) ctrlGiornoInizioEffettiva: FormControl<string | null>;
    @Input({required: true}) ctrlGiornoFineEffettiva: FormControl<string | null>;

    ngOnInit() {
        this.isModificabile = this.dialogForInterventoType === DialogForInterventoType.MODIFICA_MOVIMENTO
    }

}
