import {FormControl} from "@angular/forms";
import {AutomezzoView, OperatoreView} from "../../../api-clients/generated/services";

export interface FilterAutomezzoForm {
    targa?: FormControl<string | null>;
    descrizione?: FormControl<string | null>;
    riferimento?: FormControl<string | null>;
    stato?: FormControl<AutomezzoView.IntStatoEnum | null>; // è una select
    rimorchio?: FormControl<boolean | null>;
    isCancellato?: FormControl<boolean | null>;
    nascosto?: FormControl<boolean | null>;
    isGuidabileOperatore?: FormControl<GuidabileOperatoreEnum | null>;
}

export interface FilterAttrezzaturaForm {
    descrizione?: FormControl<string | null>;
    isCancellata?: FormControl<boolean | null>;
}

export interface FilterOperatoreForm {
    nominativo?: FormControl<string | null>;
    tipologia?: FormControl<OperatoreView.TipologiaEnum | null>
    isCancellato?: FormControl<boolean | null>;
    nascosto?: FormControl<boolean | null>;
}

export interface FilterAddebitoForm {
    descrizione?: FormControl<string | null>;
    isCancellato?: FormControl<boolean | null>;
}

export interface FilterClienteForm {
    descrizione?: FormControl<string | null>;
    isCancellato?: FormControl<boolean | null>;
    isBloccato: FormControl<boolean | null>
}

export interface FilterAutomezzoInterface {
    targa?: string;
    descrizione?: string;
    riferimento?: string;
    stato?: string;
    rimorchio?: boolean;
    isCancellato?: boolean;
    nascosto?: boolean;
    isGuidabileOperatore?: GuidabileOperatoreEnum;
}

export enum GuidabileOperatoreEnum {
    GUIDABILE_DA_OPERATORE = 'GUIDABILE_DA_OPERATORE',
    NON_GUIDABILE_DA_OPERATORE = 'NON_GUIDABILE_DA_OPERATORE',
}

export interface FilterAttrezzaturaInterface {
    descrizione?: string;
    isCancellata?: boolean;
}

export interface FilterOperatoreInterface {
    nominativo?: string;
    isCancellato?: boolean;
    tipologia?: OperatoreView.TipologiaEnum;
    nascosto?: boolean;
}

export interface FilterAddebitoInterface {
    descrizione?: string;
    isCancellato?: boolean;
}

export interface FilterClienteInterface {
    descrizione?: string;
    isCancellato?: boolean;
    isBloccato?: boolean;
}
