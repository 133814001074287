<app-dialog-wrap [data]="wrapData" (close$)="closeModal($event)" [hiddenScrollY]="true">
    <div class="w-full h-full" [style.overflow-y]="'unset'">
        <!--FORM FILTRO-->
        <div class="w-full h-1/5 flex flex-row gap-2">
            <mat-form-field class="w-full custom-input-height" appearance="outline">
                <mat-label class="active">{{data?.formConfig?.placeholder}}</mat-label>
                <input matInput #filterInput id="filterInput" [autofocus]="true"
                       [placeholder]="data?.formConfig?.placeholder"
                       [formControl]="getFormControl(data?.formConfig?.name)"
                       (keydown.enter)="searchByRequest()">
                <mat-error
                    *ngIf="data?.formConfig?.isRequired && getFormControl(data?.formConfig?.name).hasError('required')">
                    {{data?.formConfig?.errorRequiredMessage || errorRequiredMessage}}
                </mat-error>
            </mat-form-field>

            <ng-container [ngSwitch]="data?.tableConfigurationType">
                <ng-container *ngSwitchCase="CampoDaSelezionare.CLIENTE">
                    <button mat-flat-button class="mr-2"
                            color="primary"
                            (click)="openModalCreazioneCliente()"
                            [matTooltip]="'Crea cliente'">
                        <mat-icon class="ml-1 text-current" iconPositionEnd>
                            <span class="material-symbols-outlined">add</span>
                        </mat-icon>
                        {{'Crea cliente'}}
                    </button>
                </ng-container>

                <ng-container *ngSwitchCase="CampoDaSelezionare.UNITA_LOCALE">
                    <button mat-flat-button class="mr-2"
                            color="primary"
                            (click)="openModaleCreazioneUnitaLocale()"
                            [matTooltip]="'Aggiungi unità locale'">
                        <mat-icon class="ml-1 text-current" iconPositionEnd>
                            <span class="material-symbols-outlined">add</span>
                        </mat-icon>
                        {{'Aggiungi unità locale'}}
                    </button>
                </ng-container>

                <ng-container *ngSwitchCase="CampoDaSelezionare.REFERENTE">
                    <button mat-flat-button class="mr-2"
                            color="primary"
                            (click)="openModaleCreazioneReferente()"
                            [matTooltip]="'Aggiungi referente cliente'">
                        <mat-icon class="ml-1 text-current" iconPositionEnd>
                            <span class="material-symbols-outlined">add</span>
                        </mat-icon>
                        {{'Aggiungi referente cliente'}}
                    </button>
                </ng-container>

                <ng-container *ngSwitchCase="CampoDaSelezionare.ATTREZZATURA">
                    <button mat-flat-button class="mr-2"
                            color="primary"
                            (click)="openModaleCreazioneAttrezzatura()"
                            [matTooltip]="'Aggiungi attrezzatura'">
                        <mat-icon class="ml-1 text-current" iconPositionEnd>
                            <span class="material-symbols-outlined">add</span>
                        </mat-icon>
                        {{'Aggiungi attrezzatura'}}
                    </button>
                </ng-container>
            </ng-container>
        </div>

        <!--RISULTATI -->
        <div class="w-full">
            <app-generic-table class="w-full" *ngIf="!!tableConfiguration"
                               [focusable]="true"
                               [hasMaxHeight]="true"
                               (clickAction)="handleTableEvent($event)"
                               [configuration]="tableConfiguration"
                               (pageAction)="onPageAction($event)"
            ></app-generic-table>
        </div>
    </div>
</app-dialog-wrap>
