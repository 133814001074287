import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    Pipe,
    Renderer2,
    ViewChild
} from '@angular/core';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatInputModule} from "@angular/material/input";
import {CurrencyPipe, DecimalPipe, NgClass, NgIf} from "@angular/common";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {CampoDaSelezionare} from "../../modals/creazione-intervento-modal/creazione-intervento-modal.component";
import {InputValueKeyComponent} from "../input-value-key/input-value-key.component";
import {PipeModule} from "../../pipe/pipe.module";
import {keyPressNumbers} from "../../utils/utils";
import {OnlyDecimalNumbersDirective} from "../../directives/only-decimal-numbers.directive";
import {PositiveNumberOnlyDirective} from "../../directives/only-positive-numbers";
import {NgxCurrencyDirective} from "ngx-currency";
import {MatTooltipModule} from "@angular/material/tooltip";
import {NoCommaPipe} from "../../pipe/no-comma.pipe";
import {CurrencyEuroPipe} from "../../pipe/currency-euro.pipe";
import {A11yModule} from "@angular/cdk/a11y";
import moment from "moment";

@Component({
    selector: 'app-generic-form-field',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        NgIf,
        ReactiveFormsModule,
        InputValueKeyComponent,
        PipeModule,
        OnlyDecimalNumbersDirective,
        PositiveNumberOnlyDirective,
        NgClass,
        NgxCurrencyDirective,

        DecimalPipe,
        CurrencyPipe,
        MatTooltipModule,
        NoCommaPipe,
        A11yModule,
    ],
    templateUrl: './generic-form-field.component.html',
    styleUrl: './generic-form-field.component.scss',
    providers: [
        CurrencyEuroPipe,
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class GenericFormFieldComponent implements OnInit, AfterViewInit {
    @Input({required: true}) control: FormControl;
    @Input() label: string;
    @Input() campoDaSelezionare: CampoDaSelezionare;
    @Input() errorRequiredMessage: string;
    @Output() openSelect: EventEmitter<CampoDaSelezionare> = new EventEmitter<CampoDaSelezionare>();
    @Input() readonly: boolean;
    @Input() type: string;
    @Input() pipe: Pipe | undefined;
    @Input() readonlyform: boolean;
    @Input() onlyPositiveIntegers: boolean;
    @Input() cantWriteText: boolean;
    @Output() setNullEmitter: EventEmitter<CampoDaSelezionare> = new EventEmitter<CampoDaSelezionare>();
    errorHourMessage = 'L\'orario di fine deve essere maggiore dell\'orario di inizio';
    errorHourPranzoMessage = 'La pausa pranzo deve essere interna all\'orario dell\'intervento';
    errorDateMessage = 'La data di fine deve essere maggiore della data di inizio';

    @Input() set disabled(isDisabled: boolean) {
        if (!!isDisabled) {
            this.control?.disable();
        } else {
            this.control?.enable();
        }
    }

    @Input() disabledTooltip: string;
    @Input() autofocus: boolean;

    errorPatternMessage: string = "Si prega di inserire un indirizzo e-mail valido";
    errorGenericPatternMessage: string = "Si prega di inserire un campo valido";
    options = {
        allowNegative: false,
        suffix: '€',
        decimal: '.',
        precision: 2,
        nullable: false,
        min: 0,
        allowZero: true,
        prefix: ''
    }

    ngOnInit() {
    }

    constructor(private renderer: Renderer2) {
    }

    @ViewChild('myInput') myInput: ElementRef;

    ngAfterViewInit() {
        if (this.autofocus) {
            if (!!this.myInput?.nativeElement && !!this.renderer?.selectRootElement(this.myInput?.nativeElement)) {
                this.renderer?.selectRootElement(this.myInput?.nativeElement)?.focus();
            }
        }
    }

    keyPressNumber(event) {
        return keyPressNumbers(event);
    }

    preventKeyDown(): boolean | void {
        return !this.cantWriteText;
    }

    getReadOnlyValue(value: any) {
        if (!!value) {
            if (this.type === 'date') {
                return moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY')
            } else {
                return value
            }
        } else {
            return ' - '
        }
    }
}
