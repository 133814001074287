/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tentativi di risoluzione per problemi noti avvenuti durante l'esecuzione dell'applicativo. Azioni ammesse:  - *LOCK_USER*: bloccare un utente, disabilitandone l'accesso - *RESEND_VERIFICATION_EMAIL*: avviare una nuova fase di verifica dell'account di un utente - *RESEND_RECOVERY_EMAIL*: avviare una nuova fase di cambio password dell'account di un utente
 */
export type PlatformActionType = 'LOCK_USER' | 'RESEND_VERIFICATION_EMAIL' | 'RESEND_RECOVERY_EMAIL';

export const PlatformActionType = {
    LOCKUSER: 'LOCK_USER' as PlatformActionType,
    RESENDVERIFICATIONEMAIL: 'RESEND_VERIFICATION_EMAIL' as PlatformActionType,
    RESENDRECOVERYEMAIL: 'RESEND_RECOVERY_EMAIL' as PlatformActionType
};