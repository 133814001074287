export * from './addAttrezzaturaDTO';
export * from './addAttrezzaturaInterventoDTO';
export * from './addAutomezzoInterventoDTO';
export * from './addCalendarioDTO';
export * from './addCalendarioDTOOraInizio';
export * from './addCalendarioDTOOraInizioOffset';
export * from './addCalendarioDTOOraInizioOffsetRules';
export * from './addCalendarioDTOOraInizioOffsetRulesDuration';
export * from './addCalendarioDTOOraInizioOffsetRulesDurationUnits';
export * from './addCalendarioDTOOraInizioOffsetRulesTransitionRules';
export * from './addCalendarioDTOOraInizioOffsetRulesTransitions';
export * from './addClienteDTO';
export * from './addClienteInterventoDTO';
export * from './addClienteInterventoFormularioDTO';
export * from './addDurataAddebitoDTO';
export * from './addInterventoDTO';
export * from './addInterventoDaEvadereDTO';
export * from './addInterventoFormularioDTO';
export * from './addInterventoPassatoDTO';
export * from './addNotaInterventoDTO';
export * from './addOperatoreDTO';
export * from './addOperatoreInterventoDTO';
export * from './addOperatoreInterventoFormularioDTO';
export * from './addReferenteDTO';
export * from './addReferenteInterventoDTO';
export * from './addTipoInterventoDTO';
export * from './addUnitaLocaleDTO';
export * from './addUnitaLocaleInterventoDTO';
export * from './addebitiFilterForm';
export * from './addebitoView';
export * from './aggiornamentoInterventoView';
export * from './anagraficheHomeStats';
export * from './attrezzaturaInterventoView';
export * from './attrezzaturaProgrammaDTO';
export * from './attrezzaturaView';
export * from './attrezzatureFilterForm';
export * from './authorityType';
export * from './authorityUser';
export * from './automezziFilterForm';
export * from './automezzoProgrammaDTO';
export * from './automezzoView';
export * from './avviaInterventoDTO';
export * from './body';
export * from './calendarioView';
export * from './clienteProgrammaDTO';
export * from './clienteUnitaLocaleDTO';
export * from './clienteView';
export * from './clientiFilterForm';
export * from './dateTimeDTO';
export * from './dettagliJob';
export * from './dispositivo';
export * from './durataAddebitoView';
export * from './evadiInterventoDTO';
export * from './exceptionResponseDTO';
export * from './fotoIntervento';
export * from './generaRegistroGiornalieroOperatoreDTO';
export * from './genericResponseDTO';
export * from './giornoMese';
export * from './giornoSettimana';
export * from './grantType';
export * from './indirizzo';
export * from './interventiDaEvadereFilterForm';
export * from './interventiDailyWeekStats';
export * from './interventiFilterForm';
export * from './interventiFormularioFilterForm';
export * from './interventiHomeStats';
export * from './interventiOverviewStats';
export * from './interventiProgrammaFilterForm';
export * from './interventoFormularioView';
export * from './interventoProgrammaDTO';
export * from './interventoView';
export * from './jobDTO';
export * from './jobStatus';
export * from './jobType';
export * from './localTime';
export * from './loginForm';
export * from './loginResponse';
export * from './movimentoRegistro';
export * from './notaInterventoBucketView';
export * from './notaInterventoView';
export * from './notaProgrammaDTO';
export * from './noteInterventoFilterForm';
export * from './operatoreInterventoFormularioView';
export * from './operatoreInterventoView';
export * from './operatoreNoUserView';
export * from './operatoreProgrammaDTO';
export * from './operatoreView';
export * from './operatoriFilterForm';
export * from './ordineDiLavoro';
export * from './pageAddebitoView';
export * from './pageAttrezzaturaView';
export * from './pageAutomezzoView';
export * from './pageClienteView';
export * from './pageNotaInterventoBucketView';
export * from './pageOperatoreView';
export * from './pageUserView';
export * from './pageableObject';
export * from './patchAttrezzaturaDTO';
export * from './patchAutomezzoDTO';
export * from './patchClienteDTO';
export * from './patchDurataAddebitoDTO';
export * from './patchInterventoConsolidamentoDTO';
export * from './patchInterventoDTO';
export * from './patchInterventoFormularioDTO';
export * from './patchInterventoMovimentoDTO';
export * from './patchInterventoVerificaDTO';
export * from './patchOperatoreDTO';
export * from './patchReferenteDTO';
export * from './patchUnitaLocaleDTO';
export * from './platformActionType';
export * from './programmaDTO';
export * from './programmaIdStatoDataView';
export * from './pushTokenDTO';
export * from './referenteIntervento';
export * from './referenteProgrammaDTO';
export * from './referenteView';
export * from './refreshTokenForm';
export * from './registriGiornalieriOperatoreFilterForm';
export * from './registroGiornalieroOperatoreView';
export * from './resendEmailRequestDTO';
export * from './rigaProgrammaDTO';
export * from './ripetizioneMensile';
export * from './ripetizioneSettimanale';
export * from './sommarioAutomezzo';
export * from './sortObject';
export * from './tipoInterventoProgrammaDTO';
export * from './unitaLocaleIntervento';
export * from './unitaLocaleProgrammaDTO';
export * from './unitaLocaleView';
export * from './updateUserPasswordForm';
export * from './user';
export * from './userEmailRequest';
export * from './userFilterForm';
export * from './userRegistrationForm';
export * from './userView';
