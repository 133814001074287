<div class="flex w-full flex-col py-2">
    <ng-container *ngIf="!aggiornamenti || aggiornamenti.length === 0; else listaAggiornamenti">
        <fuse-alert
            class="w-full"
            [type]="'info'"
            [appearance]="'outline'"
        >{{noAggiornamentiMessage}}</fuse-alert>
    </ng-container>
</div>

<ng-template #listaAggiornamenti>
    <ng-container *ngFor="let aggiornamento of aggiornamenti; let i = index">
        <div class="flex flex-col gap-2 w-full px-1.5" [ngClass]="(i%2) ? 'bg-accent-200' : null">
            <div class="container">
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
                <div class="text mat-typography">Aggiornamento {{i + 1}}</div>
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div class="flex flex-row justify-between">
                <app-input-value-key [label]="'Operazione'"
                                     [value]="aggiornamento.operazione"
                                     [isInputFormat]="true">
                </app-input-value-key>

                <app-input-value-key [label]="'Data'"
                                     [value]="getData(aggiornamento.dataOra)"
                                     [isInputFormat]="true">
                </app-input-value-key>

                <app-input-value-key [label]="'Ora'"
                                     [value]="getOra(aggiornamento.dataOra)"
                                     [isInputFormat]="true">
                </app-input-value-key>


                <app-input-value-key [label]="'Operatore'"
                                     [value]="aggiornamento.operatore"
                                     [isInputFormat]="true">
                </app-input-value-key>
            </div>

            <!-- DISPOSITIVO-->
            <div class="container">
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
                <div class="text mat-typography">Dispositivo</div>
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
            </div>
            <div class="flex flex-row justify-between">
                <app-input-value-key [label]="'Modello'"
                                     [value]="aggiornamento?.dispositivo?.modelloDispositivo"
                                     [isInputFormat]="true">
                </app-input-value-key>

                <app-input-value-key [label]="'Sistema Operativo'"
                                     [value]="aggiornamento?.dispositivo?.sistemaOperativoDispositivo"
                                     [isInputFormat]="true">
                </app-input-value-key>

                <app-input-value-key [label]="'Marca'"
                                     [value]="aggiornamento?.dispositivo?.marcaDispositivo"
                                     [isInputFormat]="true">
                </app-input-value-key>

                <app-input-value-key [label]="'Versione Sistema Operativo'"
                                     [value]="aggiornamento?.dispositivo?.versioneSistemaOperativoDispositivo"
                                     [isInputFormat]="true">
                </app-input-value-key>
            </div>

            <!-- NOTE-->
            <ng-container *ngIf="!!aggiornamento.nota">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Note</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="w-full flex-col gap-1" [innerHTML]="aggiornamento.nota">
                    <!-- <app-input-value-key [label]="'Nota'"
                                          [value]="aggiornamento.nota"
                                          [isInputFormat]="true">
                     </app-input-value-key>-->
                </div>
            </ng-container>
        </div>
    </ng-container>
</ng-template>

