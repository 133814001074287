/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddebitoView } from './addebitoView';
import { AggiornamentoInterventoView } from './aggiornamentoInterventoView';
import { AttrezzaturaInterventoView } from './attrezzaturaInterventoView';
import { AutomezzoView } from './automezzoView';
import { CalendarioView } from './calendarioView';
import { ClienteView } from './clienteView';
import { Indirizzo } from './indirizzo';
import { NotaInterventoView } from './notaInterventoView';
import { OperatoreInterventoView } from './operatoreInterventoView';
import { OrdineDiLavoro } from './ordineDiLavoro';
import { ReferenteIntervento } from './referenteIntervento';
import { UnitaLocaleIntervento } from './unitaLocaleIntervento';

export interface InterventoView { 
    id?: string;
    notaOperatore?: string;
    kmAutomezzo?: number;
    notaPercorrenza?: string;
    aggiornamenti?: Array<AggiornamentoInterventoView>;
    dataInvioMailODL?: Date;
    statoProgramma?: InterventoView.StatoProgrammaEnum;
    dataOraInizioPrevista?: Date;
    ordineDiLavoro?: OrdineDiLavoro;
    isInterventoPassato?: boolean;
    numeroPreventivo?: string;
    unitaLocaleIntervento?: UnitaLocaleIntervento;
    destinazione?: Indirizzo;
    partenza?: Indirizzo;
    note?: Array<NotaInterventoView>;
    tipiIntervento?: Array<AddebitoView>;
    automezzo?: AutomezzoView;
    rimorchio?: AutomezzoView;
    referenteUnico?: string;
    referenteUnicoTel?: string;
    isInterventoFormulario?: boolean;
    stato?: InterventoView.StatoEnum;
    referenteIntervento?: ReferenteIntervento;
    isInterventoExtraSosta?: boolean;
    hasExtraSosta?: boolean;
    dataOraInizioReale?: Date;
    dataOraFineReale?: Date;
    costo?: number;
    dataOraFinePrevista?: Date;
    operatori?: Array<OperatoreInterventoView>;
    cliente?: ClienteView;
    attrezzature?: Array<AttrezzaturaInterventoView>;
    durataStimata?: number;
    calendario?: CalendarioView;
    isInseritoInProgramma?: boolean;
    tipologia?: InterventoView.TipologiaEnum;
}
export namespace InterventoView {
    export type StatoProgrammaEnum = 'BOZZA' | 'COMUNICATO' | 'MODIFICATO' | 'IN_CORSO' | 'EVASO' | 'VERIFICATO' | 'CONSOLIDATO';
    export const StatoProgrammaEnum = {
        BOZZA: 'BOZZA' as StatoProgrammaEnum,
        COMUNICATO: 'COMUNICATO' as StatoProgrammaEnum,
        MODIFICATO: 'MODIFICATO' as StatoProgrammaEnum,
        INCORSO: 'IN_CORSO' as StatoProgrammaEnum,
        EVASO: 'EVASO' as StatoProgrammaEnum,
        VERIFICATO: 'VERIFICATO' as StatoProgrammaEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatoProgrammaEnum
    };
    export type StatoEnum = 'DA_EVADERE' | 'PROGRAMMATO' | 'ANNULLATO' | 'CANCELLATO' | 'SPOSTATO' | 'IN_CORSO' | 'EVASO' | 'VERIFICATO' | 'CONSOLIDATO' | 'ND';
    export const StatoEnum = {
        DAEVADERE: 'DA_EVADERE' as StatoEnum,
        PROGRAMMATO: 'PROGRAMMATO' as StatoEnum,
        ANNULLATO: 'ANNULLATO' as StatoEnum,
        CANCELLATO: 'CANCELLATO' as StatoEnum,
        SPOSTATO: 'SPOSTATO' as StatoEnum,
        INCORSO: 'IN_CORSO' as StatoEnum,
        EVASO: 'EVASO' as StatoEnum,
        VERIFICATO: 'VERIFICATO' as StatoEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatoEnum,
        ND: 'ND' as StatoEnum
    };
    export type TipologiaEnum = 'ATTIVITA' | 'PARTENZA' | 'PERCORRENZA' | 'RIENTRO' | 'NOTA';
    export const TipologiaEnum = {
        ATTIVITA: 'ATTIVITA' as TipologiaEnum,
        PARTENZA: 'PARTENZA' as TipologiaEnum,
        PERCORRENZA: 'PERCORRENZA' as TipologiaEnum,
        RIENTRO: 'RIENTRO' as TipologiaEnum,
        NOTA: 'NOTA' as TipologiaEnum
    };
}