/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AutomezzoView { 
    id?: string;
    intIsCancellato?: boolean;
    intRiferimento?: string;
    intStato?: AutomezzoView.IntStatoEnum;
    intNascostoInProgramma?: boolean;
    ordineInProgramma?: number;
    intIsGuidabileDaOperatore?: boolean;
    descrizione?: string;
    targa?: string;
    rimorchio?: boolean;
}
export namespace AutomezzoView {
    export type IntStatoEnum = 'ATTIVO' | 'IN_MANUTENZIONE' | 'FERMO' | 'NON_DISPONIBILE';
    export const IntStatoEnum = {
        ATTIVO: 'ATTIVO' as IntStatoEnum,
        INMANUTENZIONE: 'IN_MANUTENZIONE' as IntStatoEnum,
        FERMO: 'FERMO' as IntStatoEnum,
        NONDISPONIBILE: 'NON_DISPONIBILE' as IntStatoEnum
    };
}