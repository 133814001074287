import {AfterViewInit, Component, ElementRef, Injectable, OnDestroy, Renderer2} from '@angular/core';
import {Subject} from "rxjs";
import {AbstractDefaultComponent} from "./abstract-default-component";


@Injectable()
export abstract class AbstractFocusManagerComponent extends AbstractDefaultComponent implements AfterViewInit{

    protected constructor(protected el: ElementRef,
                          protected renderer: Renderer2) {
        super();
    }

    ngAfterViewInit() {
        this.focusFirstInputFieldModal();
    }

    focusFirstInputFieldModal(): void {
        const inputFieldModals = this.el.nativeElement.querySelectorAll('.input-field-modal');
        const firstEnabledInputFieldModal = Array.from(inputFieldModals).find((element: Element) => {
            return !(element as HTMLInputElement).disabled;
        });
        if (firstEnabledInputFieldModal) {
            this.renderer.selectRootElement(firstEnabledInputFieldModal).focus();
        }
    }


}
