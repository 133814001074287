/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuthorityUser } from './authorityUser';
import { OperatoreNoUserView } from './operatoreNoUserView';

export interface UserView { 
    id?: string;
    credentialsExpired?: boolean;
    accountLocked?: boolean;
    accountEnabled?: boolean;
    nome?: string;
    cognome?: string;
    operatore?: OperatoreNoUserView;
    authorities?: Array<AuthorityUser>;
    email?: string;
}