import { Injectable } from '@angular/core';
import moment from "moment";

@Injectable({
  providedIn: 'root'
})
export class BlobManagerService {

  constructor() { }

  onSuccess(file: Blob, nomeFile: string) {
    const ext = nomeFile.substr(nomeFile.lastIndexOf('.') + 1);
    const imageExt= ['png', 'jpeg', 'jpg'];
    const textExt= ['pdf'];
    const typeBlob= imageExt.includes(ext)? ('image/'+ext) : textExt.includes(ext)?('application/'+ext): '';

    const fileNameToDownload = this.makeFilename(nomeFile);
    const blob = new File([file], fileNameToDownload,{type: typeBlob});
    const blobURL = URL.createObjectURL(blob);
    window.open(blobURL, '_blank');
  }

  download(file: Blob, nomeFile: string) {
      let url = window.URL.createObjectURL(file);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = nomeFile;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  }

  makeFilename(filenameFromBE: string): string {
    const extension = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('.'));
    const fileNameWithouthTimestamp = filenameFromBE?.substring(0, filenameFromBE?.lastIndexOf('_'));
    const fileNameFinalPart = fileNameWithouthTimestamp?.substring(fileNameWithouthTimestamp?.lastIndexOf('/') + 1);
    const timestampString = filenameFromBE?.substring(filenameFromBE?.lastIndexOf('_') + 1, filenameFromBE?.lastIndexOf('.'));
    const uploadDate = this.italianDateWithFormat(moment(Number(timestampString)).toDate(), 'DD_MM_YYYY');
    const fileNameToDownload = fileNameFinalPart + '_' + uploadDate + extension;
    return fileNameToDownload;
  }

  italianDateWithFormat(dataUTC: Date | string | undefined, format = 'DD/MM/YYYY HH:mm'): string {
    return !!dataUTC ? moment(moment.utc(dataUTC).local().format('DD/MM/YYYY HH:mm'), 'DD/MM/YYYY HH:mm').format(format) : null;
  }
}
