import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl, ReactiveFormsModule} from '@angular/forms';

import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgIf} from "@angular/common";

@Component({
    selector: 'app-input-file',
    templateUrl: './input-file.component.html',
    styleUrls: ['./input-file.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        NgIf
    ]
})
export class InputFileComponent implements OnInit {
    @Input() label: string;
    @Input() labelNoTranslate: string;
    @Input() form: FormControl;
    @Input() verificaId: boolean;
    @Input() idCampo: string;

    private fileSelected: File;
    @Output() uploadImgEmitter = new EventEmitter<File>();
    @Output() downloadEmitter = new EventEmitter<string>();
    @Output() deleteEmitter = new EventEmitter<string>();
    @Output() saveBeforeUpload = new EventEmitter<void>();
    @ViewChild('inputFile') inputRef: ElementRef;
    @Input() sizeMB = 15;
    // files showed in the window
    @Input() listFileAccepted = ['pdf', 'gz', 'rar', 'tar', 'gz', 'tgz', 'zip', '7z']
    // files excluded
    @Input() listFileExcluded = ['exe', 'bat', 'sh', 'com', 'wsh', 'run', 'osx']
    nameFile: string;
    acceptedFileList: string;

    constructor(private fuseConfirmationService: FuseConfirmationService) {
        this.acceptedFileList = this.listFileAccepted.map(extension => '.' + extension).join(', ');
    }

    ngOnInit(): void {
    }


    onFileSelected(event: any) {
        if (event?.target?.files?.length === 0) {
            return;
        }
        const fileInput = event?.target?.files[0];
        const fileInputType = fileInput.name.substring(fileInput.name.lastIndexOf('.'));

        if (!this.listFileExcluded.some(file => fileInputType.includes(file))) {
            if (this.checkSizeMB(fileInput.size) > this.sizeMB) {
                this.fuseConfirmationService.open({
                    title: 'Attenzione',
                    message: 'Inserire file con dimensione minore di ' + this.sizeMB + 'MB',
                    icon: {
                        name: 'mat_solid:error_outline',
                        color: 'error'
                    },
                    onBackdrop: {
                        show: true,
                        backdrop: false
                    }
                });
            } else {
                this.fileSelected = fileInput;
                this.nameFile = this.fileSelected.name;
                this.uploadImgEmitter.emit(this.fileSelected);
            }
        } else {
            this.fuseConfirmationService.open({
                title: 'Attenzione',
                message: "Formato non supportato. Inserire un file non eseguibile.",
                icon: {
                    name: 'mat_solid:error_outline',
                    color: 'error'
                },
                onBackdrop: {
                    show: true,
                    backdrop: false
                }
            });
        }

        this.inputRef.nativeElement.value = '';
    }

    download() {
        this.downloadEmitter.emit(this.form.value)
    }

    open(element: HTMLInputElement) {
        if (this.verificaId) {
            if (this.idCampo) {
                element.click()
            } else {
                this.saveBeforeUpload.emit();
            }
        } else {
            element.click()
        }

    }

    checkSizeMB(bytes: number): number {
        return bytes / (1024 * 1024);
    }

    delete() {
        this.fuseConfirmationService.open({
                title: this.form.value,
                message: "Sei sicuro di voler eliminare l'elemento?",
                icon: {
                    name: 'mat_outline:delete',
                    color: 'warning'
                },
                onBackdrop: {
                    show: false,
                    backdrop: true
                },
                additionalActions: [
                    {
                        color: 'accent',
                        label: "Chiudi",
                    },
                    {
                        color: 'primary',
                        label: "Elimina",
                        function: () => this.deleteEmitter.emit(this.form.value)
                    }]
            }
        );
    }
}
