/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DettagliJob } from './dettagliJob';
import { JobStatus } from './jobStatus';

export interface JobDTO { 
    id?: string;
    slug?: string;
    counter?: number;
    dataOraCreazione?: Date;
    dataOraInizio?: Date;
    dataOraFine?: Date;
    dettagli?: DettagliJob;
    stato?: JobStatus;
}