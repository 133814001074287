import { Injectable } from '@angular/core';
import {FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {formGroupConfigInterface} from "../shared/interfaces/generic-components.interface";
import {TypeDialogFormEnum} from "../shared/enum/generic-components-enum";
import {isNil} from "lodash";

@Injectable({
  providedIn: 'root'
})
export class FormModuleService {

  constructor() {
  }

  formControl(name: string, formGroup: FormGroup): FormControl {
    return formGroup.get(name) as FormControl;
  }


  createFormGroup(formGroupConfig: formGroupConfigInterface[], formGroup: FormGroup, validatorFns?: ValidatorFn[]) {
    formGroupConfig.forEach(form => {
      formGroup.addControl(form.name, new FormControl(null))
      if (form.required) {
        this.formControl(form.name, formGroup).addValidators(Validators.required)
      }
      if (form?.regex?.pattern) {
        this.formControl(form.name, formGroup).addValidators(Validators.pattern(new RegExp(form?.regex?.pattern)))
      }
      if (!isNil(form?.max?.number)) {
        this.formControl(form.name, formGroup).addValidators(Validators.max(form.max.number));
      }
      if (!isNil(form?.min?.number)) {
        this.formControl(form.name, formGroup).addValidators(Validators.min(form.min.number));
      }
      if (form?.maxlength) {
        this.formControl(form.name, formGroup).addValidators(Validators.maxLength(form?.maxlength));
      }
      /*if (form?.isSpace) {
          this.formControl(form.name, formGroup).addValidators(CustomValidators.noSpace());
      }*/
      if (form.disable) {
        this.formControl(form.name, formGroup).disable();
      }
    })
    if (validatorFns && validatorFns.length > 0) {
      formGroup.setValidators(validatorFns);
    }
  }

  createFormGroupWithValue(formGroupConfig: formGroupConfigInterface[], formGroup: FormGroup, validatorFns?: ValidatorFn[]): FormGroup {
    formGroupConfig.forEach((form) => {
      formGroup.addControl(form.name, new FormControl(form.value));
      if (form.required) {
        this.formControl(form.name, formGroup).addValidators(Validators.required)
      }
      if (form?.regex?.pattern) {
        this.formControl(form.name, formGroup).addValidators(Validators.pattern(new RegExp(form?.regex?.pattern)))
      }
      if (!isNil(form?.max?.number)) {
        this.formControl(form.name, formGroup).addValidators(Validators.max(form.max.number));
      }
      if (!isNil(form?.min?.number)) {
        this.formControl(form.name, formGroup).addValidators(Validators.min(form.min.number));
      }
      if (form?.maxlength) {
        this.formControl(form.name, formGroup).addValidators(Validators.maxLength(form?.maxlength));
      }
      if (form.disable) {
        this.formControl(form.name, formGroup).disable();
      }
    });
    if (validatorFns && validatorFns.length > 0) {
      formGroup.setValidators(validatorFns);
    }
    return formGroup;
  }
}
