import {AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {isArray, isEmpty, isEqual} from "lodash";
import moment, {Moment} from "moment";
import {isError} from "lodash-es";
import {
    comparisonDateValidation,
    comparisonDaysAndHourValidation,
    comparisonHoursValidation,
    getFormControlFromFormGroupTyped, initialHourIsAfterFinalHour
} from "../utils/utils";
import {InterventoToSubmit} from "../modals/creazione-intervento-modal/creazione-intervento-modal.component";

export class CustomValidators {

    /**
     * Validatore che controlla se il dominio della mail è valido
     * @param notForbiddenDomain
     */



    static forbiddenDomain(notForbiddenDomain: string[]): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            const controlEmail = formControl;

            if (!controlEmail || !notForbiddenDomain) {
                return null;
            }

            const currentEmail: string = controlEmail.value;
            const emailDomain: string = currentEmail.split('@')[1];
            if (controlEmail.hasError('forbiddenDomain')) {
                delete controlEmail.errors.forbiddenDomain;
                controlEmail.updateValueAndValidity();
            }
            if (notForbiddenDomain.indexOf(emailDomain) !== -1) {
                return null;
            }
            const errors = {forbiddenDomain: true};
            controlEmail?.setErrors(errors);
            return errors;
        };
    }

    static atLeastOneFielFisRequired(name: string | string[], surname: string, text = 'form.name_or_surname_required'): ValidatorFn {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlName = formGroup.get(name);
            const controlSurname = formGroup.get(surname);

            const error = {checkNameSurname: text};

            const isError = isArray(controlName?.value) ? (controlName?.value?.length > 0 || controlSurname?.value?.trim()) : (controlName?.value?.trim() || controlSurname?.value?.trim())
            if (isError) {
                controlName?.setErrors(null);
                controlSurname?.setErrors(null);
                return null;
            } else {
                controlName?.setErrors(error);
                controlSurname?.setErrors(error);
            }

            return error;
        };

    }

    static rangeDataValidator(dataDa: string, dataA: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {checkRangeData: 'form.invalid_range_data'};

            if ((controlDataDa?.value && controlDataA?.value) || (!controlDataDa?.value && !controlDataA?.value)) {
                controlDataDa?.setErrors(null);
                controlDataA?.setErrors(null);
                return null;
            } else if ((!controlDataDa?.value || !controlDataA.value)) {
                controlDataDa?.setErrors(error);
                controlDataA?.setErrors(error);
            }
            return error;
        };
    }

    static noSpace(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control?.value == null) {
                return null;
            }
            return control?.value?.includes(' ') ? {
                noSpace: true,
            } : null;
        };
    }


    static datePresentiNelRange(dataDa: string, dataA: string, date: {
        dataInizioCalendario: Moment,
        dataFineCalendario: Moment
    }[], format?: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            if (!date?.length) {
                return null
            }
            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);

            const error = {datePresentiNelRange: 'form.date_presenti_nel_range'};
            if (!controlDataDa?.value || !controlDataA?.value) {
                return null;
            } else if (date.some(data => {
                return moment(controlDataDa.value, format).isBetween(data.dataInizioCalendario, data.dataFineCalendario, undefined, '[]')
                    || moment(controlDataA.value, format).isBetween(data.dataInizioCalendario, data.dataFineCalendario, undefined, '[]')
            })) {
                console.log(error)
                return error;
            } else {
                return null;
            }
        };
    }

    // shows the error on end date (as I'm setting the error on dataA ctrl)
    static dataAMaggioreDiDataA(dataDa: string, dataA: string, format: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const controlDataDa = formGroup.get(dataDa);
            const controlDataA = formGroup.get(dataA);
            const error = {dataAMaggioreDiDataA: 'form.error_data_range'};
            if (!controlDataDa?.value || !controlDataA?.value) {
                return null;
            } else if (moment(controlDataDa.value, format).isAfter(moment(controlDataA.value, format))) {
                controlDataA.setErrors(error);
                return error;
            } else {
                return null;
            }
        };
    }

    static duplicateInArray(arrayName: string, controlName: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const arrayControl = formGroup.get(arrayName) as FormArray

            const valueArr = arrayControl.controls.map((item) => item.get(controlName)?.value);
            const isDuplicate = valueArr.some((item, idx) => {
                return !!item && valueArr.indexOf(item) != idx
            });
            return isDuplicate ? {duplicateInArray: 'Duplicate'} : null
        };
    }

    static duplicaDataInArray(arrayName: string, controlName: string) {
        return (formGroup: AbstractControl): ValidationErrors | null => {

            const arrayControl = formGroup.get(arrayName) as FormArray

            const dateArr = arrayControl.controls.map((item) => item.get(controlName)?.value) as Date[];
            const dateTimeArr = dateArr?.map(v => v?.getTime())
            const isDuplicate = dateTimeArr.some((item, idx) => {
                return !!item && dateTimeArr.indexOf(item) != idx
            });
            return isDuplicate ? {duplicateInArray: 'Duplicate'} : null
        };
    }


    static onlyHoursNumber(event) {
        return (formGroup: AbstractControl): ValidationErrors | null => {
            const controlHoursNumber = formGroup.get(event);
            let isValidHours;
            if ((controlHoursNumber.value > 0 && controlHoursNumber.value < 25)) {
                isValidHours = true;
            } else {
                isValidHours = false;
            }
            return !isValidHours ? {invalidNumberHours: 'Il valore massimo consentito è 24'} : null;
        };
    }

    static noWhitespaceValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control.hasError('whitespace')) {
                delete control.errors.whitespace;
                control.updateValueAndValidity();
            }
            const isWhitespace = (control?.value || '')?.trim()?.length === 0;
            const isValid = !isWhitespace;
            if (!isValid) {
                const errors = {'whitespace': true};
                control?.setErrors(errors);
            }

            return isValid ? null : {'whitespace': true};
        };
    }

    static noEmptyInnerHTMLValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let div = document.createElement("div");
            div.innerHTML = control?.value;
            if (control.hasError('whitespace')) {
                delete control.errors.whitespace;
                control.updateValueAndValidity();
            }
            const isWhitespace = (div.innerText || '')?.trim()?.length === 0;
            const isValid = !isWhitespace;
            if (!isValid) {
                const errors = {'whitespace': true};
                control?.setErrors(errors);
            }

            return isValid ? null : {'whitespace': true};
        };
    }

    static CourseYearValidator(): ValidatorFn {
        return (group: FormGroup): ValidationErrors | null => {
            const isValid = group.get('primoAnno').value
                || group.get('secondoAnno').value
                || group.get('terzoAnno').value;

            return isValid ? null : {'yearsRequired': true};
        };
    }

    static optionValidator(opzioniPossibili: Array<boolean>): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            return opzioniPossibili.some((opzione) => isEqual(opzione, formControl.value)) ?
                null : {'nonValido': true};
        };
    }

    static timeValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const value: string = control.value;

            if (!value || value.trim() === '') {
                return { 'invalidTime': true };
            }

            const parts = value.split(':');
            const hours = parseInt(parts[0], 10);
            const minutes = parseInt(parts[1], 10);

            if (isNaN(hours) || isNaN(minutes)) {
                return { 'invalidTime': true };
            }

            if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
                return { 'invalidTime': true };
            }

            return null;
        };
    }

    static nonEmptyStringValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value: string = control.value;

            if (!value || value.trim() === '') {
                return { 'nonEmptyString': true };
            }

            return null;
        };
    }

    static comparisonOrarioEGiornoValidator(pathora1: string, pathora2: string, pathgiorno1: string, pathgiorno2: string) : ValidatorFn {
        return (group: FormGroup): ValidationErrors => {

            const controlOra1 = group.controls[pathora1] as FormControl<any>;
            const controlOra2 = group.controls[pathora2] as FormControl<any>;
            const controlGiorno1 = group.controls[pathgiorno1] as FormControl<any>;
            const controlGiorno2 = group.controls[pathgiorno2] as FormControl<any>;

            if (!controlOra1 || !controlOra2 || !controlGiorno1 || !controlGiorno2) {
                return null;
            }

            if (comparisonDaysAndHourValidation(controlOra1, controlOra2, controlGiorno1, controlGiorno2)) {
                controlOra2.setErrors({hourCheck: true});
            } else {
                //controlOra2.setErrors(null);
                if(controlOra2.hasError('hourCheck')) {
                    controlOra2.errors['hourCheck'] = null;
                    delete controlOra2.errors['hourCheck'];
                }
                if(isEmpty(controlOra2.errors)) {
                    controlOra2.setErrors(null)
                }
            }
            return;
        };
    }

    static comparisonOrarioEGiornoValidatorCanBeSame(pathora1: string, pathora2: string, pathgiorno1: string, pathgiorno2: string) : ValidatorFn {
        return (group: FormGroup): ValidationErrors => {

            const controlOra1 = group.controls[pathora1] as FormControl<any>;
            const controlOra2 = group.controls[pathora2] as FormControl<any>;
            const controlGiorno1 = group.controls[pathgiorno1] as FormControl<any>;
            const controlGiorno2 = group.controls[pathgiorno2] as FormControl<any>;

            if (!controlOra1 || !controlOra2 || !controlGiorno1 || !controlGiorno2) {
                return null;
            }

            if (initialHourIsAfterFinalHour(controlOra1, controlOra2, controlGiorno1, controlGiorno2)) {
                controlOra2.setErrors({hourCheck: true});
            } else {
                //controlOra2.setErrors(null);
                if(controlOra2.hasError('hourCheck')) {
                    controlOra2.errors['hourCheck'] = null;
                    delete controlOra2.errors['hourCheck'];
                }
                if(isEmpty(controlOra2.errors)) {
                    controlOra2.setErrors(null)
                }
            }
            return;
        };
    }

    static comparisonOrarioValidator(pathora1: string, pathora2: string) : ValidatorFn {
        return (group: FormGroup): ValidationErrors => {

            const controlOra1 = group.controls[pathora1] as FormControl<any>;
            const controlOra2 = group.controls[pathora2] as FormControl<any>;

            if (!controlOra1 || !controlOra2) {
                return null;
            }

            if (comparisonHoursValidation(controlOra1, controlOra2)) {
                controlOra2.setErrors({hourCheck: true});
            } else {
                //controlOra2.setErrors(null);
                if(controlOra2.hasError('hourCheck')) {
                    controlOra2.errors['hourCheck'] = null;
                    delete controlOra2.errors['hourCheck'];
                }
                if(isEmpty(controlOra2.errors)) {
                    controlOra2.setErrors(null)
                }
            }
            return;
        };
    }

    static comparisonDateValidator(pathData1: string, pathData2: string) : ValidatorFn {
        return (group: FormGroup): ValidationErrors => {

            const controlOra1 = group.controls[pathData1] as FormControl<any>;
            const controlOra2 = group.controls[pathData2] as FormControl<any>;

            if (!controlOra1 || !controlOra2) {
                return null;
            }

            if (comparisonDateValidation(controlOra1, controlOra2)) {
                controlOra2.setErrors({dateCheck: true});
            } else {
                controlOra2.setErrors(null);
                if(controlOra2.hasError('dateCheck')) {
                    controlOra2.errors['dateCheck'] = null;
                    delete controlOra2.errors['dateCheck'];
                }
                if(isEmpty(controlOra2.errors)) {
                    controlOra2.setErrors(null)
                }
            }
            return;
        };
    }

    static comparisonOraPausaInterna(oraInizioPranzoPath: string, oraFinePranzoPath: string,
                                     oraInizioODLPath: string, oraFineODLPath: string): ValidatorFn {
        return (group: FormGroup<InterventoToSubmit>): ValidationErrors => {
            const oraInizioPranzo = group.controls[oraInizioPranzoPath] as FormControl<any>;
            const oraFinePranzo = group.controls[oraFinePranzoPath] as FormControl<any>;
            const oraInizioODL = group.controls[oraInizioODLPath] as FormControl<any>;
            const oraFineODL = group.controls[oraFineODLPath] as FormControl<any>;

            if (!oraInizioPranzo || !oraFinePranzo || !oraInizioODL || !oraFineODL) {
                return null;
            }

            if (!!oraInizioPranzo.value && !!oraFinePranzo.value && !!oraInizioODL.value && !!oraFineODL.value) {
                let condition1 = moment(oraInizioODL.value, 'HH:mm')
                    .isSameOrAfter(moment(oraInizioPranzo.value, 'HH:mm'))
                let condition2 = moment(oraFinePranzo.value, 'HH:mm')
                    .isSameOrAfter(moment(oraFineODL.value, 'HH:mm'))

                if (condition1 || condition2) {
                    oraInizioPranzo.setErrors({hourPranzoCheck: true});
                    oraFinePranzo.setErrors({hourPranzoCheck: true})
                } else {
                    if(oraInizioPranzo.hasError('hourPranzoCheck')) {
                        oraInizioPranzo.errors['hourPranzoCheck'] = null;
                        delete oraInizioPranzo.errors['hourPranzoCheck'];
                    }
                    if(oraFinePranzo.hasError('hourPranzoCheck')) {
                        oraFinePranzo.errors['hourPranzoCheck'] = null;
                        delete oraFinePranzo.errors['hourPranzoCheck'];
                    }
                    /*oraInizioPranzo.setErrors({hourPranzoCheck: null});
                    oraFinePranzo.setErrors({hourPranzoCheck: null})*/
                }
            } else {
                if(oraInizioPranzo.hasError('hourPranzoCheck')) {
                    oraInizioPranzo.errors['hourPranzoCheck'] = null;
                    delete oraInizioPranzo.errors['hourPranzoCheck'];
                }
                if(oraFinePranzo.hasError('hourPranzoCheck')) {
                    oraFinePranzo.errors['hourPranzoCheck'] = null;
                    delete oraFinePranzo.errors['hourPranzoCheck'];
                }
                if(isEmpty(oraInizioPranzo.errors)) {
                    oraInizioPranzo.setErrors(null)
                }
                if(isEmpty(oraFinePranzo.errors)) {
                    oraFinePranzo.setErrors(null)
                }
               /* oraInizioPranzo.setErrors({hourPranzoCheck: null});
                oraFinePranzo.setErrors({hourPranzoCheck: null})*/
            }
            return;
        };
    }
}
