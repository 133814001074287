import {flatten, orderBy} from "lodash";
import {AbstractControl, FormArray, FormControl, FormGroup} from "@angular/forms";
import moment from "moment";

export function keyPressNumbers(event) {
    const charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
        event.preventDefault();
        return false;
    } else {
        return true;
    }
}

export function getHoursArray(): Array<string> {
    const hourArray = flatten([...Array(23).keys()]
        .filter((n) => n > 6)
        .map((n) => (n < 10) ? '0' + n : '' + n)
        .map(
            n => [...Array(4).keys()].map(k =>
                !!k ? n + ":" + (k * 15) : n + ":00"
            )
        ))
    hourArray.push("23:00")
    return hourArray;
}

export function getDaysArrayInCurrentMonth() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;
    return getDaysArrayInMonth(currentYear, currentMonth);
}

export function getDaysArrayInMonth(year: number, month: number) {
    const numberDays = getDaysInMonth(year, month);
    return [...Array(numberDays).keys()].map(n => n + 1);
}

export function getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
}

export function getFormControlFromFormGroup(fg: FormGroup, path: string): FormControl {
    return fg.get(path) as FormControl
}

export function getAbstractControlFromFormGroupTyped<T extends { [K in keyof T]: AbstractControl<any>; }>(fg: FormGroup<T>, path: keyof T): AbstractControl<any> {
    return fg.get(path as string) as AbstractControl<any>
}

export function getFormControlFromFormGroupTyped<T extends { [K in keyof T]: AbstractControl<any>; }>(fg: FormGroup<T>, path: keyof T): FormControl<any> {
    return fg?.get(path as string) as FormControl<any>
}

export function getFormArrayFromFormGroupTyped<T extends { [K in keyof T]: AbstractControl<any>; }>(fg: FormGroup<T>, path: keyof T): FormArray {
    return fg.get(path as string) as FormArray
}

export function getFormGroupFromFormGroupTyped<T extends { [K in keyof T]: AbstractControl<any>; }>(fg: FormGroup<T>, path: keyof T): FormGroup {
    return fg.get(path as string) as FormGroup
}

export function getFormArrayFromFormGroup(fg: FormGroup, path: string): FormArray {
    return fg.get(path) as FormArray
}

export function getFormControlFromFormArray(fa: FormArray, index: number): FormControl {
    return fa.at(index) as FormControl
}

export function orderByOrdine(array: Array<any>) {
    return orderBy(array, ['ordine'], ['asc']);
}

export function orderByOrdineInProgramma(array: Array<any>) {
    return orderBy(array, ['ordineInProgramma'], ['asc']);
}

export function orderByDescrizione(array: Array<any>) {
    return orderBy(array, ['descrizione'], ['asc']);
}

export function orderBydataOraInizioPrevista(array: Array<any>) {
    return array.sort((a,b) =>  new Date(a.dataOraInizioPrevista).getTime() - new Date(b.dataOraInizioPrevista).getTime())
}

export function paginate(array: Array<any>, pageSize: number, pageIndex: number): Array<any> {
    return array.slice((pageIndex) * pageSize, (pageIndex + 1) * pageSize);
}


export function comparisonDaysAndHourValidation(oraInizio: FormControl, oraFine: FormControl,
                                                giornoInizio: FormControl, giornoFine: FormControl) {

    if (!!oraInizio && !!oraFine && !!giornoInizio && !!giornoFine &&
        !!oraInizio?.value && !!oraFine?.value && !!giornoInizio?.value && !!giornoFine?.value) {
        const dataOra1 = calcolaDataOra(
            giornoInizio,
            oraInizio
        );
        const dataOra2 = calcolaDataOra(
            giornoFine,
            oraFine
        );
        let resutl = moment(dataOra1)?.isSameOrAfter(moment(dataOra2))
        return resutl;
    } else {
        return null
    }
}

export function initialHourIsAfterFinalHour(oraInizio: FormControl, oraFine: FormControl,
                                                giornoInizio: FormControl, giornoFine: FormControl) {

    if (!!oraInizio && !!oraFine && !!giornoInizio && !!giornoFine &&
        !!oraInizio?.value && !!oraFine?.value && !!giornoInizio?.value && !!giornoFine?.value) {
        const dataOra1 = calcolaDataOra(
            giornoInizio,
            oraInizio
        );
        const dataOra2 = calcolaDataOra(
            giornoFine,
            oraFine
        );
        let resutl = moment(dataOra1)?.isAfter(moment(dataOra2))
        return resutl;
    } else {
        return null
    }
}



export function comparisonHoursValidation(oraInizio: FormControl, oraFine: FormControl) {
    if (!!oraInizio && !!oraFine && !!oraInizio?.value && !!oraFine?.value) {
            return moment(oraInizio.value, 'HH:mm').isSameOrAfter(moment(oraFine.value, 'HH:mm'))
        } else {
            return null;
        }

}

export function comparisonDateValidation(dataInizio: FormControl, dataFine: FormControl) {
    if (!!dataInizio && !!dataFine && !!dataInizio?.value && !!dataFine?.value) {
        return moment(dataInizio.value, 'YYYY-MM-DD').isSameOrAfter(
            moment(dataFine.value, 'YYYY-MM-DD'))
    } else {
        return null;
    }

}

export function calcolaDataOra(controlData: FormControl, controlOrario: FormControl) {
    const data = moment(controlData.value).format('DD/MM/YYYY');
    if (!!data && !!controlOrario?.value) {
        let arr = controlOrario?.value?.split(':')
        let hour = +arr[0]
        let minute = +arr[1]
        return moment(data, 'DD/MM/YYYY').hours(hour).minutes(minute).toDate();
    } else {
        return undefined
    }
}
