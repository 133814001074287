export enum TypeDialogFormEnum {
    TEXT = 'TEXT',
    SELECT = 'SELECT',
    NUMBER = 'NUMBER',
    TEXTAREA = 'TEXTAREA',
    DATA = 'DATA',
    AMOUNT = 'AMOUNT',
    FILE= 'FILE',
    BOOLEAN = 'BOOLEAN',
    EMAIL = 'EMAIL'
}
