import {Component, Inject} from '@angular/core';
import { CommonModule } from '@angular/common';
import {SnackbarTypes} from "../../../shared/enum/snackbar-types";
import {MAT_SNACK_BAR_DATA, MatSnackBar} from "@angular/material/snack-bar";
import {SnackBarMessage} from "../../../shared/interfaces/snack-bar-message";
import {MatIconModule} from "@angular/material/icon";

@Component({
  selector: 'app-snackbar',
  standalone: true,
    imports: [CommonModule, MatIconModule],
  templateUrl: './snackbar.component.html',
  styleUrl: './snackbar.component.scss'
})
export class SnackbarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMessage,
                public snackBar: MatSnackBar) {
    }

    getIcon() {
        switch (this.data.type) {
            case SnackbarTypes.Success:
                return 'done';
            case SnackbarTypes.Error:
                return 'error_outline';
            case SnackbarTypes.Warning:
                return 'report_problem';
            default:
                return 'cancel';
        }
    }

    closeSnackbar() {
        this.snackBar.dismiss();
    }
}
