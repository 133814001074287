/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AttrezzaturaProgrammaDTO } from './attrezzaturaProgrammaDTO';
import { ClienteProgrammaDTO } from './clienteProgrammaDTO';
import { NotaProgrammaDTO } from './notaProgrammaDTO';
import { TipoInterventoProgrammaDTO } from './tipoInterventoProgrammaDTO';

export interface InterventoProgrammaDTO { 
    ordine?: number;
    id?: string;
    idRichiestaDaEvadere?: string;
    dataOraInizioPrevista?: Date;
    dataOraFinePrevista?: Date;
    stato?: InterventoProgrammaDTO.StatoEnum;
    tipologia?: InterventoProgrammaDTO.TipologiaEnum;
    costo?: number;
    isInterventoFormulario?: boolean;
    isInterventoExtraSosta?: boolean;
    tipiIntervento?: Array<TipoInterventoProgrammaDTO>;
    numeroPreventivo?: string;
    referenteUnico?: string;
    referenteUnicoTel?: string;
    cliente?: ClienteProgrammaDTO;
    note?: Array<NotaProgrammaDTO>;
    attrezzature?: Array<AttrezzaturaProgrammaDTO>;
}
export namespace InterventoProgrammaDTO {
    export type StatoEnum = 'DA_EVADERE' | 'PROGRAMMATO' | 'ANNULLATO' | 'CANCELLATO' | 'SPOSTATO' | 'IN_CORSO' | 'EVASO' | 'VERIFICATO' | 'CONSOLIDATO' | 'ND';
    export const StatoEnum = {
        DAEVADERE: 'DA_EVADERE' as StatoEnum,
        PROGRAMMATO: 'PROGRAMMATO' as StatoEnum,
        ANNULLATO: 'ANNULLATO' as StatoEnum,
        CANCELLATO: 'CANCELLATO' as StatoEnum,
        SPOSTATO: 'SPOSTATO' as StatoEnum,
        INCORSO: 'IN_CORSO' as StatoEnum,
        EVASO: 'EVASO' as StatoEnum,
        VERIFICATO: 'VERIFICATO' as StatoEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatoEnum,
        ND: 'ND' as StatoEnum
    };
    export type TipologiaEnum = 'ATTIVITA' | 'PARTENZA' | 'PERCORRENZA' | 'RIENTRO' | 'NOTA';
    export const TipologiaEnum = {
        ATTIVITA: 'ATTIVITA' as TipologiaEnum,
        PARTENZA: 'PARTENZA' as TipologiaEnum,
        PERCORRENZA: 'PERCORRENZA' as TipologiaEnum,
        RIENTRO: 'RIENTRO' as TipologiaEnum,
        NOTA: 'NOTA' as TipologiaEnum
    };
}