/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AuthorityType } from '../model/authorityType';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { PageUserView } from '../model/pageUserView';
import { PushTokenDTO } from '../model/pushTokenDTO';
import { User } from '../model/user';
import { UserRegistrationForm } from '../model/userRegistrationForm';
import { UserView } from '../model/userView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class UserControllerService {

    protected basePath = 'https://api-dev-gestioneinterventi.southengineering.it/gestione-interventi';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Attiva/blocca un account utente a partire dal suo identificativo. Se bloccato, tutti i token associati all&#x27;utente vengono invalidati.
     * 
     * @param user_id Identificativo dell&#x27;utente da attivare. Non può essere nullo o vuoto.
     * @param activate Valore di abilitazione dell&#x27;utente. Se settato a true, l&#x27;utente viene abilitato. Se settato a false, l&#x27;utente viene bloccato. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public activateOrLockAccount(user_id: string, activate: boolean, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public activateOrLockAccount(user_id: string, activate: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public activateOrLockAccount(user_id: string, activate: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public activateOrLockAccount(user_id: string, activate: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling activateOrLockAccount.');
        }

        if (activate === null || activate === undefined) {
            throw new Error('Required parameter activate was null or undefined when calling activateOrLockAccount.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (activate !== undefined && activate !== null) {
            queryParameters = queryParameters.set('activate', <any>activate);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('put',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente la rimozione del push token.
     * 
     * @param userId 
     * @param idDevice 
     * @param pushToken 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deletePushToken(userId: string, idDevice: string, pushToken: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deletePushToken(userId: string, idDevice: string, pushToken: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deletePushToken(userId: string, idDevice: string, pushToken: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deletePushToken(userId: string, idDevice: string, pushToken: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling deletePushToken.');
        }

        if (idDevice === null || idDevice === undefined) {
            throw new Error('Required parameter idDevice was null or undefined when calling deletePushToken.');
        }

        if (pushToken === null || pushToken === undefined) {
            throw new Error('Required parameter pushToken was null or undefined when calling deletePushToken.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (idDevice !== undefined && idDevice !== null) {
            queryParameters = queryParameters.set('idDevice', <any>idDevice);
        }
        if (pushToken !== undefined && pushToken !== null) {
            queryParameters = queryParameters.set('pushToken', <any>pushToken);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/deletePushToken`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Rimuove un utente a partire dal suo identificativo e ne invalida tutti gli eventuali token.
     * 
     * @param user_id Identificativo dell&#x27;utente da rimuovere. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteUser(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteUser(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteUser(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling deleteUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere il link di reset password di un account.
     * 
     * @param user_id Identificativo dell&#x27;utente per cui richiedere il link di reset password. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getResetPasswordMailLink(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public getResetPasswordMailLink(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public getResetPasswordMailLink(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public getResetPasswordMailLink(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getResetPasswordMailLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('get',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}/get-reset-password-link`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il profilo dell&#x27;utente.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(observe?: 'body', reportProgress?: boolean): Observable<UserView>;
    public getUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserView>>;
    public getUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserView>>;
    public getUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserView>('get',`${this.basePath}/api/v1/user`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce i profili di tutti gli utenti.
     * 
     * @param nomeCognome 
     * @param email 
     * @param authorityType 
     * @param credentialsExpired 
     * @param accountLocked 
     * @param accountEnabled 
     * @param page Pagina di risultati da estrarre [0..N]
     * @param size Numero di elementi per pagina
     * @param sort Criteri di ordinamento nel formato: {property,(asc|desc)}. L&#x27;ordinamento predefinito è crescente per ordering, sono supportati più criteri di ordinamento.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUsersForm(nomeCognome?: string, email?: string, authorityType?: AuthorityType, credentialsExpired?: boolean, accountLocked?: boolean, accountEnabled?: boolean, page?: number, size?: number, sort?: string, observe?: 'body', reportProgress?: boolean): Observable<PageUserView>;
    public getUsersForm(nomeCognome?: string, email?: string, authorityType?: AuthorityType, credentialsExpired?: boolean, accountLocked?: boolean, accountEnabled?: boolean, page?: number, size?: number, sort?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PageUserView>>;
    public getUsersForm(nomeCognome?: string, email?: string, authorityType?: AuthorityType, credentialsExpired?: boolean, accountLocked?: boolean, accountEnabled?: boolean, page?: number, size?: number, sort?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PageUserView>>;
    public getUsersForm(nomeCognome?: string, email?: string, authorityType?: AuthorityType, credentialsExpired?: boolean, accountLocked?: boolean, accountEnabled?: boolean, page?: number, size?: number, sort?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {










        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined && page !== null) {
            queryParameters = queryParameters.set('page', <any>page);
        }
        if (size !== undefined && size !== null) {
            queryParameters = queryParameters.set('size', <any>size);
        }
        if (sort !== undefined && sort !== null) {
            queryParameters = queryParameters.set('sort', <any>sort);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (nomeCognome !== undefined) {
            formParams = formParams.append('nomeCognome', <any>nomeCognome) as any || formParams;
        }
        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }
        if (authorityType !== undefined) {
            formParams = formParams.append('authorityType', <any>authorityType) as any || formParams;
        }
        if (credentialsExpired !== undefined) {
            formParams = formParams.append('credentialsExpired', <any>credentialsExpired) as any || formParams;
        }
        if (accountLocked !== undefined) {
            formParams = formParams.append('accountLocked', <any>accountLocked) as any || formParams;
        }
        if (accountEnabled !== undefined) {
            formParams = formParams.append('accountEnabled', <any>accountEnabled) as any || formParams;
        }

        return this.httpClient.request<PageUserView>('post',`${this.basePath}/api/v1/user/all`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di ricevere il link di verifica di un account.
     * 
     * @param user_id Identificativo dell&#x27;utente per cui richiedere il link di verifica mail. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getVerificationMailLink(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public getVerificationMailLink(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public getVerificationMailLink(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public getVerificationMailLink(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling getVerificationMailLink.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('get',`${this.basePath}/api/v1/user/${encodeURIComponent(String(user_id))}/get-verification-link`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Registra un nuovo utente.
     * 
     * @param body Form di registrazione dell&#x27;utente da aggiungere. Tutti i campi sono obbligatori.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public registerUser(body: UserRegistrationForm, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public registerUser(body: UserRegistrationForm, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public registerUser(body: UserRegistrationForm, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public registerUser(body: UserRegistrationForm, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling registerUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<User>('post',`${this.basePath}/api/v1/user/registration`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Annulla la procedura di reset-password a partire dal token fornito tramite email.
     * Invocata da utente tramite link ricevuto via mail.
     * @param token Token fornito tramite mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetNotMe(token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public resetNotMe(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public resetNotMe(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public resetNotMe(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling resetNotMe.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/reset-not-me`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Richiede il reset della password dell&#x27;utente.
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public resetUserPasswordForm(email: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public resetUserPasswordForm(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public resetUserPasswordForm(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public resetUserPasswordForm(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling resetUserPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (email !== undefined) {
            formParams = formParams.append('email', <any>email) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/reset-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sblocca un utente a partire dal suo identificativo e ne invalida tutti gli eventuali token.
     * Invocata da utente amministratore.
     * @param user_id Identificativo dell&#x27;utente da sbloccare. Non può essere nullo o vuoto.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlockUser(user_id: string, observe?: 'body', reportProgress?: boolean): Observable<User>;
    public unlockUser(user_id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<User>>;
    public unlockUser(user_id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<User>>;
    public unlockUser(user_id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (user_id === null || user_id === undefined) {
            throw new Error('Required parameter user_id was null or undefined when calling unlockUser.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<User>('put',`${this.basePath}/api/v1/user/unlock/${encodeURIComponent(String(user_id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Sblocca un utente a partire dal token fornito tramite email ne invalida tutti gli eventuali token.
     * Invocata da utente tramite link ricevuto via mail.
     * @param token Token fornito tramite mail
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlockUserByToken(token: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public unlockUserByToken(token: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public unlockUserByToken(token: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public unlockUserByToken(token: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling unlockUserByToken.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (token !== undefined && token !== null) {
            queryParameters = queryParameters.set('token', <any>token);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/unlock-token`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di aggiornare il push token.
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePushToken(body: PushTokenDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public updatePushToken(body: PushTokenDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public updatePushToken(body: PushTokenDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public updatePushToken(body: PushTokenDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling updatePushToken.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/updatePushToken`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aggiorna la password dell&#x27;utente.
     * 
     * @param token 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUserPasswordForm(token: string, password: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public updateUserPasswordForm(token: string, password: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public updateUserPasswordForm(token: string, password: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public updateUserPasswordForm(token: string, password: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (token === null || token === undefined) {
            throw new Error('Required parameter token was null or undefined when calling updateUserPassword.');
        }

        if (password === null || password === undefined) {
            throw new Error('Required parameter password was null or undefined when calling updateUserPassword.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/update-password`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Verifica un nuovo account a partire dal token ricevuto via mail dopo la registrazione e setta la sua password.
     * 
     * @param token 
     * @param password 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verifyAccountForm(token?: string, password?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public verifyAccountForm(token?: string, password?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public verifyAccountForm(token?: string, password?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public verifyAccountForm(token?: string, password?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (token !== undefined) {
            formParams = formParams.append('token', <any>token) as any || formParams;
        }
        if (password !== undefined) {
            formParams = formParams.append('password', <any>password) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/user/verification`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
