<button
    mat-icon-button
    (click)="actionMenuDrawer.toggle()">
    <mat-icon [svgIcon]="'heroicons_outline:squares-plus'"></mat-icon>
</button>

<fuse-drawer
    class="w-screen min-w-screen sm:w-100 sm:min-w-100 z-999"
    fixed
    [mode]="'over'"
    [name]="'actionMenuDrawer'"
    [position]="'right'"
    #actionMenuDrawer
>
    <div class="flex flex-col w-full overflow-auto bg-card bg-accent-500">

        <div class="flex flex-row items-center px-6 h-20 min-h-20 text-white bg-primary">
            <div class="ml-3 text-2xl font-semibold tracking-tight">{{'Operazioni disponibili'}}</div>

            <button
                class="ml-auto"
                mat-icon-button
                (click)="actionMenuDrawer.close()">
                <mat-icon
                    class="text-current">close
                </mat-icon>
            </button>
        </div>

        <div class="flex flex-col py-3 px-3 gap-4">
            <ng-container *ngFor="let item of navbarCompactNavigation;">

                <button (click)="openNavbarItemModal(item)" mat-button class="nav-bar-button hover:bg-info-800">
                    {{item.title}}
                    <mat-icon class="pl_5_px"
                              iconPositionEnd [svgIcon]="item.icon"></mat-icon>
                </button>
            </ng-container>
        </div>

    </div>

</fuse-drawer>
