export * from './anagrafiche.service';
import { AnagraficheService } from './anagrafiche.service';
export * from './authController.service';
import { AuthControllerService } from './authController.service';
export * from './dateTimeController.service';
import { DateTimeControllerService } from './dateTimeController.service';
export * from './emailSenderController.service';
import { EmailSenderControllerService } from './emailSenderController.service';
export * from './interventi.service';
import { InterventiService } from './interventi.service';
export * from './interventiFormulario.service';
import { InterventiFormularioService } from './interventiFormulario.service';
export * from './programmazione.service';
import { ProgrammazioneService } from './programmazione.service';
export * from './registroGiornalieroOperatore.service';
import { RegistroGiornalieroOperatoreService } from './registroGiornalieroOperatore.service';
export * from './sincronizzazioni.service';
import { SincronizzazioniService } from './sincronizzazioni.service';
export * from './userController.service';
import { UserControllerService } from './userController.service';
export const APIS = [AnagraficheService, AuthControllerService, DateTimeControllerService, EmailSenderControllerService, InterventiService, InterventiFormularioService, ProgrammazioneService, RegistroGiornalieroOperatoreService, SincronizzazioniService, UserControllerService];
