import {FuseNavigationItem} from "../../../@fuse/components/navigation";
import {MenuItemTitleEnum} from "../enum/menu-item-title-enum";

export const navbarCompactNavigation: Array<Partial<FuseNavigationItem>> = [
    {
        title: MenuItemTitleEnum.RICHIESTE_DA_EVADERE,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.INTERVENTI,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.ATTREZZATURE,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.CLIENTE,
        icon: 'heroicons_outline:plus',
    },
   /* {
        title: MenuItemTitleEnum.INTERVENTO_PASSATO,
        icon: 'heroicons_outline:plus',
    },*/
]

export const navbarAdminCompactNavigation: Array<Partial<FuseNavigationItem>> = [
    {
        title: MenuItemTitleEnum.RICHIESTE_DA_EVADERE,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.INTERVENTI,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.ATTREZZATURE,
        icon: 'heroicons_outline:plus',
    },
    {
        title: MenuItemTitleEnum.CLIENTE,
        icon: 'heroicons_outline:plus',
    },
    {
         title: MenuItemTitleEnum.INTERVENTO_PASSATO,
         icon: 'heroicons_outline:plus',
    },
]

