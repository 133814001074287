import {Component, HostListener} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {AuthControllerService} from "../api-clients/generated/services";
import {MatDialog} from "@angular/material/dialog";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [RouterOutlet],
    providers: [AuthControllerService]
})
export class AppComponent {
    /**
     * Constructor
     */
    constructor(private dialog: MatDialog) {
    }

    // ALCUNE MODALI SONO BLOCCANTI
   /* @HostListener('document:keydown.Escape', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        this.closeAllModals();
    }

    closeAllModals() {
        this.dialog?.closeAll();
    }*/
}
