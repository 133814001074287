/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * payload JSON di evasione intervento.
 */
export interface EvadiInterventoDTO { 
    notaPercorrenza?: string;
    luogoDestinazione?: string;
    kmFinali?: number;
    sistemaOperativoDispositivo?: string;
    versioneSistemaOperativoDispositivo?: string;
    marcaDispositivo?: string;
    modelloDispositivo?: string;
    nominativoFirma?: string;
    dataOraInizio?: Date;
    dataOraFine?: Date;
    isPresentePausaPranzo?: boolean;
    dataOraInizioPausaPranzo?: Date;
    dataOraFinePausaPranzo?: Date;
    notaOrdineDiLavoro?: string;
    notaOperatore?: string;
    isInterventoInReperibilita?: boolean;
    dataOraRichiestaReperibilita?: Date;
    mittenteReperibilita?: string;
}