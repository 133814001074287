import {Component, Inject} from '@angular/core';
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {DurataAddebitoForm} from "../crea-cliente-modal/crea-cliente-modal.component";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {AddebitoDurataDialogConfig} from "../../interfaces/generic-components.interface";
import {DialogWrapComponent} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {NgIf} from "@angular/common";
import {
  AddDurataAddebitoDTO, AddebitoView,
  AnagraficheService,
  ClienteView,
  DurataAddebitoView,
  PatchDurataAddebitoDTO
} from "../../../../api-clients/generated/services";
import {getTestoTipoIntervento} from "../../utils/mask-functions";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {getFormControlFromFormGroupTyped} from "../../utils/utils";
import {CampoDaSelezionare} from "../creazione-intervento-modal/creazione-intervento-modal.component";
import {TipoClickEnum} from "../../components/generic-table/generic-table-model";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {finalize, Observable, take} from "rxjs";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {ManageErrorService} from "../../../services/manage-error.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

@Component({
  selector: 'app-addebito-durata-modal',
  standalone: true,
  imports: [
    DialogWrapComponent,
    NgIf,
    GenericFormFieldComponent
  ],
  templateUrl: './addebito-durata-modal.component.html',
  styleUrl: './addebito-durata-modal.component.scss',
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
  ]
})
export class AddebitoDurataModalComponent extends AbstractDefaultComponent {

  addebitoDurataFormGroup: FormGroup<DurataAddebitoForm>;
  errorRequiredMessage: string = 'Il campo è obbligatorio';

  constructor(public dialogRef: MatDialogRef<AddebitoDurataModalComponent>,
              private dialog: MatDialog,
              private fuseConfirmationService: FuseConfirmationService,
              @Inject(MAT_DIALOG_DATA) public data: AddebitoDurataDialogConfig,
              private dialogManagerService: DialogManagerService,
              private manageErrorService: ManageErrorService,
              private anagraficheService: AnagraficheService) {
    super();
    this.createFormGroup(data?.addebitoDurata)
  }

  createFormGroup(input: DurataAddebitoView) {
    this.addebitoDurataFormGroup = new FormGroup<DurataAddebitoForm>({
      id: new FormControl(input?.id),
      durata: new FormControl(input?.durata, Validators.required),
      addebitoMask: new FormControl(getTestoTipoIntervento(input.addebito), Validators.required),
      addebito: new FormControl(input.addebito, Validators.required),
    })

    if(!!this.ctrlID().value) {
      this.ctrlAddebitoMask().disable()
    }
  }

  ctrlAddebito(): FormControl {
    return getFormControlFromFormGroupTyped<DurataAddebitoForm>(this.addebitoDurataFormGroup, 'addebito')
  }

  ctrlAddebitoMask(): FormControl {
    return getFormControlFromFormGroupTyped<DurataAddebitoForm>(this.addebitoDurataFormGroup, 'addebitoMask')
  }

  ctrlDurata(): FormControl {
    return getFormControlFromFormGroupTyped<DurataAddebitoForm>(this.addebitoDurataFormGroup, 'durata')
  }

  ctrlID(): FormControl {
    return getFormControlFromFormGroupTyped<DurataAddebitoForm>(this.addebitoDurataFormGroup, 'id')
  }

  openModaleDiSelezione(field: CampoDaSelezionare) {
        this.dialogManagerService.openModaleDiSelezione(field, this.ctrlAddebito().value).subscribe(
            {
              next: (value) => {
                if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                  // GESTIONE INTERVENTO
                  this.ctrlAddebito()?.setValue(value.value);
                  this.ctrlAddebitoMask()?.setValue(getTestoTipoIntervento(value.value));
                }
              }
            }
        )
  }

  closeModal($event: void) {
    this.dialogRef.close();
  }

  onConfirm($event: string) {
      if (this.addebitoDurataFormGroup.valid) {
        const bodyAdd: AddDurataAddebitoDTO = {
          durata: this.ctrlDurata()?.value,
          idAddebito: (this.ctrlAddebito()?.value as AddebitoView)?.id
        }

        const bodyPacth: PatchDurataAddebitoDTO = {
          durata: this.ctrlDurata()?.value,

        }
        console.log('this.data.idCliente', this.data.idCliente);
        console.log('this.ctrlID()?.value', this.ctrlID()?.value);

        const request$: Observable<any> = !!this.ctrlID()?.value ?
            this.anagraficheService.patchDurataAddebitoCliente(bodyPacth, this.data.idCliente, this.ctrlID().value) :
            this.anagraficheService.addDurataAddebitoCliente(bodyAdd, this.data.idCliente);

        this.fuseConfirmationService.showLoader();
        request$.pipe(
            take(1),
            finalize(() => this.fuseConfirmationService.hideLoader())).subscribe(
            {
              next: (value: ClienteView) => {
                this.fuseConfirmationService.openSnackBar({
                  message: 'Tipologia Intervento e durata salvati con successo',
                  type: SnackbarTypes.Success,
                });
                this.dialogRef.close(value);
              },
              error: (err) => {
                this.manageErrorService.showBackendErrorMessage(err)
              }
            }
        );
      } else {
        this.addebitoDurataFormGroup.markAllAsTouched();
        this.fuseConfirmationService.openSnackBar({
            message: 'Uno o più valori inseriti non sono validi',
          type: SnackbarTypes.Error,
        });
      }
  }

  protected readonly CampoDaSelezionare = CampoDaSelezionare;
}
