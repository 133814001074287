/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PlatformActionType } from './platformActionType';

export interface ResendEmailRequestDTO { 
    /**
     * Email dell'utente di destinazione. Non può essere nullo o vuoto.
     */
    email: string;
    action: PlatformActionType;
}