import {Component, EventEmitter, Input, OnInit, Output, Pipe} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {PipeModule} from "../../pipe/pipe.module";

@Component({
    selector: 'app-input-value-key',
    templateUrl: './input-value-key.component.html',
    styleUrls: ['./input-value-key.component.scss'],
    imports: [
        NgClass,
        NgIf,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatIconModule,
        PipeModule
    ],
    standalone: true
})
export class InputValueKeyComponent implements OnInit {

    @Input() label: string;
    @Input() value: string | number;
    @Input() isFlexBasis12_5: boolean = false;
    @Input() showDownloadButton = false;
    @Output() onDownloadButtonPressed? = new EventEmitter<string>();
    @Input() pipe?: Pipe;
    @Input() hideValue?: boolean;
    @Input() isInputFormat?: boolean;

    constructor() {
    }

    ngOnInit(): void {
    }

    download() {
        this.onDownloadButtonPressed.emit();
    }

}
