import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {
    ButtonInterface,
    ClickEvent,
    ConfigurazioneColonna,
    ConfigurazioneTabella,
    GenericTableConfigurationModel,
    IconInterface,
    TableData,
    TableDataRows,
    TipoClickEnum,
    TipoColonnaEnum
} from './generic-table-model';
import {MatPaginator, MatPaginatorModule, PageEvent} from '@angular/material/paginator';
import {get, isArray, isEmpty, isString, sortBy, startCase} from 'lodash';
import {MatSort, MatSortModule, Sort} from '@angular/material/sort';
import {Router} from '@angular/router';
import {cloneDeep} from "lodash-es";

import {CommonModule} from "@angular/common";
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {TranslocoModule} from "@ngneat/transloco";
import {MatChipsModule} from "@angular/material/chips";
import {MatRadioModule} from "@angular/material/radio";
import {PipeModule} from "../../pipe/pipe.module";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {infoAccessoInnerHTML} from "../../constants/infoStatoAccesso";
import {ChipOperatoreComponent} from "../chip-operatore/chip-operatore.component";
import {MatRowKeyboardSelectionDirective} from "../../directives/mat-row-keyboard-selection";
import {SelectionModel} from "@angular/cdk/collections";
import {TableItem} from "../../modals/generic-form-modal/generic-select-form-modal.component";
import {TableSelectionManagerService} from "../../../services/table-selection-manager.service";


@Component({
    selector: 'app-generic-table',
    styleUrls: ['generic-table.component.scss'],
    templateUrl: 'generic-table.component.html',
    standalone: true,
    imports: [
        CommonModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        MatSortModule,
        MatMenuModule,
        MatCheckboxModule,
        TranslocoModule,
        MatRadioModule,
        MatChipsModule,
        PipeModule,
        ChipOperatoreComponent,
        MatRowKeyboardSelectionDirective
    ]
})
export class GenericTableComponent implements OnInit, OnDestroy {
    private sort: MatSort;
    protected paginator: MatPaginator;
    private isPaginatedBE: boolean;
    private _righeOriginali: Array<any> = [];
    private _righeSelezionate: Array<any> = [];
    private _righeAggiunte: Array<any> = [];
    private _righeRimosse: Array<any> = [];
    private disableAllCheck: boolean;
    private singleChoiceSelectedKey: any;
    no_elements = 'Nessun elemento disponibile';

    selection1: SelectionModel<TableItem>;

    constructor(private router: Router,
                private fuseConfirmationService: FuseConfirmationService,
                private tableSelectionManagerService: TableSelectionManagerService) {
    }

    ngOnInit() {
        this.selection1 = new SelectionModel<TableItem>(false);
    }
    ngOnDestroy() {
        this.tableSelectionManagerService.paginatorOld = null;
        this.tableSelectionManagerService.checkSelectionRowModel$.next(null);
        this.tableSelectionManagerService.checkFocusOnSelectionFilterForm$.next(null);
        this.tableSelectionManagerService.checkFocusOnTable$.next(null);
    }

    @ViewChild(MatSort) set matSort(ms: MatSort) {
        this.sort = ms;
        this.setDataSourceAttributes();
    }

    @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
        this.paginator = mp;
        this.setDataSourceAttributes();
    }

    totalElements = 25;
    displayedColumns: string[] = [];
    dataSourcePaginated: MatTableDataSource<any>;
    _configuration: ConfigurazioneTabella;
    tipoColonna = TipoColonnaEnum;
    messaggioDatiAssenti: string;
    isSelectable: boolean = false;
    selectedColumnKeyToShow: string = undefined;
    selectedColumnKeyPrimaryKey: string = "id";
    chipClickType = TipoClickEnum.CHIP_DETAIL;

    @Output() clickAction: EventEmitter<ClickEvent> = new EventEmitter<ClickEvent>();
    @Output() pageAction: EventEmitter<PageEvent> = new EventEmitter<PageEvent>();
    @Output() sortAction: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Output() tableDataEmitter: EventEmitter<TableData> = new EventEmitter<TableData>();

    @Output() outputTableData: EventEmitter<Sort> = new EventEmitter<Sort>();
    @Input() infoRigheInSolaLettura: { iconName: string; tooltip: string };
    @Input() righeSelezionateCustom = [];
    @Input() hasMaxHeight: boolean;
    @Input() focusable: boolean;
    @Input() hideChipSelector: boolean;

    @Input() set righeSelezionate(selezione: Array<any>) {
        if (this._configuration?.isPaginatedBE) {
            selezione.forEach(elementoSelezionato => this._righeSelezionate.push(elementoSelezionato));
            if (this._righeSelezionate.length > 0) {
                this._righeOriginali = cloneDeep(this._righeSelezionate);
            }
        } else {
            if (this.dataSourcePaginated.data.length > 0 && selezione.length > 0) {
                this.dataSourcePaginated.data.forEach((value) => {
                    const selectedElement = selezione.find(elementoSelezionato => value[this.selectedColumnKeyPrimaryKey] === elementoSelezionato.data[this.selectedColumnKeyPrimaryKey])
                    if (selectedElement) {
                        this._righeSelezionate.push(selectedElement);
                    }
                })
                if (this._righeSelezionate.length > 0) {
                    this._righeOriginali = cloneDeep(this._righeSelezionate);
                }
            }
        }
    }

    @Input() idRigheSolaLettura: string[] = [];
    @Input() hideColumns: string[] = [];

    get righeSelezionate() {
        return this._righeSelezionate;
    }

    get righeOriginali() {
        return this._righeOriginali;
    }

    get righeAggiunte() {
        if (this.righeOriginali.length === 0) {
            return this._righeSelezionate
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return new Array<any>()
        } else {
            return this._righeSelezionate.filter(rigaSelezionata => {
                const element = this._righeOriginali.find(rigaOriginale => rigaSelezionata.key === rigaOriginale.key);
                if (!element) {
                    return rigaSelezionata
                }
            })
        }
    }

    get righeRimosse() {
        if (this.righeOriginali.length === 0) {
            return new Array<any>()
        }
        if (this._righeOriginali.length > 0 && this._righeSelezionate.length === 0) {
            return this._righeOriginali
        } else {
            return this._righeOriginali.filter(rigaOriginale => {
                const element = this._righeSelezionate.find(rigaSelezionata => rigaSelezionata.key === rigaOriginale.key);
                if (!element) {
                    return rigaOriginale
                }
            })
        }
    }

    @Input({ required: true }) set configuration(data: GenericTableConfigurationModel) {
        if (!!data && !!data?.configuration) {
            this._configuration = data?.configuration || {};

            // Gestione della selezione se opportunamente configurata. Se non configurata rimuovo elementi di selezione.
            this.isSelectable = this._configuration?.selection !== undefined ? this._configuration?.selection.isSelectable : false;
            this._configuration.configurazioneTabella = this._configuration?.configurazioneTabella?.filter(value => value.tipo !== TipoColonnaEnum.SELECTION)
            if (this.isSelectable) {
                this.selectedColumnKeyToShow = this._configuration?.selection?.selectedColumnKeyToShow;
                this.selectedColumnKeyPrimaryKey = this._configuration?.selection?.selectedColumnKeyPrimaryKey
                this._configuration?.configurazioneTabella.splice(0, 0, {
                    tipo: TipoColonnaEnum.SELECTION,
                    nomeColonna: 'Seleziona',
                    colonnaKey: 'selezione',
                    flex: 10,
                })
            }
            this.displayedColumns = this._configuration?.configurazioneTabella?.filter(value => !value.hideColonna && !this.hideColumns.includes(value.colonnaKey)).map(value => value.colonnaKey);
            this.dataSourcePaginated = new MatTableDataSource<any>(this._configuration?.data);
            this.totalElements = this._configuration?.totalElements || this._configuration?.data?.length || this.totalElements;
            if (!!this._configuration && !!this._configuration?.data && this._configuration?.data?.length > 0) {
                this.messaggioDatiAssenti = 'Nessun dato corrispondente al filtro';
            } else {
                this.messaggioDatiAssenti = 'Non ci sono dati da visualizzare nella pagina corrente'
            }
            this.isPaginatedBE = this._configuration?.isPaginatedBE;

        }
    }

    @Input() readOnly: boolean;

    setDataSourceAttributes() {
        if (!this.isPaginatedBE && !!this.dataSourcePaginated) {
            this.dataSourcePaginated.paginator = this.paginator;
            this.dataSourcePaginated.sort = this.sort;
            this.dataSourcePaginated.sortingDataAccessor = (item, property) => {
                if (this._configuration?.configurazioneTabella?.find(v => v?.colonnaKey === property)?.sortByNumber) {
                    const onlyNumber = item[property]?.replace(/\D/g, '');
                    return onlyNumber ? Number(item[property].replace(/\D/g, '')) : this.castSort(item[property]);
                } else {
                    return this.castSort(item[property]);
                }
            };
        }
    }


    setPaginazione(dataSource: any[]): void {
        this.dataSourcePaginated = new MatTableDataSource<any>(dataSource);
        this.setDataSourceAttributes();
    }

    convertiStringa(nomeColonna: string): string {
        return startCase(nomeColonna);
    }


    show(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ?
            this.showEvery(button, element, value) : !!button.show ? !!value ? isArray(value)
                ? value.some(v => button.show(get(element, v, element[v]), element)) : button.show(get(element, value, element[value]), element) : true : true;
    }

    showEvery(button: ButtonInterface, element, value: string | string []): boolean {
        return !!button.showEvery ? !!value ? isArray(value) ? value.every(v => button.showEvery(element[v])) : button.showEvery(element[value]) : true : true;
    }


    getValue(colonna: ConfigurazioneColonna, element): string {
        return !!colonna.convertiValoreBoolean ? colonna.convertiValoreBoolean(element) : '';
    }


    getButton(colonna: ConfigurazioneColonna, elementElement) {
        return !!colonna.button ? this.readOnly ? colonna.button.filter(value => value.click === TipoClickEnum.INFO) : colonna.button : null;
    }

    convertiValoreInBo(colonna: ConfigurazioneColonna, elementElement): IconInterface | null {
        return !!colonna.convertiValoreBooleanInIcon ? colonna.convertiValoreBooleanInIcon(elementElement) : null;
    }

    getValueShow(colonna: ConfigurazioneColonna, element, colonnaKey: string): string {
        if (colonnaKey.includes(' ')) {
            return colonnaKey.split(' ').map(aElem => element[aElem]).join(' ')
        }
        return !!colonna.valueCheck ? !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck]) : element[colonnaKey] : element[colonnaKey]?.toString();
    }

    castSort(itemElement: any): any {
        return isString(itemElement) ? itemElement.toLowerCase() : itemElement;
    }

    onPageChange(pageEvent: PageEvent) {
        if (this.isPaginatedBE) {
            this.pageAction.emit(pageEvent)
        }
    }

    onSortChange($event: Sort) {
        if (this.isPaginatedBE) {
            this.sortAction.emit($event)
        }
    }

    goTo(colonna: ConfigurazioneColonna, element, colonnaKey: string) {
        if (colonna.goTo.value && colonna.goTo.path) {
            const baseUrl = window.location.href.replace(this.router.url, '');
            const url = new URL([...colonna.goTo.path, element[colonna.goTo.value]].join('/'), baseUrl).href
            console.log(url)
            window.open(url, '_blank');
        }
    }

    toggleAllRows() {
        if(this.dataSourcePaginated.data?.length === this.righeSelezionate?.length) {
            this._righeSelezionate = []
        } else {
            this._righeSelezionate = []
            this.dataSourcePaginated.data?.forEach((row) => {
                this.righeSelezionate.push({key: row[this.selectedColumnKeyPrimaryKey], data: row});
            })
        }
        this.tableDataEmitter.emit({
            selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    toggleSelectedRowData(element: any) {
        const elementExtracted = this.righeSelezionate.findIndex(value => value.key === element[this.selectedColumnKeyPrimaryKey]);
        if (elementExtracted !== -1) {
            this.righeSelezionate.splice(elementExtracted, 1);
            this.disableAllCheck = false;
        } else {
            this.righeSelezionate.push({key: element[this.selectedColumnKeyPrimaryKey], data: element});
        }
        if (this._configuration?.singleChoiceSelection && this.righeSelezionate.length > 0) {
            this.disableAllCheck = true;
        } else {
            this.disableAllCheck = false;
        }
        this.tableDataEmitter.emit({
            selectedRows: this.sortRigheSelezionate(this.righeSelezionate),
            removedRows: this.righeRimosse,
            addedRows: this.righeAggiunte
        });
    }

    sortRigheSelezionate(list: TableDataRows[]): TableDataRows[] {
        return sortBy(list, [function (o) {
            return o.data?.nomeCognome;
        }]);
    }


    checkIfSelected(key: string) {
        if (this._configuration?.singleChoiceSelection) {
            this.singleChoiceSelectedKey = this.righeSelezionate.find(value => value.key === key)?.key;
        }
        return this.righeSelezionate.find(value => value.key === key);
    }

    checkIfAllSelected() {
        return this.dataSourcePaginated.data?.length > 0 && (this.dataSourcePaginated.data?.length === this.righeSelezionate?.length);
    }

    disableNonSelectedCheck(element: any): boolean {
        if (this._configuration?.singleChoiceSelection) {
            if (this.righeSelezionate.length > 0) {
                return element.id !== this.singleChoiceSelectedKey;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    //----CHIP
    getValueForStatus(colonna: ConfigurazioneColonna, element, colonnaKey: string): any {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    checkIfRigaReadOnly(element: any): boolean {
        if (!element?.id || isEmpty(this.idRigheSolaLettura)) {
            return false;
        } else {
            return this.idRigheSolaLettura?.includes(element?.id);
        }
    }

    trackByFn(index: number, item: ConfigurazioneColonna): any {
        return item.colonnaKey || index;
    }


    onCellClick(element): void {
        if (this._configuration?.selection?.isSelectable
            && !(this.readOnly || this.disableNonSelectedCheck(element) || this.checkIfRigaReadOnly(element))
        ) {
            this.toggleSelectedRowData(element);
        }
    }

    protected readonly undefined = undefined;

    toggleSelectedCustomRowData(row) {
        let indextToRemove;
        if(row.id) {
            indextToRemove = this.righeSelezionateCustom?.findIndex(r => r.id === row.id);
        } else {
            indextToRemove = this.righeSelezionateCustom?.findIndex(r => r.nome === row.nome && r.cognome === row.cognome);
        }
        this.righeSelezionateCustom?.splice(indextToRemove, 1);
    }

    getValueForChip(colonna: ConfigurazioneColonna, element, colonnaKey: string): Array<any> {
        return !!colonna.valueCheck ?
            !!colonna.getValue(element[colonna.valueCheck]) ? colonna.getValue(element[colonna.valueCheck])
                : element[colonnaKey] : element[colonnaKey];
    }

    openModalHelp() {
        this.fuseConfirmationService.open({
            title: 'Accesso utente',
            message: infoAccessoInnerHTML,
            icon: {
                name: 'info',
                color:'primary'
            },
            actions: [
                {
                    label: 'Chiudi',
                    color: 'accent'
                }
            ]
        })
    }
}
