/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Tipi di grant per le richieste di auth
 */
export type GrantType = 'PASSWORD' | 'REFRESH_TOKEN';

export const GrantType = {
    PASSWORD: 'PASSWORD' as GrantType,
    REFRESHTOKEN: 'REFRESH_TOKEN' as GrantType
};