import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {LocalStorageService} from "../../../services/local-storage.service";
import {of, switchMap} from "rxjs";
import {RuoloUtenteEnum} from "../../../shared/enum/ruolo-utente-enum";

export const RuoloUtenteGuard: CanActivateFn = (route, state) => {
    const router: Router = inject(Router);

    let roles = route.data.roles as Array<RuoloUtenteEnum>;

    // Check the authentication status
    return inject(LocalStorageService).checkRuolo(roles).pipe(
        switchMap((authorized) =>
        {
            // If the user is not authenticated...
            if (!authorized)
            {
                // Redirect to the sign-in page with a redirectUrl param
                const redirectURL = state.url === '/home' ? '' : `redirectURL=${state.url}`;
                const urlTree = router.parseUrl(`home?${redirectURL}`);

                return of(urlTree);
            }

            // Allow the access
            return of(true);
        }),
    );
};
