<app-dialog-wrap [data]="data" (close$)="closeModal($event)"
                 (confirm$)="onConfirm($event)">
    <div class="w-full flex flex-col gap-1 pb-2" *ngIf="!!userFormGroup">
        <div class="flex flex-col justify-between w-full">
            <div class="flex flex-col gap-2 w-full">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Dati utente</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>

                <div class="w-full grid_2_col pt-1 pb-2">
                    <app-generic-form-field [label]="'Nome'" class="w-full"
                                            [control]="ctrlNome()"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readOnly"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Cognome'" class="w-full"
                                            [control]="ctrlCognome()"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readOnly"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'E-mail'" class="w-full"
                                            [control]="ctrlEmail()"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readOnly"
                    ></app-generic-form-field>

                    <mat-form-field appearance="outline" class="w-full" *ngIf="!data?.readOnly; else authorityReadOnly">
                        <mat-label>Ruolo</mat-label>

                        <mat-select [formControl]="ctrlAuthority()" [multiple]="false">
                            <mat-option [value]="undefined">{{'-'}}</mat-option>
                            <mat-option *ngFor="let option of ruoliOptions"
                                        [value]="option">{{(option || '') | removeUnderscores }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="ctrlAuthority()?.hasError('required')">
                            {{errorRequiredMessage}}
                        </mat-error>

                    </mat-form-field>

                    <ng-template #authorityReadOnly>
                        <div class="w-full">
                            <div class="text-lg font-medium break-words line-height-normal"
                                 [style]="'min-width: 25%'" [style.color]="'black'"
                                 [ngClass]="'basis-1/4'">
                                {{(ctrlRuoliArray().controls?.length > 1) ? 'Ruoli' : 'Ruolo'}}
                            </div>
                            <ng-container *ngFor="let ruolo of ctrlRuoliArray().controls; let i = index">
                                <div class="text-secondary break-words line-height-normal whitespace-nowrap"
                                     style="max-width: 75%">
                                    {{(ctrlRuolo(i).value || '-') | removeUnderscores}}
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>

                    <div class="w-full" *ngIf="showSelectOperatore && !data.readOnly">
                        <app-generic-form-field [label]="'Operatore'" class="w-full"
                                                [cantWriteText]="true"
                                                [control]="ctrlOperatoreMask()"
                                                [errorRequiredMessage]="errorRequiredMessage"
                                                [campoDaSelezionare]="CampoDaSelezionare.OPERATORE"
                                                (openSelect)="openModaleDiSelezioneOperatore()"
                        ></app-generic-form-field>
                    </div>

                    <div class="w-full" *ngIf="showDatiOperatore">
                        <app-input-value-key [label]="'Operatore Trasporto Collegato'"
                                             [value]="nameOperatore"
                                             [isInputFormat]="true">
                        </app-input-value-key>

                    </div>
                </div>
            </div>
        </div>
    </div>

</app-dialog-wrap>
