import {InterventoProgrammaDTO, InterventoView} from "../../../../api-clients/generated/services";
import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {compact, join} from "lodash-es";
import {getTestoCliente, getTestoOperatore, getTestoTipoIntervento} from "../mask-functions";
import moment from "moment";
import {orderByOrdine} from "../utils";

export function createTableConfigurationValidazioneInterventi(inputList?: Array<InterventoView>,
                                                              listLength?: number, pageSize?: number,
                                                              pageIndex?: number,): GenericTableConfigurationModel {

    let list = inputList.map((intervento) => {
        return {
            ...intervento,
            automezzoAndRimorchio: join(compact([intervento?.automezzo?.targa, intervento?.rimorchio?.targa]), ' / '),
            autistaKey: getTestoOperatore(intervento.operatori.find((op) => op.isAutista)?.operatore),
            tipoAttivitaKey: join(intervento.tipiIntervento.map((inter) =>
                getTestoTipoIntervento(inter)), '\n'),
            stato: intervento.stato,
            clienteKey: getTestoCliente(intervento.cliente),
            orarioPrevisto: moment(intervento.dataOraInizioPrevista).format('HH:mm') + ' / ' +
                moment(intervento.dataOraFinePrevista).format('HH:mm'),
            operatoriTutti: orderByOrdine(intervento?.operatori) || [],
            statoMail: !!intervento.dataInvioMailODL ? InterventoStatoMailEnum.INVIATA : InterventoStatoMailEnum.NON_INVIATA,
            statoInterventoFormulario: !!intervento.isInterventoFormulario ?
                InterventoFormularioServizioEnum.INTERVENTO_FORMULARIO : InterventoFormularioServizioEnum.INTERVENTO_SERVIZIO

        }
    });
    return {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            pageIndex: pageIndex,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Ora inizio / Ora fine previste',
                    colonnaKey: 'orarioPrevisto',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Automezzo / Rimorchio',
                    colonnaKey: 'automezzoAndRimorchio',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Cliente',
                    colonnaKey: 'clienteKey',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_USER,
                    nomeColonna: 'Operatori',
                    colonnaKey: 'operatoriTutti',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Attività',
                    colonnaKey: 'tipoAttivitaKey',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Stato',
                    colonnaKey: 'stato',
                    flex: 10,
                    statusType: InterventoProgrammaDTO.StatoEnum
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Categoria intervento',
                    colonnaKey: 'statoInterventoFormulario',
                    flex: 20,
                    statusType: InterventoFormularioServizioEnum
                },
                {
                    nomeColonna: 'Azioni',
                    colonnaKey: 'action',
                    flex: 25,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    button: [
                        {
                            nameIconButton: 'info',
                            click: TipoClickEnum.SHOW,
                            tooltip: 'Visualizza',
                            color: 'accent'
                        },
                        {
                            nameIconButton: 'edit',
                            click: TipoClickEnum.EDIT,
                            tooltip: 'Modifica',
                            color: 'accent',
                            valueShow: ['stato'],
                            show: (value, element) => showModificaIntervento(element)
                        },
                        {
                            nameIconButton: 'check',
                            click: TipoClickEnum.CONFERMA,
                            tooltip: 'Verifica',
                            color: 'accent',
                            valueShow: ['stato'],
                            show: (value, element) => showVerificaIntervento(element)
                        },
                        {
                            nameIconButton: 'remove_done',
                            click: TipoClickEnum.ANNULLA_VERIFICA,
                            tooltip: 'Annulla',
                            color: 'accent',
                            valueShow: ['stato'],
                            show: (value, element) => showAnnullaIntervento(element)
                        }
                    ]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile',
        }
    };
}

export function showModificaIntervento(intervento: InterventoView) {
 return (intervento.statoProgramma === InterventoView.StatoProgrammaEnum.EVASO ||
     intervento.statoProgramma === InterventoView.StatoProgrammaEnum.INCORSO) &&
     intervento.stato === InterventoView.StatoEnum.EVASO
}

export function showVerificaIntervento(intervento: InterventoView) {
    return (intervento.statoProgramma === InterventoView.StatoProgrammaEnum.EVASO ||
            intervento.statoProgramma === InterventoView.StatoProgrammaEnum.INCORSO) &&
        intervento.stato === InterventoView.StatoEnum.EVASO
}

export function showAnnullaIntervento(intervento: InterventoView) {
    return ((intervento.statoProgramma === InterventoView.StatoProgrammaEnum.EVASO ||
            intervento.statoProgramma === InterventoView.StatoProgrammaEnum.INCORSO) &&
        intervento.stato === InterventoView.StatoEnum.VERIFICATO) && !intervento.isInterventoPassato
}

export enum InterventoStatoMailEnum {
    INVIATA = 'INVIATA',
    NON_INVIATA = 'NON_INVIATA'
}


export enum InterventoFormularioServizioEnum {
    INTERVENTO_SERVIZIO = 'INTERVENTO SERVIZIO',
    INTERVENTO_FORMULARIO = 'INTERVENTO FORMULARIO'
}
