import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CustomTitleCasePipe} from "./custom-title-case.pipe";
import {RemoveUnderscoresPipe} from "./remove-underscores.pipe";
import {CurrencyEuroPipe} from "./currency-euro.pipe";
import {DynamicPipe} from "./dynamic.pipe";
import {StatusEnumToColorThemePipe} from "./status-enum-to-color-theme.pipe";


@NgModule({
    declarations: [CustomTitleCasePipe, RemoveUnderscoresPipe, CurrencyEuroPipe, DynamicPipe, StatusEnumToColorThemePipe],
    imports: [
        CommonModule
    ],
    exports: [CustomTitleCasePipe, RemoveUnderscoresPipe, CurrencyEuroPipe, DynamicPipe, StatusEnumToColorThemePipe]
})
export class PipeModule {
}
