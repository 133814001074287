<div class="flex flex-col lg:flex-col h-full items-start gap-1.5">
    <div class="text-lg font-medium break-words line-height-normal"
         [style]="isFlexBasis12_5 ? '' : 'min-width: 25%'" [style.color]="'black'"
         [ngClass]="isFlexBasis12_5 ? 'basis-1_8' : 'basis-1/4'">{{(label)}}</div>
    <div *ngIf="!hideValue && !showDownloadButton"
         class="text-secondary break-words line-height-normal "
         style="max-width: 100%">
        {{((value ?? '-') | removeUnderscores) | dynamicPipe: pipe}}
    <ng-container *ngIf="label === 'Costo'">
        {{'€'}}
    </ng-container>
    </div>
    <div *ngIf="showDownloadButton" class="text-secondary text-center break-words whitespace-nowrap" style="max-width: 75%">
        <button
            mat-mini-fab
            type="button"
            (click)="download()"
            [matTooltip]="'Download'"
            matSuffix>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'mat_outline:download'"></mat-icon>
        </button>
    </div>
    <ng-content class="lg:text-center  text-center"></ng-content>
</div>
