/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AutomezzoProgrammaDTO } from './automezzoProgrammaDTO';
import { InterventoProgrammaDTO } from './interventoProgrammaDTO';
import { OperatoreProgrammaDTO } from './operatoreProgrammaDTO';

export interface RigaProgrammaDTO { 
    id?: string;
    ordine?: number;
    idInterventoInizio?: string;
    idInterventoRientro?: string;
    inizio?: Date;
    automezzo?: AutomezzoProgrammaDTO;
    rimorchio?: AutomezzoProgrammaDTO;
    operatori?: Array<OperatoreProgrammaDTO>;
    interventi?: Array<InterventoProgrammaDTO>;
}