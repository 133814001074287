import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {FormArray, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {
    AuthorityType, OperatoreView,
    UserControllerService,
    UserRegistrationForm,
    UserView
} from "../../../../api-clients/generated/services";
import {UserDialogConfig} from "../../interfaces/generic-components.interface";
import {getFormArrayFromFormGroupTyped, getFormControlFromFormGroupTyped, orderByOrdine} from "../../utils/utils";
import {finalize, takeUntil, tap} from "rxjs";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {ManageErrorService} from "../../../services/manage-error.service";
import {DialogWrapComponent} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {NgClass, NgForOf, NgIf, TitleCasePipe} from "@angular/common";
import {MatDividerModule} from "@angular/material/divider";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {PipeModule} from "../../pipe/pipe.module";
import {CampoDaSelezionare} from "../creazione-intervento-modal/creazione-intervento-modal.component";
import {TipoClickEnum} from "../../components/generic-table/generic-table-model";
import {get} from "lodash";
import {getTestoOperatore} from "../../utils/mask-functions";
import {
    createFormControlWithoutValidator,
    createFormControlWithValidator
} from "../../../services/programmazione-forms-manager.service";
import {InputValueKeyComponent} from "../../components/input-value-key/input-value-key.component";
import {emailRegex} from "../../constants/regex";
import {LocalStorageService} from "../../../services/local-storage.service";
import {RuoloUtenteEnum} from "../../enum/ruolo-utente-enum";

@Component({
  selector: 'app-user-modal',
  standalone: true,
    imports: [
        DialogWrapComponent,
        NgIf,
        MatDividerModule,
        GenericFormFieldComponent,
        MatFormFieldModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        PipeModule,
        TitleCasePipe,
        ReactiveFormsModule,
        NgClass,
        InputValueKeyComponent
    ],
  templateUrl: './user-modal.component.html',
  styleUrl: './user-modal.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class UserModalComponent extends AbstractDefaultComponent implements OnInit {
    userFormGroup: FormGroup<UserForm>;
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    ruoliOptions: AuthorityType[];
    showSelectOperatore: boolean = false;
    showDatiOperatore: boolean = false;
    nameOperatore: string;
    errorPatternMessage: "Si prega di inserire un indirizzo email valido";


    constructor(public dialogRef: MatDialogRef<UserModalComponent>,
                private dialog: MatDialog,
                private fuseConfirmationService: FuseConfirmationService,
                private dialogManagerService: DialogManagerService,
                private userControllerService: UserControllerService,
                private manageErrorService: ManageErrorService,
                @Inject(MAT_DIALOG_DATA) public data: UserDialogConfig,
                private localStorageService: LocalStorageService,) {
        super();
        this.setFormGroup(data?.userData);
    }

    ngOnInit() {
        this.ruoliOptions = Object.values(AuthorityType).filter((item) =>
            item !== AuthorityType.ADMIN) as AuthorityType[];
        this.onValueChanges();
        this.showDatiOperatore = this.data.readOnly && this.isOperatoreTrasporto(this.data.userData);
        this.nameOperatore = this.data?.userData?.operatore?.nomeCognome || '-';

        if(this.localStorageService.checkTipoRuolo( [
            RuoloUtenteEnum.OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO, RuoloUtenteEnum.OPERATORE_CED
        ])) {
            this.ctrlAuthority().setValue(AuthorityType.OPERATORETRASPORTO);
            this.ctrlAuthority().disable({emitEvent: false});
        }
    }

    onValueChanges() {
        this.ctrlAuthority().valueChanges.pipe(
            tap((value) => {
                if(value === AuthorityType.OPERATORETRASPORTO){
                    this.showSelectOperatore = true;
                    this.ctrlOperatore().setValidators(Validators.required);
                    this.ctrlOperatoreMask().setValidators(Validators.required);
                } else {
                    this.showSelectOperatore = false;
                    this.ctrlOperatore().setValue(undefined);
                    this.ctrlOperatoreMask().setValue(undefined);
                    this.ctrlOperatore().removeValidators(Validators.required);
                    this.ctrlOperatoreMask().removeValidators(Validators.required);
                }
                this.ctrlOperatore().updateValueAndValidity();
                this.ctrlOperatoreMask().updateValueAndValidity();
            })
        ).subscribe();
    }

    private setFormGroup(userData: UserView | undefined) {
        this.userFormGroup = new FormGroup<UserForm>({
            nome: new FormControl(userData?.nome, [Validators.required]),
            cognome: new FormControl(userData?.cognome, [Validators.required]),
            email: new FormControl(userData?.email,
                [Validators.required, Validators.email]),
            authorityType: new FormControl(null, [Validators.required]),
            operatore: new FormControl(undefined),
            operatoreMask: new FormControl(undefined),
            ruoliArray: new FormArray(!!userData?.authorities && userData?.authorities?.length > 0 ?
                userData?.authorities?.map((item) =>
                    createFormControlWithoutValidator(item?.authority?.replace('ROLE_', '').toUpperCase())) :
                [createFormControlWithoutValidator()]
            )
        });
    }

    ctrlNome(): FormControl<string> {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'nome');
    }

    ctrlCognome() {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'cognome');
    }

    ctrlEmail() {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'email');
    }

    ctrlAuthority() {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'authorityType');
    }

    ctrlOperatore(): FormControl<OperatoreView> {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'operatore');
    }

    ctrlOperatoreMask() {
        return getFormControlFromFormGroupTyped<UserForm>(this.userFormGroup, 'operatoreMask');
    }

    ctrlRuoliArray(): FormArray {
        return getFormArrayFromFormGroupTyped<UserForm>(this.userFormGroup, 'ruoliArray');
    }

    ctrlRuolo(index: number): FormControl {
        return this.ctrlRuoliArray().at(index) as FormControl;
    }

    onAggiuntaConfirm() {
        if(this.userFormGroup.valid) {
            const body: UserRegistrationForm = {
                email: this.ctrlEmail()?.value,
                nome: this.ctrlNome()?.value,
                cognome: this.ctrlCognome()?.value,
                idOperatore: this.ctrlOperatore()?.value?.id,
                authorityType: this.ctrlAuthority()?.value
            }
            let request$ = this.userControllerService.registerUser(body);
            this.fuseConfirmationService.showLoader();
            request$.pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Utente aggiunto con successo',
                        type: SnackbarTypes.Success,
                    });
                    this.dialogRef.close(value);
                },
                error: (error) => {
                    this.manageErrorService.showBackendErrorMessage(error);
                }
            })
        } else {
            this.userFormGroup.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    onConfirm($event: string) {
        this.onAggiuntaConfirm();
    }

    closeModal($event: void) {
        this.dialogRef.close();
    }

    protected readonly CampoDaSelezionare = CampoDaSelezionare;

    openModaleDiSelezioneOperatore() {
        let nominativo = this.ctrlNome()?.value + ' ' + this.ctrlCognome()?.value;
        this.dialogManagerService.openModaleDiSelezione(CampoDaSelezionare.OPERATORE, nominativo).subscribe(
            {
                next: (value) => {
                    if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                        this.ctrlOperatoreMask().setValue(getTestoOperatore(value.value));
                        this.ctrlOperatore().setValue(value.value)
                    }
                }
            }
        )
    }

    isOperatoreTrasporto(user: UserView) {
        return user?.authorities?.map((item) => item.authority).some((mappedItem) =>
            mappedItem?.toLowerCase()?.includes(AuthorityType.OPERATORETRASPORTO.toLowerCase()))
    }

    protected readonly FormControl = FormControl;
}

interface UserForm {
    email?: FormControl<string | null>;
    nome?: FormControl<string | null>;
    cognome?: FormControl<string | null>;
    authorityType?: FormControl<AuthorityType | null>;
    operatore?: FormControl<OperatoreView | null>;
    operatoreMask?: FormControl<string | null>;
    ruoliArray?: FormArray;
}
