import {NgIf} from '@angular/common';
import {Component, HostBinding, Input, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {FuseFullscreenComponent} from '@fuse/components/fullscreen';
import {FuseLoadingBarComponent} from '@fuse/components/loading-bar';
import {FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent} from '@fuse/components/navigation';
import {FuseMediaWatcherService} from '@fuse/services/media-watcher';
import {NavigationService} from 'app/core/navigation/navigation.service';
import {Navigation} from 'app/core/navigation/navigation.types';
import {LanguagesComponent} from 'app/layout/common/languages/languages.component';
import {MessagesComponent} from 'app/layout/common/messages/messages.component';
import {NotificationsComponent} from 'app/layout/common/notifications/notifications.component';
import {QuickChatComponent} from 'app/layout/common/quick-chat/quick-chat.component';
import {SearchComponent} from 'app/layout/common/search/search.component';
import {ShortcutsComponent} from 'app/layout/common/shortcuts/shortcuts.component';
import {UserComponent} from 'app/layout/common/user/user.component';
import {finalize, Subject, takeUntil} from 'rxjs';
import {PathEnum} from "../../../../shared/enum/path-enum";
import {
    menuCompactNavigation,
    menuCompactNavigationFoAllUsers
} from "../../../../shared/constants/menu-compact-navigation";
import {
    navbarAdminCompactNavigation,
    navbarCompactNavigation
} from "../../../../shared/constants/nav-bar-compact-navigation";
import {DialogManagerService} from "../../../../services/dialog-manager.service";
import {MenuItemTitleEnum} from "../../../../shared/enum/menu-item-title-enum";
import {AbstractDefaultComponent} from "../../../../shared/abstracts/abstract-default-component";
import {CalendarioInterventiManagerService} from "../../../../services/calendario-interventi-manager.service";
import {LocalStorageService} from "../../../../services/local-storage.service";
import {UserView} from "../../../../../api-clients/generated/services";
import {RuoloUtenteEnum} from "../../../../shared/enum/ruolo-utente-enum";
import {
    MobileHeaderActionMenuComponent
} from "../../../../shared/components/mobile-header-action-menu/mobile-header-action-menu.component";

@Component({
    selector: 'compact-layout',
    templateUrl: './compact.component.html',
    styleUrls: ['./compact.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [FuseLoadingBarComponent, MatButtonModule, MatIconModule, LanguagesComponent, FuseFullscreenComponent, SearchComponent, ShortcutsComponent, MessagesComponent, NotificationsComponent, UserComponent, NgIf, RouterOutlet, QuickChatComponent, FuseVerticalNavigationComponent, MobileHeaderActionMenuComponent],
})
export class CompactLayoutComponent extends AbstractDefaultComponent implements OnInit, OnDestroy {
    navbarCompactNavigation: Array<Partial<FuseNavigationItem>> = navbarCompactNavigation;
    isScreenSmall: boolean;
    navigation: Navigation = menuCompactNavigation;


    private _unsubscribeAll: Subject<any> = new Subject<any>();


    /**
     * Constructor
     */
    constructor(
        private _activatedRoute: ActivatedRoute,
        private _router: Router,
        private _navigationService: NavigationService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        private _dialogManagerService: DialogManagerService,
        private _calendarioInterventiManagerService: CalendarioInterventiManagerService,
        private _localStorageService: LocalStorageService,
    ) {
        super();
        if(this._localStorageService.isAdminOrCed()) {
            this.navbarCompactNavigation = navbarAdminCompactNavigation;
        } else {
            this.navbarCompactNavigation = navbarCompactNavigation;
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for current year
     */
    get currentYear(): number {
        return new Date().getFullYear();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(({matchingAliases}) => {
                // Check if the screen is small
                this.isScreenSmall = !matchingAliases.includes('md');
            });

        this._localStorageService.checkCurrentProfile$.asObservable().pipe(
            takeUntil(this._unsubscribeAll))
            .subscribe((user: UserView) => {
                this.navigation = menuCompactNavigationFoAllUsers;
                user.authorities.forEach((authorityUser) => {
                    this.switchOnRole(authorityUser.authority)
                })


            })
    }

    switchOnRole(ruolo: string) {
        switch (ruolo) {
            case RuoloUtenteEnum.ADMIN:
                if (!this.navigation.compact.find((searchElement: FuseNavigationItem) =>
                    searchElement.title === MenuItemTitleEnum.VALIDAZIONE_INTERVENTI_ESEGUITI)) {
                    this.navigation.compact.push(
                        {
                            title: MenuItemTitleEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
                            type: 'basic',
                            link: '/' + PathEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
                            icon: 'mat_outline:domain_verification',
                        },
                    )
                }
                if (!this.navigation.compact.find((searchElement: FuseNavigationItem) =>
                    searchElement.title === MenuItemTitleEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE)) {
                    this.navigation.compact.push(
                        {
                            title: MenuItemTitleEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
                            type: 'basic',
                            link: '/' + PathEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
                            icon: 'mat_outline:fact_check',
                        },
                    )
                }
                break;
            case RuoloUtenteEnum.OPERATORE_CED:
                if (!this.navigation.compact.find((searchElement: FuseNavigationItem) =>
                    searchElement.title === MenuItemTitleEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE)) {
                    this.navigation.compact.push(
                        {
                            title: MenuItemTitleEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
                            type: 'basic',
                            link: '/' + PathEnum.RIVALIDAZIONE_SU_BASE_CONTRATTUALE,
                            icon: 'mat_outline:fact_check',
                        },
                    )
                }
                break;
            case RuoloUtenteEnum.OPERATORE_LOGISTICA_PROGRAMMAZIONE_E_CONTROLLO:
                if (!this.navigation.compact.find((searchElement: FuseNavigationItem) =>
                    searchElement.title === MenuItemTitleEnum.VALIDAZIONE_INTERVENTI_ESEGUITI)) {
                    this.navigation.compact.push(
                        {
                            title: MenuItemTitleEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
                            type: 'basic',
                            link: '/' + PathEnum.VALIDAZIONE_INTERVENTI_ESEGUITI,
                            icon: 'mat_outline:domain_verification',
                        },
                    )
                }
                break;
            default:
                break;
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle navigation
     *
     * @param name
     */
    toggleNavigation(name: string): void {
        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);
        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    openNavbarItemModal(item: Partial<FuseNavigationItem>) {
        switch (item.title) {
            case MenuItemTitleEnum.RICHIESTE_DA_EVADERE:
                this._dialogManagerService.openRichiestaDaEvadereDialog().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                ;
                break;
            case MenuItemTitleEnum.INTERVENTI:
                this._dialogManagerService.openModalAggiungiIntervento().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                break;
            case MenuItemTitleEnum.INTERVENTO_PASSATO:
                this._dialogManagerService.openModalAggiungiInterventoNelPassato().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                break;
            case MenuItemTitleEnum.ATTREZZATURE:
                this.addAttrezzatura();
                break;
            case MenuItemTitleEnum.CLIENTE:
                this.addCliente();
                break;
            case MenuItemTitleEnum.PROGRAMMAZIONE:
                this._router.navigate([PathEnum.PROGRAMMAZIONE_INTERVENTI], {
                    state: {onProgrammazione: true},
                    fragment: 'tab2'
                });
                break;
        }
    }

    addAttrezzatura() {
        this._dialogManagerService.openModaleCreazioneAttrezzatura().afterClosed().pipe(
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {

                }
            }
        );
    }

    addCliente() {
        this._dialogManagerService.openModalCreazioneCliente(undefined, false,
            'add', 'Crea Cliente', true, undefined, true).afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({}
        )
    }
}
