<div class="relative flex flex-col w-full h-full px-3">
    <!-- Dismiss button -->
    <ng-container *ngIf="true">
        <div class="absolute top-0 right-0 pt-0.5 pr-3">
            <button class="border-radius"
                    mat-icon-button color="warn"
                    (click)="close$.emit()">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </ng-container>

    <!-- Content -->
    <!-- lg:pb-6 lg:pt-6 -->
    <div class="flex flex-col flex-auto sm:items-start ">


        <div class="flex flex-col items-center mt-4 sm:mt-0 space-y-1 text-center sm:text-left w-full"
             style="align-self: center">

            <div class="flex flex-col w-full">
                <!--                    <div class=" flex flex-row items-start pl-6 pr-6">-->
                <div class=" flex flex-row items-center">
                    <!-- Icon -->
                    <ng-container *ngIf="data?.icon?.name">
                        <div class="flex-0 items-center justify-center lg:flex w-10 h-10 sm:mr-4 rounded-full hidden sm:flex"
                             [ngClass]="{'text-primary-600 bg-primary-100 dark:text-primary-50 dark:bg-primary-600': data.icon.color === 'primary',
                            'text-accent-600 bg-accent-100 dark:text-accent-50 dark:bg-accent-600': data.icon.color === 'accent',
                            'text-warn-600 bg-warn-100 dark:text-warn-50 dark:bg-warn-600': data.icon.color === 'warn',
                            'text-gray-600 bg-gray-100 dark:text-gray-50 dark:bg-gray-600': data.icon.color === 'basic',
                            'text-blue-600 bg-blue-100 dark:text-blue-50 dark:bg-blue-600': data.icon.color === 'info',
                            'text-green-500 bg-green-100 dark:text-green-50 dark:bg-green-500': data.icon.color === 'success',
                            'text-amber-500 bg-amber-100 dark:text-amber-50 dark:bg-amber-500': data.icon.color === 'warning',
                            'text-red-600 bg-red-100 dark:text-red-50 dark:bg-red-600': data.icon.color === 'error'
                            }">
                            <mat-icon
                                    class="text-current"
                                    [svgIcon]="data.icon.name"></mat-icon>
                        </div>
                    </ng-container>

                    <!-- Title -->
                    <ng-container>
                        <div class="text-xl leading-6 font-medium lg:mt-2 lg:mb-2 lg:mr-4 align-self-center"
                             style="padding-left: 5px; padding-right: 35px;"
                             [innerHTML]="data.title| uppercase"></div>
                    </ng-container>

                </div>
            </div>

            <ng-container *ngIf="data.subtitle">
                <div class="text-secondary font-semibold px-6 pt-3 text-left w-full fs-large"
                     [innerHTML]="data.subtitle">
                </div>
            </ng-container>

            <ng-container *ngIf="data.message">
                <div class="text-secondary font-semibold px-6 pt-3 text-left w-full"
                     [innerHTML]="data.message">
                </div>
            </ng-container>
            <div matDialogContent class="h-full w-full py-0.5 py-0_5-important flex flex-col"
                 [style.overflow-y]="hiddenScrollY ? 'hidden' : 'scroll'">
                <ng-content></ng-content>
            </div>

        </div>

    </div>
</div>

<!-- ACTIONS -->
<div mat-dialog-actions class="w-full p-0" *ngIf="!!data?.hasSubHeader; else whiteFooter">
    <div
            class="w-full flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">
        <div class="flex w-full sm:justify-end px-3 py-0.5 space-x-3">

            <!-- Confirm -->
            <button *ngIf="!data.hideConfirmSubHeader"
                    class="height-30-px"
                    mat-flat-button
                    (click)="confirm$.emit('Confirm')"
                    [color]="data?.actions?.confirm?.color || 'primary'"
                    [disabled]="formComponent?.formGroup?.disabled">
                <div class="flex flex-row items-center">
                    <span>{{data?.actions?.confirm?.label || 'Conferma'}}</span>
                    <mat-progress-spinner
                            style="margin-left: 5px"
                            *ngIf="formComponent?.formGroup?.disabled"
                            [diameter]="24"
                            [mode]="'indeterminate'"></mat-progress-spinner>
                </div>
                <mat-icon *ngIf="!formComponent?.formGroup?.disabled" class="pl-1"
                          iconPositionEnd>check
                </mat-icon>
            </button>

        </div>
    </div>
</div>

<ng-template #whiteFooter>

</ng-template>
