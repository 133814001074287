import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {AutomezzoView, ClienteView, OperatoreView, User, UserView} from "../../../../api-clients/generated/services";

export enum statusUser {
    BLOCCATO = 'BLOCCATO',
    NON_BLOCCATO = 'NON BLOCCATO',
    VERIFICATO = 'VERIFICATA',
    NON_VERIFICATO = 'DA VERIFICARE',
    ABILITATO = 'ABILITATO',
    NON_ABILITATO = 'NON_ABILITATO'
}

export function createTableConfigurationForGestioneUtenti(inputList?: Array<UserView>,
                                                          isSelection?: boolean,
                                                          listLength?: number,
                                                          pageSize?: number, pageIndex?: number, isOperatoreLogicaOrOperatoreCed?: boolean): GenericTableConfigurationModel {
    let list = inputList.map((element) => {
        return {
            ...element,
            nominativo: element?.nome + ' ' + element?.cognome,
            accountBloccato: !!element?.accountLocked ? statusUser.NON_ABILITATO : statusUser.ABILITATO,
            credenzialiScadute: !!element?.credentialsExpired ? statusUser.BLOCCATO : statusUser.ABILITATO,
            accountAbilitato: !!element?.accountEnabled ? statusUser.VERIFICATO : statusUser.NON_VERIFICATO,
            ruoli: element.authorities.map((ele) => ele.authority.replace('ROLE_', ''))
        }
    });
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            isPaginatedBE: true,
            sticky: true,
            totalElements: listLength,
            pageIndex: pageIndex,
            pageSize: pageSize,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Utente',
                    colonnaKey: 'nominativo',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'E-mail',
                    colonnaKey: 'email',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_ARRAY,
                    nomeColonna: 'Ruoli',
                    colonnaKey: 'ruoli',
                    flex: 25,
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Stato Utente',
                    colonnaKey: 'accountBloccato',
                    flex: 20,
                    statusType: statusUser,
                    helpIcon: true
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Stato Email',
                    colonnaKey: 'accountAbilitato',
                    flex: 20,
                    statusType: statusUser,
                    helpIcon: true
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Sicurezza',
                    colonnaKey: 'credenzialiScadute',
                    flex: 20,
                    statusType: statusUser,
                    helpIcon: true
                },
                {
                    nomeColonna: 'Dettagli',
                    colonnaKey: 'descrizione',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'info',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'Mostra dettagli utente',
                        color: 'accent'
                    }]
                },
                {
                    hideColonna: isSelection,
                    nomeColonna: 'Azioni',
                    colonnaKey: 'action',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION_MENU,
                    show: (value, element) => showActionMenu(element, isOperatoreLogicaOrOperatoreCed),
                    button: [
                        {
                            nameIconButton: 'security',
                            click: TipoClickEnum.UNLOCK,
                            tooltip: 'Abilita accesso',
                            color: 'accent',
                            valueShow: ['accountLocked'],
                            show: (value, element) => isUserAbilitato(element) && isCredentialsExpired(element)
                        },
                        {
                            nameIconButton: 'lock_open',
                            click: TipoClickEnum.UNLOCK,
                            tooltip: 'Sblocca utente',
                            color: 'accent',
                            valueShow: ['accountLocked'],
                            show: (value, element) => isUserLocked(element)
                        },
                        {
                            nameIconButton: 'lock',
                            click: TipoClickEnum.LOCK,
                            tooltip: 'Blocca utente',
                            color: 'accent',
                            valueShow: ['accountLocked'],
                            show: (value, element) => !isUserLocked(element)
                        },
                        {
                            nameIconButton: 'send',
                            click: TipoClickEnum.SEND_EMAIL,
                            tooltip: 'Invia e-mail di verifica account',
                            color: 'accent',
                            valueShow: ['accountEnabled'],
                            show: (value, element) => !isUserAbilitato(element)
                        },
                        {
                            nameIconButton: 'link',
                            click: TipoClickEnum.LINK_VERIFY_EMAIL,
                            tooltip: 'Genera link verifica email',
                            color: 'accent',
                            valueShow: ['accountEnabled'],
                            show: (value, element) => !isUserAbilitato(element) && !isCredentialsExpired(element)
                        },
                        {
                            nameIconButton: 'link',
                            click: TipoClickEnum.LINK_RESET_PWD,
                            tooltip: 'Genera link recupero password',
                            color: 'accent',
                            valueShow: ['accountEnabled'],
                            show: (value, element) => isUserAbilitato(element) && !isUserLocked(element) && !isCredentialsExpired(element)
                        },

                    ]
                },
            ],
            hidePaginator: false,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}

export function showActionMenu(element?: UserView, isOperatoreLogicaOrOperatoreCed?: boolean) {
    return !isOperatoreLogicaOrOperatoreCed || (
        isOperatoreLogicaOrOperatoreCed && element.authorities.length === 1 && !!element.authorities.find((item) => item.authority ===
            'ROLE_operatore_trasporto')
    );
}

export function isUserAbilitato(element?: User) {
    return element.accountEnabled;
}

export function isUserLocked(element?: User) {
    return element.accountLocked;
}

export function isCredentialsExpired(element?: User) {
    return element.credentialsExpired;
}
