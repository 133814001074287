import {ThemePalette} from "@angular/material/core";
import {PathEnum} from "../../enum/path-enum";
import {formGroupConfigInterface} from "../../interfaces/generic-components.interface";

export interface GenericTableConfigurationModel {
    configuration?: ConfigurazioneTabella;
}

export interface ConfigurazioneTabella {
    configurazioneTabella?: ConfigurazioneColonna[];
    data?: any[];
    pageSize?: number;
    pageIndex?: number;
    pageSizeOptions?: number[];
    sticky?: boolean;
    css?: { [p: string]: any } | null;
    customButton?: boolean,  // i tasti azione non hanon padding e sono più piccoli
    messaggioDatiAssenti?: string;
    cssMatTable?: { [p: string]: any } | null;
    cssMatPaginator?: { [p: string]: any } | null;
    isPaginatedBE?: boolean;
    selection?: OpzioniSelezione;
    singleChoiceSelection?: boolean;
    totalElements?: number;
    hidePaginator?: boolean;
    highlightRowWhen?: (row) => boolean;
}

export interface OpzioniSelezione {
    isSelectable: boolean;
    selectedColumnKeyToShow: string;
    selectedColumnKeyPrimaryKey: string;
}

export enum TipoColonnaEnum {
    SELECTION = 'selection',
    ICON = 'icon',
    STRING = 'string',
    ACTION = 'action',
    ACTION_MENU = 'action_menu',
    BOOLEAN = 'boolean',
    NUMBER = 'number',
    TEXT_BUTTON = 'text_button',
    AMOUNT = 'amount',
    HREF = 'href',
    CHIP_USER = 'chip_user',
    CHIP_STATUS = 'chip_status',
    CHECKBOX = 'checkbox',
    INPUT_NUMBER = 'input_number',
    CHIP_ARRAY = 'CHIP_ARRAY,'
}

export class IconInterface {
    nameIcon: string;
    colorIcon: ThemePalette;
    tootlip?: string;
    showIf?: (element: any) => boolean
}

export enum TipoClickEnum {
    CONFERMA = 'conferma',
    DELETE = 'delete',
    INFO = 'info',
    SHOW = 'show',
    EDIT = 'edit',
    CHIP_DETAIL = "chip_detail",
    RIPRISTINA = "RIPRISTINA",

    LOCK = 'LOCK',
    UNLOCK = 'UNLOCK',
    SEND_EMAIL = 'SEND_EMAIL',
    SECURITY = 'SECURITY',
    LINK_VERIFY_EMAIL = 'LINK_VERIFY_EMAIL',
    LINK_RESET_PWD = 'LINK_RESET_PWD',
    ANNULLA_VERIFICA = 'ANNULLA_VERIFICA',
    ELIMINA = 'ELIMINA',
}

export class ButtonInterface {
    nameIconButton?: string;
    click?: TipoClickEnum;
    tooltip?: string;
    color?: 'accent' | 'warn' | 'primary';
    show?: (value: any, element?: any) => boolean;
    showEvery?: (value: any) => boolean;
    valueShow?: string | string[];
    iconTooltip?: string;
    isIcon?: boolean;
    hide?: boolean;
}

export interface ConfigurazioneColonna {
    tipo?: TipoColonnaEnum;
    colonnaKey?: string;
    nomeColonna?: string;
    hideColonna?: boolean;
    icon?: IconInterface[];
    valueKeyForFuction?: string;
    function?: (value: any) => boolean;
    function2?: (value: any) => boolean;
    button?: ButtonInterface[];
    button2?: ButtonInterface[];
    flex?: number;
    goTo?: {
        path: PathEnum[],
        value: string
    },
    onlyInfo?: (value: any) => boolean;
    show?: (value: any, element?: any) => boolean;
    convertiValoreBoolean?: (value: any) => string;
    convertiValoreBooleanInIcon?: (value: any) => IconInterface;
    enableSort?: boolean;
    valueCheck?: string;
    getValue?: (value: any) => string;
    sortByNumber?: boolean;
    uppercase?: boolean;
    dialog?: {
        openDialog: (value: any) => void;
        fieldValueDialog: any;
    };
    isChipDisabled?: boolean;
    isCheckboxDisabled?: boolean;
    formControl?: formGroupConfigInterface;
    tooltip?: (tipoModifica: string) => string;
    chipWidthAuto?: boolean;
    statusType?: any;
    onlyPositiveWithOneDecimal?: boolean;
    stringSuffix?: string;
    numberSuffix?: string;
    helpIcon?: boolean;
}

export interface GenericTableConfigurationInterface {
    configurazioneTabella: ConfigurazioneTabella;
}

export interface ClickEvent {
    tipoClick: TipoClickEnum;
    index?: number;
    value?: any;
}

export interface TableData {
    selectedRows: Array<TableDataRows>;
    removedRows: Array<any>;
    addedRows: Array<any>;
}

export interface TableDataRows {
    key: string;
    data?: TableDataRowsDataObj;
}

export interface TableDataRowsDataObj {
    id?: string;
    studente?: string;
    codiceFiscale?: string;
    nomeCognome?: string;
}
