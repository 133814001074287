import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {MatPaginator} from "@angular/material/paginator";

@Injectable({
  providedIn: 'root'
})
export class TableSelectionManagerService {
  checkSelectionRowModel$ = new BehaviorSubject(null);
  checkFocusOnSelectionFilterForm$ = new BehaviorSubject(null);
  checkFocusOnTable$ = new BehaviorSubject(null);

    paginatorOld: MatPaginator;

  constructor() { }
}
