/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MovimentoRegistro } from './movimentoRegistro';
import { OperatoreView } from './operatoreView';
import { SommarioAutomezzo } from './sommarioAutomezzo';

export interface RegistroGiornalieroOperatoreView { 
    id?: string;
    data?: string;
    operatore?: OperatoreView;
    numeroDiMinutiLavorati?: number;
    movimentiRegistro?: Array<MovimentoRegistro>;
    sommarioAutomezzi?: Array<SommarioAutomezzo>;
    fileUri?: string;
}