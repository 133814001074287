import {Component, ElementRef, HostListener, Inject, OnInit, Renderer2} from '@angular/core';
import {DialogWrapComponent} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {NgClass, NgForOf, NgIf, NgOptimizedImage, NgTemplateOutlet, UpperCasePipe} from "@angular/common";
import {MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogRef} from "@angular/material/dialog";
import {
    FormArray,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule,
    Validators,
    ɵFormGroupRawValue,
    ɵFormGroupValue,
    ɵTypedOrUntyped
} from "@angular/forms";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerModule} from "@angular/material/datepicker";
import moment from "moment/moment";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatIconModule} from "@angular/material/icon";
import {MatDividerModule} from "@angular/material/divider";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {MatTooltipModule} from "@angular/material/tooltip";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {TipoClickEnum} from "../../components/generic-table/generic-table-model";
import {get, isEqual} from "lodash";
import {
    GenericComponentDialogConfig,
    InterventoComponentDialogConfig
} from "../../interfaces/generic-components.interface";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {compact, head, join} from "lodash-es";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {
    getTestoAutomezzo,
    getTestoCliente,
    getTestoNota,
    getTestoOperatore,
    getTestoReferente,
    getTestoUnitaLocale
} from "../../utils/mask-functions";
import {DialogForInterventoType, DialogInterventoConfirmEnum} from "../../enum/dialog-intervento-confirm-enum";
import {
    AddebitoView,
    AddInterventoDTO,
    AttrezzaturaInterventoView,
    AutomezzoView,
    AvviaInterventoDTO,
    ClienteView,
    EvadiInterventoDTO,
    Indirizzo,
    InterventiService,
    InterventoProgrammaDTO,
    InterventoView,
    NotaInterventoBucketView,
    NotaInterventoView,
    OperatoreInterventoView,
    PatchInterventoDTO,
    PatchInterventoMovimentoDTO,
    ReferenteIntervento,
    ReferenteView,
    TipoInterventoProgrammaDTO,
    UnitaLocaleIntervento,
    UnitaLocaleView
} from "../../../../api-clients/generated/services";
import {ClienteUnitaReferente} from "../generic-form-modal/generic-select-form-modal.component";
import {MatChipsModule} from "@angular/material/chips";
import {PipeModule} from "../../pipe/pipe.module";
import {
    getFormArrayFromFormGroupTyped,
    getFormControlFromFormArray,
    getFormControlFromFormGroupTyped,
    orderByDescrizione,
    orderByOrdine
} from "../../utils/utils";
import {catchError, finalize, forkJoin, Observable, of, switchMap, takeUntil, tap} from "rxjs";
import {ManageErrorService} from "../../../services/manage-error.service";
import {MatTabsModule} from "@angular/material/tabs";
import {AnnotazioniInterventoComponent} from "../../components/annotazioni-intervento/annotazioni-intervento.component";
import {
    FormAvvioInterventiNonAttivitaComponent
} from "../../components/form-avvio-interventi-non-attivita/form-avvio-interventi-non-attivita.component";
import {InputFileComponent} from "../../components/input-file/input-file.component";
import {BlobManagerService} from "../../../services/blob-manager.service";
import {DomSanitizer, SafeUrl} from "@angular/platform-browser";
import {
    DialogWrapInterventoComponent
} from "../../../layout/common/dialogs/dialog-wrap-intervento/dialog-wrap-intervento.component";
import {
    mapAddebitoViewToAddTipoInterventoDTO,
    mapAttrezzaturaInterventoToAddAttrezzaturaIntervento,
    mapAttrezzaturaViewToAttrezzaturaInterventoView,
    mapAutomezzoViewToAddAutomezzoInterventoDTO,
    mapClienteViewToAddClienteInterventoDTO,
    mapNotaInterventoBucketViewToNotaInterventoView,
    mapOperatoreInterventoViewToAddOperatoreInterventoDTO,
    mapOperatoreViewToOperatoreInterventoView,
    mapReferenteViewToReferenteIntervento,
    mapUnitaViewToUnitaLocaleIntervento
} from "../../utils/intervento-map-functions";
import {
    GenericFieldRigaProgrammazioneComponent
} from "../../components/generic-field-riga-programmazione/generic-field-riga-programmazione.component";
import {TypeDialogFormEnum} from "../../enum/generic-components-enum";
import {GenericDialogComponent} from "../../../layout/common/dialogs/generic-dialog/generic-dialog.component";
import {AbstractFocusManagerComponent} from "../../abstracts/abstract-focus-manager.component";
import {CustomValidators} from "../../validators/custom-validators";

@Component({
    selector: 'app-creazione-intervento-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        MatButtonModule,
        MatProgressSpinnerModule,
        NgIf,
        MatDialogClose,
        FormsModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        MatIconModule,
        MatDividerModule,
        MatTooltipModule,
        GenericFormFieldComponent,
        NgClass,
        MatCheckboxModule,
        MatChipsModule,
        PipeModule,
        UpperCasePipe,
        MatTabsModule,
        NgTemplateOutlet,
        AnnotazioniInterventoComponent,
        FormAvvioInterventiNonAttivitaComponent,
        InputFileComponent,
        NgOptimizedImage,
        DialogWrapInterventoComponent,
        GenericFieldRigaProgrammazioneComponent
    ],
    templateUrl: './creazione-intervento-modal.component.html',
    styleUrl: './creazione-intervento-modal.component.scss',
    providers: [
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class CreazioneInterventoModalComponent extends AbstractFocusManagerComponent implements OnInit {
    interventoFormGroup: FormGroup<InterventoForm>;
    destinazioneFormGroup: FormGroup<IndirizzoForm>;
    partenzaFormGroup: FormGroup<IndirizzoForm>;
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    canShowOdl: boolean;
    showChip: boolean;
    statusChip: InterventoProgrammaDTO.StatoEnum | undefined;
    showEditPart: boolean;
    CampoDaSelezionare = CampoDaSelezionare;
    InterventoStatoEnum = InterventoProgrammaDTO.StatoEnum;
    evasoByDashboardText = 'L\'intervento è stato evaso dalla dashboard';
    notEvasoByDashboardText = 'L\'intervento non è stato evaso dalla dashboard';
    IsInterventoFormularioLabel = 'È un intervento formulario?';
    tipologiaIntervento: InterventoProgrammaDTO.TipologiaEnum;

    interventoExtendedFormGroup: FormGroup<InterventoToSubmit> = new FormGroup<InterventoToSubmit>({});
    isPartenza: boolean;
    dialogTypeModifica = DialogForInterventoType.MODIFICA
    isDialogTypeModifica: boolean;
    isRientro: boolean;
    isPercorrenza: boolean;
    isAttivita: boolean;
    idIntervento: string;
    fotoArray: SafeUrl[] = [];
    initialValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any>;
    InterventoProgrammaDTO = InterventoProgrammaDTO;
    requiredClienteForReferente = 'Per selezionare un referente occorre selezionare prima un cliente';
    requiredClienteForUnitaLocale = 'Per selezionare un\'unità locale occorre selezionare prima un cliente';
    initialValueExtended: ɵTypedOrUntyped<InterventoToSubmit, ɵFormGroupRawValue<InterventoToSubmit>, any>;
    luogoDefaultValue = 'Piazzale';
    isDialogTypeCreazione: boolean;
    aggiungiDaInterventiDaEvadereLabel = 'Aggiungi da Interventi Da Evadere';

    constructor(public dialogRef: MatDialogRef<CreazioneInterventoModalComponent>,
                private dialog: MatDialog,
                private fuseConfirmationService: FuseConfirmationService,
                private dialogManagerService: DialogManagerService,
                @Inject(MAT_DIALOG_DATA) public data: InterventoComponentDialogConfig,
                private interventiService: InterventiService,
                private manageErrorService: ManageErrorService,
                private blobManagerService: BlobManagerService,
                private sanitizer: DomSanitizer,
                protected el: ElementRef,
                protected renderer: Renderer2) {
        super(el, renderer);
        this.setAttributes();
        this.idIntervento = this.data?.formValue?.id
        this.partenzaFormGroup = this.createIndirizzoFormGroup(this.data?.formValue?.partenza);
        this.destinazioneFormGroup = this.createIndirizzoFormGroup(this.data?.formValue?.destinazione);
        this.setFormGroup(this.data.formValue);
    }

    setAttributes() {
        this.showChip = !!this.data?.formValue?.stato;
        this.statusChip = this.data?.formValue?.stato
        this.isDialogTypeModifica = this.data.dialogType === this.dialogTypeModifica;
        this.isDialogTypeCreazione = this.data.dialogType === DialogForInterventoType.CREAZIONE;
        this.tipologiaIntervento = this.data?.formValue?.tipologia;
        this.isPartenza = (this.tipologiaIntervento === InterventoProgrammaDTO.TipologiaEnum.PARTENZA);
        this.isRientro = (this.tipologiaIntervento === InterventoProgrammaDTO.TipologiaEnum.RIENTRO);
        this.isPercorrenza = (this.tipologiaIntervento === InterventoProgrammaDTO.TipologiaEnum.PERCORRENZA);
        this.isAttivita = (this.tipologiaIntervento === InterventoProgrammaDTO.TipologiaEnum.ATTIVITA);

        this.canShowOdl = (this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.EVASO ||
            this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.VERIFICATO ||
            this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.CONSOLIDATO) && this.isAttivita;
    }

    setFormGroup(intervento?: InterventoView) {
        this.interventoFormGroup = new FormGroup<InterventoForm>({
                id: new FormControl(intervento?.id),
                isInterventoFormulario: new FormControl(intervento?.isInterventoFormulario),
                idOrdineLavoro: new FormControl(intervento?.ordineDiLavoro?.codice),
                idRichiestaDaEvadere: new FormControl(null),
                data: new FormControl(!!intervento?.dataOraInizioPrevista ?
                        moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD') : null,
                    {
                        validators: [Validators.required]
                    }),
                giornoInizioPrevisto: new FormControl(!!intervento?.dataOraInizioPrevista ?
                        moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD') : null,
                    {
                        validators: [Validators.required]
                    }),
                giornoFinePrevisto: new FormControl(!!intervento?.dataOraFinePrevista ?
                        moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') : null,
                    {
                        validators: [Validators.required]
                    }),
                oraInizioPrevista: new FormControl(
                    !!intervento?.dataOraInizioPrevista ?
                        moment(intervento?.dataOraInizioPrevista).format('HH:mm') : null, {
                        validators: [Validators.required]
                    }),
                oraFinePrevista: new FormControl(
                    !!intervento?.dataOraFinePrevista ?
                        moment(intervento?.dataOraFinePrevista).format('HH:mm') : null, {
                        validators: [Validators.required]
                    }),
                stato: new FormControl(intervento?.stato),
                costo: new FormControl(intervento?.costo || 0),
                numeroPreventivo: new FormControl(intervento?.numeroPreventivo),
                referenteUnico: new FormControl(intervento?.referenteUnico),
                referenteUnicoTel: new FormControl(intervento?.referenteUnicoTel),
                tipologiaInterventoArray: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                    orderByDescrizione(intervento?.tipiIntervento)?.map((item: AddebitoView) => this.createFormControl(item)) : [this.createFormControl()]),
                cliente: new FormControl(intervento?.cliente, {
                    validators: [Validators.required]
                }),
                automezzo: new FormControl(intervento?.automezzo, {
                    validators: [Validators.required]
                }),
                rimorchio: new FormControl(intervento?.rimorchio),
                attrezzatura: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                    orderByOrdine(intervento?.attrezzature).map((item: AttrezzaturaInterventoView, index: number) => this.createFormControlWithoutValidator(
                        mapAttrezzaturaViewToAttrezzaturaInterventoView(item?.attrezzatura, index))) :
                    [this.createFormControlWithoutValidator()]),
                operatore: new FormArray(!!intervento?.operatori && intervento?.operatori?.length > 0 ?
                    orderByOrdine(intervento?.operatori).map((item: OperatoreInterventoView) =>
                        this.createFormControl(item)) :
                    [this.createFormControl()]),
                nota: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                    orderByOrdine(intervento?.note).map((item: NotaInterventoView) =>
                        this.createFormControlWithoutValidator(item)) :
                    [this.createFormControlWithoutValidator()]),
                durata: new FormControl(intervento?.durataStimata),

                //---- MASK
                automezzoMask: new FormControl(getTestoAutomezzo(intervento?.automezzo), {validators: Validators.required}),
                rimorchioMask: new FormControl(getTestoAutomezzo(intervento?.rimorchio)),
                operatoriMask: new FormArray(!!intervento?.operatori && intervento?.operatori?.length > 0 ?
                    orderByOrdine(intervento?.operatori).map((item: OperatoreInterventoView) =>
                        this.createFormControl(get(item.operatore, 'nomeCognome', null)))
                    : [this.createFormControl()]),
                tipologiaInterventoArrayMask: new FormArray(!!intervento?.tipiIntervento && intervento?.tipiIntervento?.length > 0 ?
                    orderByDescrizione(intervento?.tipiIntervento)?.map((item: AddebitoView) =>
                        this.createFormControl(get(item, 'descrizione', null)))
                    : [this.createFormControl()]),
                attrezzatureMask: new FormArray(!!intervento?.attrezzature && intervento?.attrezzature?.length > 0 ?
                    orderByOrdine(intervento?.attrezzature).map((item: AttrezzaturaInterventoView) =>
                        this.createFormControlWithoutValidator(get(item.attrezzatura, 'descrizione', null)))
                    : [this.createFormControlWithoutValidator()]),
                clienteMask: new FormControl(getTestoCliente(intervento?.cliente), {validators: Validators.required}),
                notaMask: new FormArray(!!intervento?.note && intervento?.note?.length > 0 ?
                    orderByOrdine(intervento?.note).map((item: NotaInterventoView) =>
                        this.createFormControlWithoutValidator(get(item.notaInterventoBucket, 'descrizione', null)))
                    : [this.createFormControlWithoutValidator()]),
                referenteCliente: new FormControl(intervento?.referenteIntervento),
                referenteClienteMask: new FormControl(getTestoReferente(intervento?.referenteIntervento)),
                unitaLocale: new FormControl(intervento?.unitaLocaleIntervento, {validators: Validators.required}),
                unitaLocaleMask: new FormControl(getTestoUnitaLocale(intervento?.unitaLocaleIntervento), {validators: Validators.required}),
                hasExtraSosta: new FormControl(!!intervento?.hasExtraSosta),
            },
            CustomValidators.comparisonOrarioEGiornoValidator(
                'oraInizioPrevista', 'oraFinePrevista', 'giornoInizioPrevisto', 'giornoFinePrevisto'
            )
        );
        this.switchOnTipologiaForSettingForms(intervento);
        if (this.data.readonly) {
            this.interventoFormGroup.disable();
            this.interventoFormGroup.updateValueAndValidity();
        }
        if (this.isDialogTypeModifica) {
            this.enableByField();
        }
        if (this.data.dialogType === DialogForInterventoType.CREAZIONE) {
            this.getInterventoFormControlByPath('data').setValue(moment().format('YYYY-MM-DD'));
            getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'giornoInizioPrevisto')
                .setValue(moment().format('YYYY-MM-DD'));
            getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'giornoFinePrevisto')
                .setValue(moment().format('YYYY-MM-DD'));
        }
    }

    switchOnTipologiaForSettingForms(intervento?: InterventoView) {
        switch (this.tipologiaIntervento) {
            case InterventoProgrammaDTO.TipologiaEnum.ATTIVITA:
                this.setEditForms(intervento);
                this.switchOnActivityStatusToAddControls(intervento);
                break;
            case InterventoProgrammaDTO.TipologiaEnum.PARTENZA:
                this.setFormGroupPartenza(intervento);
                break;
            case InterventoProgrammaDTO.TipologiaEnum.PERCORRENZA:
                this.setFormGroupPercorrenza(intervento);
                break;
            case InterventoProgrammaDTO.TipologiaEnum.RIENTRO:
                this.setFormGroupRientro(intervento);
                break;
        }
    }

    setFormGroupPartenza(intervento?: InterventoView) {
        this.interventoFormGroup.disable();
        this.destinazioneFormGroup.disable();
        this.getInterventoFormControlByPath('oraInizioPrevista').disable();
        this.getInterventoFormControlByPath('oraFinePrevista').disable();
        this.getInterventoFormControlByPath('costo').disable();
        if (this.data.editDialog || this.isEvasoVerificatoOrCosolidato() || this.isInCorso()) {
            this.interventoExtendedFormGroup.addControl(
                'kmAutomezzo', new FormControl(intervento?.kmAutomezzo,
                    !!this.isProgrammato() ? {validators: Validators.required} : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'luogoPartenza', new FormControl(intervento?.partenza?.descrizione || this.luogoDefaultValue,
                    !!this.isProgrammato() ? {validators: Validators.required} : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'notaOperatore', new FormControl(intervento?.notaOperatore)
            );
            this.addControlsOrariEffettivi(intervento);
            this.showEditPart = true;
        }

    }

    setFormGroupPercorrenza(intervento?: InterventoView) {
        if (this.data.editDialog || this.isEvasoVerificatoOrCosolidato() || this.isInCorso()) {
            this.interventoExtendedFormGroup.addControl(
                'notaPercorrenza', new FormControl(intervento?.notaPercorrenza)
            );
            this.interventoExtendedFormGroup.addControl(
                'notaOperatore', new FormControl(intervento?.notaOperatore)
            );
            this.addControlsOrariEffettivi(intervento);
            this.showEditPart = true;
        }
    }

    setFormGroupRientro(intervento?: InterventoView) {
        if (this.data.editDialog || this.isEvasoVerificatoOrCosolidato() || this.isInCorso()) {
            this.interventoExtendedFormGroup.addControl(
                'luogoDestinazione', new FormControl(intervento?.destinazione?.descrizione
                    || this.luogoDefaultValue,
                    (!!this.isProgrammato() || !!this.isInCorso())
                        ? {validators: Validators.required} : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'kmAutomezzo', new FormControl(intervento?.kmAutomezzo,
                    !!this.isInCorso() ? {validators: Validators.required} : undefined)
            );
            this.interventoExtendedFormGroup.addControl(
                'notaOperatore', new FormControl(intervento?.notaOperatore)
            );
            this.addControlsOrariEffettivi(intervento);
            this.showEditPart = true;
        }
    }

    createIndirizzoFormGroup(indirizzo: Indirizzo) {
        return new FormGroup<IndirizzoForm>({
            descrizione: new FormControl(indirizzo?.descrizione),
            indirizzo: new FormControl(indirizzo?.indirizzo),
            numero: new FormControl(indirizzo?.numero),
            frazione: new FormControl(indirizzo?.frazione),
            comune: new FormControl(indirizzo?.comune),
            cap: new FormControl(indirizzo?.cap),
            provincia: new FormControl(indirizzo?.provincia),
            indirizzoCompleto: new FormControl(join(compact([
                indirizzo?.indirizzo,
                indirizzo?.numero,
                indirizzo?.frazione,
                indirizzo?.comune,
                indirizzo?.provincia,
                indirizzo?.cap]), ' - '))
        });
    }

    setEditForms(intervento?: InterventoView) {
        if (this.data.editDialog) {
            this.interventoExtendedFormGroup.addControl(
                'notaPercorrenza', new FormControl(null)
            );
            if (!!this.isInCorso()) {
                this.interventoExtendedFormGroup.addControl(
                    'giornoInizioODL',
                    new FormControl(
                        !!intervento?.ordineDiLavoro?.dataOraInizioODL ?
                            moment(intervento?.ordineDiLavoro?.dataOraInizioODL).format('YYYY-MM-DD') :
                            !!intervento?.dataOraInizioPrevista ?
                                moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD')
                                : null,
                        {
                            validators: [Validators.required]
                        })
                );
                this.interventoExtendedFormGroup.addControl(
                    'giornoFineODL',
                    new FormControl(
                        !!intervento?.ordineDiLavoro?.dataOraFineODL ?
                            moment(intervento?.ordineDiLavoro?.dataOraFineODL).format('YYYY-MM-DD') :
                            !!intervento?.dataOraFinePrevista ?
                                moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') : null,
                        {
                            validators: [Validators.required]
                        })
                );
                this.interventoExtendedFormGroup.addControl(
                    'giornoInizioEffettivo',
                    new FormControl(
                        !!intervento?.dataOraInizioReale ?
                            moment(intervento?.dataOraInizioReale).format('YYYY-MM-DD') :
                            !!intervento?.dataOraInizioPrevista ?
                                moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD')
                                : null,
                        {
                            validators: [Validators.required]
                        })
                );
                this.interventoExtendedFormGroup.addControl(
                    'giornoFineEffettivo',
                    new FormControl(
                        !!intervento?.dataOraFineReale ?
                            moment(intervento?.dataOraFineReale).format('YYYY-MM-DD') :
                            !!intervento?.dataOraFinePrevista ?
                                moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') : null,
                        {
                            validators: [Validators.required]
                        })
                );
                this.interventoExtendedFormGroup.addControl(
                    'dataOraInizioODL', new FormControl(
                        !!intervento?.dataOraInizioPrevista ?
                            moment(intervento?.dataOraInizioPrevista).format('HH:mm') : null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'dataOraFineODL', new FormControl(
                        !!intervento?.dataOraFinePrevista ?
                            moment(intervento?.dataOraFinePrevista).format('HH:mm') : null
                    )
                );
                this.interventoExtendedFormGroup.addControl(
                    'nominativoFirmatario', new FormControl(null, {validators: Validators.required})
                );
                this.interventoExtendedFormGroup.addControl(
                    'isPausaPranzo', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'dataOraInizioPausaPranzo', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'dataOraFinePausaPranzo', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'isInterventoInReperibilita', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'dataOraRichiestaReperibilita', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'mittenteReperibilita', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'notaOperatore', new FormControl(null)
                );
                this.interventoExtendedFormGroup.addControl(
                    'notaOrdineDiLavoro', new FormControl(null)
                );
                this.interventoExtendedFormGroup.setValidators([
                    CustomValidators.comparisonOrarioEGiornoValidator(
                        'dataOraInizioODL', 'dataOraFineODL', 'giornoInizioODL', 'giornoFineODL'
                    ),
                    CustomValidators.comparisonOrarioEGiornoValidatorCanBeSame(
                        'oraInizioEffettiva', 'oraFineEffettiva', 'giornoInizioEffettivo', 'giornoFineEffettivo'
                    ),
                    CustomValidators.comparisonOrarioValidator(
                        'dataOraInizioPausaPranzo', 'dataOraFinePausaPranzo'
                    ),
                    CustomValidators.comparisonOraPausaInterna(
                        'dataOraInizioPausaPranzo', 'dataOraFinePausaPranzo', 'dataOraInizioODL', 'dataOraFineODL'
                    )
                ])
            }
            this.onValueChange();
        }
        this.showEditPart = true;
    }

    switchOnActivityStatusToAddControls(intervento?: InterventoView) {
        if (!this.data.editDialog) {
            switch (this.data?.formValue?.stato) {
                case InterventoProgrammaDTO.StatoEnum.INCORSO:
                    this.interventoExtendedFormGroup.addControl(
                        'oraInizioEffettiva', new FormControl(
                            !!intervento?.dataOraInizioReale ?
                                moment(intervento?.dataOraInizioReale).format('HH:mm') : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'giornoInizioEffettivo',
                        new FormControl(
                            !!intervento?.dataOraInizioReale ?
                                moment(intervento?.dataOraInizioReale).format('YYYY-MM-DD')
                                : null,
                            {
                                validators: [Validators.required]
                            })
                    );
                    break;
                case InterventoProgrammaDTO.StatoEnum.EVASO:
                case InterventoProgrammaDTO.StatoEnum.VERIFICATO:
                case InterventoProgrammaDTO.StatoEnum.CONSOLIDATO:
                    this.interventoExtendedFormGroup.addControl(
                        'oraInizioEffettiva', new FormControl(
                            !!intervento?.dataOraInizioReale ?
                                moment(intervento?.dataOraInizioReale).format('HH:mm') : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'oraFineEffettiva', new FormControl(
                            !!intervento?.dataOraFineReale ?
                                moment(intervento?.dataOraFineReale).format('HH:mm') : undefined
                        )
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'nominativoFirmatario',
                        new FormControl(intervento?.ordineDiLavoro?.nominativoFirmaCliente)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'isPausaPranzo', new FormControl(intervento?.ordineDiLavoro?.isPresentePausaPranzo)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'dataOraInizioPausaPranzo', new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraInizioPausaPranzo ?
                                moment(intervento?.ordineDiLavoro?.dataOraInizioPausaPranzo).format('HH:mm') : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'dataOraFinePausaPranzo', new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraFinePausaPranzo ?
                                moment(intervento?.ordineDiLavoro?.dataOraFinePausaPranzo).format('HH:mm') : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'isInterventoInReperibilita',
                        new FormControl(intervento?.ordineDiLavoro?.isInterventoInReperibilita)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'dataOraRichiestaReperibilita', new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraRichiestaReperibilita ?
                                moment(intervento?.ordineDiLavoro?.dataOraRichiestaReperibilita).format('HH:mm')
                                : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'mittenteReperibilita', new FormControl(
                            intervento?.ordineDiLavoro?.mittenteReperibilita)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'giornoInizioODL',
                        new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraInizioODL ?
                                moment(intervento?.ordineDiLavoro?.dataOraInizioODL).format('YYYY-MM-DD') :
                                !!intervento?.dataOraInizioPrevista ?
                                    moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD')
                                    : null,
                            {
                                validators: [Validators.required]
                            })
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'giornoFineODL',
                        new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraFineODL ?
                                moment(intervento?.ordineDiLavoro?.dataOraFineODL).format('YYYY-MM-DD') :
                                !!intervento?.dataOraFinePrevista ?
                                    moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') : null,
                            {
                                validators: [Validators.required]
                            })
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'giornoInizioEffettivo',
                        new FormControl(
                            !!intervento?.dataOraInizioReale ?
                                moment(intervento?.dataOraInizioReale).format('YYYY-MM-DD') :
                                !!intervento?.dataOraInizioPrevista ?
                                    moment(intervento?.dataOraInizioPrevista).format('YYYY-MM-DD')
                                    : null,
                            {
                                validators: [Validators.required]
                            })
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'giornoFineEffettivo',
                        new FormControl(
                            !!intervento?.dataOraFineReale ?
                                moment(intervento?.dataOraFineReale).format('YYYY-MM-DD') :
                                !!intervento?.dataOraFinePrevista ?
                                    moment(intervento?.dataOraFinePrevista).format('YYYY-MM-DD') : null,
                            {
                                validators: [Validators.required]
                            })
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'dataOraInizioODL', new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraInizioODL ?
                                moment(intervento?.ordineDiLavoro?.dataOraInizioODL).format('HH:mm') : undefined)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'dataOraFineODL', new FormControl(
                            !!intervento?.ordineDiLavoro?.dataOraFineODL ?
                                moment(intervento?.ordineDiLavoro?.dataOraFineODL).format('HH:mm') : undefined
                        )
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'durata', new FormControl(intervento?.ordineDiLavoro?.durataEffettiva)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'firma', new FormControl(intervento?.ordineDiLavoro?.uriImmagineFirmaCliente)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'notaOrdineDiLavoro', new FormControl(intervento?.ordineDiLavoro?.notaOrdineDiLavoro)
                    );
                    // TODO DA DOVE?
                    this.interventoExtendedFormGroup.addControl(
                        'isEvasoByDashBoard', new FormControl(null)
                    );
                    if (this.data?.readonly) {
                        this.getInterventExtendedFormControlByPath('isEvasoByDashBoard').disable();
                    }
                    // TODO DA DOVE?
                    this.interventoExtendedFormGroup.addControl(
                        'nominativoOperatoreDashboard', new FormControl(null)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'ODL_PDF', new FormControl(intervento?.ordineDiLavoro?.uriOdl)
                    );
                    this.interventoExtendedFormGroup.addControl(
                        'notaOperatore', new FormControl(intervento?.notaOperatore)
                    );
                    this.requestFoto()
                    break;
                default:
                    break;
            }
        }
    }

    onValueChange() {
        getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'isPausaPranzo')?.valueChanges.pipe(
            tap((value) => {
                const dataOraInizioControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'dataOraInizioPausaPranzo');
                const dataOraFineControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'dataOraFinePausaPranzo');
                if (!!value) {
                    dataOraInizioControl.setValidators([Validators.required]);
                    dataOraFineControl.setValidators([Validators.required]);
                    dataOraInizioControl.enable()
                    dataOraFineControl.enable()
                } else {
                    dataOraInizioControl.clearValidators();
                    dataOraFineControl.clearValidators();
                    dataOraInizioControl.setErrors(null);
                    dataOraFineControl.setErrors(null);
                    dataOraInizioControl.setValue(null)
                    dataOraFineControl.setValue(null)
                    dataOraInizioControl.disable()
                    dataOraFineControl.disable()
                }

                dataOraInizioControl.updateValueAndValidity();
                dataOraFineControl.updateValueAndValidity();
            })
        ).subscribe()

        const isInterventoInReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'isInterventoInReperibilita'
        );

        const dataOraRichiestaReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'dataOraRichiestaReperibilita'
        );

        const mittenteReperibilitaControl = getFormControlFromFormGroupTyped<InterventoToSubmit>(
            this.interventoExtendedFormGroup,
            'mittenteReperibilita'
        );


        isInterventoInReperibilitaControl?.valueChanges.pipe(
            tap((value) => {
                if (value) {
                    dataOraRichiestaReperibilitaControl.setValidators(Validators.required);
                    mittenteReperibilitaControl.setValidators(Validators.required);
                    dataOraRichiestaReperibilitaControl.enable();
                    mittenteReperibilitaControl.enable();
                } else {
                    dataOraRichiestaReperibilitaControl.clearValidators();
                    mittenteReperibilitaControl.clearValidators();
                    dataOraRichiestaReperibilitaControl.setErrors(null);
                    mittenteReperibilitaControl.setErrors(null);
                    dataOraRichiestaReperibilitaControl.setValue(null);
                    mittenteReperibilitaControl.setValue(null);
                    dataOraRichiestaReperibilitaControl.disable();
                    mittenteReperibilitaControl.disable();
                }

                dataOraRichiestaReperibilitaControl.updateValueAndValidity();
                mittenteReperibilitaControl.updateValueAndValidity();
            })
        ).subscribe();
    }

    getInterventoFormControlByPath(path: string): FormControl {
        return this.interventoFormGroup?.get(path) as FormControl;
    }

    getInterventExtendedFormControlByPath(path: string): FormControl {
        return this.interventoExtendedFormGroup?.get(path) as FormControl;
    }

    ctrlCliente(): FormControl<ClienteView> {
        return this.getInterventoFormControlByPath('cliente')
    }

    ctrlReferente(): FormControl<ReferenteIntervento> {
        return this.getInterventoFormControlByPath('referenteCliente')
    }

    ctrlUnitaLocale(): FormControl<UnitaLocaleIntervento> {
        return this.getInterventoFormControlByPath('unitaLocale')
    }

    ctrlClienteMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('clienteMask')
    }

    ctrlReferenteMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('referenteClienteMask')
    }

    ctrlUnitaLocaleMask(): FormControl<string> {
        return this.getInterventoFormControlByPath('unitaLocaleMask')
    }

    openModaleDiSelezione(field: CampoDaSelezionare, index?: number) {
        switch (field) {
            case CampoDaSelezionare.NOTA :
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('nota', index).value
                ).subscribe({
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const notaInterventoBucketView: NotaInterventoBucketView = value.value as NotaInterventoBucketView;
                                this.getFormControlByIndexInFormArrayByPath('notaMask', index).setValue(getTestoNota(notaInterventoBucketView));
                                this.getFormControlByIndexInFormArrayByPath('nota', index).setValue(
                                    mapNotaInterventoBucketViewToNotaInterventoView(notaInterventoBucketView, index)
                                )
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.CLIENTE :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('cliente').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const descrizioneCliente = get(value.value, 'descrizione', null);
                                this.getInterventoFormControlByPath('clienteMask').setValue(descrizioneCliente);
                                this.getInterventoFormControlByPath('cliente').setValue(value.value)
                                const cliente = value.value as ClienteView;
                                const unitaLocale = cliente?.unitaLocali?.find((unita) => !!unita.isPrincipale) ||
                                    head(cliente?.unitaLocali);
                                this.ctrlUnitaLocale().setValue(mapUnitaViewToUnitaLocaleIntervento(unitaLocale));
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(unitaLocale));
                                const referente = cliente?.referenti?.find((ref) => !!ref.isPrincipale) ||
                                    head(cliente?.referenti);
                                this.ctrlReferente().setValue(mapReferenteViewToReferenteIntervento(referente));
                                this.ctrlReferenteMask().setValue(getTestoReferente(referente));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.TIPOLOGIA_INTERVENTO:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlAddebito(index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.ctrlAddebitoMask(index).setValue(result);
                                this.ctrlAddebito(index).setValue(value.value,);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.ATTREZZATURA:
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('attrezzatura', index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.getFormControlByIndexInFormArrayByPath('attrezzatureMask', index).setValue(result);
                                this.getFormControlByIndexInFormArrayByPath('attrezzatura', index).setValue(
                                    mapAttrezzaturaViewToAttrezzaturaInterventoView(value.value, index));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.AUTOMEZZO :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('automezzo').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                this.getInterventoFormControlByPath('automezzoMask').setValue(getTestoAutomezzo(value.value));
                                this.getInterventoFormControlByPath('automezzo').setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.RIMORCHIO :
                this.dialogManagerService.openModaleDiSelezione(field, this.getInterventoFormControlByPath('rimorchio').value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                this.getInterventoFormControlByPath('rimorchioMask').setValue(getTestoAutomezzo(value.value));
                                this.getInterventoFormControlByPath('rimorchio').setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.OPERATORE:
                this.dialogManagerService.openModaleDiSelezione(field, this.getFormControlByIndexInFormArrayByPath('operatore', index).value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                this.getFormControlByIndexInFormArrayByPath('operatoriMask', index)
                                    .setValue(getTestoOperatore(value.value));
                                this.getFormControlByIndexInFormArrayByPath('operatore', index).setValue(
                                    mapOperatoreViewToOperatoreInterventoView(value.value, index))
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.REFERENTE:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlReferente().value,
                    this.ctrlCliente()?.value?.referenti, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value: ClienteUnitaReferente) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.referente) {
                                let referente: ReferenteView = value.referente;
                                this.ctrlReferenteMask().setValue(getTestoReferente(value.referente));
                                this.ctrlReferente().setValue(mapReferenteViewToReferenteIntervento(referente));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.UNITA_LOCALE:
                this.dialogManagerService.openModaleDiSelezione(field, this.ctrlUnitaLocale().value,
                    this.ctrlCliente()?.value?.unitaLocali, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value: ClienteUnitaReferente) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.unita) {
                                let unita: UnitaLocaleView = value.unita
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(value.unita));
                                this.ctrlUnitaLocale().setValue(mapUnitaViewToUnitaLocaleIntervento(unita));
                            }
                        }
                    })
                break;
        }
    }

    closeModal($event: any) {
        this.dialogRef.close($event);
    }

    createFormControl(value?: any) {
        return new FormControl<any | null>(value, {
            validators: [Validators.required]
        });
    }

    createFormControlWithoutValidator(value?: any) {
        return new FormControl<any | null>(value);
    }

    getFormArrayByPath(path: string): FormArray {
        return this.interventoFormGroup.controls[path] as FormArray;
    }

    addFormControlToFormArray(path: string): void {
        this.getFormArrayByPath(path).push(this.createFormControl());
    }

    addFormControlToFormArrayWithoutValidator(path: string): void {
        this.getFormArrayByPath(path).push(this.createFormControlWithoutValidator());
    }

    getFormControlByIndexInFormArrayByPath(path: string, index: number) {
        return this.getFormArrayByPath(path).at(index) as FormControl
    }

    removeFormControlToFormArray(path: string, index: number) {
        this.getFormArrayByPath(path).removeAt(index);
    }

    onConfirm($event: string) {
        console.log('$event', $event);
        console.log('interventoFormGroup', this.interventoFormGroup);
        switch ($event) {
            case DialogInterventoConfirmEnum.ODL:
                this.downloadPDF_ODL();
                break;
            case DialogInterventoConfirmEnum.FIRMA:
                this.downloadFirma();
                break;
            case DialogInterventoConfirmEnum.INVIA_MAIL_BOZZA:
                this.inviaMailBozza();
                break;
            case DialogInterventoConfirmEnum.AVVIA:
                if (this.interventoExtendedFormGroup.valid) {
                    this.fuseConfirmationService.showLoader();
                    let idIntervento = getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'id')?.value
                    this.interventiService.checkInterventoAvviabile(idIntervento).pipe(
                        tap((value) => console.log(value)),
                        switchMap(() => this.interventiService.avviaIntervento(this.getBodyAvviaInterventoDTO(), idIntervento)),
                        takeUntil(this.destroy$),
                        finalize(() => this.fuseConfirmationService.hideLoader())
                    ).subscribe({
                            next: (value) => {
                                this.onSuccessResult('Intervento avviato con successo')
                            },
                            error: (error) => {
                                this.manageErrorService.showBackendErrorMessage(error);
                            }
                        }
                    )
                } else {
                    this.interventoExtendedFormGroup.markAllAsTouched();
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Uno o più valori inseriti non sono validi',
                        type: SnackbarTypes.Error,
                    });
                }
                break;
            case DialogInterventoConfirmEnum.EVADI:
                if (this.interventoExtendedFormGroup.valid) {
                    this.fuseConfirmationService.showLoader();
                    this.interventiService.evadiInterventoForm(
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'id').value,
                        this.getBodyEvadiInterventoDTO(),
                        !!this.isAttivita ? getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'firma')?.value : undefined
                    ).pipe(
                        takeUntil(this.destroy$),
                        finalize(() => this.fuseConfirmationService.hideLoader())
                    ).subscribe({
                            next: (value) => {
                                this.onSuccessResult('Intervento evaso con successo')
                            },
                            error: (error) => {
                                this.manageErrorService.showBackendErrorMessage(error);
                            }
                        }
                    )
                } else {
                    this.interventoExtendedFormGroup.markAllAsTouched();
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Uno o più valori inseriti non sono validi',
                        type: SnackbarTypes.Error,
                    });
                }
                break;
            case DialogInterventoConfirmEnum.SPOSTA:
                this.openModalDiConfermaAzione(
                    'Spostare il seguente intervento?',
                    'free_cancellation',
                    (form, dialogRef) => this.spostaIntervento(form, dialogRef)
                ).afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                    next: (value) => {
                        if (!!value) {
                            this.onSuccessResult('Intervento spostato con successo')
                        }
                    }
                })
                break;
            case DialogInterventoConfirmEnum.ELIMINA:
                this.openModalDiConfermaAzione(
                    'Annullare il seguente intervento?',
                    'delete',
                    (form, dialogRef) => this.annullaIntervento(form, dialogRef)
                ).afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                    next: (value) => {
                        if (!!value) {
                            this.onSuccessResult('Intervento annullato con successo')
                        }
                    }
                })
                break;
            case DialogInterventoConfirmEnum.PATCH_MODIFICA:
                if (!!this.interventoFormGroup.valid) {
                    let bodyPatchInterventoDTO = this.createBodyPatchInterventoDTO();
                    const noUpdates = Object.values(bodyPatchInterventoDTO).every(el => el === undefined)
                    if (!noUpdates) {
                        this.fuseConfirmationService.showLoader();
                        this.interventiService.patchIntervento(bodyPatchInterventoDTO, this.idIntervento).pipe(
                            takeUntil(this.destroy$),
                            finalize(() => this.fuseConfirmationService.hideLoader())
                        ).subscribe({
                            next: (value) => {
                                this.onSuccessResult('Intervento modificato con successo')
                            },
                            error: (error) => {
                                this.manageErrorService.showBackendErrorMessage(error);
                            }
                        })
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Nessuna modifica effettuata',
                            type: SnackbarTypes.Warning,
                        });
                    }
                } else {
                    this.interventoFormGroup.markAllAsTouched();
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Uno o più valori inseriti non sono validi',
                        type: SnackbarTypes.Error,
                    });
                }


                break;
            case DialogInterventoConfirmEnum.CREAZIONE:
                if (!!this.interventoFormGroup.valid) {
                    const bodyAddInterventoDTO: AddInterventoDTO = this.createAddInterventoDTO()
                    console.log('AAA bodyAddInterventoDTO', bodyAddInterventoDTO);
                    this.fuseConfirmationService.showLoader();
                    this.interventiService.aggiungiIntervento(bodyAddInterventoDTO).pipe(
                        takeUntil(this.destroy$),
                        finalize(() => this.fuseConfirmationService.hideLoader())
                    ).subscribe({
                        next: (value) => {
                            this.onSuccessResult('Intervento creato con successo')
                        },
                        error: (err) => {

                            let httpStatus: number = err?.status;
                            let errorCode: number = !!err.error ? err.error.code : null;
                            if (httpStatus === 400 && errorCode === 1019) {
                                this.creaProgramma();
                            } else {
                                this.manageErrorService.showBackendErrorMessage(err);
                            }
                        }
                    })
                } else {
                    this.interventoFormGroup.markAllAsTouched();
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Uno o più valori inseriti non sono validi',
                        type: SnackbarTypes.Error,
                    });
                }
                break;
            case DialogInterventoConfirmEnum.PATCH_MODIFICA_MOVIMENTO:
                if (!!this.interventoExtendedFormGroup.valid) {
                    let bodyPatchMovimento = this.createPatchInterventoMovimentoDTO();
                    const noUpdates = Object.values(bodyPatchMovimento).every(el => el === undefined)
                    if (!noUpdates) {
                        this.fuseConfirmationService.showLoader();
                        this.interventiService.patchInterventoMovimento(bodyPatchMovimento, this.idIntervento).pipe(
                            takeUntil(this.destroy$),
                            finalize(() => this.fuseConfirmationService.hideLoader())
                        ).subscribe({
                            next: (value) => {
                                this.onSuccessResult('Movimento modificato con successo')
                            },
                            error: (error) => {
                                this.manageErrorService.showBackendErrorMessage(error);
                            }
                        })
                    } else {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Nessuna modifica effettuata',
                            type: SnackbarTypes.Warning,
                        });
                    }
                } else {
                    this.interventoExtendedFormGroup.markAllAsTouched();
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Uno o più valori inseriti non sono validi',
                        type: SnackbarTypes.Error,
                    });
                }
                break;
            case DialogInterventoConfirmEnum.CREA_EXTRA_SOSTA:
                this.openModalDiConfermaGenerazioneExtraSosta(
                    'Per la generazione occorre inserire la durata stimata',
                    'Generare l\'extra sosta?',
                    'add',
                    (form, dialogRef) =>
                        this.creaExtraSosta(form, dialogRef)
                ).afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                    next: (value) => {
                        if (!!value) {
                            this.onSuccessResult('Extra sosta generata con successo')
                        }
                    }
                })
                break;
            default:
                break;
        }
    }

    creaExtraSosta(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        if (form.valid) {
            console.log('form', form);
            this.fuseConfirmationService.showLoader();
            this.interventiService.generaExtraSostaIntervento(this.idIntervento,
                form?.get('durataStimata')?.value).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    // this.onSuccessResult('Extra sosta generata con successo')
                    dialogRef.close(true)
                },
                error: (error) => {
                    this.manageErrorService.showBackendErrorMessage(error);
                }
            })
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }

    }

    inviaMailBozza() {
        this.fuseConfirmationService.showLoader();
        this.interventiService.inviaMailBozzaIntervento(this.idIntervento).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                const message = 'Mail inviata con successo'
                this.fuseConfirmationService.openSnackBar({
                    message: message,
                    type: SnackbarTypes.Success,
                });
            },
            error: (error) => {
                this.manageErrorService.showBackendErrorMessage(error);
            }
        })
    }

    spostaIntervento(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        console.log('AAA form spostamento', form);
        this.fuseConfirmationService.showLoader();
        this.interventiService.spostaIntervento(this.idIntervento,
            !!form?.get('deleteExtraSosta')?.value).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
                next: (value) => {
                    //this.onSuccessResult('Intervento spostato con successo')
                    dialogRef.close(true);
                },
                error: (error) => {
                    this.manageErrorService.showBackendErrorMessage(error);
                }
            }
        )
    }

    annullaIntervento(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        this.fuseConfirmationService.showLoader();
        this.interventiService.anullaIntervento(this.idIntervento,
            !!form?.get('deleteExtraSosta')?.value).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
                next: (value) => {
                    //this.onSuccessResult('Intervento annullato con successo')
                    dialogRef.close(true);
                },
                error: (error) => {
                    this.manageErrorService.showBackendErrorMessage(error);
                }
            }
        )
    }

    openModalDiConfermaAzione(dialogMessage: string,
                              iconName: string,
                              action: (form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) => void) {
        const data: GenericComponentDialogConfig = {
            title: 'ATTENZIONE',
            message: dialogMessage,
            isOneColumn: true,
            icon: {
                show: true,
                name: iconName,
                color: 'primary'
            },
            formConfig: [{
                show: false,
                name: 'confirm',
                transloco: '',
                type: TypeDialogFormEnum.BOOLEAN,
            },
                {
                    show: getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'hasExtraSosta')?.value,
                    name: 'deleteExtraSosta',
                    transloco: 'L\'intervento presenta extra sosta. Annullarla insieme all\'intervento?',
                    type: TypeDialogFormEnum.BOOLEAN,
                }
            ],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        action(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    openModalDiConfermaGenerazioneExtraSosta(dialogMessage: string,
                                             subtitle: string,
                                             iconName: string,
                                             action: (form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) => void) {
        const data: GenericComponentDialogConfig = {
            title: 'ATTENZIONE',
            message: dialogMessage,
            subtitle: subtitle,
            isOneColumn: true,
            icon: {
                show: true,
                name: iconName,
                color: 'primary'
            },
            formConfig: [{
                show: true,
                value: 10,
                name: 'durataStimata',
                transloco: 'Durata stimata (minuti)',
                onlyPositiveIntegers: true,
                type: TypeDialogFormEnum.NUMBER,
                required: true,
            }],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        action(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }


    creaProgramma() {
        let data = moment(getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup,
            'giornoInizioPrevisto')?.value
        ).format('DD/MM/YYYY')
        let messageVerifica = 'Non esiste un programma per il giorno ' + data + ', vuoi crearlo?';
        this.openModalConfermaCreazioneProgramma(messageVerifica).afterClosed().pipe(
            takeUntil(this.destroy$),
        ).subscribe({
            next: (value) => {

            }
        });
    }

    openModalConfermaCreazioneProgramma(dialogMessage: string) {
        const data: GenericComponentDialogConfig = {
            title: 'ATTENZIONE',
            message: dialogMessage,
            icon: {
                show: true,
                name: 'info',
                color: 'primary'
            },
            formConfig: [{
                show: false,
                name: 'confirm',
                transloco: '',
                type: TypeDialogFormEnum.BOOLEAN,
            }],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmCreazioneProgramma(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmCreazioneProgramma(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>) {
        this.fuseConfirmationService.showLoader();
        const bodyAddInterventoDTO: AddInterventoDTO = this.createAddInterventoDTO()
        this.fuseConfirmationService.showLoader();
        this.interventiService.aggiungiIntervento(bodyAddInterventoDTO, true).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                dialogRef.close(true);
                this.onSuccessResult('Intervento creato con successo')
            },
            error: (err) => {
                this.manageErrorService.showBackendErrorMessage(err);
            }
        })
    }


    createPatchInterventoMovimentoDTO(): PatchInterventoMovimentoDTO {
        const currentValue: ɵTypedOrUntyped<InterventoToSubmit, ɵFormGroupValue<InterventoToSubmit>, any> = this.interventoExtendedFormGroup.getRawValue();
        let luogoResult;
        if (this.isPartenza) {
            luogoResult = this.initialValueExtended.luogoPartenza === currentValue.luogoPartenza ? undefined
                : currentValue.luogoPartenza
        }
        if (this.isRientro) {
            luogoResult = this.initialValueExtended.luogoDestinazione === currentValue.luogoDestinazione ? undefined
                : currentValue.luogoDestinazione
        }

        return {
            luogo: luogoResult,
            kmAutomezzo: this.initialValueExtended.kmAutomezzo === currentValue.kmAutomezzo ? undefined
                : currentValue.kmAutomezzo,
            dataOraInizioReale: this.initialValueExtended.oraInizioEffettiva === currentValue?.oraInizioEffettiva ?
                undefined : this.calcolaDataOra(
                    moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'oraInizioEffettiva')
                ),
            dataOraFineReale: this.initialValueExtended.oraFineEffettiva === currentValue?.oraFineEffettiva ?
                undefined : this.calcolaDataOra(
                    moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'oraFineEffettiva')
                ),
        }
    }

    //-----  MARK: CREAZIONE BODY PER LE RICHIESTE -------//
    createBodyPatchInterventoDTO(): PatchInterventoDTO {
        const currentValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any> = this.interventoFormGroup.getRawValue();
        return {
            isInterventoFormulario: (this.isAttivita && this.isProgrammato()) ?
                this.initialValue.isInterventoFormulario === currentValue?.isInterventoFormulario ?
                    undefined : currentValue.isInterventoFormulario : undefined,
            dataOraInizioPrevista: ((this.isAttivita || this.isPartenza) && this.isProgrammato()) ?
                this.initialValue.oraInizioPrevista === currentValue?.oraInizioPrevista ?
                    undefined : this.calcolaDataOra(
                        moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista')
                    ) : undefined,
            dataOraFinePrevista: ((this.isAttivita || this.isPartenza) && (this.isProgrammato() || this.isInCorso())) ?
                this.initialValue.oraFinePrevista === currentValue?.oraFinePrevista ?
                    undefined : this.calcolaDataOra(
                        moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista')
                    ) : undefined,
            idTipiIntervento: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                isEqual(this.initialValue.tipologiaInterventoArray, compact(currentValue.tipologiaInterventoArray)) ? undefined :
                    currentValue.tipologiaInterventoArray
                        .filter((tipoIntervento: AddebitoView) => !!tipoIntervento?.id)
                        .map((tipoIntervento, index) =>
                            tipoIntervento?.id)
                : undefined,
            costoIntervento: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                (this.initialValue.costo === currentValue.costo ? undefined : currentValue.costo)
                : undefined,
            numeroPreventivo: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                (this.initialValue.numeroPreventivo === currentValue.numeroPreventivo
                    ? undefined : currentValue.numeroPreventivo)
                : undefined,
            referenteUnico: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                (this.initialValue.referenteUnico === currentValue.referenteUnico
                    ? undefined : currentValue.referenteUnico)
                : undefined,
            referenteUnicoTel: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                (this.initialValue.referenteUnicoTel === currentValue.referenteUnicoTel
                    ? undefined : currentValue.referenteUnicoTel)
                : undefined,
            attrezzature: ((this.isAttivita) && (this.isProgrammato())) ?
                isEqual(this.initialValue.attrezzatura, compact(currentValue.attrezzatura)) ? undefined :
                    orderByOrdine(currentValue.attrezzatura)
                        .filter((attrezzatura: AttrezzaturaInterventoView) => !!attrezzatura?.attrezzatura)
                        .map((attrezzatura, index) =>
                            mapAttrezzaturaInterventoToAddAttrezzaturaIntervento(attrezzatura, index))
                : undefined,
            note: ((this.isAttivita) && (this.isProgrammato())) ?
                isEqual(this.initialValue.notaMask, compact(currentValue.notaMask)) ? undefined :
                    orderByOrdine(currentValue.notaMask).map((nota, index) => {
                        let ctrlNota = getFormArrayFromFormGroupTyped<InterventoForm>
                        (this.interventoFormGroup, 'nota').at(index) as FormControl<NotaInterventoView>;
                        return {
                            nota: nota,
                            idNotaInterventoBucket: this.checkUpdateNota(ctrlNota, nota) ?
                            (getFormArrayFromFormGroupTyped<InterventoForm>
                            (this.interventoFormGroup, 'nota').at(index)?.value as NotaInterventoView)?.notaInterventoBucket?.id
                            : undefined,
                        }
                    }).filter((item) => !!item.nota).map((_nota, index) => {
                        return {
                            ..._nota,
                            ordine: index + 1
                        } as NotaInterventoView
                    }) : undefined,
            operatori: ((this.isAttivita) && (this.isProgrammato() || this.isInCorso())) ?
                isEqual(this.initialValue.operatore, currentValue.operatore) ? undefined :
                    orderByOrdine(currentValue.operatore)
                        .filter((operatore: OperatoreInterventoView) => !!operatore.operatore)
                        .map((operatore, index) =>
                            mapOperatoreInterventoViewToAddOperatoreInterventoDTO(operatore, index))
                : undefined,
        }
    }

    createAddInterventoDTO(): AddInterventoDTO {
        const currentValue: ɵTypedOrUntyped<InterventoForm, ɵFormGroupValue<InterventoForm>, any> = this.interventoFormGroup.value;
        console.log(this.interventoFormGroup.value, 'this.interventoFormGroup.value');
        return {
            idRichiestaDaEvadere: getFormControlFromFormGroupTyped<InterventoForm>
            (this.interventoFormGroup, 'idRichiestaDaEvadere')?.value,
            isInterventoFormulario: !!this.ctrlIsInterventoFormulario()?.value,
            dataOraInizioPrevista: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'giornoInizioPrevisto')?.value
                ).format('DD/MM/YYYY'),
                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista')
            ),
            dataOraFinePrevista: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'giornoFinePrevisto')?.value
                ).format('DD/MM/YYYY'),
                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista')
            ),
            costo: currentValue?.costo,
            numeroPreventivo: currentValue?.numeroPreventivo,
            referenteUnico: currentValue?.referenteUnico,
            referenteUnicoTel: currentValue?.referenteUnicoTel,
            automezzo: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.automezzo),
            rimorchio: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.rimorchio),
            operatori: orderByOrdine(currentValue.operatore)
                .filter((operatore: OperatoreInterventoView) => !!operatore.operatore)
                .map((operatore, index) =>
                    mapOperatoreInterventoViewToAddOperatoreInterventoDTO(operatore, index)),
            //tipoIntervento: mapAddebitoViewToAddTipoInterventoDTO(currentValue.tipologiaIntervento),
            tipiIntervento: compact(currentValue.tipologiaInterventoArray)
                .map((tipoIntervento) => mapAddebitoViewToAddTipoInterventoDTO(tipoIntervento)),
            cliente: mapClienteViewToAddClienteInterventoDTO(
                currentValue.cliente,
                currentValue.unitaLocale,
                currentValue.referenteCliente
            ),
            note: orderByOrdine(currentValue.notaMask).map((nota, index) => {
                let ctrlNota = getFormArrayFromFormGroupTyped<InterventoForm>
                (this.interventoFormGroup, 'nota').at(index) as FormControl<NotaInterventoView>;
                return {
                    nota: nota,
                    idNotaInterventoBucket: this.checkUpdateNota(ctrlNota, nota) ?
                    (getFormArrayFromFormGroupTyped<InterventoForm>
                    (this.interventoFormGroup, 'nota').at(index)?.value as NotaInterventoView)?.notaInterventoBucket?.id
                    : undefined,
                }
            }).filter((item) => !!item.nota).map((_nota, index) => {
                return {
                    ..._nota,
                    ordine: index + 1
                } as NotaInterventoView
            }),
            attrezzature: orderByOrdine(currentValue.attrezzatura)
                .filter((attrezzatura: AttrezzaturaInterventoView) => !!attrezzatura?.attrezzatura)
                .map((attrezzatura, index) =>
                    mapAttrezzaturaInterventoToAddAttrezzaturaIntervento(attrezzatura, index)),
        }
    }

    getBodyEvadiInterventoDTO(): EvadiInterventoDTO {
        return {
            notaPercorrenza: (!!this.isPercorrenza || !!this.isAttivita) ?
                getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'notaPercorrenza').value : undefined,
            kmFinali: (this.isRientro) ?
                getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'kmAutomezzo').value : undefined,
            luogoDestinazione: this.isRientro ? this.ctrlLuogoDestinazione()?.value : undefined,
            sistemaOperativoDispositivo: undefined,
            versioneSistemaOperativoDispositivo: undefined,
            marcaDispositivo: undefined,
            modelloDispositivo: undefined,
            nominativoFirma: this.getInterventExtendedFormControlByPath('nominativoFirmatario')?.value,
            dataOraInizio: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'giornoInizioODL')?.value
                ).format('DD/MM/YYYY'),
                this.getInterventExtendedFormControlByPath('dataOraInizioODL')
            ),
            dataOraFine: this.calcolaDataOra(
                moment(getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
                    'giornoFineODL')?.value
                ).format('DD/MM/YYYY'),
                this.getInterventExtendedFormControlByPath('dataOraFineODL'),
            ),
            isPresentePausaPranzo: this.getInterventExtendedFormControlByPath('isPausaPranzo')?.value,
            dataOraInizioPausaPranzo: this.calcolaDataOra(
                moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                this.getInterventExtendedFormControlByPath('dataOraInizioPausaPranzo')
            ),
            dataOraFinePausaPranzo: this.calcolaDataOra(
                moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                this.getInterventExtendedFormControlByPath('dataOraFinePausaPranzo')
            ),
            isInterventoInReperibilita: this.getInterventExtendedFormControlByPath('isInterventoInReperibilita')?.value,
            dataOraRichiestaReperibilita: this.calcolaDataOra(
                moment(this.data.formValue.dataOraInizioPrevista).format('DD/MM/YYYY'),
                this.getInterventExtendedFormControlByPath('dataOraRichiestaReperibilita')
            ),
            mittenteReperibilita: this.getInterventExtendedFormControlByPath('mittenteReperibilita')?.value,
            notaOrdineDiLavoro: this.getInterventExtendedFormControlByPath('notaOrdineDiLavoro')?.value,
            notaOperatore: !!this.ctrlNotaOperatore()?.value ? this.ctrlNotaOperatore()?.value : undefined,
        }
    }

    getBodyAvviaInterventoDTO(): AvviaInterventoDTO {
        return {
            kmIniziali: (this.isPartenza) ?
                getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'kmAutomezzo').value
                : undefined,
            notaPercorrenza: (!!this.isPercorrenza || !!this.isAttivita) ?
                getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'notaPercorrenza').value
                : undefined,
            luogoPartenza: this.isPartenza ? this.ctrlLuogoPartenza()?.value : undefined,
            luogoDestinazione: this.isRientro ? this.ctrlLuogoDestinazione()?.value : undefined,
            sistemaOperativoDispositivo: undefined,
            versioneSistemaOperativoDispositivo: undefined,
            marcaDispositivo: undefined,
            modelloDispositivo: undefined,
        }
    }

    //-----  ENDMARK: CREAZIONE BODY PER LE RICHIESTE -------//
    onSuccessResult(message: string) {
        this.fuseConfirmationService.openSnackBar({
            message: message,
            type: SnackbarTypes.Success,
        });
        this.closeModal(true);
    }

    isEvasoVerificatoOrCosolidato(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.EVASO || this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.VERIFICATO
            || this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.CONSOLIDATO;
    }

    isInCorso(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.INCORSO;
    }

    isProgrammato(): boolean {
        return this.data?.formValue?.stato === InterventoProgrammaDTO.StatoEnum.PROGRAMMATO;
    }

    downloadPDF_ODL() {
        this.fuseConfirmationService.showLoader()
        this.interventiService.downloadAllegato(this.idIntervento, this.data.formValue.ordineDiLavoro.uriOdl).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                this.blobManagerService.onSuccess(value, 'Ordine_di_lavoro.pdf')
            },
            error: async (error) => {
                console.error(error);
                await this.manageErrorService.showBackendErrorMessageBLOB(error)
                //this.manageErrorService.showBackendErrorMessage(error)
            }
        })
    }

    downloadFirma() {
        this.fuseConfirmationService.showLoader()
        this.interventiService.downloadAllegato(this.idIntervento, this.data.formValue.ordineDiLavoro.uriImmagineFirmaCliente).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                this.blobManagerService.onSuccess(value, 'Firma.png')
            },
            error: async (error) => {
                //this.manageErrorService.showBackendErrorMessage(error)
                await this.manageErrorService.showBackendErrorMessageBLOB(error)
            }
        })
    }

    clienteViewToClienteWithDescrizione(cliente: ClienteView) {
        return {
            ...cliente,
            descrizione: join(compact([cliente.ragioneSociale, cliente.estensioneRagioneSociale, cliente.alias]), ' - '),
            indirizzoCompleto: join(compact([
                head(cliente.unitaLocali)?.indirizzo,
                head(cliente.unitaLocali)?.frazione,
                head(cliente.unitaLocali)?.comune,
                head(cliente.unitaLocali)?.provincia]), ' - ')
        }
    }

    checkChipLabel(tipologiaAttivita: "ATTIVITA" | "PARTENZA" | "RIENTRO" | "NOTA") {
        if (tipologiaAttivita === "ATTIVITA") {
            return 'ATTIVITÀ'
        } else {
            return tipologiaAttivita;
        }
    }

    ctrlDescrizioneDestinazione(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<IndirizzoForm>(this.destinazioneFormGroup, 'descrizione');
    }

    ctrlIndirizzoCompletoDestinazione(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<IndirizzoForm>(this.destinazioneFormGroup, 'indirizzoCompleto');
    }

    ctrlDescrizionePartenza(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<IndirizzoForm>(this.partenzaFormGroup, 'descrizione');
    }

    ctrlIndirizzoCompletoPartenza(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<IndirizzoForm>(this.partenzaFormGroup, 'indirizzoCompleto');
    }

    getLabelOperatore(index: number): string {
        if (index === 0) {
            return 'Autista '
        } else {
            return 'Operatore ' + index;
        }
    }

    ctrlKilometri(): FormControl {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'kmAutomezzo')
    }

    ctrlLuogoPartenza(): FormControl {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'luogoPartenza')
    }

    ctrlLuogoDestinazione(): FormControl {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'luogoDestinazione')
    }

    ctrlnotaPercorrenza(): FormControl {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'notaPercorrenza')
    }

    calcolaDataOra(data: string, controlOrario: FormControl) {
        if (!!data && !!controlOrario?.value) {
            let arr = controlOrario?.value?.split(':')
            let hour = +arr[0]
            let minute = +arr[1]
            return moment(data, 'DD/MM/YYYY').hours(hour).minutes(minute).toDate();
        } else {
            return undefined
        }

    }

    requestFoto() {
        if (!!this.getFoto() && this.getFoto().length > 0) {
            this.fuseConfirmationService.showLoader();
            forkJoin(
                this.getFoto().map((fotoUri) => {
                    return this.interventiService.downloadAllegato(this.idIntervento, fotoUri).pipe(
                        catchError(async (error) => {
                                await this.manageErrorService.showBackendErrorMessageBLOB(error)
                                console.error(error);
                                return of(error)
                            }
                        )
                    ) as Observable<Blob>
                })
            ).pipe(
                takeUntil(this.destroy$),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe({
                next: (value) => {
                    value.forEach((blob: Blob) => {
                        if (!!blob) {
                            let objectURL = URL.createObjectURL(blob);
                            let image = this.sanitizer.bypassSecurityTrustUrl(objectURL);
                            this.fotoArray.push(image);
                        }
                    })
                },
                error: (error) => {

                }
            })
        }
    }

    getFoto(): Array<string> {
        const listFoto = this.data?.formValue?.ordineDiLavoro?.foto?.map(
            (foto) => foto?.fotoUri);
        return listFoto;
    }

    enableByField() {
        this.interventoFormGroup.disable();
        switch (this.tipologiaIntervento) {
            case  InterventoProgrammaDTO.TipologiaEnum.ATTIVITA:
                switch (this.data?.formValue?.stato) {
                    case  InterventoView.StatoEnum.PROGRAMMATO:
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'costo').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'numeroPreventivo').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnico').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnicoTel').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'attrezzatura').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'attrezzatureMask').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'nota').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'notaMask').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatore').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatoriMask').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'isInterventoFormulario').enable();
                        this.getFormControlByIndexInFormArrayByPath('operatore', 0).disable()
                        this.getFormControlByIndexInFormArrayByPath('operatoriMask', 0).disable()
                        break;
                    case  InterventoView.StatoEnum.INCORSO:
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'costo').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'numeroPreventivo').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnico').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnicoTel').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatore').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatoriMask').enable();
                        getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'isInterventoFormulario').enable();
                        this.getFormControlByIndexInFormArrayByPath('operatore', 0).disable()
                        this.getFormControlByIndexInFormArrayByPath('operatoriMask', 0).disable()
                        break;
                }
                break;
            case  InterventoProgrammaDTO.TipologiaEnum.PARTENZA:
                switch (this.data?.formValue?.stato) {
                    case  InterventoView.StatoEnum.PROGRAMMATO:
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista').enable();
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista').enable();
                        break;
                    case  InterventoView.StatoEnum.INCORSO:
                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista').enable();
                        break;
                }
                break;
            default:
                break;
        }
        this.interventoFormGroup.updateValueAndValidity();
    }

    ngOnInit(): void {
        this.initialValue = this.interventoFormGroup.getRawValue()
        this.initialValueExtended = this.interventoExtendedFormGroup.getRawValue();
    }

    ctrlNotaOperatore(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'notaOperatore') as FormControl;
    }

    ctrlOraInizioEffettiva(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'oraInizioEffettiva') as FormControl;
    }

    ctrlOraFineEffettiva(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup, 'oraFineEffettiva') as FormControl;
    }

    ctrlGiornoInizioEffettiva(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
            'giornoInizioEffettivo') as FormControl;
    }

    ctrlGiornoFineEffettiva(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<InterventoToSubmit>(this.interventoExtendedFormGroup,
            'giornoFineEffettivo') as FormControl;
    }

    addControlsOrariEffettivi(intervento: InterventoView) {
        this.interventoExtendedFormGroup.addControl(
            'oraInizioEffettiva', new FormControl(
                !!intervento?.dataOraInizioReale ?
                    moment(intervento?.dataOraInizioReale).format('HH:mm') : undefined)
        );
        this.interventoExtendedFormGroup.addControl(
            'oraFineEffettiva', new FormControl(
                !!intervento?.dataOraFineReale ?
                    moment(intervento?.dataOraFineReale).format('HH:mm') : undefined
            )
        );
        this.interventoExtendedFormGroup.addControl(
            'giornoInizioEffettivo',
            new FormControl(
                !!intervento?.dataOraInizioReale ?
                    moment(intervento?.dataOraInizioReale).format('YYYY-MM-DD')
                    : null,
            )
        );
        this.interventoExtendedFormGroup.addControl(
            'giornoFineEffettivo',
            new FormControl(
                !!intervento?.dataOraFineReale ?
                    moment(intervento?.dataOraInizioReale).format('YYYY-MM-DD')
                    : null)
        );
    }


    scaricaFoto(fotouri: SafeUrl, index: number) {
        this.fuseConfirmationService.showLoader()
        this.interventiService.downloadAllegato(this.idIntervento, this.getFoto().at(index)).pipe(
            takeUntil(this.destroy$),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                this.blobManagerService.download(value, 'Foto_ordine_di_lavoro_' + (index + 1) + '.png')
            },
            error: async (error) => {
                console.error(error);
                await this.manageErrorService.showBackendErrorMessageBLOB(error)
            }
        })
    }

    setNullEmitterOnCliente() {
        this.getInterventoFormControlByPath('clienteMask').setValue(null);
        this.getInterventoFormControlByPath('cliente').setValue(null);
        this.getInterventoFormControlByPath('referenteCliente').setValue(null);
        this.getInterventoFormControlByPath('referenteClienteMask').setValue(null);
        this.getInterventoFormControlByPath('unitaLocale').setValue(null);
        this.getInterventoFormControlByPath('unitaLocaleMask').setValue(null);
    }

    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.el.nativeElement.contains(document.activeElement)) {
            const inputElements = this.el.nativeElement.querySelectorAll('.input-field-modal');
            const activeElement = document.activeElement as HTMLInputElement;
            let activeElementIndex = Array.from(inputElements).findIndex(input => input === activeElement);

            // Navigazione destra
            if (event.key === "ArrowDown" || event.key === "Enter") {
                do {
                    activeElementIndex = (activeElementIndex + 1) % inputElements.length;
                    const nextElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!nextElement.disabled) {
                        nextElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }

            // Navigazione sinistra
            if (event.key === "ArrowUp") {
                do {
                    activeElementIndex = (activeElementIndex - 1 + inputElements.length) % inputElements.length;
                    const previousElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!previousElement.disabled) {
                        previousElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }
        }
    }

    @HostListener('keydown.F10', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        switch (this.data.dialogType) {
            case DialogForInterventoType.CREAZIONE:
                this.onConfirm(DialogInterventoConfirmEnum.CREAZIONE);
            case DialogForInterventoType.SUBMIT:
                switch (this.data?.formValue?.stato) {
                    case  InterventoView.StatoEnum.PROGRAMMATO:
                        this.onConfirm(DialogInterventoConfirmEnum.AVVIA);
                    case  InterventoView.StatoEnum.INCORSO:
                        this.onConfirm(DialogInterventoConfirmEnum.EVADI);
                }
                this.onConfirm(DialogInterventoConfirmEnum.AVVIA);
            case DialogForInterventoType.MODIFICA:
                this.onConfirm(DialogInterventoConfirmEnum.PATCH_MODIFICA);

        }
    }

    // MARK ADDEBITO //
    ctrlAddebitiFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray') as FormArray;
    }

    ctrlAddebitiMaskFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask') as FormArray;

    }

    ctrlAddebito(index: number): FormControl {
        return this.ctrlAddebitiFormArray().at(index) as FormControl;
    }

    ctrlAddebitoMask(index: number): FormControl {
        return this.ctrlAddebitiMaskFormArray().at(index) as FormControl;
    }

    addAddebito() {
        this.addAddebitoFormControlToFormArray();
        this.addAddebitoMaskFormControlToFormArray();
    }

    addAddebitoFormControlToFormArray(): void {
        this.ctrlAddebitiFormArray().push(this.createFormControl(null));
    }

    addAddebitoMaskFormControlToFormArray(): void {
        this.ctrlAddebitiMaskFormArray().push(this.createFormControl(null));
    }

    removeAddebito(index: number) {
        this.removeAddebitoMaskFormControlFromFormArray(index);
        this.removeAddebitoFormControlFromFormArray(index);
    }

    removeAddebitoFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiFormArray().removeAt(index);
    }

    removeAddebitoMaskFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiMaskFormArray().removeAt(index);
    }

    ctrlIsInterventoFormulario(): FormControl<boolean | null> {
        return getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'isInterventoFormulario')
    }

    cambiaValoreCheckbox() {
        const currentValue = this.ctrlIsInterventoFormulario().value;
        this.ctrlIsInterventoFormulario().setValue(!currentValue);
    }

    // ENDMARK ADDEBITO //

    showIsInterventoFormulario() {
        return (!this.data?.readonly &&
                this.isAttivita &&
                (this.isProgrammato() || this.isInCorso()))
            || (this.data.dialogType === DialogForInterventoType.CREAZIONE);
    }

    checkUpdateNota(notaCtrl: FormControl<NotaInterventoView>, testoNota: string) {
        return notaCtrl?.value?.notaInterventoBucket?.descrizione === testoNota;
    }

    selectInterventoDaEvadere() {
        this.fuseConfirmationService.showLoader();
        this.interventiService.getInterventiDaEvadereForm(
            undefined,
            undefined,
            undefined,
            false,
            undefined
        ).pipe(
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe({
            next: (value) => {
                // Apertura modale di selezione e gestione dei dati di attivita da evadere
                this.dialogManagerService.openModaleDiSelezione(CampoDaSelezionare.ATTIVITA_DA_EVADERE, value)
                    .subscribe({
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result: InterventoView = value.value
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'automezzo')
                                    .setValue(result.automezzo);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'automezzoMask')
                                    .setValue(getTestoAutomezzo(result.automezzo));
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'rimorchio')
                                    .setValue(result.rimorchio);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'rimorchioMask')
                                    .setValue(getTestoAutomezzo(result.rimorchio));
                                if (!!result?.operatori && result?.operatori?.length > 0) {
                                    //Non devo sovrascrivere gli operatori già presenti e uguali
                                    orderByOrdine(result?.operatori)?.forEach((operatore: OperatoreInterventoView, index) => {
                                        if (!this.ctrlOperatore(index)) {
                                            this.addOperatore();
                                        }
                                        this.setOperatoreWithMask(operatore,
                                            this.ctrlOperatore(index),
                                            this.ctrlOperatoreMask(index));
                                    })
                                }
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup,
                                    'idRichiestaDaEvadere').setValue(
                                    result.id
                                );
                                this.setClienteWithMask(result.cliente,
                                    getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'cliente'),
                                    getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'clienteMask'));
                                if (!!result.referenteIntervento) {
                                    this.setReferenteClienteWithMask(result.referenteIntervento,
                                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteCliente'),
                                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteClienteMask'));}
                                if (!!result.unitaLocaleIntervento) {
                                    this.setUnitaLocaleWithMask(result.unitaLocaleIntervento,
                                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'unitaLocale'),
                                        getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'unitaLocaleMask'));}
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraInizioPrevista')
                                    .setValue(moment().format('HH:mm'));
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'oraFinePrevista')
                                    .setValue(moment().add(1, 'hour').format('HH:mm'));
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'costo').setValue(result.costo);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'isInterventoFormulario').setValue(result.isInterventoFormulario);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'numeroPreventivo').setValue(result.numeroPreventivo);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnico').setValue(result.referenteUnico);
                                getFormControlFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'referenteUnicoTel').setValue(result.referenteUnicoTel);
                                if (!!result?.tipiIntervento && result?.tipiIntervento?.length > 0) {
                                    orderByOrdine(result?.tipiIntervento)?.forEach((tipoIntervento, index) => {
                                        if (!getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray').at(index)) {
                                            getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray').push(this.createFormControl());
                                            getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask').push(this.createFormControl());
                                        }
                                        this.setTipologiaInterventoWithMask(tipoIntervento,
                                            getFormControlFromFormArray(getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArray'), index),
                                            getFormControlFromFormArray(getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'tipologiaInterventoArrayMask'), index)
                                        )})
                                }
                                if (!!result?.attrezzature && result?.attrezzature?.length > 0) {
                                    orderByOrdine(result?.attrezzature)?.forEach((attrezzatura, index) => {
                                        if (!getFormArrayFromFormGroupTyped(this.interventoFormGroup, 'attrezzatura').at(index)) {
                                            getFormArrayFromFormGroupTyped(this.interventoFormGroup, 'attrezzatura').push(this.createFormControl());
                                            getFormArrayFromFormGroupTyped(this.interventoFormGroup, 'attrezzatureMask').push(this.createFormControl());
                                        }
                                        this.setAttrezzaturaWithMask(attrezzatura,
                                            getFormControlFromFormArray(getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'attrezzatura'), index),
                                            getFormControlFromFormArray(getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'attrezzatureMask'), index))
                                    })
                                }
                                if (!!result?.note && result?.note?.length > 0) {
                                    orderByOrdine(result?.note)?.forEach((nota, index) => {
                                        if (!getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup,  'nota').at(index)) {
                                            getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'nota').push(this.createFormControl());
                                            getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'notaMask').push(this.createFormControl());
                                        }
                                        this.setNotaWithMask(nota,
                                            getFormControlFromFormArray(
                                                getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'nota'), index),
                                            getFormControlFromFormArray(
                                                getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'notaMask'), index)
                                        )})}}
                        },
                        error: (error) => {
                            this.manageErrorService.showBackendErrorMessage(error);
                        }
                    })
            },
            error: (error) => {
                this.manageErrorService.showBackendErrorMessage(error);
            }
        })
    }
    // MARK CONTROLLI Operatori
    ctrlOperatoriFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatore') as FormArray;
    }
    ctrlOperatoriMaskFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<InterventoForm>(this.interventoFormGroup, 'operatoriMask') as FormArray;
    }
    ctrlOperatore(index: number): FormControl {
        return this.ctrlOperatoriFormArray()?.at(index) as FormControl;
    }
    ctrlOperatoreMask(index: number): FormControl {
        return this.ctrlOperatoriMaskFormArray()?.at(index) as FormControl;
    }
    addOperatore() {
        this.addOperatoreFormControlToFormArray();
        this.addOperatoreMaskFormControlToFormArray()
    }
    removeOperatore(index: number) {
        this.removeOperatoreFormControlFromFormArray(index);
        this.removeOperatoreMaskFormControlFromFormArray(index);
    }
    addOperatoreFormControlToFormArray(): void {
        this.ctrlOperatoriFormArray()
            ?.push(this.createFormControl());
    }
    removeOperatoreFormControlFromFormArray(index: number): void {
        this.ctrlOperatoriFormArray()?.removeAt(index);
    }
    addOperatoreMaskFormControlToFormArray(): void {
        this.ctrlOperatoriMaskFormArray()?.push(this.createFormControl());
    }
    removeOperatoreMaskFormControlFromFormArray(index: number): void {
        this.ctrlOperatoriMaskFormArray()?.removeAt(index);
    }
    setOperatoreWithMask(operatore: OperatoreInterventoView, formControl: FormControl, formControlMask: FormControl) {
        const result = get(operatore.operatore, 'nomeCognome', null);
        formControlMask?.setValue(result);
        formControl?.setValue(operatore);
    }
    // ENDMARK CONTROLLI Operatori
    // MARK CONTROLLI Cliente
    setClienteWithMask(cliente: ClienteView, formControl: FormControl, formControlMask: FormControl) {
        const result = join(compact([cliente?.ragioneSociale, cliente?.estensioneRagioneSociale, cliente?.alias]), ' - ')
        formControlMask.setValue(result);
        formControl.setValue(cliente);
    }
    setReferenteClienteWithMask(referente: ReferenteIntervento, formControl: FormControl, formControlMask: FormControl) {
        formControlMask.setValue(getTestoReferente(referente));
        formControl.setValue(referente);
    }
    setUnitaLocaleWithMask(unitaLocale: UnitaLocaleIntervento, formControl: FormControl, formControlMask: FormControl) {
        formControlMask.setValue(getTestoUnitaLocale(unitaLocale));
        formControl.setValue(unitaLocale);
    }
    setTipologiaInterventoWithMask(tipologiaIntervento: AddebitoView, formControl: FormControl, formControlMask: FormControl) {
        const result = get(tipologiaIntervento, 'descrizione', null);
        formControlMask.setValue(result);
        formControl.setValue(tipologiaIntervento);
    }
    setAttrezzaturaWithMask(attrezzatura: AttrezzaturaInterventoView, formControl: FormControl, formControlMask: FormControl) {
        const result = get(attrezzatura.attrezzatura, 'descrizione', null);
        formControlMask.setValue(result);
        formControl.setValue(attrezzatura);
    }
    setNotaWithMask(nota: NotaInterventoView, formControl: FormControl, formControlMask: FormControl) {
        formControlMask.setValue(getTestoNota(nota.notaInterventoBucket));
        formControl.setValue(nota);
    }
}

export interface InterventoForm {
    id?: FormControl<string | null>;
    idOrdineLavoro?: FormControl<any | null>;
    data?: FormControl<any | null>;
    giornoInizioPrevisto?: FormControl<any | null>;
    giornoFinePrevisto?: FormControl<any | null>;
    oraInizioPrevista?: FormControl<string | null>;
    oraFinePrevista?: FormControl<string | null>;
    stato?: FormControl<InterventoProgrammaDTO.StatoEnum | null>;
    descrizione?: FormControl<string | null>;
    costo?: FormControl<number | null>;
    cliente?: FormControl<ClienteView | null>;
    automezzo?: FormControl<AutomezzoView | null>;
    attrezzatura?: FormArray<FormControl<AttrezzaturaInterventoView | null>>;
    operatore?: FormArray<FormControl<OperatoreInterventoView | null>>;
    nota?: FormArray<FormControl<NotaInterventoView | null>>;
    rimorchio?: FormControl<AutomezzoView | null>;
    //--- MASK
    automezzoMask?: FormControl<string | null>;
    rimorchioMask?: FormControl<string | null>;
    operatoriMask?: FormArray;
    attrezzatureMask?: FormArray;
    clienteMask?: FormControl<string | null>;
    notaMask?: FormArray;
    durata?: FormControl<number | null>;
    referenteCliente?: FormControl<ReferenteIntervento | null>;
    referenteClienteMask?: FormControl<string | null>;
    unitaLocale?: FormControl<UnitaLocaleIntervento | null>;
    unitaLocaleMask?: FormControl<string | null>;
    numeroPreventivo?: FormControl<string | null>;
    referenteUnico?: FormControl<string | null>;
    referenteUnicoTel?: FormControl<string | null>;
    tipologiaInterventoArray?: FormArray<FormControl<AddebitoView | null>>;
    tipologiaInterventoArrayMask?: FormArray<FormControl<string | null>>;
    isInterventoFormulario: FormControl<boolean | null>;
    hasExtraSosta?: FormControl<boolean | null>;
    idRichiestaDaEvadere?: FormControl<string | null>;
}

export interface IndirizzoForm {
    descrizione: FormControl<string | null>;
    indirizzo: FormControl<string | null>;
    numero: FormControl<string | null>;
    frazione: FormControl<string | null>;
    comune: FormControl<string | null>;
    cap: FormControl<string | null>;
    provincia: FormControl<string | null>;
    indirizzoCompleto: FormControl<string | null>;
}

export interface InterventoToSubmit {
    isInterventoInReperibilita?: FormControl<boolean | null>;
    dataOraRichiestaReperibilita?: FormControl<string | null>;
    mittenteReperibilita?: FormControl<string | null>;
    isPausaPranzo?: FormControl<boolean | null>;
    dataOraInizioPausaPranzo?: FormControl<string | null>;
    dataOraFinePausaPranzo?: FormControl<string | null>;
    //---- IN CORSO ---- //
    oraInizioEffettiva?: FormControl<string | null>;
    //---- EVASO, CONSOLIDATO, VERIFICATO ---//
    oraFineEffettiva?: FormControl<string | null>;
    nominativoFirmatario?: FormControl<string | null>;
    giornoInizioODL?: FormControl<string | null>;
    giornoFineODL?: FormControl<string | null>;
    giornoInizioEffettivo?: FormControl<string | null>;
    giornoFineEffettivo?: FormControl<string | null>;
    dataOraInizioODL?: FormControl<string | null>;
    dataOraFineODL?: FormControl<string | null>;
    durata?: FormControl<number | null>;
    firma?: FormControl<any | null>;
    notaOrdineDiLavoro?: FormControl<string | null>;
    foto?: FormArray;
    isEvasoByDashBoard?: FormControl<boolean | null>;
    nominativoOperatoreDashboard?: FormControl<string | null>;
    ODL_PDF?: FormControl<any | null>;
    notaPercorrenza?: FormControl<string | null>;
    kmAutomezzo?: FormControl<number | null>;
    luogoPartenza?: FormControl<string | null>;
    luogoDestinazione?: FormControl<string | null>;
    notaOperatore?: FormControl<string | null>;
}

export enum CampoDaSelezionare {
    CLIENTE = 'CLIENTE',
    TIPOLOGIA_INTERVENTO = 'TIPOLOGIA_INTERVENTO',
    AUTOMEZZO = 'AUTOMEZZO',
    ATTREZZATURA = 'ATTREZZATURA',
    OPERATORE = 'OPERATORE',
    NOTA = 'NOTA',
    RIMORCHIO = 'RIMORCHIO',
    REFERENTE = 'REFERENTE',
    UNITA_LOCALE = 'UNITA_LOCALE',
    ATTIVITA_DA_EVADERE = 'ATTIVITA_DA_EVADERE',
    ATTIVITA_DA_EVADERE_READONLY = 'ATTIVITA_DA_EVADERE_READONLY',
    UTENTE = 'UTENTE',
    OPERATORE_REGISTRO = 'OPERATORE_REGISTRO',
    AUTISTA = 'AUTISTA'
}
