<!----------------- MARK: FORM PER GLI INTERVENTI CHE NON SONO ATTIVITA' ---------------->
<div class="px-2 flex flex-row justify-between w-full">
    <!-- KILOMETRAGGIO per PARTENZE RIENTRI-->
    <div *ngIf="!isPercorrenza" class="flex flex-col gap-5" [style.width.%]="45">
        <div class="container">
            <div class="line">
                <mat-divider></mat-divider>
            </div>
            <div class="text mat-typography">Chilometraggio</div>
            <div class="line">
                <mat-divider></mat-divider>
            </div>
        </div>

        <ng-container *ngIf="!!isPartenza">
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Chilometri iniziali'"
                                            [control]="ctrlKilometri"
                                            [type]="'number'"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isProgrammato && !isModificabile"
                    ></app-generic-form-field>
                </div>

                <div [style.width.%]="45" *ngIf="!!isPartenza">
                    <app-generic-form-field [label]="'Luogo partenza'"
                                            [control]="ctrlLuogoPartenza"
                                            [type]="'text'"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isProgrammato && !isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Giorno Inizio Effettivo'"
                                            [type]="'date'"
                                            [control]="ctrlGiornoInizioEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
                <div [style.width.%]="45">
                    <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato"
                                            [label]="'Giorno Fine Effettiva'"
                                            [type]="'date'"
                                            [control]="ctrlGiornoFineEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Inizio Effettivo'"
                                            [type]="'time'"
                                            [control]="ctrlOraInizioEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
                <div [style.width.%]="45">
                    <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato"
                                            [label]="'Fine Effettiva'"
                                            [type]="'time'"
                                            [control]="ctrlOraFineEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
        </ng-container>

        <ng-container *ngIf="!!isRientro">
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45" *ngIf="(isInCorso && apertoPerEvadereAvviare) || isEvasoVerificatoOrCosolidato">
                    <app-generic-form-field
                        [label]="'Chilometri finali'"
                        [control]="ctrlKilometri"
                        [errorRequiredMessage]="errorRequiredMessage"
                        [readonly]="!isInCorso && !isModificabile"
                    ></app-generic-form-field>
                </div>

                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Luogo destinazione'"
                                            [control]="ctrlLuogoDestinazione"
                                            [type]="'text'"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!(isInCorso || isProgrammato) && !isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Giorno Inizio Effettivo'"
                                            [type]="'date'"
                                            [control]="ctrlGiornoInizioEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
                <div [style.width.%]="45">
                    <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato"
                                            [label]="'Giorno Fine Effettiva'"
                                            [type]="'date'"
                                            [control]="ctrlGiornoFineEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
            <div class="flex flex-row w-full justify-between">
                <div [style.width.%]="45">
                    <app-generic-form-field [label]="'Inizio Effettivo'"
                                            [type]="'time'"
                                            [control]="ctrlOraInizioEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
                <div [style.width.%]="45">
                    <app-generic-form-field *ngIf="isEvasoVerificatoOrCosolidato"
                                            [label]="'Fine Effettiva'"
                                            [type]="'time'"
                                            [control]="ctrlOraFineEffettiva"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isModificabile"
                    ></app-generic-form-field>
                </div>
            </div>
        </ng-container>

    </div>

    <!-- ANNOTAZIONI PERCORRENZE-->
    <ng-container *ngIf="(!!isPercorrenza && !!ctrlnotaPercorrenza?.value)
    || ((isInCorso && apertoPerEvadereAvviare) || isEvasoVerificatoOrCosolidato)">
        <div *ngIf="isInCorso || isEvasoVerificatoOrCosolidato"
             class="flex flex-col gap-5" [style.width.%]="45">
            <div class="container">
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
                <div class="text mat-typography">Note</div>
                <div class="line">
                    <mat-divider></mat-divider>
                </div>
            </div>

            <ng-container *ngIf="!!isPercorrenza">
                <div [style.width.%]="100" *ngIf="!!ctrlnotaPercorrenza?.value">
                    <app-generic-form-field [label]="'Nota Percorrenza'"
                                            [control]="ctrlnotaPercorrenza"
                                            [type]="'text'"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="true"
                    ></app-generic-form-field>
                </div>
            </ng-container>

            <ng-container *ngIf="(isInCorso && apertoPerEvadereAvviare) || isEvasoVerificatoOrCosolidato">
                <div [style.width.%]="100">
                    <app-generic-form-field [label]="'Nota Operatore'"
                                            [control]="ctrlNotaOperatore"
                                            [type]="'text'"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="!isInCorso"
                    ></app-generic-form-field>
                </div>
            </ng-container>
        </div>
    </ng-container>
</div>
<!----------------- ENDMARK: FORM PER GLI INTERVENTI CHE NON SONO ATTIVITA' ---------------->


