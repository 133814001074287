/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { AddInterventoFormularioDTO } from '../model/addInterventoFormularioDTO';
import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { InterventoFormularioView } from '../model/interventoFormularioView';
import { PatchInterventoFormularioDTO } from '../model/patchInterventoFormularioDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class InterventiFormularioService {

    protected basePath = 'https://api-dev-gestioneinterventi.southengineering.it/gestione-interventi';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consente di aggiungere un intervento formulario
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public aggiungiIntervento1(body: AddInterventoFormularioDTO, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public aggiungiIntervento1(body: AddInterventoFormularioDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public aggiungiIntervento1(body: AddInterventoFormularioDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public aggiungiIntervento1(body: AddInterventoFormularioDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling aggiungiIntervento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('post',`${this.basePath}/api/v1/interventi-formulario/aggiungi`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di annullare il consolidamento di un intervento.
     * 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullaConsolidamentoIntervento1(idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public annullaConsolidamentoIntervento1(idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public annullaConsolidamentoIntervento1(idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public annullaConsolidamentoIntervento1(idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling annullaConsolidamentoIntervento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}/annulla-consolidamento`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di annullare la verifica di un intervento.
     * 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public annullaVerificaIntervento1(idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public annullaVerificaIntervento1(idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public annullaVerificaIntervento1(idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public annullaVerificaIntervento1(idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling annullaVerificaIntervento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}/annulla-verifica`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di settare un intervento verificato come consolidato.
     * 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public consolidaIntervento1(idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public consolidaIntervento1(idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public consolidaIntervento1(idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public consolidaIntervento1(idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling consolidaIntervento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}/consolida`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di rimuovere un intervento formulario.
     * 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteInterventoFormulario(idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public deleteInterventoFormulario(idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public deleteInterventoFormulario(idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public deleteInterventoFormulario(idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling deleteInterventoFormulario.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('delete',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce gli Interventi Formulario per giorno.
     * Il formato dei filtri di tipo LocalDate è &#x27;dd/MM/yyyy&#x27;. 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getInterventiFormularioForm(data?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<InterventoFormularioView>>;
    public getInterventiFormularioForm(data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<InterventoFormularioView>>>;
    public getInterventiFormularioForm(data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<InterventoFormularioView>>>;
    public getInterventiFormularioForm(data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<Array<InterventoFormularioView>>('post',`${this.basePath}/api/v1/interventi-formulario`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di modificare un intervento formulario.
     * 
     * @param body 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchInterventoFormulario(body: PatchInterventoFormularioDTO, idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public patchInterventoFormulario(body: PatchInterventoFormularioDTO, idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public patchInterventoFormulario(body: PatchInterventoFormularioDTO, idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public patchInterventoFormulario(body: PatchInterventoFormularioDTO, idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (body === null || body === undefined) {
            throw new Error('Required parameter body was null or undefined when calling patchInterventoFormulario.');
        }

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling patchInterventoFormulario.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<GenericResponseDTO>('patch',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Permette di settare un intervento formulario come verificato.
     * 
     * @param idInterventoFormulario 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public verificaIntervento1(idInterventoFormulario: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public verificaIntervento1(idInterventoFormulario: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public verificaIntervento1(idInterventoFormulario: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public verificaIntervento1(idInterventoFormulario: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (idInterventoFormulario === null || idInterventoFormulario === undefined) {
            throw new Error('Required parameter idInterventoFormulario was null or undefined when calling verificaIntervento1.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/interventi-formulario/${encodeURIComponent(String(idInterventoFormulario))}/verifica`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
