import {Pipe, PipeTransform} from '@angular/core';
import {
    AutomezzoView,
    InterventoFormularioView,
    InterventoProgrammaDTO,
    JobStatus,
    ProgrammaDTO
} from "../../../api-clients/generated/services";
import {
    getColorByAutomezzoViewIntStatoEnum,
    getColorByInterventoFormularioServizioEnum,
    getColorByInterventoFormularioStatoEnum,
    getColorByInterventoProgrammaDTOStatoEnum,
    getColorByJobStatus,
    getColorByStatoProgramma,
    getColorByStatusUser,
    getColorByTipologiaIntervento
} from "../utils/style-utils";
import {statusUser} from "../utils/table-configurations/configurazione-tabella-utenti";
import {InterventoFormularioServizioEnum} from "../utils/table-configurations/configurazione-interventi-validazione";

@Pipe({
    name: 'statusEnumToColorTheme'
})
export class StatusEnumToColorThemePipe implements PipeTransform {

    transform(value: InterventoProgrammaDTO.StatoEnum | AutomezzoView.IntStatoEnum
                  | JobStatus | statusUser | InterventoProgrammaDTO.TipologiaEnum | ProgrammaDTO.StatoEnum
                  | InterventoFormularioView.StatoEnum | InterventoFormularioServizioEnum
        , valueEnum: any): string {
        switch (valueEnum) {
            case InterventoProgrammaDTO.StatoEnum:
                return getColorByInterventoProgrammaDTOStatoEnum(value as InterventoProgrammaDTO.StatoEnum);
            case AutomezzoView.IntStatoEnum:
                return getColorByAutomezzoViewIntStatoEnum(value as AutomezzoView.IntStatoEnum);
            case JobStatus:
                return getColorByJobStatus(value as JobStatus);
            case statusUser:
                return getColorByStatusUser(value as statusUser);
            case  InterventoProgrammaDTO.TipologiaEnum:
                return getColorByTipologiaIntervento(value as InterventoProgrammaDTO.TipologiaEnum);
            case  ProgrammaDTO.StatoEnum:
                return getColorByStatoProgramma(value as ProgrammaDTO.StatoEnum);
            case InterventoFormularioView.StatoEnum:
                return getColorByInterventoFormularioStatoEnum(value as InterventoFormularioView.StatoEnum);
            case InterventoFormularioServizioEnum:
                return getColorByInterventoFormularioServizioEnum(value as InterventoFormularioServizioEnum);
        }
    }

}
