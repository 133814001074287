/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { OperatoreView } from './operatoreView';
import { UserView } from './userView';

export interface OperatoreInterventoView { 
    id?: string;
    ordine?: number;
    operatore?: OperatoreView;
    user?: UserView;
    isAutista?: boolean;
}