import {get} from "lodash";
import {compact, head, join} from "lodash-es";
import {
    AddebitoView,
    AttrezzaturaProgrammaDTO, AttrezzaturaView,
    AutomezzoProgrammaDTO,
    AutomezzoView, ClienteProgrammaDTO,
    ClienteView,
    InterventoProgrammaDTO,
    NotaInterventoBucketView,
    NotaInterventoView,
    NotaProgrammaDTO, OperatoreProgrammaDTO,
    OperatoreView, ReferenteIntervento, ReferenteProgrammaDTO,
    ReferenteView,
    TipoInterventoProgrammaDTO, UnitaLocaleIntervento, UnitaLocaleProgrammaDTO,
    UnitaLocaleView
} from "../../../api-clients/generated/services";


export function getTestoAttrezzatura(attrezzatura: AttrezzaturaProgrammaDTO) {
    return get(attrezzatura, 'descrizione', null);
}

export function mapAttrezzaturaViewToAttrezzaturaProgrammaDTO(attrezzatura: AttrezzaturaView, index: number): Partial<AttrezzaturaProgrammaDTO> {
    return {
        idAttrezzatura: attrezzatura?.id,
        descrizione: attrezzatura?.descrizione,
        ordine: (index + 1)
    }
}

export function mapAddebitoViewToTipoInterventoProgrammaDTO(addebito: AddebitoView): TipoInterventoProgrammaDTO {
    return {
        descrizione: addebito?.descrizione,
        prezzo: addebito?.prezzo,
        id: addebito?.id
    }
}

export function getTestoNota(nota: NotaInterventoBucketView) {
    if(!!nota) {
        return nota.descrizione
    } else {
        return null
    }
}

export function getTestoAutomezzo(automezzo: AutomezzoProgrammaDTO): string {
    if (!!automezzo) {
        return join((compact([automezzo.riferimento, automezzo.targa])), ' - ');
    } else {
        return null;
    }
}

export function mapAutomezzoViewToAutomezzoProgrammaDTO(automezzoView?: AutomezzoView): AutomezzoProgrammaDTO {
    return {
        id: automezzoView?.id,
        riferimento: automezzoView?.intRiferimento,
        targa: automezzoView?.targa,
        descrizione: automezzoView?.descrizione
    }
}

export function getTestoCliente(cliente: ClienteView): string {
    if (!!cliente) {
        return join(compact([cliente?.ragioneSociale, cliente?.estensioneRagioneSociale, cliente?.alias]), ' - ')
    } else {
        return null;
    }
}

export function mapClienteViewToClienteProgrammaDTO(cliente: ClienteView): Partial<ClienteProgrammaDTO> {
    return {
        id: cliente?.id,
        ragioneSociale: cliente?.ragioneSociale,
        estensioneRagioneSociale: cliente?.estensioneRagioneSociale,
        alias: cliente?.alias,
        codiceFiscale: cliente?.codiceFiscale,
        partitaIva: cliente?.partitaIva,
        unitaLocale: !!cliente ?
            mapUnitaLocaleViewToUnitaLocaleProgrammaDTO(cliente?.unitaLocali?.find((unita) => !!unita?.isPrincipale))
        : undefined,
        referente: !!cliente ?
            mapReferenteViewToReferenteProgrammaDTO(cliente?.referenti?.find((ref) => !!ref?.isPrincipale))
        : undefined,
    }
}

export function mapUnitaLocaleViewToUnitaLocaleProgrammaDTO(unita: UnitaLocaleView): Partial<UnitaLocaleProgrammaDTO> {
    return {
        idUnitaLocale: unita?.id,
        indirizzo: unita?.indirizzo,
        numero: unita?.numero,
        frazione: unita?.frazione,
        comune: unita?.comune,
        cap: unita?.cap,
        provincia: unita?.provincia,
    }
}

export function mapUnitaLocaleInterventoToUnitaLocaleProgrammaDTO(unita: UnitaLocaleIntervento): Partial<UnitaLocaleProgrammaDTO> {
    return {
        idUnitaLocale: unita?.idUnitaLocale,
        indirizzo: unita?.indirizzo,
        numero: unita?.numero,
        frazione: unita?.frazione,
        comune: unita?.comune,
        cap: unita?.cap,
        provincia: unita?.provincia,
    }
}

export function mapReferenteViewToReferenteProgrammaDTO(referente: ReferenteView): Partial<ReferenteProgrammaDTO> {
    return {
        idReferente: referente?.id,
        nominativo: referente?.nominativo,
        email: referente?.email,
        telefono: referente?.telefono,
    }
}

export function mapReferenteIntervnetoToReferenteProgrammaDTO(referente: ReferenteIntervento): Partial<ReferenteProgrammaDTO> {
    return {
        idReferente: referente?.idReferente,
        nominativo: referente?.nominativo,
        email: referente?.email,
        telefono: referente?.telefono,
    }
}


export function getTestoOperatore(operatore: OperatoreProgrammaDTO) {
    if (!!operatore) {
       return operatore.nomeCognome
    } else {
        return null;
    }
}

export function mapOperatoreViewToOperatoreProgrammaDTO(operatoreView: OperatoreView, index: number): Partial<OperatoreProgrammaDTO> {
    return {
        idOperatore: operatoreView?.id,
        idUtente: operatoreView?.utente?.id,
        nome: operatoreView?.utente?.nome,
        cognome: operatoreView?.utente?.cognome,
        nomeCognome: operatoreView?.nomeCognome,
        ordine: (index + 1)
    }
}

export function mapNotaInterventoBucketViewToNotaProgrammaDTO(notaInterventoBucketView: NotaInterventoBucketView, index: number):
Partial<NotaProgrammaDTO> {
    return {
        idNotaInterventoBucket: notaInterventoBucketView?.id,
        nota: notaInterventoBucketView?.descrizione,
        ordine: index + 1,
    }
}

export function getTestoTipoIntervento(tipologiaIntervento: TipoInterventoProgrammaDTO) {
    return get(tipologiaIntervento, 'descrizione', null);
}

export function getTestoUnitaLocale(unitalocale: UnitaLocaleView) {
    if(!!unitalocale) {
        return join(compact([unitalocale?.indirizzo, unitalocale?.numero,
        unitalocale?.frazione, unitalocale?.comune, unitalocale?.provincia]), ' - ');
    } else {
        return null;
    }
}

export function getTestoReferente(referente: ReferenteView) {
    if(!!referente) {
        return join(compact([referente?.nominativo, referente?.email,
            referente?.telefono]), ' - ');
    } else {
        return null
    }
}


export function getNotaGenericaFromInterventoDiTipoNota(intervento: InterventoProgrammaDTO): string {
    return getTestoNotaFromNotaProgrammaDTO(head(intervento.note))
}

export function getTestoNotaFromNotaProgrammaDTO (notaProgramma: NotaProgrammaDTO): string {
    return notaProgramma?.nota;
}
