/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RigaProgrammaDTO } from './rigaProgrammaDTO';

export interface ProgrammaDTO { 
    righe?: Array<RigaProgrammaDTO>;
    id?: string;
    data?: string;
    promemoria?: string;
    stato?: ProgrammaDTO.StatoEnum;
}
export namespace ProgrammaDTO {
    export type StatoEnum = 'BOZZA' | 'COMUNICATO' | 'MODIFICATO' | 'IN_CORSO' | 'EVASO' | 'VERIFICATO' | 'CONSOLIDATO';
    export const StatoEnum = {
        BOZZA: 'BOZZA' as StatoEnum,
        COMUNICATO: 'COMUNICATO' as StatoEnum,
        MODIFICATO: 'MODIFICATO' as StatoEnum,
        INCORSO: 'IN_CORSO' as StatoEnum,
        EVASO: 'EVASO' as StatoEnum,
        VERIFICATO: 'VERIFICATO' as StatoEnum,
        CONSOLIDATO: 'CONSOLIDATO' as StatoEnum
    };
}