/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AnagraficheHomeStats { 
    clientiTotali?: number;
    clientiCancellati?: number;
    clientiBloccati?: number;
    automezziTotali?: number;
    automezziCancellati?: number;
    rimorchiTotali?: number;
    rimorchiCancellati?: number;
    autistiTotali?: number;
    autistiCancellati?: number;
    operatoriTotali?: number;
    operatoriCancellati?: number;
}