/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddReferenteDTO } from './addReferenteDTO';
import { AddUnitaLocaleDTO } from './addUnitaLocaleDTO';

export interface AddClienteDTO { 
    ragioneSociale?: string;
    codiceFiscale?: string;
    partitaIva?: string;
    telefono?: string;
    appunti?: string;
    indirizzo?: string;
    numero?: string;
    frazione?: string;
    comune?: string;
    cap?: string;
    provincia?: string;
    unitaLocali?: Array<AddUnitaLocaleDTO>;
    referenti?: Array<AddReferenteDTO>;
}