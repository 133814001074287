/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface OperatoriFilterForm { 
    /**
     * nominativo
     */
    nominativo?: string;
    /**
     * tipologia
     */
    tipologia?: OperatoriFilterForm.TipologiaEnum;
    /**
     * nascosto
     */
    nascosto?: boolean;
    /**
     * isCancellato
     */
    isCancellato?: boolean;
}
export namespace OperatoriFilterForm {
    export type TipologiaEnum = 'AUTISTA' | 'OPERATORE';
    export const TipologiaEnum = {
        AUTISTA: 'AUTISTA' as TipologiaEnum,
        OPERATORE: 'OPERATORE' as TipologiaEnum
    };
}