import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {compact, join} from "lodash-es";
import {AttivitaDaEvadere} from "../../interfaces/mock-interfaces/richiesta-da-evadere-mock";
import {InterventoView} from "../../../../api-clients/generated/services";
import {getTestoTipoIntervento} from "../mask-functions";

export function createTableConfigurationForRichiesteDaEvadere(inputList: Array<InterventoView>,
                                                              length: number,
                                                              pageIndex: number,
                                                              pageSize: number,
                                                              isSelection?: boolean): GenericTableConfigurationModel {
    let list = inputList.map((element) => {
        return {
            ...element,
            descrizioneIntervento: join(element.tipiIntervento.map((inter) =>
                getTestoTipoIntervento(inter)), '\n'),
            descrizioneCliente: join(compact([element?.cliente?.ragioneSociale,
                element?.cliente?.estensioneRagioneSociale, element?.cliente?.alias]), ' - ')
        }
    });
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: length,
            isPaginatedBE: true,
            sticky: true,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Descrizione',
                    colonnaKey: 'descrizioneIntervento',
                    flex: 40,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Cliente',
                    colonnaKey: 'descrizioneCliente',
                    flex: 40,
                },
                {
                    nomeColonna: 'Dettagli',
                    colonnaKey: 'dettagli',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'description',
                        click: TipoClickEnum.SHOW,
                        tooltip: 'Descrizione',
                        color: 'accent'
                    }],
                },
                {
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    hideColonna: !isSelection,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            pageIndex: pageIndex,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}
