/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddCalendarioDTOOraInizio } from './addCalendarioDTOOraInizio';

export interface GiornoSettimana { 
    id?: string;
    giorno?: GiornoSettimana.GiornoEnum;
    oraInizio?: AddCalendarioDTOOraInizio;
    oraFine?: AddCalendarioDTOOraInizio;
}
export namespace GiornoSettimana {
    export type GiornoEnum = 'LUN' | 'MAR' | 'MER' | 'GIO' | 'VEN' | 'SAB' | 'DOM';
    export const GiornoEnum = {
        LUN: 'LUN' as GiornoEnum,
        MAR: 'MAR' as GiornoEnum,
        MER: 'MER' as GiornoEnum,
        GIO: 'GIO' as GiornoEnum,
        VEN: 'VEN' as GiornoEnum,
        SAB: 'SAB' as GiornoEnum,
        DOM: 'DOM' as GiornoEnum
    };
}