export enum DialogInterventoConfirmEnum {
    ODL = 'ODL',
    FIRMA = 'FIRMA',
    PHOTO = 'PHOTO',
    AVVIA = 'AVVIA',
    EVADI = 'EVADI',
    PATCH_MODIFICA = 'PATCH_MODIFICA',
    ELIMINA = 'ELIMINA',
    SPOSTA = 'SPOSTA',
    CREAZIONE = 'CREAZIONE',
    PATCH_MODIFICA_EVASO = 'PATCH_MODIFICA_EVASO',
    PATCH_MODIFICA_MOVIMENTO = 'PATCH_MODIFICA_MOVIMENTO',
    INVIA_MAIL_BOZZA = 'INVIA_MAIL_BOZZA',
    CREA_EXTRA_SOSTA = 'CREA_EXTRA_SOSTA',
}

export enum DialogForInterventoType {
    VISUALIZZA = 'VISUALIZZA',
    MODIFICA = 'MODIFICA',
    SUBMIT = 'SUBMIT',
    CREAZIONE = 'CREAZIONE',
    MODIFICA_VERIFICATO = 'MODIFICA_VERIFICATO',
    MODIFICA_MOVIMENTO = 'MODIFICA_MOVIMENTO',
    MODIFICA_DA_EVADERE = 'MODIFICA_DA_EVADERE',
}
