/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddCalendarioDTOOraInizio } from './addCalendarioDTOOraInizio';

export interface GiornoMese { 
    id?: string;
    giornoDelMese?: number;
    numeroSettimana?: GiornoMese.NumeroSettimanaEnum;
    giornoSettimana?: GiornoMese.GiornoSettimanaEnum;
    oraInizio?: AddCalendarioDTOOraInizio;
    oraFine?: AddCalendarioDTOOraInizio;
}
export namespace GiornoMese {
    export type NumeroSettimanaEnum = 'PRIMA' | 'SECONDA' | 'TERZA' | 'QUARTA' | 'ULTIMA';
    export const NumeroSettimanaEnum = {
        PRIMA: 'PRIMA' as NumeroSettimanaEnum,
        SECONDA: 'SECONDA' as NumeroSettimanaEnum,
        TERZA: 'TERZA' as NumeroSettimanaEnum,
        QUARTA: 'QUARTA' as NumeroSettimanaEnum,
        ULTIMA: 'ULTIMA' as NumeroSettimanaEnum
    };
    export type GiornoSettimanaEnum = 'LUN' | 'MAR' | 'MER' | 'GIO' | 'VEN' | 'SAB' | 'DOM';
    export const GiornoSettimanaEnum = {
        LUN: 'LUN' as GiornoSettimanaEnum,
        MAR: 'MAR' as GiornoSettimanaEnum,
        MER: 'MER' as GiornoSettimanaEnum,
        GIO: 'GIO' as GiornoSettimanaEnum,
        VEN: 'VEN' as GiornoSettimanaEnum,
        SAB: 'SAB' as GiornoSettimanaEnum,
        DOM: 'DOM' as GiornoSettimanaEnum
    };
}