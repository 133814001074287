 <mat-form-field class="w-full" appearance="outline">
        <mat-label>{{label}}</mat-label>
        <input #inputFile hidden type="file" (change)="onFileSelected($event)" placeholder="Carica file"
               [accept]="acceptedFileList">
        <input
            matInput
            readonly
            (click)="open(inputFile)"
            [value]="nameFile"
            [formControl]="form"
        >
        <button
            *ngIf="!form.disabled"
            mat-icon-button
            type="button"
            (click)="open(inputFile)"
            matSuffix>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'mat_outline:file_upload'"></mat-icon>
        </button>
       <!-- *ngIf="form?.value"-->
        <button
            *ngIf="false"
            mat-icon-button
            type="button"
            (click)="download()"
            matSuffix>
            <mat-icon
                class="icon-size-5"
                [svgIcon]="'mat_outline:download'"></mat-icon>
        </button>
        <!--"!form.disabled && form?.value"-->
        <button
            *ngIf="false"
            mat-icon-button
            type="button"
            (click)="delete()"
            matSuffix>
            <mat-icon
                class="icon-size-5 text-warn"
                [svgIcon]="'mat_outline:delete'"></mat-icon>
        </button>
        <ng-content></ng-content>
        <mat-error *ngIf="form.hasError('required') && form.touched">
            {{"Il campo è obbligatorio"}}
        </mat-error>
    </mat-form-field>


