/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface AutomezziFilterForm { 
    /**
     * testo
     */
    testo?: string;
    /**
     * targa
     */
    targa?: string;
    /**
     * descrizione
     */
    descrizione?: string;
    /**
     * riferimento
     */
    riferimento?: string;
    /**
     * stato
     */
    stato?: AutomezziFilterForm.StatoEnum;
    /**
     * rimorchio
     */
    rimorchio?: boolean;
    /**
     * isCancellato
     */
    isCancellato?: boolean;
    /**
     * isGuidabileDaOperatore
     */
    isGuidabileDaOperatore?: boolean;
    /**
     * nascosto
     */
    nascosto?: boolean;
}
export namespace AutomezziFilterForm {
    export type StatoEnum = 'ATTIVO' | 'IN_MANUTENZIONE' | 'FERMO' | 'NON_DISPONIBILE';
    export const StatoEnum = {
        ATTIVO: 'ATTIVO' as StatoEnum,
        INMANUTENZIONE: 'IN_MANUTENZIONE' as StatoEnum,
        FERMO: 'FERMO' as StatoEnum,
        NONDISPONIBILE: 'NON_DISPONIBILE' as StatoEnum
    };
}