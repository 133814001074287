<ng-container>
    <!--<div class="flex flex-col form-width form-panel p-6"
         style="align-self: center ">-->
    <div [ngClass]="!!isOneColumn ? 'grid_1_col' : 'grid_2_col'"
         class="form-width form-panel px-2 py-0"
         style="align-self: center ">

        <ng-container *ngFor="let form of formGroupConfig">
            <ng-container [ngSwitch]="form.type">
                <ng-container *ngSwitchCase="type.BOOLEAN">
                    <ng-container *ngIf="form.show">
                        <div class="w-full flex flex-row gap-2 items-center"
                             *ngIf="getFormControl(form.name) as formControl">
                            <mat-label>{{form.noTransloco || form.transloco}}</mat-label>
                            <mat-checkbox class="m-1" color="primary" [formControl]="formControl">
                            </mat-checkbox>
                        </div>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
    </div>

    <div [ngClass]="!!isUnitaLocaleForm ? 'unita_locale_form_layout' : !!isOneColumn ? 'grid_1_col' : 'grid_2_col'"
         class="form-width form-panel pb-2 px-2"
         style="align-self: center ">

        <ng-container *transloco="let t">
            <ng-container *ngFor="let form of formGroupConfig">
                <ng-container [ngSwitch]="form.type">

                    <ng-container *ngSwitchCase="type.TEXT">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput class="input-field-modal"
                                       [autofocus]="form.autofocus"
                                       [readonly]="form.readOnlyForm"
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       (blur)="formControl.setValue(formControl?.value?.trim())"
                                       [formControl]="formControl">
                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{"Il campo è obbligatorio"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{"Il campo non è valido"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('noSpace')">
                                    {{t('form.no_space')}}
                                </mat-error>
                                <mat-error
                                    *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{"Il campo non può contenere solo spazi bianchi."}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.AMOUNT">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput class="input-field-modal"
                                       currencyMask
                                       [readonly]="form.readOnlyForm"
                                       [options]="{ prefix: '',  suffix: ' €',
                                       allowNegative: false,
                                       thousands: '.', decimal: ',', align: 'left' }"
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       [formControl]="formControl">
                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{"Il campo è obbligatorio"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{t('form.email')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('max')">
                                    {{t(form?.max?.text) + ' ' + ('(' + form?.max?.number + ')')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('min')">
                                    {{t(form?.min?.text) + ' ' + ('(' + form?.min?.number + ')')}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.NUMBER">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input class="text-align-left input-field-modal"
                                    [readonly]="form.readOnlyForm"
                                    *ngIf="!form?.onlyPositiveWithOneDecimal; else positiveWithOneDecimalTemplate"
                                    matInput [placeholder]="form.noTransloco || t(form.transloco)"
                                    [type]="form?.onlyPositiveIntegers ? 'text' : 'number'"
                                    (keypress)="form?.onlyPositiveIntegers ? keyPressNumbers($event) : undefined"
                                    [formControl]="formControl"
                                >
                                <ng-template #positiveWithOneDecimalTemplate>
                                    <input matInput class="input-field-modal"
                                           [placeholder]="form.noTransloco || t(form.transloco)"
                                           [type]="'number'"
                                           [min]="0.1"
                                           appOnlyDecimalNumbers
                                           [numOfDecimal]="1"
                                           [formControl]="formControl"
                                    >
                                </ng-template>

                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{"Il campo è obbligatorio"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{"Il campo non è valido"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('max')">
                                    {{t(form?.max?.text) + ' ' + ('(' + form?.max?.number + ')')}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('min')">
                                    {{t(form?.min?.text) + ' ' + ('(' + form?.min?.number + ')')}}
                                </mat-error>
                                <mat-error
                                    *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{"Il campo non può contenere solo spazi bianchi."}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>

                    </ng-container>

                    <ng-container *ngSwitchCase="type.SELECT">
                        <ng-container *ngIf="form.show">
                            <ng-container>
                                <mat-form-field class="w-full" appearance="outline"
                                                *ngIf="getFormControl(form.name) as formControl">
                                    <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                    <input *ngIf="form.readOnlyForm" matInput [formControl]="formControl"
                                           [readonly]="true" class="input-field-modal">
                                    <mat-select *ngIf="!form.readOnlyForm"
                                                [disabled]="form.readOnlyForm"
                                                [formControl]="formControl" [multiple]="form.multiple">
                                        <mat-option *ngIf="!form.multiple && !form.hideNullOption"
                                                    [value]="form?.defaultOption?.value || undefined"> {{ form?.defaultOption?.key || '-'}}</mat-option>
                                        <ng-container *ngIf="!!form.options && form?.options?.length > 0, else keyTmp">
                                            <mat-option *ngFor="let option of form.options"
                                                        [value]="option">{{(option || '') | dynamicPipe: form?.pipe | removeUnderscores }}
                                            </mat-option>
                                        </ng-container>
                                        <ng-template #keyTmp>
                                            <mat-option *ngFor="let option of form.optionsKeyValue"
                                                        [value]="option.key">{{(option.value || '') | dynamicPipe: form?.pipe | removeUnderscores}}
                                            </mat-option>
                                        </ng-template>

                                    </mat-select>
                                    <mat-error *ngIf="formControl.hasError('required')">
                                        {{"Il campo è obbligatorio"}}
                                    </mat-error>
                                    <mat-error *ngIf="formControl.hasError('pattern')">
                                        {{"Il campo non è valido"}}
                                    </mat-error>
                                </mat-form-field>
                            </ng-container>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.DATA">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full mat-button-datapicker" appearance="outline">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <input matInput class="input-field-modal"
                                       [placeholder]="form.noTransloco || t(form.transloco)"
                                       [value]="dateValue(form.name)"
                                       readonly
                                       [readonly]="form.readOnlyForm"
                                       [formControl]="getFormControl(form.name)"
                                       (dateInput)="event('input', $event, form.name)"
                                       [matDatepicker]="picker1"
                                >
                                <mat-datepicker-toggle [disabled]="form.readOnlyForm"
                                                       matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1></mat-datepicker>
                                <mat-error *ngIf="getFormControl(form.name).hasError('required')">
                                    {{"Il campo è obbligatorio"}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.TEXTAREA">
                        <ng-container *ngIf="form.show">
                            <mat-form-field class="w-full" appearance="outline"
                                            *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || t(form.transloco)}}</mat-label>
                                <textarea
                                    [readonly]="form.readOnlyForm"
                                    cdkTextareaAutosize
                                    cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="3"
                                    (blur)="formControl.setValue(formControl?.value?.trim())"
                                    [placeholder]="form.noTransloco ||  t(form.transloco)"
                                    [formControl]="formControl"
                                    matInput
                                ></textarea>

                                <mat-error *ngIf="formControl.hasError('required')">
                                    {{"Il campo è obbligatorio"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('pattern')">
                                    {{"Il campo non è valido"}}
                                </mat-error>
                                <mat-error *ngIf="formControl.hasError('maxlength')">
                                    {{t('form.max_length')}}
                                </mat-error>
                                <mat-error
                                    *ngIf="!formControl.hasError('required') && formControl.hasError('whitespace')">
                                    {{"Il campo non può contenere solo spazi bianchi."}}
                                </mat-error>
                            </mat-form-field>
                        </ng-container>
                    </ng-container>

                    <ng-container *ngSwitchCase="type.FILE">
                        <ng-container *ngIf="form.show">
                            <app-input-file [label]="form.transloco" [labelNoTranslate]="form.noTransloco"
                                            [listFileAccepted]="form.optionFile?.listaAccepted"
                                            [sizeMB]="form.optionFile?.sizeMb" [form]="getFormControl(form.name)"
                                            (uploadImgEmitter)="getUploadedFile($event, form.name)"></app-input-file>
                        </ng-container>
                    </ng-container>

                    <!--<ng-container *ngSwitchCase="type.BOOLEAN">
                        <ng-container *ngIf="form.show">
                            <div class="w-full flex flex-row gap-2 items-center"
                                 *ngIf="getFormControl(form.name) as formControl">
                                <mat-label>{{form.noTransloco || form.transloco}}</mat-label>
                                <mat-checkbox class="m-1" color="primary" [formControl]="formControl">
                                </mat-checkbox>
                            </div>
                        </ng-container>
                    </ng-container>-->


                </ng-container>
            </ng-container>

            <ng-container *ngIf="touched">
                <mat-hint *ngFor="let error of formGroup.errors | keyvalue"
                          class="mat-error">{{error.value}}</mat-hint>

            </ng-container>
        </ng-container>


    </div>
</ng-container>

