/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AddCalendarioDTOOraInizioOffsetRulesTransitionRules } from './addCalendarioDTOOraInizioOffsetRulesTransitionRules';
import { AddCalendarioDTOOraInizioOffsetRulesTransitions } from './addCalendarioDTOOraInizioOffsetRulesTransitions';

export interface AddCalendarioDTOOraInizioOffsetRules { 
    fixedOffset?: boolean;
    transitions?: Array<AddCalendarioDTOOraInizioOffsetRulesTransitions>;
    transitionRules?: Array<AddCalendarioDTOOraInizioOffsetRulesTransitionRules>;
}