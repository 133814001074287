/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { FotoIntervento } from './fotoIntervento';

export interface OrdineDiLavoro { 
    codice?: number;
    dataOraInizioODL?: Date;
    dataOraFineODL?: Date;
    uriImmagineFirmaCliente?: string;
    nominativoFirmaCliente?: string;
    importo?: number;
    uriOdl?: string;
    notaOrdineDiLavoro?: string;
    isPresentePausaPranzo?: boolean;
    dataOraInizioPausaPranzo?: Date;
    dataOraFinePausaPranzo?: Date;
    durataPausaPranzo?: number;
    durataEffettiva?: number;
    isInterventoInReperibilita?: boolean;
    dataOraRichiestaReperibilita?: Date;
    mittenteReperibilita?: string;
    foto?: Array<FotoIntervento>;
}