import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import {
    BehaviorSubject,
    catchError,
    distinctUntilChanged,
    EMPTY,
    map,
    Observable, ObservedValueOf, OperatorFunction,
    switchMap,
    take,
    throwError,
    timer
} from 'rxjs';

import {Router} from '@angular/router';
import {filter, mergeMap, tap} from 'rxjs/operators';
import {get} from "lodash";
import {MatDialog} from "@angular/material/dialog";
import {LocalStorageService} from "../services/local-storage.service";
import {FuseConfirmationConfig, FuseConfirmationService} from "../../@fuse/services/confirmation";
import {LogoutService} from "../services/logout.service";
import {AppInitService} from "../services/app-init.service";
import {SnackbarTypes} from "../shared/enum/snackbar-types";
import {PathEnum} from "../shared/enum/path-enum";
import {
    AuthControllerService, GrantType,
    LoginResponse,
    UserControllerService,
    UserView
} from "../../api-clients/generated/services";
import {endsWithPathUserPath} from "../shared/utils/url-functions";



@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

    isTokenRefreshing: BehaviorSubject<boolean | undefined> | undefined;
    private errorRefresh: string = 'Errore durante l\'aggiornamento della sessione. Si prega di rieseguire la login.';
    counterError401 = 0; // Se le chiamate vanno in 401 uno più di 10 volte viene effettuata la logout
    maxError401 = 10;
    counterError401User = 0;
    maxError401User = 10;

    /**
     * Constructor
     */
    constructor(
        private localStorageService: LocalStorageService,
        private router: Router,
        private fuseConfirmationService: FuseConfirmationService,
        private logoutService: LogoutService,
        private appInitservice: AppInitService,
        private matDialog: MatDialog,
        private authControllerService: AuthControllerService,
        private userControllerService: UserControllerService
    ) {}

    /**
     * Intercept
     *
     * @param httpRequest
     * @param next
     */
    intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        let authReq = httpRequest;
        if (authReq.url.includes('login') || authReq.url.includes('token') || authReq.url.includes('logout')) {
            console.log('Intercettata chiamata di login o refresh token, non utilizzo interceptor');
            return next.handle(authReq);
        }

        if (httpRequest.method !== 'OPTIONS') {
            return this.handleRequest(authReq, next);
        } else {
            return next.handle(authReq);
        }
    }

    handleRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                    if (error.status === 401) {
                        if (this.counterError401 <= this.maxError401
                            && this.counterError401User <= this.maxError401User ) {
                            console.log('error');
                            if(endsWithPathUserPath(error?.url)) {
                                this.counterError401User += 1;
                            } else {
                                this.counterError401 += 1;
                            }
                            return this.refreshToken(request, next, error);
                        } else {
                            return this.stopRefreshAndLogout(error);
                        }
                    } else if (error?.status === 403 && error?.error?.code === 1009) {
                        return this.stopRefreshAndLogout(error);
                    } else {
                        return throwError(() => error);
                    }
                }),
            tap((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse && event?.status === 200) {
                    if(endsWithPathUserPath(event?.url)) {
                        this.counterError401User = 0;
                    } else {
                        this.counterError401 = 0;
                    }

                }
            }));
    }

    refreshToken(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        if (!!this.isTokenRefreshing) {
            console.log('isTokenRefreshing eiste');
            return this.isTokenRefreshing.pipe(
                filter(value => value !== undefined),
                take(1),
                switchMap((result) => {
                        if (result) {
                            console.log('Chiamata handlerequest 1');
                            return timer(500).pipe(
                                switchMap(() => this.handleRequest(request.clone({
                                    setHeaders: {
                                        Authorization: 'Bearer ' + this.localStorageService.getAccessToken(),
                                    },
                                }), next)));
                        } else {
                            return EMPTY;
                        }
                    }
                )
            );
        } else {
            console.log('isTokenRefreshing non esiste');
            this.isTokenRefreshing = new BehaviorSubject<boolean | undefined>(undefined);
            return this.postRequestRefreshFlow$().pipe(
                mergeMap((result) => {
                    this.localStorageService.setTokenResponse(result);
                    console.log('Chiamata handlerequest 2');
                    return this.handleRequest(request.clone({
                        setHeaders: {
                            // eslint-disable-next-line @typescript-eslint/naming-convention
                            Authorization: 'Bearer ' + result.access_token,
                        },
                    }), next);
                }),
                catchError((tokenError) => {
                    if (tokenError.url.includes('token')) {
                        return this.stopRefreshAndLogout(tokenError);
                    } else {
                        return throwError(() => error);
                    }
                }));
        }
    }


    postRequestRefreshFlow$(): Observable<LoginResponse | any> {
        return (this.authControllerService.refreshTokenForm(
            this.localStorageService.getRefreshToken(),
            this.localStorageService.getProfileResponse(true)?.email,
            GrantType.REFRESHTOKEN
        ) as Observable<LoginResponse>).pipe(
            tap((value) => {
                this.isTokenRefreshing?.next(true);
                this.isTokenRefreshing = undefined;
            }),
            distinctUntilChanged(),
            take(1),
            tap((value: LoginResponse) => this.localStorageService.setTokenResponse(value)),
            switchMap((LoginResponse: LoginResponse) => this.userControllerService.getUser().pipe(
                this.catchErrorInRefresh$(this.userControllerService.getUser()),
                tap((userView: UserView) => this.localStorageService.setProfileResponse(userView)),
                map(() => LoginResponse),
            )),
            catchError((error) => {
                if (error.url.includes('token')) {
                    return this.stopRefreshAndLogout(error);
                } else {
                    return throwError(() => error);
                }
            })
        );
    }

    getConfigModalError(): FuseConfirmationConfig {
        return {
            title: 'Attenzione',
            message: 'Si è verificato un errore. Si prega di riprovare',
            onBackdrop: {
                show: false,
                backdrop: false,
            },
            additionalActions: [
                {
                    function: (): void => {
                        this.logoutService.logout();
                        this.isTokenRefreshing?.next(false);
                        this.isTokenRefreshing = undefined;
                        this.showErrorMessage();
                    },
                    label: 'Vai alla login',
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF'
                },
                {
                    label: 'Riprova',
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF',
                    closeValue: true,
                }
            ]
        };
    }

    catchErrorInRefresh$(inputObs: Observable<any>): OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return this.fuseConfirmationService.catchErrorCustom$(inputObs, this.getConfigModalError(), true);
    }

    private stopRefreshAndLogout(error: HttpErrorResponse): Observable<HttpEvent<any>> {

        // tslint:disable-next-line:no-non-null-assertion
        if (this.isTokenRefreshing) {
            this.isTokenRefreshing!.next(false);
            this.isTokenRefreshing = undefined;
        }
        this.counterError401 = 0;
        this.counterError401User = 0;
        this.showErrorMessage();

        if (error.status === 401 || error.status === 400) {
            this.localStorageService.cleanAllClassVariablesAndStorage();
        } else if (error.status === 403) {
            this.logoutService.logout();
        }
        this.router.navigateByUrl('/' + PathEnum.SIGN_IN);
        this.matDialog?.closeAll();
        return EMPTY;
    }

    showErrorMessage(): void {
        this.fuseConfirmationService.openSnackBar({
            message: 'Errore durante il refresh del token',
            type: SnackbarTypes.Error,
        });
    }
}
