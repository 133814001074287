import {MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition} from "@angular/material/snack-bar";
import {SnackbarTypes} from "../../../app/shared/enum/snackbar-types";
import {FuseAlertType} from "../../components/alert";

export interface FuseConfirmationConfig
{
    title?: string;
    message?: string;
    icon?: {
        show?: boolean;
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
    actions?: {
        function?: () => void;
        label: string;
        color?: 'primary' | 'accent' | 'warn';
    } []
    dismissible?: boolean;
    alert?: {
        type?: FuseAlertType;
        message?: string;
    };
    onBackdrop?: {
        function?: () => void;
        show?: boolean;
        backdrop?: boolean;
    };
    additionalActions?: {
        function?: () => void;
        label: string;
        color?: 'primary' | 'accent' | 'warn';
        hexColor?: string;
        hexColorText?: string;
        closeValue?: any;
    } [];
}

export interface SnackbarConfig {
    message: string;
    type?: SnackbarTypes;
    horizontalPosition?: MatSnackBarHorizontalPosition;
    verticalPosition?: MatSnackBarVerticalPosition;
    duration?: number;
}
