import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlItalian  extends MatPaginatorIntl {
    itemsPerPageLabel = 'Elementi per pagina:';
    nextPageLabel     = 'Prossima pagina';
    previousPageLabel = 'Pagina precedente';

    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 di ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;

        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' di ' + length;
    };
}
