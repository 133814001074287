import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {AutomezzoView} from "../../../../api-clients/generated/services";


export function createTableConfigurationForAutomezzi(inputList?: Array<AutomezzoView>,
                                                     isSelection?: boolean, listLength?: number, pageSize?: number,
                                                     pageIndex?: number, isRimorchio?: boolean): GenericTableConfigurationModel {
    let list = inputList;
    const config: GenericTableConfigurationModel = {
        configuration: {
            data: list,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            pageIndex: pageIndex,
            configurazioneTabella: [
                {
                    hideColonna: isSelection || isRimorchio,
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'Ordine in programma',
                    colonnaKey: 'ordineInProgramma',
                    flex: 10,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Descrizione',
                    colonnaKey: 'descrizione',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Riferimento',
                    colonnaKey: 'intRiferimento',
                    flex: 20,
                },
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Targa',
                    colonnaKey: 'targa',
                    flex: 20,
                },
                {
                    hideColonna: isSelection,
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'Rimorchio',
                    colonnaKey: 'rimorchio',
                    flex: 20,
                    convertiValoreBoolean: element => !!element ? 'Sì' : 'No'
                },
                {
                    tipo: TipoColonnaEnum.CHIP_STATUS,
                    nomeColonna: 'Stato',
                    colonnaKey: 'intStato',
                    flex: 18,
                    statusType: AutomezzoView.IntStatoEnum
                },
                {
                    hideColonna: isSelection,
                    tipo: TipoColonnaEnum.BOOLEAN,
                    nomeColonna: 'Nascosto in programma',
                    colonnaKey: 'intNascostoInProgramma',
                    flex: 20,
                    convertiValoreBoolean: element => !!element ? 'Sì' : 'No'
                },
                {
                    hideColonna: isSelection,
                    nomeColonna: 'Modifica',
                    colonnaKey: 'modifica',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'edit',
                        click: TipoClickEnum.EDIT,
                        tooltip: 'Modifica l\'automezzo',
                        color: 'accent'
                    }],
                },
                {
                    hideColonna: !isSelection,
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile'
        }
    };
    return config;
}

