import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AnagraficheService } from './api/anagrafiche.service';
import { AuthControllerService } from './api/authController.service';
import { DateTimeControllerService } from './api/dateTimeController.service';
import { EmailSenderControllerService } from './api/emailSenderController.service';
import { InterventiService } from './api/interventi.service';
import { InterventiFormularioService } from './api/interventiFormulario.service';
import { ProgrammazioneService } from './api/programmazione.service';
import { RegistroGiornalieroOperatoreService } from './api/registroGiornalieroOperatore.service';
import { SincronizzazioniService } from './api/sincronizzazioni.service';
import { UserControllerService } from './api/userController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AnagraficheService,
    AuthControllerService,
    DateTimeControllerService,
    EmailSenderControllerService,
    InterventiService,
    InterventiFormularioService,
    ProgrammazioneService,
    RegistroGiornalieroOperatoreService,
    SincronizzazioniService,
    UserControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
