import { Pipe, PipeTransform } from '@angular/core';
import {isNumber} from "lodash-es";

@Pipe({
  name: 'currencyEuro'
})
export class CurrencyEuroPipe implements PipeTransform {

    // eslint-disable-next-line id-blacklist
  transform(number: number | string): string {
      // eslint-disable-next-line id-blacklist
    return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(isNumber(number) ?  number : Number(number) );
  }

}
