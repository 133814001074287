import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { of, switchMap } from 'rxjs';
import {LocalStorageService} from "../../../services/local-storage.service";
import {LogoutService} from "../../../services/logout.service";

export const NoLoggedGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
    const router: Router = inject(Router);
    const localStorageService = inject(LocalStorageService);
    const logoutService = inject(LogoutService);

    const token = route.queryParams['token'];
    const resetPwdToken = route.queryParams['resetPwdToken'];

    // Check the authentication status
    return inject(LocalStorageService).check().pipe(
        switchMap((authenticated) =>
        {
            // If the user is authenticated...
            if ( authenticated )
            {
                if (token || resetPwdToken) {
                    // Effettua il logout e poi naviga all'indirizzo dell'url
                    return logoutService.logout$().pipe(
                        switchMap(() => of(true))
                    );
                } else {
                    // Se non c'è il parametro 'token', impedisci l'accesso
                    return of(router.parseUrl('/'));
                }
            }

            // Allow the access
            return of(true);
        }),
    );
};
