import {Injectable} from '@angular/core';
import {HttpErrorResponse} from "@angular/common/http";
import {FuseConfirmationService} from "../../@fuse/services/confirmation";
import {SnackbarTypes} from "../shared/enum/snackbar-types";
import {PathEnum} from "../shared/enum/path-enum";
import {Router} from "@angular/router";


export enum RequestTypesEnum {
    LOGIN = 'LOGIN',
    ERRORE_GENERICO = 'ERRORE_GENERICO',
    SINCRONIZZAZIONE = 'SINCRONIZZAZIONE',
    CREAZIONE_CLIENTE = 'CREAZIONE_CLIENTE',
    MODIFICA_CLIENTE = 'MODIFICA_CLIENTE',
    CANCELLAZIONE_CLIENTE = 'CANCELLAZIONE_CLIENTE',
    CREAZIONE_REFEFERENTE = 'CREAZIONE_REFEFERENTE',
    MODIFICA_REFEFERENTE = 'MODIFICA_REFEFERENTE',
    CANCELLAZIONE_REFEFERENTE = 'CANCELLAZIONE_REFEFERENTE',
    CREAZIONE_UNITA_LOCALE = 'CREAZIONE_UNITA_LOCALE',
    MODIFICA_UNITA_LOCALE = 'MODIFICA_UNITA_LOCALE',
    CANCELLAZIONE_UNITA_LOCALE = 'CANCELLAZIONE_UNITA_LOCALE',
    CREAZIONE_ATTREZZATURA = 'CREAZIONE_ATTREZZATURA',
    MODIFICA_ATTREZZATURA = 'MODIFICA_ATTREZZATURA',
    CREAZIONE_OPERATORE = 'CREAZIONE_OPERATORE',
    MODIFICA_OPERATORE = 'MODIFICA_OPERATORE',
    MODIFICA_AUTOMEZZO = 'MODIFICA_AUTOMEZZO',
    MODIFICA_ADDEBITO = 'MODIFICA_ADDEBITO',

    AGGIUNTA_UTENTE = 'AGGIUNTA_UTENTE',
    RICERCA_UTENTI = 'RICERCA_UTENTI'
}

@Injectable({
    providedIn: 'root'
})
export class ManageErrorService {

    constructor(private fuseConfirmationService: FuseConfirmationService,
                private _router: Router,) {
    }


    showBackendErrorMessage(error: HttpErrorResponse) {
        let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
        this.fuseConfirmationService.openSnackBar({
            message: (!!error && !!error.error && !!error.error.message) ? error?.error?.message : defaultMessage,
            type: SnackbarTypes.Error,
        });
    }

    async showBackendErrorMessageBLOB(error: HttpErrorResponse) {
        let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
        let basicMessage = (!!error && !!error.error && !!error.error.message) ? error?.error?.message : defaultMessage;
        const message = JSON.parse(await error?.error?.text())?.message;
        this.fuseConfirmationService.openSnackBar({
            message: !!message ? message : basicMessage,
            type: SnackbarTypes.Error,
        });
    }

    showBackendErrorMessageWithRetryOption(getData: () => void, error: HttpErrorResponse) {
        let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
        let message = (!!error && !!error.error && !!error.error.message) ? error?.error?.message : defaultMessage;
        this.fuseConfirmationService.errorGetDataOrGoToHome(() =>
            this.goToHome(),
            () => getData(),
            message);
    }

    showBackendErrorMessageWithRetryAndCancelOptions(getData: () => void, error: HttpErrorResponse) {
        let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
        let message = (!!error && !!error.error && !!error.error.message) ? error?.error?.message : defaultMessage;
        this.fuseConfirmationService.errorRetryOrCancel(() =>
                () => getData(),
            message);
    }


    goToHome() {
        this._router.navigate([PathEnum.HOME]);
    }

    manageError(error: HttpErrorResponse, requestType: RequestTypesEnum) {
        let httpStatus: number = error.status;
        let errorCode: number = !!error.error ? error.error.code : null;
        let errorMessage: string = !!error.error ? error.error.message : null;
        let messageToShow: string;
        messageToShow = this.getErrorMessage(requestType, httpStatus, errorCode, errorMessage);
        this.fuseConfirmationService.openSnackBar({
            message: messageToShow,
            type: SnackbarTypes.Error,
        });
    }

    getErrorMessage(requestType: RequestTypesEnum, httpStatus: number, errorCode: number, errorMessage: string): string {
        let defaultMessage = 'Si è verificato un errore, si prega di riprovare.';
        let defaultLogInMessage = 'Si è verificato un errore durante richiesta di log in, si prega di riprovare.';
        switch (requestType) {
            case RequestTypesEnum.LOGIN:
                if (!!httpStatus) {
                    switch (httpStatus) {
                        case (401):
                            return (!!errorCode && errorCode === 401) ? 'Le credenziale inserite non sono corrette, si prega di riprovare.' : defaultLogInMessage;
                        default:
                            return defaultLogInMessage;
                    }
                } else {
                    return defaultLogInMessage;
                }
            case RequestTypesEnum.ERRORE_GENERICO:
                return 'Errore durante il caricamento dei dati';
            case RequestTypesEnum.SINCRONIZZAZIONE:
                return 'Errore durante la sincronizzazione dei dati';
            case RequestTypesEnum.CREAZIONE_CLIENTE:
                return 'Errore durante la creazione del cliente';
            case RequestTypesEnum.MODIFICA_CLIENTE:
                return 'Errore durante la modifica del cliente';
            case RequestTypesEnum.CANCELLAZIONE_CLIENTE:
                return 'Errore durante la cancellazione del cliente';
            case RequestTypesEnum.CREAZIONE_REFEFERENTE:
                return 'Errore durante la creazione del referente';
            case RequestTypesEnum.MODIFICA_REFEFERENTE:
                return 'Errore durante la modifica del referente';
            case RequestTypesEnum.CANCELLAZIONE_REFEFERENTE:
                return 'Errore durante la cancellazione del referente';
            case RequestTypesEnum.CREAZIONE_UNITA_LOCALE:
                return 'Errore durante la creazione dell\'unità locale';
            case RequestTypesEnum.MODIFICA_UNITA_LOCALE:
                return 'Errore durante la modifica dell\'unità locale';
            case RequestTypesEnum.CREAZIONE_ATTREZZATURA:
                return 'Errore durante la creazione dell\'attrezzatura';
            case RequestTypesEnum.MODIFICA_ATTREZZATURA:
                return 'Errore durante la modifica dell\'attrezzatura';
            case RequestTypesEnum.MODIFICA_AUTOMEZZO:
                return 'Errore durante la modifica dell\'automezzo';
            case RequestTypesEnum.CREAZIONE_OPERATORE:
                return 'Errore durante la creazione dell\'operatore';
            case RequestTypesEnum.MODIFICA_OPERATORE:
                return 'Errore durante la modifica dell\'operatore';
            case RequestTypesEnum.MODIFICA_ADDEBITO:
                return 'Errore durante la modifica dell\'addebito';
            case RequestTypesEnum.CANCELLAZIONE_UNITA_LOCALE:
                return 'Errore durante la cancellazione dell\'unità locale';
                case RequestTypesEnum.AGGIUNTA_UTENTE:
                return 'Errore durante l\'aggiunta del nuovo utente';
            case RequestTypesEnum.RICERCA_UTENTI:
                return 'Si è verificato un errore durante la ricerca degli utenti';
            default:
                return defaultMessage;
        }
    }
}
