import { inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {FuseConfirmationConfig, SnackbarConfig} from '@fuse/services/confirmation/confirmation.types';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { merge } from 'lodash-es';
import {SnackbarTypes} from "../../../app/shared/enum/snackbar-types";
import {MatSnackBar, MatSnackBarConfig} from "@angular/material/snack-bar";
import {SnackbarComponent} from "../../../app/layout/common/snackbar/snackbar.component";
import {catchError, EMPTY, Observable, ObservedValueOf, OperatorFunction, switchMap, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";
import { LocalStorageService } from 'app/services/local-storage.service';

@Injectable({providedIn: 'root'})
export class FuseConfirmationService
{
    loadingSpinner: boolean = false;
    private _matDialog: MatDialog = inject(MatDialog);
    private _defaultConfig: FuseConfirmationConfig = {
        title      : 'Confirm action',
        message    : 'Are you sure you want to confirm this action?',
        icon       : {
            show : true,
            name : 'heroicons_outline:exclamation-triangle',
            color: 'warn',
        },
        actions: [
            {
                label: 'Conferma',
                color: 'warn',
            },
        ],
        dismissible: false,
    };
    private _defaultSnackbarConfig: SnackbarConfig = {
        message: '',
        type: SnackbarTypes.Success,
        horizontalPosition: 'center',
        verticalPosition: 'top',
        duration: 3500
    };

    /**
     * Constructor
     */
    constructor(public snackBar: MatSnackBar,
                private localStorageService: LocalStorageService) {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    open(config: FuseConfirmationConfig = {}): MatDialogRef<FuseConfirmationDialogComponent>
    {
        // Merge the user config with the default config
        const userConfig = merge({}, this._defaultConfig, config);

        // Open the dialog
        return this._matDialog.open(FuseConfirmationDialogComponent, {
            autoFocus   : false,
            disableClose: !userConfig.dismissible,
            data        : userConfig,
            panelClass  : 'fuse-confirmation-dialog-panel',
        });
    }

    openSnackBar(_config: SnackbarConfig) {
        const userConfig = merge({}, this._defaultSnackbarConfig, _config);
        const config: MatSnackBarConfig = new MatSnackBarConfig();
        config.verticalPosition = userConfig.verticalPosition;
        config.horizontalPosition = userConfig.horizontalPosition;
        config.duration = userConfig.duration;
        config.data = {message: userConfig.message, type: userConfig.type};
        config.panelClass = this.getPanelClass(userConfig.type);
        this.snackBar.openFromComponent(SnackbarComponent, config);
    }

    getPanelClass(type: SnackbarTypes) {
        switch (type) {
            case SnackbarTypes.Success:
                return 'mat-bg-success';
            case SnackbarTypes.Error:
                return 'mat-bg-error';
            case SnackbarTypes.Warning:
                return 'mat-bg-warn';
            default:
                return 'error';
        }
    }

    showLoader() {
        this.loadingSpinner = true;
    }

    hideLoader() {
        this.loadingSpinner = false;
    }

    catchErrorCustom$(inputObs: Observable<any>, config?: FuseConfirmationConfig, returnEmpty?: boolean, showRelativeError?: boolean, showGenericError?: boolean):
        OperatorFunction<unknown, ObservedValueOf<Observable<any>> | unknown> {
        return catchError((error: HttpErrorResponse) => {
            const dialogRef = this.openModalError(config,
                showRelativeError ? error : null, showGenericError);
            return dialogRef.afterClosed().pipe(
                switchMap((value: any) => {
                    if (!!value) {
                        return inputObs.pipe(this.catchErrorCustom$(inputObs, config, returnEmpty));
                    } else {
                        return !!returnEmpty ? EMPTY : throwError(() => new Error());
                    }
                })
            );
        });
    }

    openModalError(config?: FuseConfirmationConfig, errorMessageFromBackend?: HttpErrorResponse, genericErrorMessage?: boolean): MatDialogRef<FuseConfirmationDialogComponent> {
        const unsuccessful_sign_in = "Si è verificato un errore. Non è stato possibile completare il procedimento di log in."
        const generic_error = "Si è verificato un errore, si prega di riprovare";
        const defaultConfig: FuseConfirmationConfig = {
                title: 'Attenzione',
            message: !!errorMessageFromBackend.error.message ? errorMessageFromBackend.error.message : (genericErrorMessage ? generic_error : unsuccessful_sign_in),
            onBackdrop: {
                show: false,
                backdrop: false,
            },
            additionalActions: [
                {
                    function: (): void => this.localStorageService.cleanAllClassVariablesAndStorage(),
                    label: 'Annulla',
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF',
                },
                {
                    label: 'Riprova',
                    hexColor: '#809dbd',
                    hexColorText: '#FFFFFF',
                    closeValue: true
                }
            ]
        };
        const myConfiguration = !!config ? config : defaultConfig;
        return this.open(myConfiguration);
    }

    errorGetDataToLogin(goToLogin: () => void, getData: () => void, message?: string) {
        this.open({
            message: message || 'Si è verificato un errore',
            onBackdrop: {
                show: false,
                backdrop: true
            },
            title      : 'Attenzione',
            icon       : {
                show : true,
                name : 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            },
            actions: [
                {
                    function: () => goToLogin(),
                    label: 'Vai alla login',
                    color: 'accent'
                },
                {
                    function: () => getData(),
                    label: 'Riprova',
                    color: 'primary'
                }
            ],
        })
    }

    errorGetDataOrGoToHome(goToHome: () => void, getData: () => void, message?: string) {
        this.open({
            message: message || 'Si è verificato un errore',
            onBackdrop: {
                show: false,
                backdrop: true
            },
            title      : 'Attenzione',
            icon       : {
                show : true,
                name : 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            },
            actions: [
                {
                    function: () => goToHome(),
                    label: 'Vai alla home',
                    color: 'accent'
                },
                {
                    function: () => getData(),
                    label: 'Riprova',
                    color: 'primary'
                }
            ],
        })
    }


    errorRetryOrCancel(retryfunction: () => void, message?: string) {
        this.open({
            message: message || 'Si è verificato un errore durante la richiesta. Si prega di riprovare',
            onBackdrop: {
                show: false,
                backdrop: true
            },
            title      : 'Attenzione',
            icon       : {
                show : true,
                name : 'heroicons_outline:exclamation-triangle',
                color: 'warn',
            },
            actions: [
                {
                    label: 'Annulla',
                    color: 'accent'
                },
                {
                    function: () => retryfunction(),
                    label: 'Riprova',
                    color: 'primary'
                }
            ],
        })
    }
}
