
    <div class="container-parent-table justify-content_center">
        <div *ngIf="isSelectable  && !_configuration?.singleChoiceSelection && !hideChipSelector"
             class="py-2 px-2 rounded-lg bg-gray-500 dark:bg-gray-800 ng-star-inserted w-full">
            <mat-chip-set *ngIf="righeSelezionateCustom?.length > 0">
                <div class="table-selection grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full mb-4">
                    <ng-container *ngFor="let riga of righeSelezionateCustom">
                        <div class="flex flex-col w-full items-center">
                            <mat-chip color="primary" class="mat-chip-multiselection flex flex-row justify-between">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{riga.data[selectedColumnKeyToShow] | uppercase}}</span>
                                <button [style]="'padding-left: 5px'" [disabled]="readOnly"
                                        (click)="toggleSelectedCustomRowData(riga)">
                                    <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                                </button>
                            </mat-chip>
                        </div>
                    </ng-container>
                </div>
            </mat-chip-set>
            <mat-chip-set>
                <div *ngIf="(righeSelezionate !== undefined && righeSelezionate.length > 0); else noSelezione"
                     [style]="(righeSelezionate.length > 8)? 'height: 150px !important;' : 'height: 100px !important;'"
                     class="table-selection grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 w-full">
                    <ng-container *ngFor="let riga of righeSelezionate">
                        <div class="flex flex-col w-full items-center">
                            <mat-chip color="primary" class="mat-chip-multiselection flex flex-row justify-between">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{riga.data[selectedColumnKeyToShow] | uppercase}}</span>
                                <button [style]="'padding-left: 5px'" [disabled]="readOnly"
                                        *ngIf="!checkIfRigaReadOnly(riga?.data); else rigaSolaLetturaIcon"
                                        (click)="toggleSelectedRowData(riga.data)">
                                    <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                                </button>
                                <ng-template #rigaSolaLetturaIcon>
                                    <button [style]="'padding-left: 5px'"
                                            *ngIf="checkIfRigaReadOnly(riga?.data) && !!infoRigheInSolaLettura">
                                        <mat-icon
                                            [matTooltip]="infoRigheInSolaLettura?.tooltip">{{infoRigheInSolaLettura?.iconName}}</mat-icon>
                                    </button>
                                </ng-template>
                            </mat-chip>
                        </div>
                    </ng-container>
                </div>
                <ng-template #noSelezione>
                    <div class="w-full table-no-selection flex flex-col justify-center items-center gap-2.5">
                        <span>{{no_elements}}</span>
                    </div>
                </ng-template>
            </mat-chip-set>
        </div>

        <div *ngIf="isSelectable && _configuration?.singleChoiceSelection"
             class="py-2 px-2 rounded-lg bg-gray-500 dark:bg-gray-800 ng-star-inserted w-full">
            <mat-chip-set class="flex flex-col w-full items-center">
                <div *ngIf="(righeSelezionate !== undefined && righeSelezionate.length > 0); else noSelezione">
                    <div class="flex flex-col w-full items-center">
                        <mat-chip color="primary" class=" flex flex-row justify-between">
                                <span *ngIf="selectedColumnKeyToShow"
                                      class="line-clamp-1">{{(righeSelezionate[0])?.data[selectedColumnKeyToShow] | uppercase}}</span>
                            <button [style]="'padding-left: 5px'"
                                    (click)="toggleSelectedRowData((righeSelezionate[0])?.data)">
                                <mat-icon [style]="'margin-top: 5px'">delete</mat-icon>
                            </button>

                        </mat-chip>
                    </div>

                </div>
            </mat-chip-set>

            <ng-template #noSelezione>
                <div class="w-full table-no-single-selection flex flex-col justify-center items-center gap-2.5">
                    <span>{{no_elements}}</span>
                </div>
            </ng-template>

        </div>


        <div class="container_table"  [style]="_configuration?.css">
            <mat-table #table
                       *ngIf="!!_configuration && !!_configuration?.configurazioneTabella"
                       [dataSource]="dataSourcePaginated" matSort
                       (matSortChange)="onSortChange($event)"
                       class="flex flex-col items-center mat-table-custom xl:block xl:items-start"
                       [style]="!!_configuration?.cssMatTable ? _configuration?.cssMatTable : undefined">
                <ng-container *ngFor="let colonna of _configuration?.configurazioneTabella; trackBy: trackByFn"
                              matColumnDef="{{colonna.colonnaKey}}">
                    <mat-header-cell *matHeaderCellDef [style.max-width.%]="colonna?.flex" mat-sort-header
                                     [disabled]="!colonna.enableSort" class="justify-center" [ngStyle]="_configuration?.css">

                        {{(colonna.nomeColonna || colonna.colonnaKey) |  customTitleCase: colonna.uppercase | removeUnderscores}}

                        <mat-icon *ngIf="colonna.helpIcon"
                                  class="ml-1 cursor-pointer icon-size-4" (click)="openModalHelp()">help</mat-icon>

                        <ng-container *ngIf="colonna.tipo === tipoColonna.SELECTION">
                            <mat-checkbox class="m-1" color="primary" (change)="toggleAllRows()"
                                          (click)="$event.stopPropagation()"
                                          [checked]="checkIfAllSelected()"
                                          [disabled]="readOnly || !(this.dataSourcePaginated.data?.length > 0)"></mat-checkbox>

                        </ng-container>
                    </mat-header-cell>

                    <mat-cell *matCellDef="let element; let idx = index" class="justify-content_center mat-cell-custom"
                              [style.max-width.%]="colonna?.flex"
                              [ngStyle]="_configuration?.css"
                              [ngClass]="{'cursor-pointer': _configuration.selection?.isSelectable}"
                              (click)="onCellClick(element)">

                        <ng-container [ngSwitch]="colonna.tipo">
                    <span [ngStyle]="_configuration?.css"
                          class="mobile-label"> {{(colonna.nomeColonna || colonna.colonnaKey) |  customTitleCase: colonna.uppercase | removeUnderscores}}</span>

                            <ng-container *ngSwitchCase="tipoColonna.SELECTION">
                                <ng-container>
                                    <mat-checkbox class="m-1" color="primary" (change)="toggleSelectedRowData(element)"
                                                  (click)="$event.stopPropagation()"
                                                  [checked]="checkIfSelected(element[selectedColumnKeyPrimaryKey])"
                                                  [disabled]="readOnly|| disableNonSelectedCheck(element) || checkIfRigaReadOnly(element)"></mat-checkbox>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.ICON">
                                <ng-container *ngFor="let icon of colonna.icon">
                                    <mat-icon
                                        *ngIf="!icon.showIf || icon.showIf(element[colonna.colonnaKey])"
                                        [color]="icon.colorIcon"
                                        [matTooltip]="(icon.tootlip || '') | customTitleCase: colonna.uppercase  | removeUnderscores">{{icon.nameIcon}}</mat-icon>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="tipoColonna.STRING">
                                <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                    <div style="  text-align: center;   white-space: pre-line;"
                                         *ngIf="(colonna.colonnaKey === 'email' || colonna.colonnaKey === 'username'); else showValue">
                                        <span class="mobile-text" style="word-break: break-word;">{{value}}  </span>
                                    </div>
                                    <ng-template #showValue>
                            <span style="  text-align: center;   white-space: pre-line; word-break: break-word;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value  | customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                                        <span>{{colonna.stringSuffix}}</span>
                                    </ng-template>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.NUMBER">
                                <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value }}</span> <span>{{colonna.numberSuffix}}</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.HREF">
                                <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="text-align: center;"
                                  (click)="goTo(colonna, element, colonna.colonnaKey); $event.stopPropagation()"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text cursor-pointer">
                                {{ value}}</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.AMOUNT">
                                <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                            <span style="  text-align: center;"
                                  *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                  class="mobile-text">
                                {{ value | currencyEuro}}</span>
                                </ng-container>
                            </ng-container>


                            <ng-container *ngSwitchCase="tipoColonna.TEXT_BUTTON">
                                <ng-container *ngIf="getValueShow(colonna, element, colonna.colonnaKey) as value">
                                    <div class="flex flex-row justify-between items-center">
                                        <div><span style="  text-align: center;   white-space: pre-line"
                                                   *ngIf="!colonna?.show || colonna?.show(element[colonna.colonnaKey])"
                                                   class="mobile-text"
                                        >
                                {{ value}}</span>
                                        </div>

                                        <mat-icon matTooltip="Visualizza in tabella" *ngIf="!!value"
                                                  style="margin-left: 5px"
                                                  class="cursor-pointer icon-size-4"
                                                  (click)="colonna.dialog.openDialog(element[colonna.dialog.fieldValueDialog]); $event.stopPropagation()">
                                            open_in_new
                                        </mat-icon>
                                    </div>


                                </ng-container>
                            </ng-container>
                            <ng-container *ngSwitchCase="tipoColonna.BOOLEAN">
                                <ng-container *ngIf="!!colonna.convertiValoreBooleanInIcon">
                                    <mat-icon
                                        *ngIf="convertiValoreInBo(colonna, element[colonna.colonnaKey]) as iconConfig"
                                        [color]="iconConfig.colorIcon"
                                        [matTooltip]="(iconConfig.tootlip || '') | customTitleCase: colonna.uppercase | removeUnderscores">{{iconConfig.nameIcon}}</mat-icon>
                                </ng-container>
                                <ng-container *ngIf="!!colonna.convertiValoreBoolean">
                                <span
                                    class="mobile-text">{{ getValue(colonna, element[colonna.colonnaKey])| customTitleCase: colonna.uppercase | removeUnderscores}}</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.ACTION">
                                <div *ngIf="!colonna?.show || colonna?.show(element[colonna.valueCheck || colonna.colonnaKey], element)"
                                     class="flex flex-col justify-center items-center" style="width: 100%"
                                     [class.custom-button]="_configuration.customButton">
                                    <div class="flex flex-row flex-wrap justify-center items-center" style="width: 100%">
                                        <ng-container
                                            *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                            <ng-container *ngIf="!button.hide">
                                                <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                        style="margin: 2px; box-shadow: none"
                                                        [color]="button.color" mat-mini-fab
                                                        [matTooltip]="(button.tooltip) || ''"
                                                        (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element}); $event.stopPropagation()">
                                                    <mat-icon>{{button.nameIconButton}}</mat-icon>
                                                </button>

                                                <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                          [color]="button.color"
                                                          [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                            </ng-container>

                                        </ng-container>
                                    </div>

                                </div>

                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.ACTION_MENU">
                                <button style="box-shadow: unset !important;"
                                        *ngIf="!colonna?.show || colonna?.show(element[colonna.valueCheck || colonna.colonnaKey], element)"
                                        color="accent"
                                        mat-mini-fab
                                        [matMenuTriggerFor]="action"
                                >
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu class="custom-mat-menu-action max-w-[unset]" [xPosition]="'after'"
                                          #action="matMenu">

                                    <ng-container
                                        *ngFor="let button of getButton(colonna, element[colonna.valueKeyForFuction || colonna.colonnaKey])">
                                        <ng-container *ngIf="!button.hide">
                                            <button *ngIf="show(button, element, button?.valueShow) && !button.isIcon"
                                                    style="margin: 2px"
                                                    mat-menu-item
                                                    (click)="clickAction.emit({tipoClick: button.click, index: idx, value: element}); $event.stopPropagation()">
                                                <mat-icon>{{button.nameIconButton}}</mat-icon>
                                                <span class="ml-2">{{(button.tooltip) || ''}}</span>
                                            </button>

                                            <mat-icon *ngIf="button.isIcon && show(button, element, button?.valueShow)"
                                                      [color]="button.color"
                                                      [matTooltip]="button?.iconTooltip || button?.tooltip">{{button.nameIconButton}}</mat-icon>
                                        </ng-container>

                                    </ng-container>

                                </mat-menu>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.CHIP_STATUS">
                                <ng-container *ngIf="getValueForStatus(colonna, element, colonna.colonnaKey) as chipStatus">
                                    <mat-chip-list [style]="colonna.chipWidthAuto ? 'width: auto' : 'width:93%'">
                                        <div class="chips-container w-full justify-center items-center text-center">
                                            <mat-chip [class]="chipStatus | statusEnumToColorTheme: colonna.statusType"
                                                      [matTooltip]="!!colonna.tooltip ? colonna.tooltip(chipStatus) : chipStatus"
                                                      class="custom-chip chip-width user-chip flex flex-row justify-center">
                                                <span style="text-align: center; white-space: pre-line; word-break: break-word !important;"
                                                      class="break-all">
                                                {{chipStatus | removeUnderscores}}
                                                    </span>
                                            </mat-chip>
                                        </div>
                                    </mat-chip-list>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.CHIP_ARRAY">
                                <ng-container *ngIf="getValueForChip(colonna, element, colonna.colonnaKey) as chipList">

                                    <mat-chip-list [style]="'width:93%'">
                                        <div class="chips-container w-full justify-center items-center">
                                            <ng-container *ngFor="let chipItem of chipList;">
                                                <mat-chip class="text-center custom-chip chip-width user-chip flex flex-row justify-center"
                                                          color="primary">
                                            <span style="text-align: center; white-space: pre-line; word-break: break-word !important;"
                                                  class="break-all">{{ chipItem | uppercase | removeUnderscores }}</span>
                                                </mat-chip>
                                            </ng-container>
                                        </div>
                                    </mat-chip-list>

                                </ng-container>
                            </ng-container>

                            <ng-container *ngSwitchCase="tipoColonna.CHIP_USER">
                                <ng-container *ngIf="getValueForChip(colonna, element, colonna.colonnaKey) as chipOperatori">
                                    <mat-chip-list [style]="'width:93%'">
                                        <div class="chips-container w-full justify-center items-center">
                                            <ng-container *ngFor="let chipItem of chipOperatori;">
                                                <app-chip-operatore [chipItem]="chipItem">

                                                </app-chip-operatore>
                                            </ng-container>
                                        </div>
                                    </mat-chip-list>

                                </ng-container>
                            </ng-container>

                        </ng-container>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns; sticky: _configuration.sticky"
                                class="w-full justify-center mat-header-row-custom"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"
                         class="w-full justify-center mat-row-custom"
                         [ngClass]="{'highlighted-row': _configuration.highlightRowWhen && _configuration.highlightRowWhen(row),
                     'selected': focusable && selection1.isSelected(row)}"
                         [matRowKeyboardSelection]="selection1" [rowModel]="row"
                         [paginator]="paginator"
                         [selectOnFocus]="true" [deselectOnBlur]="true" >
                </mat-row>
                <tr class="mat-row margin flex fle-row justify-center items-center" *matNoDataRow>
                    <td class="mat-cell justify-content_center" [attr.colspan]=displayedColumns.length>
                        <br>{{(_configuration?.messaggioDatiAssenti) || messaggioDatiAssenti}}<br>
                    </td>
                </tr>


            </mat-table>

            <div *ngIf="!_configuration?.hidePaginator && !!this.dataSourcePaginated
        && this.dataSourcePaginated?.data && this.dataSourcePaginated?.data?.length > 0"
                 class="mat-paginator-sticky flex flex-row justify-end items-end"
                 [ngStyle]="!!_configuration?.cssMatPaginator ? _configuration?.cssMatPaginator : _configuration?.css">
                <mat-paginator
                    [length]="totalElements" [pageSize]="_configuration?.pageSize || 25"
                    [ngStyle]="_configuration?.css"
                    [pageIndex]="_configuration?.pageIndex"
                    [pageSizeOptions]="_configuration?.pageSizeOptions || [10, 25, 50, 100]" showFirstLastButtons
                    (page)="onPageChange($event)">
                </mat-paginator>
            </div>
        </div>

    </div>





