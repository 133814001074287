import {Injectable} from '@angular/core';
import {
    AddebitoDurataDialogConfig,
    GenericComponentDialogConfig
} from "../shared/interfaces/generic-components.interface";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GenericDialogComponent} from "../layout/common/dialogs/generic-dialog/generic-dialog.component";
import {FormGroup} from "@angular/forms";
import {TypeDialogFormEnum} from "../shared/enum/generic-components-enum";
import {
    AggiungiRichiestaDaEvadereModalComponent
} from "../shared/modals/aggiungi-richiesta-da-evadere-modal/aggiungi-richiesta-da-evadere-modal.component";
import {FuseConfirmationService} from "../../@fuse/services/confirmation";
import {CalendarEvent} from "angular-calendar";
import {getHoursArray} from "../shared/utils/utils";
import {
    ComunicazioneProgrammazioneModalComponent
} from "../shared/modals/comunicazione-programmazione-modal/comunicazione-programmazione-modal.component";
import {
    CampoDaSelezionare,
    CreazioneInterventoModalComponent
} from "../shared/modals/creazione-intervento-modal/creazione-intervento-modal.component";
import {
    GenericSelectFormModalComponent,
    SelectModalForm
} from "../shared/modals/generic-form-modal/generic-select-form-modal.component";
import {compact, join} from "lodash-es";
import {DialogForInterventoType} from "../shared/enum/dialog-intervento-confirm-enum";
import {CreaClienteModalComponent} from "../shared/modals/crea-cliente-modal/crea-cliente-modal.component";
import {finalize, Observable, take} from "rxjs";
import {
    AddOperatoreDTO,
    AddReferenteDTO,
    AddUnitaLocaleDTO,
    AnagraficheService,
    AttrezzaturaView,
    AutomezzoView, ClienteUnitaLocaleDTO,
    ClienteView,
    DurataAddebitoView,
    InterventoProgrammaDTO,
    InterventoView,
    NotaInterventoView,
    OperatoreView,
    PatchAttrezzaturaDTO,
    PatchAutomezzoDTO,
    PatchOperatoreDTO,
    PatchReferenteDTO,
    PatchUnitaLocaleDTO,
    ProgrammaDTO,
    ReferenteView,
    UnitaLocaleView,
    UserView
} from "../../api-clients/generated/services";
import {SnackbarTypes} from "../shared/enum/snackbar-types";
import {ManageErrorService, RequestTypesEnum} from "./manage-error.service";
import {UserModalComponent} from "../shared/modals/user-modal/user-modal.component";
import {AddebitoDurataModalComponent} from "../shared/modals/addebito-durata-modal/addebito-durata-modal.component";
import {GuidabileOperatoreEnum} from "../shared/interfaces/filter-interfaces";
import {flatten} from "lodash";
import {
    ModificaRichiestaDaEvadereComponent
} from "../shared/modals/modifica-richiesta-da-evadere/modifica-richiesta-da-evadere.component";
import {cellularRegex, emailRegex} from "../shared/constants/regex";
import {
    AggiungiInterventoPassatoModalComponent
} from "../shared/modals/aggiungi-intervento-passato/aggiungi-intervento-passato-modal.component";

@Injectable({
    providedIn: 'root'
})
export class DialogManagerService {
    arrayHours: Array<string>;

    constructor(private dialog: MatDialog,
                private fuseConfirmationService: FuseConfirmationService,
                private anagraficheService: AnagraficheService,
                private manageErrorService: ManageErrorService) {
        this.arrayHours = getHoursArray();
    }

    openRichiestaDaEvadereDialog() {
        return this.dialog.open(AggiungiRichiestaDaEvadereModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            height: 'auto',
            maxHeight: '90vh',
        });
    }

    openModalComunicazioneProgrammazione(interventi: InterventoProgrammaDTO[], date: string, programma?: ProgrammaDTO) {
        return this.dialog.open(ComunicazioneProgrammazioneModalComponent, {
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: true,
            data: {
                events: flatten(programma.righe.map((riga) => riga.interventi)),
                date: date,
                programma: programma
            }
        });
    }

    openModalAggiungiIntervento() {
        return this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                title: 'Aggiungi intervento',
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                close: true,
                hasSubHeader: true,
                icon: {
                    show: true,
                    name: 'add',
                    color: 'primary'
                },
                hideTabs: true,
                formValue: {
                    tipologia: InterventoView.TipologiaEnum.ATTIVITA,
                },
                dialogType: DialogForInterventoType.CREAZIONE,
            }
        });
    }

    openModalAggiungiInterventoNelPassato() {
        return this.dialog.open(AggiungiInterventoPassatoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                title: 'Aggiungi intervento nel passato',
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                close: true,
                hasSubHeader: true,
                icon: {
                    show: true,
                    name: 'add',
                    color: 'primary'
                },
                hideTabs: true,
                formValue: {
                    tipologia: InterventoView.TipologiaEnum.ATTIVITA,
                },
                dialogType: DialogForInterventoType.CREAZIONE,
            }
        });
    }

    openModalModificaInterventoNelPassato(event: Partial<CalendarEvent<InterventoView>>) {
        return this.dialog.open(AggiungiInterventoPassatoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                title: 'Modifica intervento nel passato',
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                close: true,
                hasSubHeader: true,
                icon: {
                    show: true,
                    name: 'add',
                    color: 'primary'
                },
                hideTabs: true,
                formValue: event.meta,
                dialogType: DialogForInterventoType.MODIFICA,
            }
        });
    }


    openModalInfoInterventoNotCalendar(intervento: InterventoView) {
        this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: 'Dettagli intervento',
                icon: {
                    show: true,
                    name: 'info',
                    color: 'primary'
                },
                readonly: true,
                showStatus: false,
                showCodiceIntervento: false,
                showOdl: true,
                dataWithGrayBackground: true,
                formValue: intervento,
                dialogType: DialogForInterventoType.VISUALIZZA,
                hideTabs: true,
            }
        });
    }

    openModalInfoIntervento(event: Partial<CalendarEvent<InterventoView>>) {
        this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: 'Dettagli intervento',
                icon: {
                    show: true,
                    name: 'info',
                    color: 'primary'
                },
                readonly: true,
                showStatus: false,
                showCodiceIntervento: false,
                showOdl: true,
                dataWithGrayBackground: true,
                //formValue: this.mapToInterventoData(event, true),
                formValue: event.meta,
                dialogType: DialogForInterventoType.VISUALIZZA,
            }
        });
    }

    openModalModificaIntervento(event: Partial<CalendarEvent<InterventoView>>) {
        return this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: 'Modifica intervento',
                icon: {
                    show: true,
                    name: 'edit',
                    color: 'primary'
                },
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                formValue: event.meta,
                dataWithGrayBackground: true,
                dialogType: DialogForInterventoType.MODIFICA,
            }
        });
    }

    openModalModificaInterventoDaEvadere(event: Partial<CalendarEvent<InterventoView>>) {
        return this.dialog.open(ModificaRichiestaDaEvadereComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: 'Modifica intervento',
                icon: {
                    show: true,
                    name: 'edit',
                    color: 'primary'
                },
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                formValue: event.meta,
                // dialogType: DialogForInterventoType.MODIFICA_DA_EVADERE,
                dialogType: DialogForInterventoType.MODIFICA,
            }
        });
    }

    openModalModificaMovimento(event: Partial<CalendarEvent<InterventoView>>) {
        return this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: 'Modifica intervento',
                icon: {
                    show: true,
                    name: 'edit',
                    color: 'primary'
                },
                readonly: false,
                showStatus: false,
                showCodiceIntervento: false,
                formValue: event.meta,
                dataWithGrayBackground: true,
                dialogType: DialogForInterventoType.MODIFICA_MOVIMENTO,
            }
        });
    }

    openModalSubmitIntervento(event: Partial<CalendarEvent<InterventoView>>) {
        return this.dialog.open(CreazioneInterventoModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: {
                close: true,
                hasSubHeader: true,
                title: event.meta.stato === InterventoView.StatoEnum.INCORSO ?
                    'Evadi intervento' : 'Avvia intervento',
                icon: {
                    show: true,
                    name: 'send',
                    color: 'primary'
                },
                readonly: true,
                showStatus: false,
                showCodiceIntervento: false,
                formValue: event.meta,
                dataWithGrayBackground: true,
                editDialog: true, // PER EVADERE
                dialogType: DialogForInterventoType.SUBMIT,
            }
        });
    }

    openModalCreazioneCliente(cliente: ClienteView, readonly: boolean,
                              iconName: string, title: string, hasSubHeader: boolean, isModifica?: boolean, isCreazioneCliente?: boolean) {
        return this.dialog.open(CreaClienteModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            data: {
                close: true,
                hasSubHeader: hasSubHeader,
                title: title,
                icon: {
                    show: true,
                    name: iconName,
                    color: 'primary'
                },
                readonly: readonly,
                showStatus: false,
                showCodiceIntervento: false,
                dataWithGrayBackground: false,
                dialogType: DialogForInterventoType.SUBMIT,
                clienteData: cliente,
                isModifica: !!isModifica,
                hideConfirmSubHeader: !!isModifica,
                isCreazioneCliente: !!isCreazioneCliente,
            }
        });
    }

    openModaleDiSelezione(field: CampoDaSelezionare,
                          value?: any,
                          datiCorrenti?: Array<ReferenteView | UnitaLocaleView | OperatoreView>,
                          idCliente?: string,
                          idOperatoriDaEscludere?: Array<string>,
                          onlyEnabledValues?: boolean,
                          ) {
        switch (field) {
            case CampoDaSelezionare.NOTA :
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Nota',
                            name: 'nota',
                            value: (value as NotaInterventoView)?.notaInterventoBucket?.descrizione,
                            fieldsToSearch: ['nota'],
                        },
                        // datiCorrenti: mockNote,
                        tableConfigurationType: CampoDaSelezionare.NOTA,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.CLIENTE :
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Cliente',
                            name: 'cliente',
                            value: value?.ragioneSociale || value?.estensioneRagioneSociale || value?.alias,
                            fieldsToSearch: ['descrizione'],
                        },
                        tableConfigurationType: CampoDaSelezionare.CLIENTE,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.TIPOLOGIA_INTERVENTO:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Tipologia intervento',
                            name: 'tipologiaIntervento',
                            value: value?.descrizione,
                            fieldsToSearch: ['descrizione'],
                        },
                        tableConfigurationType: CampoDaSelezionare.TIPOLOGIA_INTERVENTO,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.ATTREZZATURA:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Attrezzatura',
                            name: 'attrezzatura',
                            value: value?.descrizione,
                            fieldsToSearch: ['tipo'],
                        },
                        datiCorrenti: [],
                        tableConfigurationType: CampoDaSelezionare.ATTREZZATURA,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.AUTOMEZZO :
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Targa',
                            name: 'automezzo',
                            value: value?.targa,
                            fieldsToSearch: ['marca'],
                        },
                        tableConfigurationType: CampoDaSelezionare.AUTOMEZZO,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.RIMORCHIO :
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Targa',
                            name: 'automezzo',
                            value: value?.targa,
                            fieldsToSearch: ['marca'],
                        },
                        datiCorrenti: [].filter((item) => item.rimorchio),
                        tableConfigurationType: CampoDaSelezionare.RIMORCHIO,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.OPERATORE:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Operatore',
                            name: 'operatore',
                            value: (value as OperatoreView)?.nomeCognome,
                            fieldsToSearch: ['cognome'],
                        },
                        datiCorrenti: [],
                        tableConfigurationType: CampoDaSelezionare.OPERATORE,
                        idOperatoriDaEscludere: idOperatoriDaEscludere,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.ATTIVITA_DA_EVADERE:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Cliente',
                            name: 'cliente',
                            value: null,
                        },
                        title: 'Seleziona un\'attività da evadere',
                        datiCorrenti: value as InterventoView[],
                        tableConfigurationType: CampoDaSelezionare.ATTIVITA_DA_EVADERE
                    }
                ).afterClosed();
            case CampoDaSelezionare.UNITA_LOCALE:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Unità locale',
                            name: 'unitalocale',
                            value: value?.indirizzo,
                        },
                        title: 'Seleziona un\'unità locale',
                        datiCorrenti: datiCorrenti,
                        tableConfigurationType: CampoDaSelezionare.UNITA_LOCALE,
                        idCliente: idCliente,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.REFERENTE:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Referente',
                            name: 'referente',
                            value: (value as ReferenteView)?.nominativo,
                        },
                        title: 'Seleziona un referente',
                        datiCorrenti: datiCorrenti,
                        tableConfigurationType: CampoDaSelezionare.REFERENTE,
                        idCliente: idCliente,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
            case CampoDaSelezionare.OPERATORE_REGISTRO:
                return this.openSelectModal(
                    {
                        formConfig: {
                            placeholder: 'Operatore',
                            name: 'operaotre',
                            value: (value as OperatoreView)?.nomeCognome,
                        },
                        title: 'Seleziona un operatore',
                        datiCorrenti: datiCorrenti,
                        tableConfigurationType: CampoDaSelezionare.OPERATORE_REGISTRO,
                        idCliente: idCliente,
                        onlyEnabledValues: onlyEnabledValues
                    }
                ).afterClosed();
        }
    }

    openSelectModal(data: SelectModalForm) {
        return this.dialog.open(GenericSelectFormModalComponent, {
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: true,
            autoFocus: false,
            data: data,
        });
    }

    openModalAttivitadaEvadere(interventi: InterventoView[]) {
        return this.openSelectModal(
            {
                title: 'Lista attività da evadere',
                datiCorrenti: interventi,
                tableConfigurationType: CampoDaSelezionare.ATTIVITA_DA_EVADERE_READONLY,
                formConfig: {
                    placeholder: 'Cliente',
                    name: 'cliente',
                    fieldsToSearch: ['cliente'],
                },
            }
        )
    }

    openModalCreazioneReferenteCliente(idcliente: string): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            title: 'Creazione Referente cliente',
            message: 'Inserisci i dati del referente',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: true,
                    name: 'nominativo',
                    transloco: 'Nome e cognome',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'email',
                    transloco: 'E-mail',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                    regex: {
                        text: emailRegex,
                        pattern: emailRegex
                    }
                },
                {
                    show: true,
                    name: 'telefono',
                    transloco: 'Telefono',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                    regex: {
                        text: cellularRegex,
                        pattern: cellularRegex
                    }
                },
            ],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmReferente(form, dialogRef, idcliente)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
            autoFocus: false,
        })
    }

    modalConfirmReferente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, idcliente: string): void {
        this.fuseConfirmationService.showLoader();
        if (form.valid) {
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.addReferenteCliente(form.value, idcliente).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: (value: ClienteView) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Referente salvato con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(value);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.CREAZIONE_REFEFERENTE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    openModalCreazioneUnitaLocale(idcliente: string, sendResultWithUnita: boolean): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            title: 'Creazione Unità Locale',
            message: 'Inserisci i dati della nuova unità locale',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            isUnitaLocaleForm: true,
            formConfig: [
                {
                    show: true,
                    autofocus: true,
                    name: 'indirizzo',
                    transloco: 'Indirizzo',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'numero',
                    transloco: 'Numero',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'frazione',
                    transloco: 'Frazione',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'comune',
                    transloco: 'Comune',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'cap',
                    transloco: 'CAP',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'provincia',
                    transloco: 'Provincia',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
            ],
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmUnitaLocale(form, dialogRef, idcliente, sendResultWithUnita)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
            autoFocus: false,
        })
    }

    modalConfirmUnitaLocale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>,
                            idcliente: string, sendResultWithUnita: boolean): void {
        if (form.valid) {
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.addUnitaLocaleCliente(form.value, idcliente).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: (value: ClienteUnitaLocaleDTO) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Unità locale salvata con successo',
                            type: SnackbarTypes.Success,
                        });
                        if(sendResultWithUnita) {
                            dialogRef.close(value);
                        } else {
                            dialogRef.close(value.cliente);
                        }

                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.CREAZIONE_UNITA_LOCALE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }

    }

    openModaleCreazioneAttrezzatura(attrezzatura?: AttrezzaturaView, isModifica?: boolean) {
        const data: GenericComponentDialogConfig = {
            title: !!isModifica ? 'Modifica attrezzature' : 'Creazione Attrezzatura',
            message: !!isModifica ? 'Inserisci i dati per modificare l\'attrezzatura'
                : 'Inserisci i dati della nuova attrezzatura',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: false,
                    name: 'id',
                    transloco: 'id',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'descrizione',
                    transloco: 'Descrizione',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'quantita',
                    transloco: 'Quantità',
                    required: true,
                    onlyPositiveIntegers: true,
                    type: TypeDialogFormEnum.NUMBER,
                },
            ],
            valueForm: {
                id: attrezzatura?.id,
                descrizione: attrezzatura?.descrizione,
                quantita: attrezzatura?.quantita
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) => !isModifica ?
                        this.modalConfirmSaveAttrezzatura(form, dialogRef) :
                        this.modalConfirmEditAttrezzatura(form, dialogRef, attrezzatura?.id)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        });
    }

    //---MARK: AUTOMEZZO ---//
    openModaleModificaAutomezzo(automezzo?: AutomezzoView) {
        const data: GenericComponentDialogConfig = {
            title: 'Modifica automezzo',
            message: 'Inserisci i dati per modificare l\'automezzo',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: false,
                    name: 'id',
                    transloco: 'id',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'riferimento',
                    transloco: 'Riferimento',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'stato',
                    transloco: 'Stato',
                    required: true,
                    type: TypeDialogFormEnum.SELECT,
                    options: [
                        PatchAutomezzoDTO.StatoEnum.ATTIVO,
                        PatchAutomezzoDTO.StatoEnum.FERMO,
                        PatchAutomezzoDTO.StatoEnum.INMANUTENZIONE,
                        PatchAutomezzoDTO.StatoEnum.NONDISPONIBILE
                    ]
                },
                {
                    show: !automezzo.rimorchio,
                    name: 'isGuidabileDaOperatore',
                    transloco: 'Stato Guida',
                    required: !automezzo.rimorchio,
                    type: TypeDialogFormEnum.SELECT,
                    hideNullOption: true,
                    options: [
                        GuidabileOperatoreEnum.GUIDABILE_DA_OPERATORE,
                        GuidabileOperatoreEnum.NON_GUIDABILE_DA_OPERATORE,
                    ]
                },
                {
                    show: true,
                    name: 'nascosto',
                    transloco: 'Automezzo nascosto nel programma',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
                {
                    show: !automezzo.rimorchio,
                    name: 'ordineInProgramma',
                    transloco: 'Ordine in Programma',
                    required: !automezzo.rimorchio,
                    type: TypeDialogFormEnum.NUMBER,
                    onlyPositiveIntegers: true
                },
            ],
            valueForm: {
                id: automezzo?.id,
                riferimento: automezzo?.intRiferimento,
                stato: automezzo?.intStato,
                nascosto: automezzo?.intNascostoInProgramma,
                isGuidabileDaOperatore: automezzo?.intIsGuidabileDaOperatore ? GuidabileOperatoreEnum.GUIDABILE_DA_OPERATORE
                    : GuidabileOperatoreEnum.NON_GUIDABILE_DA_OPERATORE,
                ordineInProgramma: automezzo?.ordineInProgramma,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmEditAutomezzo(form, dialogRef, automezzo?.id)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        });
    }

    modalConfirmEditAutomezzo(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, id: string): void {
        if (form.valid) {
            const body: PatchAutomezzoDTO = {
                stato: form?.value?.stato,
                nascosto: form?.value?.nascosto,
                riferimento: form?.value?.riferimento,
                ordineInProgramma: form?.value?.ordineInProgramma,
                isGuidabileDaOperatore: (form?.value?.isGuidabileDaOperatore === GuidabileOperatoreEnum.GUIDABILE_DA_OPERATORE)
            }
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.patchAutomezzo(body, id).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Automezzo modificato con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(true);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.MODIFICA_AUTOMEZZO)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }
    modalConfirmSaveAttrezzatura(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>): void {
        if (form.valid) {
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.addAttrezzatura(form.value).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Attrezzatura salvata con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(true);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.CREAZIONE_ATTREZZATURA)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    modalConfirmEditAttrezzatura(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, id: string): void {
        if (form.valid) {
            const body: PatchAttrezzaturaDTO = {
                descrizione: form.value.descrizione,
                quantita: form.value.quantita,
                isCancellata: false,
            }
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.patchAttrezzatura(body, id).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Attrezzatura salvata con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(true);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.MODIFICA_ATTREZZATURA)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }

    }

    openModalCreazioneOperatore(operatore?: OperatoreView, isModifica?: boolean): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: true,
            title: !!isModifica ? 'Modifica Operatore' : 'Creazione Operatore',
            message: !!isModifica ? 'Inserisci i dati per modificare l\'operatore' :
                'Inserisci i dati per la creazione del nuovo operatore',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: !!isModifica,
                    name: 'nascosto',
                    transloco: 'Nascosto nel programma',
                    type: TypeDialogFormEnum.BOOLEAN,
                },
                {
                    show: true,
                    name: 'nomeCognome',
                    transloco: 'Nominativo',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                    disable: !operatore?.intIsManuale && !!isModifica
                },
                {
                    show: true,
                    name: 'tipologia',
                    transloco: 'Tipologia',
                    required: true,
                    type: TypeDialogFormEnum.SELECT,
                    hideNullOption: true,
                    disable: !operatore?.intIsManuale && !!isModifica,
                    options: [
                        OperatoreView.TipologiaEnum.OPERATORE,
                        OperatoreView.TipologiaEnum.AUTISTA,
                    ]
                },
                {
                    show: false,
                    name: 'id',
                    transloco: 'id',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: !operatore?.intIsManuale && !!isModifica
                },
            ],
            valueForm: {
                id: operatore?.id,
                nomeCognome: operatore?.nomeCognome,
                nascosto: operatore?.intNascostoInProgramma,
                tipologia: operatore?.tipologia
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        !!isModifica ? this.modalConfirmEditOperatore(form, dialogRef, operatore?.id) :
                            this.modalConfirmSaveOperatore(form, dialogRef)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmSaveOperatore(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>): void {
        if (form.valid) {
            const body: AddOperatoreDTO = {
                nominativo: form?.value?.nomeCognome,
                tipologia: form?.value?.tipologia,
            }
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.addOperatore(body).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Operatore salvato con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(true);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.CREAZIONE_OPERATORE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    modalConfirmEditOperatore(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, id: string): void {
        if (form.valid) {
            const body: PatchOperatoreDTO = {
                nomeCognome: form?.value?.nomeCognome,
                nascosto: form?.value?.nascosto,
                tipologia: form?.value?.tipologia
            }
            this.fuseConfirmationService.showLoader();
            this.anagraficheService.patchOperatore(body, id).pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())
            ).subscribe(
                {
                    next: () => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Operatore salvato con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(true);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.MODIFICA_OPERATORE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    ////////// REFERENTE
    openModalModificaReferente(idcliente: string, referente?: ReferenteView): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: true,
            title: 'Modifica referente',
            message: 'Inserisci i dati per modificare il referente',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: true,
                    name: 'isPrincipale',
                    transloco: 'Principale',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
                {
                    show: false,
                    name: 'id',
                    transloco: 'id',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'nominativo',
                    transloco: 'Nominativo',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'email',
                    transloco: 'E-mail',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'telefono',
                    transloco: 'Telefono',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                },
            ],
            valueForm: {
                id: referente.id,
                nominativo: referente.nominativo,
                email: referente.email,
                telefono: referente.telefono,
                isPrincipale: referente.isPrincipale,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmEditRefererente(form, dialogRef, idcliente, referente)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmEditRefererente(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, idcliente: string, referente?: ReferenteView) {
        if (form.valid) {
            const body: PatchReferenteDTO | AddReferenteDTO = {
                nominativo: form?.value?.nominativo,
                email: form?.value?.email,
                telefono: form?.value?.telefono,
                isPrincipale: form?.value?.isPrincipale,
            }
            const request$: Observable<any> = !!referente?.id ? this.anagraficheService.patchReferenteCliente(body, idcliente, referente?.id) :
                this.anagraficheService.addReferenteCliente(body, idcliente);
            this.fuseConfirmationService.showLoader();
            request$.pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())).subscribe(
                {
                    next: (value: ClienteView) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Referente salvato con successo',
                            type: SnackbarTypes.Success,
                        });
                        dialogRef.close(value);
                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.MODIFICA_REFEFERENTE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    openModalEliminaReferente(idcliente: string, referente: ReferenteView): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: true,
            title: 'Elimina referente',
            message: 'Sicuro di voler eliminare il referente?',
            icon: {
                show: true,
                name: 'delete',
                color: 'primary'
            },
            formConfig: [
                {
                    show: false,
                    name: 'confirm',
                    transloco: 'confirm',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
            ],
            valueForm: {
                confirm: true,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Sì, elimina',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmDeleteRefererente(dialogRef, idcliente, referente)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmDeleteRefererente(dialogRef: MatDialogRef<GenericDialogComponent>, idcliente: string, referente: ReferenteView) {
        const request$ = this.anagraficheService.deleteReferenteCliente(idcliente, referente.id)
        this.fuseConfirmationService.showLoader();
        request$.pipe(
            take(1),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (value: ClienteView) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Referente eliminato con successo',
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close(value);
                },
                error: (err) => {
                    this.manageErrorService.manageError(err, RequestTypesEnum.CANCELLAZIONE_REFEFERENTE)
                }
            }
        );
    }

    /// MARK: ADDEBITO DURATA
    openModalModificaAddebitoDurata(idcliente: string,
                                    addebitoDurata: DurataAddebitoView): MatDialogRef<AddebitoDurataModalComponent> {
        const data: AddebitoDurataDialogConfig = {
            addebitoDurata: addebitoDurata,
            idCliente: idcliente,
            hasSubHeader: true,
            title: 'Modifica Tipologia Intervento e Durata',
            message: 'Inserisci i dati per modificare la durata e la tipologia intervento',
            close: true,
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
        }

        return this.dialog.open(AddebitoDurataModalComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        })
    }


    /// ENDMARK: ADDEBITO DURATA

//////// UNITA LOCALE
    openModalModificaUnitaLocale(idcliente: string, unita?: UnitaLocaleView): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: false,
            isUnitaLocaleForm: true,
            title: 'Modifica unità locale',
            message: 'Inserisci i dati per modificare l\'unità locale',
            icon: {
                show: true,
                name: 'edit',
                color: 'primary'
            },
            formConfig: [
                {
                    show: true,
                    name: 'isPrincipale',
                    transloco: 'Principale',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
                {
                    show: false,
                    name: 'id',
                    transloco: 'id',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                },
                {
                    show: true,
                    name: 'indirizzo',
                    transloco: 'Indirizzo',
                    required: true,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
                {
                    show: true,
                    name: 'numero',
                    transloco: 'Numero',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
                {
                    show: true,
                    name: 'frazione',
                    transloco: 'Frazione',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
                {
                    show: true,
                    name: 'comune',
                    transloco: 'Comune',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
                {
                    show: true,
                    name: 'cap',
                    transloco: 'CAP',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
                {
                    show: true,
                    name: 'provincia',
                    transloco: 'Provincia',
                    required: false,
                    type: TypeDialogFormEnum.TEXT,
                    disable: unita.isWinWaste,
                },
            ],
            valueForm: {
                id: unita?.id,
                indirizzo: unita?.indirizzo,
                numero: unita?.numero,
                frazione: unita?.frazione,
                comune: unita?.comune,
                cap: unita?.cap,
                provincia: unita?.provincia,
                isPrincipale: unita?.isPrincipale,
                isWinWaste: unita?.isWinWaste,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Conferma',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmEditUnitaLocale(form, dialogRef, idcliente, unita)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive-full-screen',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmEditUnitaLocale(form: FormGroup, dialogRef: MatDialogRef<GenericDialogComponent>, idcliente: string,
                                unita?: UnitaLocaleView) {
        if (form.valid) {
            const body: PatchUnitaLocaleDTO | AddUnitaLocaleDTO = {
                indirizzo: form?.value?.indirizzo,
                numero: form?.value?.numero,
                frazione: form?.value?.frazione,
                comune: form?.value?.comune,
                cap: form?.value?.cap,
                provincia: form?.value?.provincia,
                isPrincipale: form?.value?.isPrincipale,
            }
            const request$: Observable<any> = !!unita?.id ?
                this.anagraficheService.patchUnitaLocaleCliente(body, idcliente, unita?.id) :
                this.anagraficheService.addUnitaLocaleCliente(body, idcliente);
            this.fuseConfirmationService.showLoader();
            request$.pipe(
                take(1),
                finalize(() => this.fuseConfirmationService.hideLoader())).subscribe(
                {
                    next: (value: ClienteView | ClienteUnitaLocaleDTO) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Unità locale salvata con successo',
                            type: SnackbarTypes.Success,
                        });
                        if(!!unita?.id) {
                            dialogRef.close(value as ClienteView);
                        } else {
                            dialogRef.close((value as ClienteUnitaLocaleDTO).cliente);
                        }

                    },
                    error: (err) => {
                        this.manageErrorService.manageError(err, RequestTypesEnum.MODIFICA_UNITA_LOCALE)
                    }
                }
            );
        } else {
            form.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    openModalEliminaUnitaLocale(idcliente: string, unita: UnitaLocaleView): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: true,
            title: 'Elimina unità locale',
            message: 'Sicuro di voler eliminare l\'unità locale?',
            icon: {
                show: true,
                name: 'delete',
                color: 'primary'
            },
            formConfig: [
                {
                    show: false,
                    name: 'confirm',
                    transloco: 'confirm',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
            ],
            valueForm: {
                confirm: true,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Sì, elimina',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmDeleteUnitaLocale(dialogRef, idcliente, unita)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmDeleteUnitaLocale(dialogRef: MatDialogRef<GenericDialogComponent>, idcliente: string, unita: UnitaLocaleView) {
        const request$ = this.anagraficheService.deleteUnitaLocaleCliente(idcliente, unita.id)
        this.fuseConfirmationService.showLoader();
        request$.pipe(
            take(1),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (value: ClienteView) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Tipologia intervento e durata eliminati con successo',
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close(value);
                },
                error: (err) => {
                    this.manageErrorService.showBackendErrorMessage(err);
                }
            }
        );
    }

    openModalEliminaAddebitoDurata(idcliente: string, addebitoDurata: DurataAddebitoView): MatDialogRef<GenericDialogComponent> {
        const data: GenericComponentDialogConfig = {
            isOneColumn: true,
            title: 'Elimina tipologia intervento e durata',
            message: 'Sicuro di voler eliminare la tipologia intervento e la durata?',
            icon: {
                show: true,
                name: 'delete',
                color: 'primary'
            },
            formConfig: [
                {
                    show: false,
                    name: 'confirm',
                    transloco: 'confirm',
                    required: false,
                    type: TypeDialogFormEnum.BOOLEAN,
                },
            ],
            valueForm: {
                confirm: true,
            },
            actions: {
                confirm: {
                    show: true,
                    label: 'Sì, elimina',
                    color: 'primary',
                    function: (form, dialogRef) =>
                        this.modalConfirmDeleteAddebitoDurata(dialogRef, idcliente, addebitoDurata)
                },
                cancel: {
                    show: true,
                    label: 'Annulla',
                }
            },
        };
        return this.dialog.open(GenericDialogComponent, {
            data: data,
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: false,
        })
    }

    modalConfirmDeleteAddebitoDurata(dialogRef: MatDialogRef<GenericDialogComponent>,
                                     idcliente: string, addebitoDurata: DurataAddebitoView) {
        const request$ = this.anagraficheService.deleteDurataAddebitoCliente(idcliente, addebitoDurata.id)
        this.fuseConfirmationService.showLoader();
        request$.pipe(
            take(1),
            finalize(() => this.fuseConfirmationService.hideLoader())
        ).subscribe(
            {
                next: (value: ClienteView) => {
                    this.fuseConfirmationService.openSnackBar({
                        message: 'Tipologia intervento e durata eliminati con successo',
                        type: SnackbarTypes.Success,
                    });
                    dialogRef.close(value);
                },
                error: (err) => {
                    this.manageErrorService.showBackendErrorMessage(err);
                }
            }
        );
    }


    openUserModal(user: UserView, readonly: boolean,
                  iconName: string, title: string, hasSubHeader: boolean) {
        return this.dialog.open(UserModalComponent, {
            panelClass: 'dialog-responsive',
            hasBackdrop: true,
            disableClose: true,
            data: {
                close: true,
                hasSubHeader: hasSubHeader,
                title: title,
                icon: {
                    show: true,
                    name: iconName,
                    color: 'primary'
                },
                readOnly: readonly,
                userData: user,
                // hideConfirmSubHeader: !!false,
            }
        });
    }
}
