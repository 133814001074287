<mat-chip *ngIf="isAutista; else notAutista"
          class="text-center custom-chip chip-width user-chip flex flex-row justify-center bg-primary-500">
    <span style="text-align: center; white-space: pre-line; word-break: break-word !important;"
          class="break-all color-white">{{ nominativoOperatore | uppercase | removeUnderscores }}</span>
</mat-chip>

<ng-template #notAutista>
    <mat-chip class="text-center custom-chip chip-width user-chip flex flex-row justify-center"
              color="primary">
    <span style="text-align: center; white-space: pre-line; word-break: break-word !important;"
          class="break-all">{{ nominativoOperatore | uppercase | removeUnderscores }}</span>
    </mat-chip>
</ng-template>
