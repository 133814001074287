import {SelectionModel} from '@angular/cdk/collections';
import {Directive, ElementRef, Host, HostListener, Input, OnDestroy, OnInit, Self} from '@angular/core';

import {debounce, debounceTime, distinctUntilChanged, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatRow, MatTable, MatTableDataSource} from "@angular/material/table";
import {TableSelectionManagerService} from "../../services/table-selection-manager.service";
import {MatPaginator} from "@angular/material/paginator";
import {clone, last} from "lodash-es";

@Directive({
    selector: '[matRowKeyboardSelection]',
    standalone: true
})
export class MatRowKeyboardSelectionDirective implements OnInit, OnDestroy {

    private selection: SelectionModel<any>;
    private dataSource: MatTableDataSource<any>;
    private rows: NodeListOf<HTMLElement>;
    private renderedData: any[];
    private paginatorOld: MatPaginator;

    @Input('matRowKeyboardSelection') set MatRowKeyboardSelection(selection) {
        this.selection = selection;
    }

    @Input() rowModel;
    @Input() toggleOnEnter = true;
    @Input() selectOnFocus = false;
    @Input() deselectOnBlur = false;
    @Input() preventNewSelectionOnTab = false;
    @Input() paginator: MatPaginator;

    private unsubscriber$ = new Subject();

    constructor(private el: ElementRef,
                @Host() @Self() private row: MatRow,
                @Host() private matTable: MatTable<any>,
                private tableSelectionManagerService: TableSelectionManagerService) {}

    ngOnInit(): void {
        if (!this.selection) {
            throw new Error('Attribute \'selection\' is required');
        }
        if (!this.matTable || !this.matTable.dataSource) {
            throw new Error('MatTable [dataSource] is required');
        }
        if (!this.rowModel) {
            throw new Error('[rowModel] is required');
        }
        if (this.el.nativeElement.tabIndex < 0) {
            this.el.nativeElement.tabIndex = 0;
        }
        this.dataSource = this.matTable.dataSource as MatTableDataSource<any>;
        this.dataSource.connect().pipe(
            takeUntil(this.unsubscriber$),
            debounceTime(300)
        ).subscribe(data => {
            this.renderedData = data;
            this.rows = this.getTableRows();
            if(!!this.tableSelectionManagerService.paginatorOld &&
                this.tableSelectionManagerService.paginatorOld.pageIndex !== this.paginator?.pageIndex) {
                if(this.tableSelectionManagerService.paginatorOld.pageIndex < this.paginator?.pageIndex) {
                    this.focusFirst(true);
                } else {
                    this.focusLast(true);
                }
            }
            this.tableSelectionManagerService.paginatorOld = clone(this.paginator);
        });

        this.contextChange();
    }

    contextChange() {
        this.tableSelectionManagerService.checkFocusOnTable$.asObservable().pipe(
            takeUntil(this.unsubscriber$)
        ).subscribe(
            {
                next: (value) => {
                    this.focusFirst(value);
                }
            }
        )

    }

    private focusFirst(value: boolean) {
        if (!!value && !!this.rows && this.rows.length > 0) {
            let firstRow = this.rows[0];
            firstRow.focus()
        }
    }

    private focusLast(value: boolean) {
        if (!!value && !!this.rows && this.rows.length > 0) {
            let lastRow = last(this.rows);
            lastRow.focus()
        }
    }

    ngOnDestroy(): void {
        this.unsubscriber$.next(true);
        this.unsubscriber$.complete();
    }

    @HostListener('focus', ['$event']) onFocus() {
        if (this.selectOnFocus && !this.selection.isMultipleSelection()) {
            this.selection.select(this.rowModel);
        }

        if (this.selectOnFocus && this.preventNewSelectionOnTab) {
            this.rows.forEach(row => {
                if (row !== this.el.nativeElement) {
                    row.tabIndex = -1;
                }
            });
        }
    }

    @HostListener('blur', ['$event']) onBlur() {
        if (this.deselectOnBlur && !this.selection.isMultipleSelection()) {
            this.selection.deselect(this.rowModel);
        }
        if (this.selectOnFocus) {
            this.el.nativeElement.tabIndex = 0;
        }
    }

    @HostListener('keydown', ['$event']) onKeydown(event: KeyboardEvent) {
        let newRow;
        const currentIndex = this.renderedData.findIndex(row => row === this.rowModel);
        if (event.key === 'ArrowDown' || event.key === "Enter" ) {
            console.log('this.rows', this.rows);
            newRow = this.rows[currentIndex + 1];
        } else if (event.key === 'ArrowUp') {
            newRow = this.rows[currentIndex - 1];
            if(currentIndex === 0) {
                this.selection.deselect(this.rowModel);
                this.tableSelectionManagerService.checkFocusOnSelectionFilterForm$.next(true);
            }
        } else if (event.key === 'F10') {
            if (this.toggleOnEnter) {
                console.log('AAA this.rowmodel', this.rowModel);
                this.selection.toggle(this.rowModel);
                this.tableSelectionManagerService.checkSelectionRowModel$.next(this.rowModel);
            }
            event.preventDefault();
        } else if (event.key === 'ArrowRight') {
            this.paginator.nextPage();
        } else if (event.key === 'ArrowLeft') {
            this.paginator.previousPage();
        }
        if (newRow) {
            newRow.focus();
        }
    }

    private getTableRows() {
        let el = this.el.nativeElement;
        while (el && el.parentNode) {
            el = el.parentNode;
            if (el.tagName && el.tagName.toLowerCase() === 'mat-table' || el.hasAttribute('mat-table')) {
                return el.querySelectorAll('mat-row, tr[mat-row]');
            }
        }
        return null;
    }

}
