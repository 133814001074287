<app-dialog-wrap [data]="data"
                 (close$)="closeModal($event)"
                 (confirm$)="saveForm()"
                 [formComponent]="formComponent">
    <ng-container *ngIf="formExist">
        <app-form [formGroupConfig]="data.formConfig"
                  [validatorFns]="data.formValidator"
                  [valueForm]="data.valueForm"
                  [touched]="touched"
                  [readOnly]="data.readOnly"
                  [isOneColumn]="data.isOneColumn"
                  [isUnitaLocaleForm]="data.isUnitaLocaleForm"
        ></app-form>

        <ng-container *ngIf="data?.alert">
            <fuse-alert
                    class="w-full"
                    [type]="data?.alert?.type"
                    [appearance]="'outline'"
            >{{data?.alert?.message}}</fuse-alert>
        </ng-container>

        <!--<ng-container *ngIf="data?.table && data?.table?.configuration" >
            <mat-label *ngIf="data?.table?.title">{{data?.table?.title}}</mat-label>
            <div style="border-radius: 6px;  border: 1px solid #e2e8f0;" class="mb-3 mt-2 mx-6">
                <app-generic-table  [configuration]="data?.table?.configuration">
                </app-generic-table>
            </div>
        </ng-container>-->

        <fuse-alert
                class="fuse-alter mt-8 "
                *ngIf="alert$ | async"
                [appearance]="'outline'"
                [type]="'error'"
                [@shake]="true">{{alert$ | async}}
        </fuse-alert>

    </ng-container>
</app-dialog-wrap>
<!--Form-->

<!-- Actions -->
<ng-container *ngIf="data.actions.confirm.show || data.actions.cancel.show">
    <div *ngIf="!data.hasSubHeader"
         class="flex items-center justify-center sm:justify-end px-6 py-4 space-x-3 bg-gray-50 dark:bg-black dark:bg-opacity-10">

        <!-- Cancel -->
        <!--<ng-container *ngIf="data.actions.cancel.show">
            <button color="accent" mat-flat-button [matDialogClose]="'cancelled'"
                    [disabled]="formComponent?.formGroup?.disabled && !data.readOnly">
                {{data.actions.cancel.label || 'Annulla'}}
            </button>
        </ng-container>-->

        <!-- Confirm -->
        <ng-container *ngIf="data.actions.confirm.show && !data.readOnly">
            <button mat-flat-button
                    (click)=" saveForm()"
                    [disabled]="formComponent?.formGroup?.disabled"
                    [color]="data.actions.confirm.color">
                                    <span>
                    {{data.actions.confirm.label || 'Conferma'}}
                                    </span>
                <mat-progress-spinner
                        style="margin-left: 5px"
                        *ngIf="formComponent?.formGroup?.disabled"
                        [diameter]="24"
                        [mode]="'indeterminate'"></mat-progress-spinner>
            </button>
        </ng-container>

    </div>
</ng-container>


