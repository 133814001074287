/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface User { 
    id?: string;
    username?: string;
    password?: string;
    email?: string;
    nome?: string;
    cognome?: string;
    creationDate?: Date;
    accountLocked: boolean;
    credentialsExpired: boolean;
    accountEnabled: boolean;
}