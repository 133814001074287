/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AuthorityType } from './authorityType';

export interface UserFilterForm { 
    /**
     * nomeCognome
     */
    nomeCognome?: string;
    /**
     * email
     */
    email?: string;
    authorityType?: AuthorityType;
    /**
     * sicurezza
     */
    credentialsExpired?: boolean;
    /**
     * stato utente
     */
    accountLocked?: boolean;
    /**
     * stato email
     */
    accountEnabled?: boolean;
}