import {Injectable} from '@angular/core';
import {catchError, finalize, of, switchMap} from 'rxjs';
import {LocalStorageService} from './local-storage.service';
import {tap} from "rxjs/operators";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {AuthControllerService} from "../../api-clients/generated/services";
import {FuseConfirmationService} from "../../@fuse/services/confirmation";
import {PathEnum} from "../shared/enum/path-enum";


@Injectable({
    providedIn: 'root'
})
export class LogoutService {

    constructor(private authService: AuthControllerService,
                private localStorageService: LocalStorageService,
                private fuseConfirmationService: FuseConfirmationService,
                private _router: Router,
                private matDialog: MatDialog) {
    }

    logout(): void {
        this.fuseConfirmationService.showLoader();
        this.authService.logoutUser().pipe(
            tap(() => this.localStorageService.cleanAllClassVariablesAndStorage()),
            catchError(() => {
                    this.localStorageService.cleanAllClassVariablesAndStorage();
                    return of(null);
                }
            ),
            finalize(() => {
                    this.fuseConfirmationService.hideLoader();
                }
            )
        ).subscribe({
            next: () => {
                this.matDialog.closeAll();
                this._router.navigate([PathEnum.SIGN_IN]);
            },
            error: () => {
                this.fuseConfirmationService.hideLoader();
            }
        });
    }

    logout$() {
        return this.authService.logoutUser().pipe(
            tap(() => this.localStorageService.cleanAllClassVariablesAndStorage()),
            catchError(() => {
                    this.localStorageService.cleanAllClassVariablesAndStorage();
                    return of(null);
                }
            ),
        )
    }
}
