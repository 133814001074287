export enum PathEnum {
    HOME = 'home',
    GESTIONE_UTENTI = 'gestione-utenti',
    GESTIONE_ANAGRAFICHE = 'gestione-anagrafiche',
    PROGRAMMAZIONE_INTERVENTI = 'programmazione-interventi', // COMPRENDE 'calendarizzazione', RICHIESTA_DA_EVADERE = 'richiesta-da-evadere',
    IMPOSTAZIONI = 'impostazioni',
    DISPONIBILITA_MEZZI = 'disponibilita-mezzi',
    VALIDAZIONE_INTERVENTI_ESEGUITI = 'validazione-interventi-eseguiti',
    RIVALIDAZIONE_SU_BASE_CONTRATTUALE = 'rivalidazione-su-base-contrattuale',
    PROFILO = 'profilo',
    SIGN_IN = 'sign-in',
    REGISTRI_OPERATORI = 'registri-operatori',
    REGISTRO_OPERATORI = 'registri-operatori',

}
