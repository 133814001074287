import {Injectable} from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class CalendarioInterventiManagerService {

    checkUpdateInterventi: BehaviorSubject<boolean> = new BehaviorSubject(null);
    lastFocusedInputElement: HTMLElement = null;

    constructor() {
    }

    aggiornaCalendarioInterventi() {
        this.checkUpdateInterventi.next(true)
    }

    setLastFocusedInputElement(lastFocusedInputElement: HTMLElement) {
        this.lastFocusedInputElement = lastFocusedInputElement;
    }

    focusOnLastFocusedInputElement() {
        this.lastFocusedInputElement.focus()
    }
}
