import {Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {
    FormArray,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidatorFn,
    Validators,
    ɵFormGroupValue,
    ɵTypedOrUntyped
} from "@angular/forms";
import moment from "moment";
import {
    getDaysArrayInCurrentMonth,
    getDaysArrayInMonth,
    getFormArrayFromFormGroupTyped,
    getFormControlFromFormGroupTyped,
    getHoursArray,
} from "../../utils/utils";
import {DialogWrapComponent, DialogWrapI} from "../../../layout/common/dialogs/dialog-wrap/dialog-wrap.component";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerInputEvent, MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {NgForOf, NgIf, NgSwitch} from "@angular/common";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatIconModule} from "@angular/material/icon";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {finalize, takeUntil, tap} from "rxjs";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_DIALOG_DATA, MatDialog, MatDialogClose, MatDialogRef} from "@angular/material/dialog";

import {NgxCurrencyDirective} from "ngx-currency";
import {GenericFormFieldComponent} from "../../components/generic-form-field/generic-form-field.component";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MatDividerModule} from "@angular/material/divider";
import {MatTooltipModule} from "@angular/material/tooltip";
import {CampoDaSelezionare} from "../creazione-intervento-modal/creazione-intervento-modal.component";
import {TipoClickEnum} from "../../components/generic-table/generic-table-model";
import {get} from "lodash";
import {CurrencyEuroPipe} from "../../pipe/currency-euro.pipe";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {AttivitaDaEvadere} from "../../interfaces/mock-interfaces/richiesta-da-evadere-mock";
import {compact, head, join} from "lodash-es";
import {PipeModule} from "../../pipe/pipe.module";
import {
    getTestoAutomezzo,
    getTestoNota,
    getTestoOperatore,
    getTestoReferente,
    getTestoUnitaLocale
} from "../../utils/mask-functions";
import {
    AddCalendarioDTO,
    AddebitoView,
    AddInterventoDaEvadereDTO,
    AddNotaInterventoDTO,
    AttrezzaturaView,
    AutomezzoView,
    ClienteView,
    InterventiService,
    InterventoProgrammaDTO,
    NotaInterventoBucketView, NotaProgrammaDTO,
    OperatoreView,
    ReferenteView,
    UnitaLocaleView
} from "../../../../api-clients/generated/services";
import {FuseConfirmationService} from "../../../../@fuse/services/confirmation";
import {SnackbarTypes} from "../../enum/snackbar-types";
import {ManageErrorService} from "../../../services/manage-error.service";
import {
    mapAddebitoViewToAddTipoInterventoDTO,
    mapAttrezzaturaViewToAddAttrezzaturaIntervento,
    mapAutomezzoViewToAddAutomezzoInterventoDTO,
    mapClienteViewToAddClienteInterventoDTO,
    mapOperatoreViewToAddOperatoreInterventoDTO,
    mapReferenteViewToReferenteIntervento,
    mapUnitaViewToUnitaLocaleIntervento
} from "../../utils/intervento-map-functions";
import {ClienteUnitaReferente} from "../generic-form-modal/generic-select-form-modal.component";
import {MatTabChangeEvent, MatTabGroup, MatTabsModule} from "@angular/material/tabs";
import {ProgrammazioneFormsManagerService} from "../../../services/programmazione-forms-manager.service";
import {AbstractFocusManagerComponent} from "../../abstracts/abstract-focus-manager.component";
import {CustomValidators} from "../../validators/custom-validators";
import GiorniSettimanaEnum = AddCalendarioDTO.GiorniSettimanaEnum;

@Component({
    selector: 'app-aggiungi-richiesta-da-evadere-modal',
    standalone: true,
    imports: [
        DialogWrapComponent,
        ReactiveFormsModule,
        MatInputModule,
        MatDatepickerModule,
        MatSelectModule,
        NgForOf,
        MatExpansionModule,
        MatIconModule,
        NgIf,
        MatCheckboxModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatDialogClose,
        NgxCurrencyDirective,
        GenericFormFieldComponent,
        MatDividerModule,
        MatTooltipModule,
        PipeModule,
        MatTabsModule,
        NgSwitch,
    ],
    templateUrl: './aggiungi-richiesta-da-evadere-modal.component.html',
    styleUrl: './aggiungi-richiesta-da-evadere-modal.component.scss',
    providers: [
        CurrencyEuroPipe,
        {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {floatLabel: 'always'}},
    ]
})
export class AggiungiRichiestaDaEvadereModalComponent extends AbstractFocusManagerComponent implements OnInit {
    attivitaFormGroup: FormGroup<AttivitaDaEvadereForm>;
    calendarioAttivitaFormGroup: FormGroup<CalendarioAttivitaDaEvadereForm>;
    arrayHours: Array<string>;
    wrapData: DialogWrapI = {
        title: 'Aggiungi richiesta da evadere',
        icon: {
            name: 'add',
            color: 'primary'
        },
        close: true,
        hasSubHeader: true,
    };
    errorRequiredMessage: string = 'Il campo è obbligatorio';
    ctrlDataNonConosciuta = new FormControl<boolean | null>(false);
    ctrlAttivitaPeriodica = new FormControl<boolean | null>(false);
    showDatiPeriodici: boolean = false;
    arrayTipoPeriodicita = [
        AddCalendarioDTO.TipoPeriodicitaEnum.SETTIMANALE,
        AddCalendarioDTO.TipoPeriodicitaEnum.MENSILE
    ]
    giorniDellaSettimana = Object.values(GiorniSettimanaEnum);
    giorniSelezionati: GiorniSettimanaEnum[] = [];
    giorniSelezionatiCasoMensile: GiorniSettimanaEnum[] = [];
    arrayMonthsDays: number[] = [];
    selectedMonthsDays: number[] = [];
    arrayHoursForInit: string[];
    arrayHoursForEnd: string[];
    ctrlDurata: FormControl = new FormControl();
    readonly: boolean;
    currentValue: ɵTypedOrUntyped<AttivitaDaEvadereForm, ɵFormGroupValue<AttivitaDaEvadereForm>, any>;
    arraySettimaneDelMese = Object.values(AddCalendarioDTO.NumeroSettimanaEnum);
    isSceltaGiornoMese: boolean;
    daysMap: { [key: string]: string } = {
        "LUN": "Lunedì",
        "MAR": "Martedì",
        "MER": "Mercoledì",
        "GIO": "Giovedì",
        "VEN": "Venerdì",
        "SAB": "Sabato",
        "DOM": "Domenica"
    };
    errorDateMessage = 'La data di fine deve essere maggiore della data di inizio';
    IsInterventoFormularioLabel = 'È un intervento formulario?';

    constructor(@Inject(MAT_DIALOG_DATA) public data: AttivitaDaEvadereDialogDataConfig,
                public dialogRef: MatDialogRef<AggiungiRichiestaDaEvadereModalComponent>,
                private dialog: MatDialog,
                private dialogManagerService: DialogManagerService,
                private interventiService: InterventiService,
                private fuseConfirmationService: FuseConfirmationService,
                private manageErrorService: ManageErrorService,
                protected el: ElementRef,
                protected renderer: Renderer2,
                private programmazioneFormsManagerService: ProgrammazioneFormsManagerService) {
        super(el, renderer);
        this.arrayHours = getHoursArray();
        this.arrayHoursForInit = this.arrayHours.slice(0, -1)
        this.arrayHoursForEnd = this.arrayHours.slice(1)
        this.arrayMonthsDays = getDaysArrayInCurrentMonth();
        this.readonly = !!this.data?.readonly;
    }

    ngOnInit(): void {
        this.setFormGroup();
        this.valueChange();

        this.attivitaFormGroup.setValidators(
            CustomValidators.comparisonOrarioValidator('oraInizioPrevista', 'oraFinePrevista')
            );
        this.calendarioAttivitaFormGroup.setValidators([
            CustomValidators.comparisonOrarioValidator('oraInizioPrevista', 'oraFinePrevista'),
            CustomValidators.comparisonDateValidator('dataInizio', 'dataFine')
        ]);
    }

    setFormGroup() {
        this.attivitaFormGroup = new FormGroup<AttivitaDaEvadereForm>({
            idOrdineLavoro: new FormControl(null),
            data: new FormControl(moment().add(1, "days").format('YYYY-MM-DD')),
            oraInizioPrevista: new FormControl('09:00'),
            oraFinePrevista: new FormControl('10:00'),
            stato: new FormControl(null),
            costo: new FormControl(null),
            numeroPreventivo: new FormControl(null),
            referenteUnico: new FormControl(null),
            referenteUnicoTel: new FormControl(null),
            tipologiaInterventoArray: new FormArray([this.createFormControl(null, [Validators.required])]),
            tipologiaInterventoArrayMask: new FormArray([this.createFormControl(null, [Validators.required])]),
            cliente: new FormControl(null, [Validators.required]),
            clienteMask: new FormControl(null, [Validators.required]),
            automezzo: new FormControl(null),
            automezzoMask: new FormControl(null),
            rimorchio: new FormControl(null),
            rimorchioMask: new FormControl(null),
            attrezzature: new FormArray([this.createFormControl()]),
            attrezzatureMask: new FormArray([this.createFormControl()]),
            operatori: new FormArray([this.createFormControl()]),
            operatoriMask: new FormArray([this.createFormControl()]),
            note: new FormArray([this.createFormControl()]),
            noteMask: new FormArray([this.createFormControl()]),
            unitaLocale: new FormControl(null),
            unitaLocaleMask: new FormControl(null),
            referenteCliente: new FormControl(null),
            referenteClienteMask: new FormControl(null),
            isInterventoFormulario: new FormControl(null),
        });

        this.calendarioAttivitaFormGroup = new FormGroup<CalendarioAttivitaDaEvadereForm>({
            numeroPeriodicita: new FormControl(1, {
                validators: [Validators.required, Validators.min(1)]
            }),
            tipoPeriodicita: new FormControl(AddCalendarioDTO.TipoPeriodicitaEnum.SETTIMANALE, {
                validators: [Validators.required]
            }),
            dataInizio: new FormControl(
                moment().add(1, "days").format('YYYY-MM-DD'), {
                    validators: [Validators.required]
                }),
            dataFine: new FormControl(
                moment().add(1, "days").add(1, "months").format('YYYY-MM-DD'), {
                    validators: [Validators.required]
                }),
            oraInizioPrevista: new FormControl('09:00', {validators: [Validators.required]}),
            oraFinePrevista: new FormControl('10:00', {validators: [Validators.required]}),
            giorniSettimana: new FormArray([this.createFormControl()]),
            giornoMese: new FormControl(null),
            numeroSettimana: new FormControl(null),
            giornoSettimana: new FormControl(null),
        }, {})
        this.calendarioAttivitaFormGroup.disable();
    }

    getAttivitaFormControlByPath(path: string): FormControl {
        return this.attivitaFormGroup.get(path) as FormControl;
    }

    createFormControl(value?: any, validators?: ValidatorFn[]) {
        return new FormControl<any | null>(value, {
            validators: validators || []
        });
    }

    getCalendarioFormControlByPath(path: string): FormControl {
        return this.calendarioAttivitaFormGroup?.get(path) as FormControl;
    }

    event(type: string, event: MatDatepickerInputEvent<Date>, formControl: FormControl) {
        formControl?.setValue(event.value);
        formControl.setErrors(null);
        formControl.setValidators([]);
    }

    valueChange() {
        this.ctrlDataNonConosciuta.valueChanges.pipe(
            tap((value) => {
                if (!!value) {
                    this.getAttivitaFormControlByPath('data').setValue(null, {emitEvent: false});
                    this.getAttivitaFormControlByPath('oraInizioPrevista').setValue(null, {emitEvent: false});
                    this.getAttivitaFormControlByPath('oraFinePrevista').setValue(null, {emitEvent: false});
                    this.getAttivitaFormControlByPath('data').disable();
                    this.getAttivitaFormControlByPath('oraInizioPrevista').disable();
                    this.getAttivitaFormControlByPath('oraFinePrevista').disable();
                } else {
                    this.getAttivitaFormControlByPath('data').setValue(
                        moment().add(1, "days").toDate(),
                        {emitEvent: false}
                    );
                    this.getAttivitaFormControlByPath('data').enable();
                    this.getAttivitaFormControlByPath('oraInizioPrevista').enable();
                    this.getAttivitaFormControlByPath('oraFinePrevista').enable();
                    this.getAttivitaFormControlByPath('data').setValue(moment().add(1, "days").format('YYYY-MM-DD'));
                }
            })
        ).subscribe()

        this.ctrlAttivitaPeriodica.valueChanges.pipe(
            tap((value) => {
                this.showDatiPeriodici = value;
                if (!!value) {
                    this.calendarioAttivitaFormGroup.enable();
                    this.ctrlDataNonConosciuta.setValue(false);
                } else {
                    this.calendarioAttivitaFormGroup.disable();
                }
            })
        ).subscribe()

        this.valueChangesOnDataInizioPeriodicita();
    }

    valueChangesOnDataInizioPeriodicita() {
        this.calendarioAttivitaFormGroup?.controls?.dataInizio?.valueChanges.pipe(
            tap((value) => console.log('AAA data', value)),
            tap((data) => {
                this.selectedMonthsDays = [];
                this.arrayMonthsDays = getDaysArrayInMonth(new Date(data).getFullYear(), (new Date(data).getMonth() + 1));
            })
        ).subscribe()
    }

    checkCalendarioFormGroupStatus() {
        if (!!this.calendarioAttivitaFormGroup.disabled) {
            return true
        } else {
            if (this.calendarioAttivitaFormGroup.valid) {
                if (this.isMensile()) {
                    return this.tabGroupMensile.selectedIndex === 0 ?
                        !!getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                            'giornoMese')?.value
                        : (!!getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                                'numeroSettimana')?.value &&
                            getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                                'giornoSettimana')?.value)
                } else {
                    return !!this.giorniSelezionati && this.giorniSelezionati.length > 0
                }
            } else {
                return true
            }
        }

    }

    onConfirmClick() {
        if (!!this.attivitaFormGroup.valid) {
            if (!!this.checkCalendarioFormGroupStatus()) {
                this.fuseConfirmationService.showLoader();
                console.log('AAA this.buildAddInterventoDaEvadereDTO()', this.buildAddInterventoDaEvadereDTO());
                this.interventiService.aggiungiInterventoDaEvadere(this.buildAddInterventoDaEvadereDTO()).pipe(
                    takeUntil(this.destroy$),
                    finalize(() => this.fuseConfirmationService.hideLoader())
                ).subscribe({
                    next: (value) => {
                        this.fuseConfirmationService.openSnackBar({
                            message: 'Richiesta da evadere creata con successo',
                            type: SnackbarTypes.Success,
                        });
                        this.closeModal(true);
                    },
                    error: (error) => {
                        console.error(error);
                        this.manageErrorService.showBackendErrorMessage(error)
                    }
                });
            } else {
                this.calendarioAttivitaFormGroup.markAllAsTouched()
                this.fuseConfirmationService.openSnackBar({
                    message: 'Nel caso di intervento periodico occorre selezionare i giorni e, eventualmente, la settimana',
                    type: SnackbarTypes.Error,
                });
            }
        } else {
            this.attivitaFormGroup.markAllAsTouched();
            this.fuseConfirmationService.openSnackBar({
                message: 'Uno o più valori inseriti non sono validi',
                type: SnackbarTypes.Error,
            });
        }
    }

    buildAddInterventoDaEvadereDTO(): AddInterventoDaEvadereDTO {
        let currentValue = this.attivitaFormGroup.value;
        return {
            dataOraInizioPrevista: !this.ctrlAttivitaPeriodica?.value ?
                this.calcolaDataOra(
                    moment(getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'data')?.value
                    ).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'oraInizioPrevista')
                )
                : undefined,
            dataOraFinePrevista: !this.ctrlAttivitaPeriodica?.value ?
                this.calcolaDataOra(
                    moment(getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'data')?.value
                    ).format('DD/MM/YYYY'),
                    getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'oraFinePrevista')
                )
                : undefined,
            costo: currentValue.costo,
            numeroPreventivo: currentValue.numeroPreventivo,
            referenteUnico: currentValue.referenteUnico,
            referenteUnicoTel: currentValue.referenteUnicoTel,
            automezzo: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.automezzo),
            rimorchio: mapAutomezzoViewToAddAutomezzoInterventoDTO(currentValue.rimorchio),
            tipiIntervento: (!!currentValue?.tipologiaInterventoArray
                && currentValue?.tipologiaInterventoArray.filter((op: AddebitoView) => !!op?.id)?.length > 0)
                ? currentValue?.tipologiaInterventoArray?.filter((op: AddebitoView) => !!op?.id).map(
                    (addebito, index) => mapAddebitoViewToAddTipoInterventoDTO(addebito)) : [],
            operatori: (!!currentValue?.operatori
                && currentValue?.operatori.filter((op: OperatoreView) => !!op?.id)?.length > 0)
                ? currentValue?.operatori?.filter((op: OperatoreView) => !!op?.id).map(
                    (operatore, index) => mapOperatoreViewToAddOperatoreInterventoDTO(operatore, index)) : [],
            cliente: mapClienteViewToAddClienteInterventoDTO(
                currentValue.cliente,
                mapUnitaViewToUnitaLocaleIntervento(currentValue.unitaLocale),
                mapReferenteViewToReferenteIntervento(currentValue.referenteCliente)
            ),
            note: this.mapNote(),
            attrezzature: (!!currentValue?.attrezzature
                && currentValue?.attrezzature.filter((attr: AttrezzaturaView) => !!attr?.id)?.length > 0)
                ? currentValue?.attrezzature?.filter((attr: OperatoreView) => !!attr?.id).map(
                    (attrezzatura, index) => mapAttrezzaturaViewToAddAttrezzaturaIntervento(attrezzatura, index)) : [],
            calendario: !!this.ctrlAttivitaPeriodica?.value ? this.buildAddCalendarioDTO() : undefined,
            isInterventoFormulario: currentValue.isInterventoFormulario,
        }
    }

    @ViewChild('tabGroupMensile') tabGroupMensile: MatTabGroup;

    buildAddCalendarioDTO(): AddCalendarioDTO {
        let calendarValue = this.calendarioAttivitaFormGroup.value;
        return {
            ripetiOgni: getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                'numeroPeriodicita')?.value,
            tipoPeriodicita: getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                'tipoPeriodicita')?.value,
            /*
            giorniSettimana: getFormArrayFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                'giorniSettimana')?.value,
                */
            giorniSettimana: this.isSettimanale() ? this.giorniSelezionati : undefined,
            giornoMese: (this.isMensile() && this.tabGroupMensile.selectedIndex === 0) ?
                getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                    'giornoMese')?.value : undefined,
            numeroSettimana: (this.isMensile() && this.tabGroupMensile.selectedIndex === 1) ?
                getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                    'numeroSettimana')?.value : undefined,
            giornoSettimana: (this.isMensile() && this.tabGroupMensile.selectedIndex === 1) ?
                getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                    'giornoSettimana')?.value : undefined,
            dataInizio: moment(getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                'dataInizio')?.value).format('DD/MM/YYYY'),
            dataFine: moment(getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
                'dataFine')?.value).format('DD/MM/YYYY'),
            oraInizio: this.transformTimeString(this.ctrlOraInizioCalendarizzazione()) as any,
            oraFine: this.transformTimeString(this.ctrlOraFineCalendarizzazione()) as any,
        }
    }

    ctrlOraInizioCalendarizzazione(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
            'oraInizioPrevista')
    }

    ctrlOraFineCalendarizzazione(): FormControl<string | null> {
        return getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
            'oraFinePrevista');
    }

    transformTimeString(input: FormControl): string {
        const date = this.calcolaDataOra(
            moment(getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup, 'dataInizio')?.value).format('DD/MM/YYYY'),
            input
        );
        return moment(date).format("HH:mm:ss.SSSSSSSSSZ");
    }


    mapNote(): Array<AddNotaInterventoDTO> {
        return getFormArrayFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'noteMask')?.controls
            .map((notaTestuale, index) => {
                let notaCtrlIndex = getFormArrayFromFormGroupTyped<AttivitaDaEvadereForm>(
                    this.attivitaFormGroup, 'note')?.at(index) as FormControl<NotaInterventoBucketView>
                    return {
                        idNotaInterventoBucket: this.checkUpdateNota(notaCtrlIndex, notaTestuale?.value) ?
                        notaCtrlIndex?.value?.id : undefined,
                        nota: notaTestuale?.value
                    }
                }
            ).filter((item) => !!item.nota)?.map((nota, index) => {
                return {
                    ...nota,
                    ordine: index + 1
                } as AddNotaInterventoDTO
            })
    }

    checkUpdateNota(notaCtrl: FormControl<NotaInterventoBucketView>, testoNota: string) {
        return notaCtrl?.value?.descrizione === testoNota;
    }


    calcolaDataOra(data: string, controlOrario: FormControl) {
        if (!!data && !!controlOrario?.value) {
            let arr = controlOrario?.value?.split(':')
            let hour = +arr[0]
            let minute = +arr[1]
            return moment(data, 'DD/MM/YYYY').hours(hour).minutes(minute).toDate();
        } else {
            return undefined
        }

    }

    setGiorno(giorno: GiorniSettimanaEnum): void {
        const index = this.giorniSelezionati.findIndex(selectedGiorno =>
            selectedGiorno === giorno);
        if (index > -1) {
            this.giorniSelezionati.splice(index, 1);
        } else {
            this.giorniSelezionati.push(giorno);
            this.sortByDay(this.giorniSelezionati);
        }
    }

    sortByDay(value: GiorniSettimanaEnum[]): GiorniSettimanaEnum[] {
        const sorter = Object.values(GiorniSettimanaEnum)
        return value.sort((a, b) => {
            return sorter.indexOf(a) - sorter.indexOf(b);
        });
    }

    setMonthDay(day: number): void {
        const index = this.selectedMonthsDays.findIndex(selectedDay =>
            selectedDay === day);
        if (index > -1) {
            this.selectedMonthsDays.splice(index, 1);
            this.ctrlGiornoMeseNumerico().setValue(null);
        } else {
            this.selectedMonthsDays = [];
            this.selectedMonthsDays.push(day);
            this.ctrlGiornoMeseNumerico().setValue(day);
        }
    }

    ctrlGiornoMeseNumerico(): FormControl<number | null> {
        return getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup,
            'giornoMese');
    }

    isSettimanale(): boolean {
        return this.getCalendarioFormControlByPath('tipoPeriodicita')?.value === AddCalendarioDTO.TipoPeriodicitaEnum.SETTIMANALE;
    }

    isMensile() {
        return this.getCalendarioFormControlByPath('tipoPeriodicita')?.value === AddCalendarioDTO.TipoPeriodicitaEnum.MENSILE;
    }

    closeModal($event: any) {
        this.dialogRef.close($event);
    }

    onConfirm($event: string) {
        this.onConfirmClick();
    }

    CampoDaSelezionare = CampoDaSelezionare;

    openModaleDiSelezione(field: CampoDaSelezionare, formControl: FormControl, formControlMask?: FormControl) {
        switch (field) {
            case CampoDaSelezionare.NOTA :
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value)
                    .subscribe(
                        {
                            next: (value) => {
                                if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                    const nota = getTestoNota(value.value);
                                    formControlMask?.setValue(nota);
                                    formControl?.setValue(value.value)
                                }
                            }
                        }
                    )
                break;
            case CampoDaSelezionare.CLIENTE :
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const _cliente: ClienteView = value.value
                                const descrizioneCliente = get(value.value, 'descrizione', null);
                                formControlMask?.setValue(descrizioneCliente);
                                formControl?.setValue(value.value)
                                // gestione form associati
                                let unitaPrincipale = (!!_cliente.unitaLocali && _cliente.unitaLocali.length > 0) ?
                                    (_cliente.unitaLocali.find((unita) => !!unita.isPrincipale) ||
                                    head(_cliente.unitaLocali)) : undefined;
                                let referentePrincipale = (!!_cliente.referenti && _cliente.referenti.length > 0) ?
                                    (_cliente.referenti.find((refernete) => !!refernete.isPrincipale) ||
                                    head(_cliente.referenti)) : undefined;
                                this.ctrlUnitaLocale().setValue(unitaPrincipale);
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(unitaPrincipale));
                                this.ctrlReferenteCliente().setValue(referentePrincipale);
                                this.ctrlReferenteClienteMask().setValue(getTestoReferente(referentePrincipale));
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.TIPOLOGIA_INTERVENTO:
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                formControlMask?.setValue(result);
                                formControl?.setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.ATTREZZATURA:
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'descrizione', null);
                                formControlMask?.setValue(result);
                                formControl?.setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.AUTOMEZZO :
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = get(value.value, 'codice', null);
                                formControlMask?.setValue(getTestoAutomezzo(value.value));
                                formControl?.setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.RIMORCHIO :
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                formControlMask?.setValue(getTestoAutomezzo(value.value));
                                formControl?.setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.OPERATORE:
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value).subscribe(
                    {
                        next: (value) => {
                            if (value?.tipoClick === TipoClickEnum.CONFERMA) {
                                const result = getTestoOperatore(value.value);
                                formControlMask?.setValue(result);
                                formControl?.setValue(value.value);
                            }
                        }
                    }
                )
                break;
            case CampoDaSelezionare.UNITA_LOCALE:
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value,
                    this.ctrlCliente()?.value?.unitaLocali, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value: ClienteUnitaReferente) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.unita) {
                                this.ctrlUnitaLocaleMask().setValue(getTestoUnitaLocale(value.unita));
                                this.ctrlUnitaLocale().setValue(value.unita);
                            }
                        }
                    }
                );
                break;
            case CampoDaSelezionare.REFERENTE:
                this.dialogManagerService.openModaleDiSelezione(field, formControl.value,
                    this.ctrlCliente()?.value?.referenti, this.ctrlCliente()?.value?.id).subscribe(
                    {
                        next: (value) => {
                            if (!!value.cliente) {
                                const cliente = this.clienteViewToClienteWithDescrizione(value.cliente)
                                const descrizioneCliente = get(cliente, 'descrizione', null);
                                this.ctrlClienteMask().setValue(descrizioneCliente);
                                this.ctrlCliente().setValue(cliente);
                            }
                            if (!!value.referente) {
                                this.ctrlReferenteClienteMask().setValue(getTestoReferente(value.referente));
                                this.ctrlReferenteCliente().setValue(value.referente);
                            }
                        }
                    }
                );
                break;
        }
    }

    clienteViewToClienteWithDescrizione(cliente: ClienteView) {
        return {
            ...cliente,
            descrizione: join(compact([cliente.ragioneSociale, cliente.estensioneRagioneSociale, cliente.alias]), ' - '),
            indirizzoCompleto: join(compact([
                head(cliente.unitaLocali)?.indirizzo,
                head(cliente.unitaLocali)?.frazione,
                head(cliente.unitaLocali)?.comune,
                head(cliente.unitaLocali)?.provincia]), ' - ')
        }
    }

    ctrlCliente(): FormControl<ClienteView> {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'cliente');
    }

    ctrlClienteMask(): FormControl<ClienteView> {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'clienteMask');
    }

    getFormArrayByPath(path: string): FormArray {
        return this.attivitaFormGroup.controls[path] as FormArray;
    }

    addFormControlToFormArray(path: string): void {
        this.getFormArrayByPath(path).push(this.createFormControl());
    }

    getFormControlByIndexInFormArrayByPath(path: string, index: number) {
        return this.getFormArrayByPath(path).at(index) as FormControl
    }

    removeFormControlToFormArray(path: string, index: number) {
        this.getFormArrayByPath(path).removeAt(index);
    }

    protected readonly CurrencyEuroPipe = CurrencyEuroPipe;

    ctrlUnitaLocale(): FormControl {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'unitaLocale');
    }

    ctrlUnitaLocaleMask(): FormControl {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'unitaLocaleMask');
    }

    ctrlReferenteCliente(): FormControl {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'referenteCliente');
    }

    ctrlReferenteClienteMask(): FormControl {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup, 'referenteClienteMask');
    }

    setNullCliente() {
        this.getAttivitaFormControlByPath('cliente').setValue(null);
        this.getAttivitaFormControlByPath('clienteMask').setValue(null);
        this.getAttivitaFormControlByPath('unitaLocale').setValue(null);
        this.getAttivitaFormControlByPath('unitaLocaleMask').setValue(null);
        this.getAttivitaFormControlByPath('referenteCliente').setValue(null);
        this.getAttivitaFormControlByPath('referenteClienteMask').setValue(null);
    }

    @HostListener('keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (this.el.nativeElement.contains(document.activeElement)) {
            const inputElements = this.el.nativeElement.querySelectorAll('.input-field-modal');
            const activeElement = document.activeElement as HTMLInputElement;
            let activeElementIndex = Array.from(inputElements).findIndex(input => input === activeElement);

            // Navigazione destra
            if (event.key === "ArrowDown" || event.key === "Enter") {
                do {
                    activeElementIndex = (activeElementIndex + 1) % inputElements.length;
                    const nextElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!nextElement.disabled) {
                        nextElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }

            // Navigazione sinistra
            if (event.key === "ArrowUp") {
                do {
                    activeElementIndex = (activeElementIndex - 1 + inputElements.length) % inputElements.length;
                    const previousElement = inputElements[activeElementIndex] as HTMLInputElement;
                    if (!previousElement.disabled) {
                        previousElement.focus();
                        event.preventDefault();
                        break;
                    }
                } while (activeElementIndex !== Array.from(inputElements).findIndex(input => input === activeElement))
            }
        }
    }

    onTabChanged($event: MatTabChangeEvent) {
        console.log('AAA tab $event', $event);
        if ($event?.index === 0) {
            this.isSceltaGiornoMese = true;
        } else {
            this.isSceltaGiornoMese = false;
        }
    }

    ctrlGiornoSettimanaCasoMensile(): FormControl<GiorniSettimanaEnum | null> {
        return getFormControlFromFormGroupTyped<CalendarioAttivitaDaEvadereForm>(this.calendarioAttivitaFormGroup, 'giornoSettimana')
    }

    setGiornoCasoPeriodicitaMensile(giorno: GiorniSettimanaEnum): void {
        const index = this.giorniSelezionatiCasoMensile.findIndex(selectedGiorno =>
            selectedGiorno === giorno);
        if (index > -1) {
            this.giorniSelezionatiCasoMensile.splice(index, 1);
            this.ctrlGiornoSettimanaCasoMensile().setValue(null);
        } else {
            this.giorniSelezionatiCasoMensile = [];
            this.giorniSelezionatiCasoMensile.push(giorno);
            this.ctrlGiornoSettimanaCasoMensile().setValue(giorno);
            this.sortByDay(this.giorniSelezionatiCasoMensile);
        }
    }

    replaceLastLetter(input: string): string {
        if (input.length === 0) {
            return input;
        }
        return input.slice(0, -1) + 'O';
    }

    convertDayAbbreviation(dayAbbr: AddCalendarioDTO.GiornoSettimanaEnum) {
        return this.daysMap[dayAbbr];
    }

    getDescrizioneRicorrenza() {
        const msg = 'La ricorrenza avverrà ogni';
        let order =
            this.replaceLastLetter(this.getCalendarioFormControlByPath('numeroSettimana')?.value).toLowerCase();
        let day = this.convertDayAbbreviation(this.ctrlGiornoSettimanaCasoMensile()?.value).toLowerCase();
        return `${msg} ${order} ${day} del mese`;
    }
    ctrlIsInterventoFormulario() {
        return getFormControlFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup,
            'isInterventoFormulario') as FormControl<boolean | null>
    }

    // MARK ADDEBITO //
    ctrlAddebitiFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup,
            'tipologiaInterventoArray') as FormArray;
    }

    ctrlAddebitiMaskFormArray(): FormArray {
        return getFormArrayFromFormGroupTyped<AttivitaDaEvadereForm>(this.attivitaFormGroup,
            'tipologiaInterventoArrayMask') as FormArray;

    }

    ctrlAddebito(index: number): FormControl {
        return this.ctrlAddebitiFormArray().at(index) as FormControl;
    }

    ctrlAddebitoMask(index: number): FormControl {
        return this.ctrlAddebitiMaskFormArray().at(index) as FormControl;
    }

    addAddebito() {
        this.addAddebitoFormControlToFormArray();
        this.addAddebitoMaskFormControlToFormArray();
    }

    addAddebitoFormControlToFormArray(): void {
        this.ctrlAddebitiFormArray().push(this.createFormControl(null, [Validators.required]));
    }

    addAddebitoMaskFormControlToFormArray(): void {
        this.ctrlAddebitiMaskFormArray().push(this.createFormControl(null, [Validators.required]));
    }

    removeAddebito(index: number) {
        this.removeAddebitoMaskFormControlFromFormArray(index);
        this.removeAddebitoFormControlFromFormArray(index);
    }

    removeAddebitoFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiFormArray().removeAt(index);
    }

    removeAddebitoMaskFormControlFromFormArray(index: number): void {
        this.ctrlAddebitiMaskFormArray().removeAt(index);
    }

    // ENDMARK ADDEBITO //
    cambiaValoreCheckbox() {
        const currentValue = this.ctrlIsInterventoFormulario().value;
        this.ctrlIsInterventoFormulario().setValue(!currentValue);
    }
}

export interface AttivitaDaEvadereForm {
    idOrdineLavoro?: FormControl<string | null>;
    data?: FormControl<string | null>;
    oraInizioPrevista?: FormControl<string | null>;
    oraFinePrevista?: FormControl<string | null>;
    stato?: FormControl<InterventoProgrammaDTO.StatoEnum | null>;
    descrizione?: FormControl<string | null>;
    costo?: FormControl<number | null>;
    numeroPreventivo?: FormControl<string | null>;
    referenteUnico?: FormControl<string | null>;
    referenteUnicoTel?: FormControl<string | null>;
    tipologiaInterventoArray: FormArray<FormControl<AddebitoView | null>>;
    tipologiaInterventoArrayMask: FormArray<FormControl<string | null>>;
    cliente?: FormControl<ClienteView | null>;
    clienteMask?: FormControl<string | null>;
    automezzo?: FormControl<AutomezzoView | null>;
    rimorchio?: FormControl<AutomezzoView | null>;
    attrezzature?: FormArray;
    operatori?: FormArray;
    note?: FormArray<FormControl<NotaInterventoBucketView>>;

    noteMask: FormArray;
    attrezzatureMask: FormArray;
    rimorchioMask?: FormControl<string | null>;
    automezzoMask?: FormControl<string | null>;
    operatoriMask: FormArray;
    referenteCliente?: FormControl<ReferenteView | null>;
    referenteClienteMask?: FormControl<string | null>;
    unitaLocale?: FormControl<UnitaLocaleView | null>;
    unitaLocaleMask?: FormControl<string | null>;

    isInterventoFormulario?: FormControl<boolean | null>;
}

export interface CalendarioAttivitaDaEvadereForm {
    numeroPeriodicita?: FormControl<number | null>;
    tipoPeriodicita?: FormControl<AddCalendarioDTO.TipoPeriodicitaEnum | null>;
    dataInizio?: FormControl<string | null>;
    dataFine?: FormControl<string | null>;
    oraInizioPrevista?: FormControl<string | null>;
    oraFinePrevista?: FormControl<string | null>;

    giorniSettimana: FormArray<FormControl<AddCalendarioDTO.GiorniSettimanaEnum | null>>;
    giornoMese: FormControl<number | null>;
    numeroSettimana: FormControl<AddCalendarioDTO.NumeroSettimanaEnum | null>;
    giornoSettimana: FormControl<AddCalendarioDTO.GiornoSettimanaEnum | null>;

}

export interface AttivitaDaEvadereDialogDataConfig {
    readonly?: boolean;
    attivitaDaEvadere?: AttivitaDaEvadere;
}
