/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface PatchOperatoreDTO { 
    nomeCognome?: string;
    tipologia?: PatchOperatoreDTO.TipologiaEnum;
    nascosto?: boolean;
    isCancellato?: boolean;
}
export namespace PatchOperatoreDTO {
    export type TipologiaEnum = 'AUTISTA' | 'OPERATORE';
    export const TipologiaEnum = {
        AUTISTA: 'AUTISTA' as TipologiaEnum,
        OPERATORE: 'OPERATORE' as TipologiaEnum
    };
}