import {AfterViewInit, Component, ElementRef, HostBinding, Input, OnInit, Renderer2} from '@angular/core';
import {inOutAnimation} from "../../animations/animation";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";



@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    inOutAnimation
  ],
  imports: [
    MatProgressSpinnerModule
  ],
  standalone: true
})
export class SpinnerComponent implements OnInit, AfterViewInit {

  @Input() colorVariable: string;
  @Input() diameter: number | undefined = 100;
  @Input() description = 'Caricamento in corso...';
  @Input()
  @HostBinding('attr.data-state')
  state: 'transparent' | 'gray' = 'gray';

  constructor(private element: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    this.renderer.addClass(document.body.parentElement, 'no-scroll');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body.parentElement, 'no-scroll');
  }
  ngAfterViewInit(){
    const element = this.element.nativeElement;
    if(!!element && element.style){
      element.style.bacakground = `rgba(0, 0, 0, 0.5)`;
      const svg = element.querySelector("svg");
      if(!!svg && !!svg.style){
        svg.style.width = '100%';
        svg.style.height = '100%';
      }
      const circle = element.querySelector("circle");
      if(!!circle && circle.style){
        circle.style.stroke = 'var(' + this.colorVariable + ')';
      }
    }

  }


}
