import {
    GenericTableConfigurationModel,
    TipoClickEnum,
    TipoColonnaEnum
} from "../../components/generic-table/generic-table-model";
import {AddebitoView} from "../../../../api-clients/generated/services";


export function createTableConfigurationForTipiIntervento(inputList?: Array<AddebitoView>,
                                                          isSelection?: boolean, listLength?: number, pageSize?: number,
                                                          pageIndex?: number, isCancellato?: boolean): GenericTableConfigurationModel {
    return {
        configuration: {
            data: inputList,
            totalElements: listLength,
            isPaginatedBE: true,
            sticky: true,
            pageIndex: pageIndex,
            configurazioneTabella: [
                {
                    tipo: TipoColonnaEnum.STRING,
                    nomeColonna: 'Descrizione',
                    colonnaKey: 'descrizione',
                    flex: 70,
                },
                {
                    tipo: TipoColonnaEnum.NUMBER,
                    nomeColonna: 'Costo',
                    colonnaKey: 'prezzo',
                    flex: 30,
                    numberSuffix: '€'
                },
                {
                    hideColonna: !isSelection,
                    nomeColonna: 'Aggiungi',
                    colonnaKey: 'actionSelection',
                    flex: 10,
                    tipo: TipoColonnaEnum.ACTION,
                    button: [{
                        nameIconButton: 'check',
                        click: TipoClickEnum.CONFERMA,
                        tooltip: 'Conferma questa opzione',
                        color: 'accent'
                    }]
                },
            ],
            hidePaginator: false,
            pageSize: pageSize,
            css: {
                'min-width': 'unset',
                'max-height': '65vh !important'
            },
            messaggioDatiAssenti: 'Nessun dato disponibile',
        }
    };
}
