<app-dialog-wrap [data]="wrapData"
                 (close$)="closeModal($event)"
                 (confirm$)="onConfirm($event)">
    <div class="flex flex-col w-full gap-4">
    <fuse-alert *ngIf="isAttividaDaEvadere"
                class="w-full mt-4 sm:mt-0"
                [showIcon]="true"
                [appearance]="'outline'"
                [type]="'info'"
    >{{alertMessage}}</fuse-alert>


    <ng-container *ngIf="!!attivitaProgrammate && attivitaProgrammate.length > 0; else noAttvitaProgrammate">
        <div class="flex flex-col w-full">
            <div class="mainOption">
                <span><b>{{'Riepilogo interventi programmati'}}</b></span>
            </div>
            <ng-container *ngFor="let event of attivitaProgrammate; let i = index">
                <div class="w-full"
                                 [class]="i%2 ? 'myoption' : null">
                    <div class="w-full flex flex-row justify-between">
                        <div class="flex flex-col justify-start items-start" [style.width.%]="40">
                            <span><b>Tipologia intervento: </b> {{mapDataEvent(event)}}</span>
                            <span><b>Cliente: </b>{{getClienteEvent(event)}}</span>
                            <span><b>Automezzo: </b>{{getAutomezzo(event)}}</span>
                            <ng-container *ngFor="let operatore of getOperatori(event); let i = index">
                                <span *ngIf="i === 0"><b>Autista: </b>{{operatore}}</span>
                                <span *ngIf="i !==0"><b>Operatore</b> {{i}}: {{operatore}}</span>
                            </ng-container>
                        </div>
                        <div class="flex flex-col justify-start items-end" [style.width.%]="40">
                            <span>{{'Ora inizio: ' + getOraInizioEvent(event)}}</span>
                            <span>{{'Ora fine: ' + getOraFineEvent(event)}}</span>
                        </div>

                    </div>

                </div>
            </ng-container>
        </div>
    </ng-container>
    </div>

</app-dialog-wrap>

<ng-template #noAttvitaProgrammate>
    <fuse-alert
            class="w-full"
            [type]="'info'"
            [appearance]="'outline'"
    >{{'Nessuna attività programmata in data ' + this.data.date + '. Proseguire con la comunicazione?'}}</fuse-alert>
</ng-template>
