<app-dialog-wrap [data]="data" (close$)="closeModal($event)"
                 (confirm$)="onConfirm($event)"
                 [dialogForInterventoType]="data.dialogType">
    <!-- CONTENT -->
    <div class="w-full flex flex-col gap-1" *ngIf="!!clienteFormGroup"
         [ngClass]="null">
        <div class="flex flex-col justify-between w-full">
            <div #sezioneIntestazione class="flex flex-col gap-2 w-full">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Dati cliente</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="w-full flex-row flex justify-between">
                    <!-- ragioneSociale -->
                    <app-generic-form-field [label]="'Ragione Sociale'"
                                            [style.width.%]="22"
                                            [control]="getClienteFormControlByPath('ragioneSociale')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Codice Fiscale'"
                                            [style.width.%]="22"
                                            [control]="getClienteFormControlByPath('codiceFiscale')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Partita Iva'"
                                            [style.width.%]="22"
                                            [control]="getClienteFormControlByPath('partitaIva')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Telefono'"
                                            [style.width.%]="22"
                                            [control]="getClienteFormControlByPath('telefono')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>
                </div>
            </div>

            <!--<div class="w-full flex-row flex justify-center">
                <button *ngIf="!!data?.clienteData?.intIsManuale && !!data?.isModifica;" mat-flat-button class="mr-2"
                        color="primary"
                        (click)="saveDatiCliente()"
                        [matTooltip]="'Salva dati cliente'">
                    <mat-icon class="ml-1" iconPositionEnd>
                        <span class="material-symbols-outlined">save</span>
                    </mat-icon>
                    {{'Salva dati cliente'}}
                </button>
            </div>-->
        </div>

        <div class="flex flex-col justify-between w-full">
            <div #sezioneSedeLegale class="flex flex-col gap-2 w-full">
                <div class="container">
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                    <div class="text mat-typography">Sede legale</div>
                    <div class="line">
                        <mat-divider></mat-divider>
                    </div>
                </div>
                <div class="w-full flex-row flex justify-between">
                    <app-generic-form-field [label]="'Indirizzo'"
                                            [style.width.%]="15"
                                            [control]="getClienteFormControlByPath('sedeLegaleIndirizzo')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Numero'"
                                            [style.width.%]="10"
                                            [control]="getClienteFormControlByPath('sedeLegaleNumero')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Frazione'"
                                            [style.width.%]="15"
                                            [control]="getClienteFormControlByPath('sedeLegaleFrazione')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Comune'"
                                            [style.width.%]="15"
                                            [control]="getClienteFormControlByPath('sedeLegaleComune')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'CAP'"
                                            [style.width.%]="15"
                                            [control]="getClienteFormControlByPath('sedeLegaleCap')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                    <app-generic-form-field [label]="'Provincia'"
                                            [style.width.%]="15"
                                            [control]="getClienteFormControlByPath('sedeLegaleProvincia')"
                                            [errorRequiredMessage]="errorRequiredMessage"
                                            [readonly]="data?.readonly"
                                            [readonlyform]="data?.isModifica && !data?.clienteData?.intIsManuale"
                    ></app-generic-form-field>

                </div>
            </div>

            <div class="w-full flex-row flex justify-center">
                <button *ngIf="!!data?.clienteData?.intIsManuale && !!data?.isModifica;" mat-flat-button class="mr-2"
                        color="primary"
                        (click)="saveDatiCliente()"
                        [matTooltip]="'Salva dati cliente'">
                    <mat-icon class="ml-1" iconPositionEnd>
                        <span class="material-symbols-outlined">save</span>
                    </mat-icon>
                    {{'Salva dati cliente'}}
                </button>
            </div>
        </div>

        <!-- ----------- MARK: REFERENTI ------------- -->
        <div>
            <div class="flex flex-col gap-5" [style.width.%]="100">
                <div #sezioneReferenti class="flex flex-col">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div *ngIf="ctrlReferentiFormArray()?.controls?.length === 1"
                             class="text mat-typography">Referente
                        </div>
                        <div *ngIf="ctrlReferentiFormArray()?.controls?.length > 1"
                             class="text mat-typography">Referenti
                        </div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <div class="pt-0.5 flex flex-col gap-3">
                        <ng-container *ngFor="let element of ctrlReferentiFormArray()?.controls; let i = index">
                            <div class="flex flex-row justify-between w-full">
                                    <div class="flex flex-row justify-between" [style.width.%]="92">
                                        <mat-checkbox *ngIf="data?.readonly || data?.isModifica; else chekboxEnabled"
                                                      [disabled]="data?.readonly || data?.isModifica"
                                                      class="align-items-baseline"
                                                      [checked]="getReferenteFormControlByPathAndIndex(i, 'isPrincipale')?.value">
                                            {{'Principale'}}
                                        </mat-checkbox>

                                        <ng-template #chekboxEnabled>
                                            <mat-checkbox class="align-items-baseline"
                                                          [formControl]="getReferenteFormControlByPathAndIndex(i, 'isPrincipale')">
                                                {{'Principale'}}
                                            </mat-checkbox>
                                        </ng-template>

                                        <app-generic-form-field [style.width.%]="26" [label]="'Nominativo'"
                                                                [control]="getReferenteFormControlByPathAndIndex( i,'nominativo')"
                                                                [errorRequiredMessage]="errorRequiredMessage"
                                                                [readonly]="data?.readonly"
                                                                [readonlyform]="data?.isModifica"
                                        ></app-generic-form-field>

                                        <app-generic-form-field [style.width.%]="26"
                                                                [label]="'E-mail'"
                                                                [control]="getReferenteFormControlByPathAndIndex( i,'email')"
                                                                [errorRequiredMessage]="errorRequiredMessage"
                                                                [readonly]="data?.readonly"
                                                                [readonlyform]="data?.isModifica"
                                        ></app-generic-form-field>

                                        <app-generic-form-field [style.width.%]="26" [label]="'Telefono'"
                                                                [control]="getReferenteFormControlByPathAndIndex( i,'telefono')"
                                                                [errorRequiredMessage]="errorRequiredMessage"
                                                                [readonly]="data?.readonly"
                                                                [readonlyform]="data?.isModifica"
                                        ></app-generic-form-field>
                                    </div>

                                    <div class="flex flex-row justify-between pt-2.5" *ngIf="!data.isModifica && !data?.readonly">
                                        <mat-icon color="primary" class="cursor-pointer"
                                                  (click)="addReferenteToFormArray()"
                                                  matTooltip="Aggiungi referente"
                                                  *ngIf="i === 0">add
                                        </mat-icon>

                                        <mat-icon color="warn"
                                                  *ngIf="!data.isModifica && ctrlReferentiFormArray()?.controls?.length > 1 && (i > 0)"
                                                  matTooltip="Rimuovi referente" class="cursor-pointer"
                                                  (click)="removeReferenteFromFormArray(i)">delete
                                        </mat-icon>
                                    </div>

                                    <div class="flex flex-row justify-between pt-2.5"
                                         *ngIf="!data?.readonly && !!data.isModifica">
                                        <mat-icon color="primary"
                                                 class="cursor-pointer"
                                                  (click)="modificaReferente(i)">edit
                                        </mat-icon>

                                        <mat-icon color="warn"
                                                  *ngIf="!!getReferenteFormControlByPathAndIndex(i, 'id')?.value
                                                            && ctrlReferentiFormArray()?.controls?.length > 1"
                                                  matTooltip="Rimuovi definitivamente referente" class="cursor-pointer"
                                                  (click)="removeReferente(i)">delete_forever
                                        </mat-icon>
                                    </div>
                                </div>

                        </ng-container>
                        <div class="p-1 flex flex-row justify-end" *ngIf="!data?.readonly && !!data.isModifica">
                            <button mat-flat-button class="mr-2" color="primary"
                                    [matTooltip]="'Aggiungi referente'"
                                    (click)="aggiungiReferente()">
                                <mat-icon class="ml-1 text-current" >
                                    <span class="material-symbols-outlined">add</span>
                                </mat-icon>
                                {{'Aggiungi referente'}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ----------- ENDMARK: REFERENTI --------------->

        <!-- ----------- MARK: UNITA LOCALI --------------->
        <div>
            <div class="flex flex-col gap-5" [style.width.%]="100">
                <div #sezioneIndirizzo class="flex flex-col">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div *ngIf="ctrlUnitaLocaliFormArray()?.controls?.length === 1"
                             class="text mat-typography">Unità locale
                        </div>
                        <div *ngIf="ctrlUnitaLocaliFormArray()?.controls?.length > 1"
                             class="text mat-typography">Unità locali
                        </div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <div class="pt-0.5 flex flex-col gap-3">
                        <ng-container *ngFor="let element of ctrlUnitaLocaliFormArray()?.controls; let i = index">
                            <div class="flex flex-row justify-between">
                                <div class="flex flex-row justify-between" [style.width.%]="92">
                                    <mat-checkbox *ngIf="data?.readonly || data?.isModifica; else chekboxEnabled2"
                                                  [disabled]="data?.readonly || data?.isModifica"
                                                  class="align-items-baseline"
                                                  [checked]="getUnitaLocaleFormControlByPathAndIndex(i, 'isPrincipale')?.value">
                                        {{'Principale'}}
                                    </mat-checkbox>

                                    <ng-template #chekboxEnabled2>
                                        <mat-checkbox class="align-items-baseline"
                                                      [formControl]="getUnitaLocaleFormControlByPathAndIndex(i, 'isPrincipale')">
                                            {{'Principale'}}
                                        </mat-checkbox>
                                    </ng-template>

                                    <app-generic-form-field [label]="'Indirizzo'" [style.width.%]="15"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'indirizzo')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'Numero'" [style.width.%]="10"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'numero')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'Frazione'" [style.width.%]="15"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'frazione')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'Comune'" [style.width.%]="15"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'comune')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'CAP'" [style.width.%]="10"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'cap')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'Provincia'" [style.width.%]="10"
                                                            [control]="getUnitaLocaleFormControlByPathAndIndex( i,'provincia')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>
                                </div>

                                <div class="flex flex-row justify-between pt-2.5" *ngIf="!data.isModifica && !data?.readonly">
                                    <mat-icon color="primary" class="cursor-pointer"
                                              (click)="addUnitaLocaleToFormArray()"
                                              matTooltip="Aggiungi unità locale"
                                              *ngIf="i === 0">
                                        add
                                    </mat-icon>

                                    <mat-icon color="warn"
                                              *ngIf="!data.isModifica && ctrlUnitaLocaliFormArray()?.controls?.length > 1 && (i > 0)"
                                              matTooltip="Rimuovi unità locale" class="cursor-pointer"
                                              (click)="removeUnitaLocaleFromFormArray(i)">
                                        delete
                                    </mat-icon>
                                </div>

                                <div class="flex flex-row justify-between pt-2.5"
                                     *ngIf="!data?.readonly && !!data.isModifica">
                                    <mat-icon color="primary"
                                             class="cursor-pointer"
                                              (click)="modificaUnitaLocale(i)">edit
                                    </mat-icon>

                                    <mat-icon color="warn"
                                              *ngIf="!!getUnitaLocaleFormControlByPathAndIndex(i, 'id')?.value
                                              && ctrlUnitaLocaliFormArray()?.controls?.length > 1
                                                && !getUnitaLocaleFormControlByPathAndIndex(i, 'isWinWaste')?.value"
                                              matTooltip="Rimuovi definitivamente unità locale" class="cursor-pointer"
                                              (click)="removeUnitaLocale(i)">delete_forever
                                    </mat-icon>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="!data?.readonly && !!data.isModifica"
                            class="p-1 flex flex-row justify-end">
                            <button mat-flat-button class="mr-2" color="primary"
                                    [matTooltip]="'Aggiungi unità locale'"
                                    (click)="aggiungiUnitaLocale()">
                                <mat-icon class="ml-1 text-current" >
                                    <span class="material-symbols-outlined">add</span>
                                </mat-icon>
                                {{'Aggiungi unità locale'}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ----------- ENDMARK: INDIRIZZO --------------->
            </div>
        </div>
        <!-- ----------- ENDMARK: UNITA LOCALI --------------->

        <!-- ----------- MARK: ADDEBITI-DURATE --------------->
        <div *ngIf="!data.isCreazioneCliente">
            <div class="flex flex-col gap-5" [style.width.%]="100">
                <div #sezioneAddebitiDurate class="flex flex-col">
                    <div class="container">
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                        <div class="text mat-typography">Tipologie interventi e durate</div>
                        <div class="line">
                            <mat-divider></mat-divider>
                        </div>
                    </div>

                    <div class="pt-0.5 flex flex-col gap-3">
                        <ng-container *ngFor="let element of ctrlAddebitoDurataFormArray()?.controls; let k = index">
                            <div class="flex flex-row justify-between">
                                <div class="flex flex-row justify-between" [style.width.%]="92">

                                    <app-generic-form-field [label]="'Tipologia Intervento'" [style.width.%]="48"
                                                            [control]="getAddebitoDurataFormControlByPathAndIndex( k,'addebitoMask')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>

                                    <app-generic-form-field [label]="'Durata'" [style.width.%]="48"
                                                            [control]="getAddebitoDurataFormControlByPathAndIndex( k,'durata')"
                                                            [errorRequiredMessage]="errorRequiredMessage"
                                                            [readonly]="data?.readonly"
                                                            [readonlyform]="data?.isModifica"
                                    ></app-generic-form-field>
                                </div>

                                <div class="flex flex-row justify-between pt-2.5" *ngIf="!data.isModifica && !data?.readonly">
                                    <mat-icon color="primary" class="cursor-pointer"
                                              (click)="addAddebitoDurataToFormArray()"
                                              matTooltip="Aggiungi Tipologia Intervento e durata"
                                              *ngIf="k === 0">
                                        add
                                    </mat-icon>

                                    <mat-icon color="warn"
                                              *ngIf="!data.isModifica && ctrlAddebitoDurataFormArray()?.controls?.length > 1 && (k > 0)"
                                              matTooltip="Rimuovi Tipologia Intervento e durata" class="cursor-pointer"
                                              (click)="removeAddebitoDurataFromFormArray(k)">
                                        delete
                                    </mat-icon>
                                </div>

                                <div class="flex flex-row justify-between pt-2.5"
                                     *ngIf="!data?.readonly && !!data.isModifica">
                                    <mat-icon color="primary"
                                              class="cursor-pointer"
                                              (click)="modificaAddebitoDurata(k)">edit
                                    </mat-icon>
                                    <mat-icon color="warn"
                                              *ngIf="!!getAddebitoDurataFormControlByPathAndIndex(k, 'id')?.value
                                              && ctrlAddebitoDurataFormArray()?.controls?.length > 0"
                                              matTooltip="Rimuovi definitivamente Tipologia Intervento e Durata" class="cursor-pointer"
                                              (click)="removeAddebitoEDurata(k)">delete_forever
                                    </mat-icon>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="!data?.readonly && !!data.isModifica"
                             class="p-1 flex flex-row justify-end">
                            <button mat-flat-button class="mr-2" color="primary"
                                    [matTooltip]="'Aggiungi Tipologia Intervento e durata'"
                                    (click)="aggiungiAddebito()">
                                <mat-icon class="ml-1 text-current" >
                                    <span class="material-symbols-outlined">add</span>
                                </mat-icon>
                                {{'Aggiungi Tipologia Intervento e durata'}}
                            </button>
                        </div>
                    </div>
                </div>
                <!-- ----------- ENDMARK: INDIRIZZO --------------->
            </div>
        </div>
        <!-- ----------- ENDMARK: ADDEBITI-DURATE --------------->
    </div>
</app-dialog-wrap>


