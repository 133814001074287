import {Component, EventEmitter, HostListener, Input, Output, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatDialogActions, MatDialogClose, MatDialogContent} from "@angular/material/dialog";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {GenericComponentDialogConfig} from "../../../../shared/interfaces/generic-components.interface";
import {FormComponent} from "../../form/form.component";
import {MatChipsModule} from "@angular/material/chips";
import {PipeModule} from "../../../../shared/pipe/pipe.module";
import {
    DialogForInterventoType,
    DialogInterventoConfirmEnum,
} from "../../../../shared/enum/dialog-intervento-confirm-enum";
import {InterventoProgrammaDTO} from "../../../../../api-clients/generated/services";

@Component({
    selector: 'app-dialog-wrap',
    standalone: true,
    imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogContent, MatProgressSpinnerModule, MatDialogClose, MatChipsModule, PipeModule, MatDialogActions],
    templateUrl: './dialog-wrap.component.html',
    styleUrl: './dialog-wrap.component.scss'
})
export class DialogWrapComponent {

    @Input() data: GenericComponentDialogConfig;
    @Output() close$: EventEmitter<void> = new EventEmitter<void>();
    @Output() confirm$: EventEmitter<string> = new EventEmitter<string>();
    @Input() formComponent: FormComponent;
    @Input() showChip: boolean | undefined;
    @Input() statusChip: InterventoProgrammaDTO.StatoEnum | undefined
    @Input() dialogForInterventoType: DialogForInterventoType;
    @Input() hiddenScrollY: boolean;

    protected readonly InterventoProgrammaDTO = InterventoProgrammaDTO;

    @HostListener('keydown.Escape', ['$event'])
    handleKeyboardF10Event(event: KeyboardEvent) {
        event.preventDefault();
        this.close$.emit();
    }

}

export interface DialogWrapI {
    close?: boolean;
    title?: string;
    message?: string;
    icon?: {
        name?: string;
        color?: 'primary' | 'accent' | 'warn' | 'basic' | 'info' | 'success' | 'warning' | 'error';
    };
    hasSubHeader?: boolean;
    hideConfirmSubHeader?: boolean;
}

