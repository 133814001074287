import {Component} from '@angular/core';
import {FuseDrawerComponent} from "../../../../@fuse/components/drawer";
import {FuseNavigationItem} from "../../../../@fuse/components/navigation";
import {MenuItemTitleEnum} from "../../enum/menu-item-title-enum";
import {takeUntil} from "rxjs";
import {PathEnum} from "../../enum/path-enum";
import {AbstractDefaultComponent} from "../../abstracts/abstract-default-component";
import {DialogManagerService} from "../../../services/dialog-manager.service";
import {CalendarioInterventiManagerService} from "../../../services/calendario-interventi-manager.service";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {navbarAdminCompactNavigation, navbarCompactNavigation} from "../../constants/nav-bar-compact-navigation";
import {NgForOf} from "@angular/common";
import {LocalStorageService} from "../../../services/local-storage.service";

@Component({
    selector: 'app-mobile-header-action-menu',
    standalone: true,
    imports: [
        FuseDrawerComponent,
        MatButtonModule,
        MatIconModule,
        NgForOf
    ],
    templateUrl: './mobile-header-action-menu.component.html',
    styleUrl: './mobile-header-action-menu.component.scss'
})
export class MobileHeaderActionMenuComponent extends AbstractDefaultComponent {

    navbarCompactNavigation: Array<Partial<FuseNavigationItem>> = navbarCompactNavigation;

    constructor(private _dialogManagerService: DialogManagerService,
                private _calendarioInterventiManagerService: CalendarioInterventiManagerService,
                private localStorageService: LocalStorageService) {
        super();
        if(this.localStorageService.isAdminOrCed()) {
            this.navbarCompactNavigation = navbarAdminCompactNavigation;
        } else {
            this.navbarCompactNavigation = navbarCompactNavigation;
        }
    }

    openNavbarItemModal(item: Partial<FuseNavigationItem>) {
        switch (item.title) {
            case MenuItemTitleEnum.RICHIESTE_DA_EVADERE:
                this._dialogManagerService.openRichiestaDaEvadereDialog().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                ;
                break;
            case MenuItemTitleEnum.INTERVENTI:
                this._dialogManagerService.openModalAggiungiIntervento().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                break;
            case MenuItemTitleEnum.INTERVENTO_PASSATO:
                this._dialogManagerService.openModalAggiungiInterventoNelPassato().afterClosed().pipe(
                    takeUntil(this.destroy$)
                ).subscribe({
                        next: (value) => {
                            if (!!value) {
                                this._calendarioInterventiManagerService.aggiornaCalendarioInterventi();
                            }
                        }
                    }
                );
                break;
            case MenuItemTitleEnum.ATTREZZATURE:
                this.addAttrezzatura();
                break;
            case MenuItemTitleEnum.CLIENTE:
                this.addCliente();
                break;
        }
    }

    addAttrezzatura() {
        this._dialogManagerService.openModaleCreazioneAttrezzatura().afterClosed().pipe(
            takeUntil(this.destroy$),
        ).subscribe({
                next: (value) => {

                }
            }
        );
    }

    addCliente() {
        this._dialogManagerService.openModalCreazioneCliente(undefined, false,
            'add', 'Crea Cliente', true, undefined, true).afterClosed().pipe(
            takeUntil(this.destroy$)
        ).subscribe({}
        )
    }


}
