import {Component, Input} from '@angular/core';
import {MatChipsModule} from "@angular/material/chips";
import {NgIf, UpperCasePipe} from "@angular/common";
import {PipeModule} from "../../pipe/pipe.module";
import {OperatoreInterventoView} from "../../../../api-clients/generated/services";
import {getTestoOperatore} from "../../utils/mask-functions";

@Component({
  selector: 'app-chip-operatore',
  standalone: true,
    imports: [
        MatChipsModule,
        UpperCasePipe,
        PipeModule,
        NgIf
    ],
  templateUrl: './chip-operatore.component.html',
  styleUrl: './chip-operatore.component.scss'
})
export class ChipOperatoreComponent {
    nominativoOperatore: string;
    isAutista: boolean;
    @Input() set chipItem( chipItem: OperatoreInterventoView) {
        this.nominativoOperatore = getTestoOperatore(chipItem.operatore)
        this.isAutista = chipItem.isAutista;
    }

    constructor() {

    }

}
