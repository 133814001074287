/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { RipetizioneMensile } from './ripetizioneMensile';
import { RipetizioneSettimanale } from './ripetizioneSettimanale';

export interface CalendarioView { 
    id?: string;
    ripetizioneSettimanale?: RipetizioneSettimanale;
    ripetizioneMensile?: RipetizioneMensile;
    dataInizio?: string;
    dataFine?: string;
    tipoPeriodicita?: CalendarioView.TipoPeriodicitaEnum;
}
export namespace CalendarioView {
    export type TipoPeriodicitaEnum = 'SETTIMANALE' | 'MENSILE';
    export const TipoPeriodicitaEnum = {
        SETTIMANALE: 'SETTIMANALE' as TipoPeriodicitaEnum,
        MENSILE: 'MENSILE' as TipoPeriodicitaEnum
    };
}