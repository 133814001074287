/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ExceptionResponseDTO } from '../model/exceptionResponseDTO';
import { GenericResponseDTO } from '../model/genericResponseDTO';
import { OperatoreView } from '../model/operatoreView';
import { RegistroGiornalieroOperatoreView } from '../model/registroGiornalieroOperatoreView';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class RegistroGiornalieroOperatoreService {

    protected basePath = 'https://api-dev-gestioneinterventi.southengineering.it/gestione-interventi';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Consente di scaricare il pdf contenente la lista dei registri giornalieri del giorno indicato.
     * 
     * @param data Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadListaRegistriGiornalieri(data: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadListaRegistriGiornalieri(data: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadListaRegistriGiornalieri(data: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadListaRegistriGiornalieri(data: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling downloadListaRegistriGiornalieri.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/registro-giornaliero-operatore/lista-registri-giornalieri`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di scaricare il pdf di un registro giornaliero.
     * 
     * @param data Data
     * @param id_operatore Id Operatore
     * @param fileUri 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadRegistro(data: string, id_operatore: string, fileUri: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public downloadRegistro(data: string, id_operatore: string, fileUri: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public downloadRegistro(data: string, id_operatore: string, fileUri: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public downloadRegistro(data: string, id_operatore: string, fileUri: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling downloadRegistro.');
        }

        if (id_operatore === null || id_operatore === undefined) {
            throw new Error('Required parameter id_operatore was null or undefined when calling downloadRegistro.');
        }

        if (fileUri === null || fileUri === undefined) {
            throw new Error('Required parameter fileUri was null or undefined when calling downloadRegistro.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }
        if (id_operatore !== undefined && id_operatore !== null) {
            queryParameters = queryParameters.set('id_operatore', <any>id_operatore);
        }
        if (fileUri !== undefined && fileUri !== null) {
            queryParameters = queryParameters.set('fileUri', <any>fileUri);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Blob>('get',`${this.basePath}/api/v1/registro-giornaliero-operatore/pdf`,
            {
             responseType: "blob" as "json",
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di rigenerare tutti i registri giornalieri operatore per un determinato giorno
     * 
     * @param idOperatore 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generaRegistriGiornalieriOperatoriForm(idOperatore?: string, data?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public generaRegistriGiornalieriOperatoriForm(idOperatore?: string, data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public generaRegistriGiornalieriOperatoriForm(idOperatore?: string, data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public generaRegistriGiornalieriOperatoriForm(idOperatore?: string, data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idOperatore !== undefined) {
            formParams = formParams.append('idOperatore', <any>idOperatore) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/registro-giornaliero-operatore/genera-registri`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di rigenerare un registro giornaliero operatore
     * 
     * @param idOperatore 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generaRegistroGiornalieroOperatoreForm(idOperatore?: string, data?: string, observe?: 'body', reportProgress?: boolean): Observable<GenericResponseDTO>;
    public generaRegistroGiornalieroOperatoreForm(idOperatore?: string, data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<GenericResponseDTO>>;
    public generaRegistroGiornalieroOperatoreForm(idOperatore?: string, data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<GenericResponseDTO>>;
    public generaRegistroGiornalieroOperatoreForm(idOperatore?: string, data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idOperatore !== undefined) {
            formParams = formParams.append('idOperatore', <any>idOperatore) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<GenericResponseDTO>('put',`${this.basePath}/api/v1/registro-giornaliero-operatore/genera`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Restituisce il registro giornalierio con eventuali filtri.
     * Il formato dei filtri di tipo LocalDate è &#x27;dd/MM/yyyy&#x27;. Il filtraggio per data è valido solo se il valore del filtro è in &#x27;dataInizioPrevistaDa&#x27;.
     * @param idOperatore 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRegistriGiornalieriOperatoreForm(idOperatore?: string, data?: string, observe?: 'body', reportProgress?: boolean): Observable<RegistroGiornalieroOperatoreView>;
    public getRegistriGiornalieriOperatoreForm(idOperatore?: string, data?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RegistroGiornalieroOperatoreView>>;
    public getRegistriGiornalieriOperatoreForm(idOperatore?: string, data?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RegistroGiornalieroOperatoreView>>;
    public getRegistriGiornalieriOperatoreForm(idOperatore?: string, data?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/x-www-form-urlencoded'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (idOperatore !== undefined) {
            formParams = formParams.append('idOperatore', <any>idOperatore) as any || formParams;
        }
        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }

        return this.httpClient.request<RegistroGiornalieroOperatoreView>('post',`${this.basePath}/api/v1/registro-giornaliero-operatore`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Consente di scaricare la lista degli operatori coinvolti nelle attività per il giorno in input.
     * 
     * @param data Data
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listaOperatori(data: string, observe?: 'body', reportProgress?: boolean): Observable<Array<OperatoreView>>;
    public listaOperatori(data: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<OperatoreView>>>;
    public listaOperatori(data: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<OperatoreView>>>;
    public listaOperatori(data: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling listaOperatori.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (data !== undefined && data !== null) {
            queryParameters = queryParameters.set('data', <any>data);
        }

        let headers = this.defaultHeaders;

        // authentication (bearer-token) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<OperatoreView>>('get',`${this.basePath}/api/v1/registro-giornaliero-operatore/operatori`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
