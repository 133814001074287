/**
 * Gestione Interventi API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

/**
 * Stati supportati di un Job.
 */
export type JobStatus = 'DA_AVVIARE' | 'IN_CORSO' | 'TERMINATO_CON_SUCCESSO' | 'FALLITO';

export const JobStatus = {
    DAAVVIARE: 'DA_AVVIARE' as JobStatus,
    INCORSO: 'IN_CORSO' as JobStatus,
    TERMINATOCONSUCCESSO: 'TERMINATO_CON_SUCCESSO' as JobStatus,
    FALLITO: 'FALLITO' as JobStatus
};